import React from 'react';
import { withStyles, WithStyles, Theme } from '@material-ui/core/styles';
import Dialog from '@material-ui/core/Dialog';
import {
  createStyles,
  Grid,
  Button,
  Typography,
  TextField,
  Paper
} from '@material-ui/core';
import { Dispatch } from 'redux';
import { IChangePasswordRequest } from '../../scenes/auth/types';
import { connect } from 'react-redux';
import { IStoreState } from '../../types';
import {
  IUpdateNotification,
  fireNotification
} from '../../actions/Notification.action';
import INotificationInfo from '../../types/Notification.type';
import {
  MESSAGE_NOTIFICATION_ERROR,
  MESSAGE_NOTIFICATION_SUCCESS
} from '../../constants/Notification.constant';
import Variant from '../../types/Variant.type';
import Axios from 'axios';

interface IProps extends WithStyles<typeof styles> {
  showDialog: boolean;
  handleCloseDialog: () => void;
  isCode: boolean;
  code: string;
  fireNotification: (noti: INotificationInfo) => void;
}

interface IState {
  code: string;
  curPassword: string;
  newPassword: string;
  rePassword: string;
}
const styles = (theme: Theme) =>
  createStyles({
    dialogTitle: {
      height: 100,
      padding: 0,
      [theme.breakpoints.down('xs')]: {
        height: 70
      }
    },
    closeButton: {
      position: 'absolute',
      right: 30,
      top: 28,
      color: 'black'
    },
    dialogContent: {
      padding: 20
    },
    dialogActions: {
      height: 100,
      padding: '30px 0',
      borderTop: '1px solid #DCE0E4',
      [theme.breakpoints.down('xs')]: {
        padding: 20
      }
    },
    buttonYesWithdraw: {
      width: 255,
      height: 50,
      textTransform: 'capitalize',
      [theme.breakpoints.down('xs')]: {
        flex: 1
      }
    },
    buttonNoWithdraw: {
      width: 255,
      height: 50,
      textTransform: 'capitalize',
      backgroundColor: '#C5021C',
      '&:hover': {
        backgroundColor: '#C5021C'
      },
      [theme.breakpoints.down('xs')]: {
        flex: 1
      }
    },
    textWithDraw: {
      fontSize: 20,
      color: '#000000',
      lineHeight: '24px',
      fontWeight: 600
    },
    code: {
      textAlign: 'center',
      color: '#222222',
      fontFamily: 'Montserrat',
      fontStyle: 'normal',
      fontWeight: 'normal',
      fontSize: 40,
      lineHeight: '40px',
      letterSpacing: '0.3em',
      [theme.breakpoints.down('xs')]: {
        fontSize: 20
      }
    },
    buttonCanel: {
      color: '#222222',
      border: '1px solid #DCE0E4',
      width: 195,
      textTransform: 'capitalize',
      marginRight: 5,
      backgroundColor: '#FFFFFF',
      '&:hover': {
        backgroundColor: '#FFFFFF'
      },
      [theme.breakpoints.down('xs')]: {
        flex: 1
      }
    },
    buttonLogin: {
      width: 195,
      textTransform: 'capitalize',
      marginLeft: 5,
      [theme.breakpoints.down('xs')]: {
        flex: 1
      }
    },

    rootDialog: {
      display: 'block',
      '& .MuiDialog-paperWidthXs': {
        height: 'auto'
      },
      [theme.breakpoints.down('sm')]: {
        width: '80%',
        padding: '0 10%'
      }
    },
    textFiled: {
      height: 70,
      marginTop: 30,
      [theme.breakpoints.down('xs')]: {
        height: 50
      }
    },
    codeFiled: {
      height: 70,
      [theme.breakpoints.down('xs')]: {
        height: 40
      }
    },
    inputFiled: {
      height: '100%',
      backgroundColor: '#FAFAFB'
    }
  });

class PasswordChange extends React.Component<IProps, IState> {
  public state: IState = {
    code: '',
    curPassword: '',
    rePassword: '',
    newPassword: ''
  };

  public handleTextChange = (key: string) => (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const value = event.target.value;
    if (key === 'code' && (value.length > 6 || value.match(/\D/g))) {
      return;
    }
    this.setState({ [key]: value } as any);
  };

  clearText = () => {
    this.setState({
      code: '',
      curPassword: '',
      rePassword: '',
      newPassword: ''
    });
  };

  closeDialog = () => {
    this.clearText();
    this.props.handleCloseDialog();
  };

  handleChangePassword = () => {
    const { code, rePassword, curPassword, newPassword } = this.state;
    const { isCode } = this.props;
    if (isCode && this.state.code === this.props.code) {
      return this.props.fireNotification({
        message: MESSAGE_NOTIFICATION_ERROR.VERIFY_CODE_INVALID,
        variant: Variant.ERROR
      });
    }
    const request: IChangePasswordRequest = {
      code,
      password: curPassword,
      newPassword,
      reNewPassword: rePassword
    };
    Axios.post('/users/change-password', request)
      .then(() => {
        this.props.fireNotification({
          message: MESSAGE_NOTIFICATION_SUCCESS.RESET_PASSWORD,
          variant: Variant.SUCCESS
        });
        this.closeDialog();
      })
      .catch(err => {
        const error =
          err.response &&
          err.response.data &&
          err.response.data.error &&
          err.response.data.error.message;
        this.props.fireNotification({
          message: error
            ? error
            : MESSAGE_NOTIFICATION_ERROR.VERIFY_CODE_INVALID,
          variant: Variant.ERROR
        });
      });
  };

  public render() {
    const { showDialog, classes, isCode } = this.props;
    const { curPassword, rePassword, newPassword, code } = this.state;
    return (
      <Dialog
        onClose={this.closeDialog}
        aria-labelledby="customized-dialog-title"
        open={showDialog}
        maxWidth="xs"
        className={classes.rootDialog}
        data-test="dialog-changepassword"
      >
        <Paper elevation={1}>
          <Grid className={classes.dialogTitle}>
            <Grid
              container
              justify="center"
              alignItems="center"
              style={{
                height: '100%',
                position: 'relative',
                borderBottom: '1px solid #DCE0E4'
              }}
            >
              <Typography variant="h2">Change Password</Typography>
            </Grid>
          </Grid>
          <Grid
            container
            justify="center"
            alignContent="center"
            className={classes.dialogContent}
          >
            <TextField
              data-test="current-password"
              label="Current Password"
              variant="outlined"
              type="password"
              onChange={this.handleTextChange('curPassword')}
              value={curPassword}
              error={curPassword.length > 0 && curPassword.length < 6}
              helperText={
                curPassword.length > 0 && curPassword.length < 6
                  ? 'The password must be at least 6 characters'
                  : ''
              }
              className={classes.textFiled}
              style={{ marginTop: 10 }}
            />
            <TextField
              data-test="new-password"
              label="New Password"
              error={newPassword.length > 0 && newPassword.length < 6}
              helperText={
                newPassword.length > 0 && newPassword.length < 6
                  ? 'The password must be at least 6 characters'
                  : ''
              }
              variant="outlined"
              value={newPassword}
              type="password"
              onChange={this.handleTextChange('newPassword')}
              className={classes.textFiled}
            />
            <TextField
              data-test="re-password"
              label="Confirm Password"
              variant="outlined"
              error={(rePassword && newPassword !== rePassword) || false}
              helperText={
                rePassword && newPassword !== rePassword
                  ? 'The password confirmation does not match'
                  : undefined
              }
              type="password"
              value={rePassword}
              onChange={this.handleTextChange('rePassword')}
              className={classes.textFiled}
            />
            {this.props.isCode ? (
              <Grid container justify={'center'} alignItems={'center'}>
                <Typography
                  variant="subtitle2"
                  style={{
                    marginTop: 40,
                    marginBottom: 9,
                    textAlign: 'center'
                  }}
                >
                  Enter the two-step Authentication code
                </Typography>
                <TextField
                  className={classes.codeFiled}
                  InputProps={{
                    className: classes.inputFiled
                  }}
                  value={code}
                  placeholder={'_ _ _ _ _ _'}
                  inputProps={{
                    className: classes.code
                  }}
                  onChange={this.handleTextChange('code')}
                  variant="outlined"
                />{' '}
              </Grid>
            ) : null}
          </Grid>
          <Grid
            container
            justify="center"
            alignItems="center"
            style={{ height: 120 }}
            className={classes.dialogActions}
          >
            <Button
              variant="contained"
              color="primary"
              size="large"
              className={classes.buttonCanel}
              onClick={this.closeDialog}
            >
              <span style={{ color: '#222222' }}> Cancel</span>
            </Button>
            <Button
              data-test="btn-changepassword"
              variant="contained"
              color="primary"
              size="large"
              disabled={
                this.state.curPassword.length < 6 ||
                this.state.newPassword !== this.state.rePassword ||
                this.state.newPassword.length < 6 ||
                (isCode && this.state.code.length < 6)
              }
              onClick={this.handleChangePassword}
              className={classes.buttonLogin}
            >
              Change
            </Button>
          </Grid>
        </Paper>
      </Dialog>
    );
  }
}

const mapDisPatchToProps = (dispatch: Dispatch<IUpdateNotification>) => {
  return {
    fireNotification: (noti: INotificationInfo) =>
      dispatch(fireNotification(noti))
  };
};

const mapStateToProps = ({ code }: IStoreState) => {
  return { code };
};
export default connect(
  mapStateToProps,
  mapDisPatchToProps
)(withStyles(styles)(PasswordChange));
