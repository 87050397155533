import * as React from 'react';
import {
  WithStyles,
  withStyles,
  createStyles,
  Theme
} from '@material-ui/core/styles';
import {
  Paper,
  Grid,
  Typography,
  FormControl,
  TextField
} from '@material-ui/core';

import { IStoreState } from '../types';
import { connect } from 'react-redux';
import { IUserProfile } from '../scenes/setting/types';

interface IProps extends WithStyles<typeof styles> {
  profile: IUserProfile;
}

const styles = (theme: Theme) =>
  createStyles({
    pageProfile: {
      height: 425,
      [theme.breakpoints.down('xs')]: {
        height: 200
      }
    },
    contentProfile: {
      height: 70,
      paddingLeft: 26,
      paddingRight: 26,
      borderBottom: '1px solid #F1F1F4',
      [theme.breakpoints.down('sm')]: {
        height: 50
      }
    },
    buttonEditProfile: {
      width: 120,
      height: 30,
      border: '1px solid #E6E6E6'
    },
    labelButton: {
      color: '#9BA0A6',
      textTransform: 'capitalize'
    },
    outlineButton: {
      padding: '0px 15px'
    },
    inputProfile: {
      padding: '29px 29px 0px 29px'
    },
    textField: {
      width: '100%',
      backgroundColor: '#FFFFFF',
      textTransform: 'uppercase',
      '& :disabled': {
        color: '#000000'
      }
    },
    formControl: {
      marginTop: 20
    },
    inputLabelProps: {
      color: '#9BA0A6 !important',
      fontSize: 12,
      fontWeight: 600,
      lineHeight: '12px',
      textTransform: 'uppercase'
    },
    keyboardDatePicker: {
      width: '100%',
      backgroundColor: '#FFFFFF',
      textTransform: 'uppercase',
      '& :disabled': {
        color: '#000000'
      }
    }
  });

class CardProfile extends React.Component<IProps> {
  shouldComponentUpdate(nextProps: IProps) {
    return (
      JSON.stringify(nextProps.profile) !== JSON.stringify(this.props.profile)
    );
  }
  public render() {
    const { classes, profile } = this.props;
    return (
      <Paper elevation={0} className={classes.pageProfile}>
        <Grid container alignItems="center" className={classes.contentProfile}>
          <Grid container justify="flex-start" alignItems="center" item xs={6}>
            <Typography variant="h3">Profile</Typography>
          </Grid>
        </Grid>
        <Grid className={classes.inputProfile}>
          <FormControl
            required={true}
            fullWidth={true}
            className={classes.formControl}
          >
            <TextField
              InputLabelProps={{
                className: classes.inputLabelProps
              }}
              className={classes.textField}
              value={profile.email}
              label="Email address"
              disabled
              InputProps={{
                disableUnderline: true,
                inputProps: {
                  'data-test': 'setting-email'
                }
              }}
            />
          </FormControl>
        </Grid>
      </Paper>
    );
  }
}

const mapStateToProps = ({ user }: IStoreState) => {
  return {
    profile: user.profile
  };
};

export default connect(mapStateToProps, {})(withStyles(styles)(CardProfile));
