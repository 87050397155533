import {
  IPaymentSumary,
  initPaymentSumary,
  IConfigInfo,
  initConfigInfo,
  INftData,
  IPaymentHistoryContainer,
  initMetaData
} from '../types';
import {
  ISetRankInfo,
  ISetPaymentSumaryAction,
  ISetNftListAction,
  ISetTomoPriceAction,
  ISetPaymentHistoryAction
} from '../actions/payment.action';
import {
  SET_RANK_INFO,
  SET_PAYMENT_SUMARY,
  SET_NFT_LIST,
  SET_TOMO_PRICE
} from '../constants/payment.constant';

export const paymentSumary = (
  state: IPaymentSumary = initPaymentSumary,
  action: ISetPaymentSumaryAction
): IPaymentSumary => {
  if (action.type === SET_PAYMENT_SUMARY) {
    return action.payload;
  }
  return state;
};

export const rankInfo = (
  state: IConfigInfo[] = [],
  action: ISetRankInfo
): IConfigInfo[] => {
  if (action.type === SET_RANK_INFO) {
    return action.payload;
  }
  return state;
};

export const nftPayment = (
  state: INftData = {
    data: [],
    meta: {
      currentPage: 0,
      limit: 0,
      offset: 0,
      pageCount: 0,
      total: 0
    }
  },
  action: ISetNftListAction
): INftData => {
  if (action.type === SET_NFT_LIST) {
    return action.payload;
  }
  return state;
};

export const tomoPrice = (
  state: IConfigInfo = initConfigInfo,
  action: ISetTomoPriceAction
): IConfigInfo => {
  if (action.type === SET_TOMO_PRICE) {
    return action.payload;
  }
  return state;
};

export const paymentHistory = (
  state: IPaymentHistoryContainer = {
    data: [],
    meta: initMetaData
  },
  action: ISetPaymentHistoryAction
): IPaymentHistoryContainer => {
  if (action.type === SET_PAYMENT_SUMARY) {
    return action.payload;
  }
  return state;
};
