import {
  IGetTableData,
  GET_TABLE_DATA,
  setTableData,
  setLoadingAction
} from '../actions';
import { Dispatch } from 'react';
import Axios from 'axios';
import { ITableStoreState } from '../types';
import { Store } from 'redux';

export const tableMiddleware = (store: Store<ITableStoreState>) => (
  dispatch: Dispatch<IGetTableData>
) => (action: IGetTableData) => {
  switch (action.type) {
    case GET_TABLE_DATA: {
      store.dispatch(setLoadingAction(true));
      Axios.get(action.payload, { params: action.params })
        .then(res => {
          store.dispatch(setTableData(res.data));
          store.dispatch(setLoadingAction(false));
        })
        .catch(() => store.dispatch(setLoadingAction(false)));
      break;
    }
  }
  return dispatch(action);
};
