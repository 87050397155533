import React from 'react';
import Profile from '.';
import {
  Typography,
  Grid,
  createStyles,
  WithStyles,
  withStyles,
  Button,
  Paper,
  Snackbar,
  Theme
} from '@material-ui/core';
import IconAddress from '../IconAdress.component';
import information_icon from '../../../assets/images/information_icon.svg';
import copy_icon from '../../../assets/images/copy_icon.svg';
import LinkAddress from '../LinkAddress.component';
import { PATH } from '../../../constants/Path.constant';
import { History } from 'history';
import AvatarComponent from './Avatar.component';
import classNames from 'classnames';
import { IUserProfile } from '../../../scenes/setting/types';
import { ROLE } from '../../../constants/auth.constant';
import admin_icon from '../../../assets/images/admin.svg';
import agent_icon from '../../../assets/images/agent.svg';
import MediaQuery from 'react-responsive';
const styles = (theme: Theme) =>
  createStyles({
    tomoText: {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      fontWeight: 500,
      fontFamily: 'Montserrat',
      lineHeight: '15px',
      fontSize: 12,
      color: '#222222',
      '& span': {
        color: '#E3001E',
        margin: '0 auto',
        display: 'flex',
        alignItems: 'center'
      },
      '& a': {
        color: '#3DACEB'
      },
      '& img': {
        width: 12,
        height: 12,
        marginLeft: 6,
        alignItems: 'center'
      },
      [theme.breakpoints.up(1020)]: {
        [theme.breakpoints.down(1279)]: {
          fontSize: 9
        }
      }
    },
    root: {
      '&:after': {
        content: "''",
        backgroundColor: '#00AFC1',
        boxShadow: '0px 0px 30px rgba(0, 0, 0, 0.1)',
        borderRadius: '0px 0px 3px 3px',
        width: 200,
        height: 3
      }
    },
    referText: {
      fontWeight: 500,
      fontStyle: 'normal',
      fontFamily: 'Montserrat',
      lineHeight: '17px',
      fontSize: 14,
      color: '#00AFC1',
      [theme.breakpoints.up(1020)]: {
        [theme.breakpoints.down(1371)]: {
          fontSize: 12
        }
      }
    },
    referCode: {
      fontWeight: 500,
      fontStyle: 'normal',
      fontFamily: 'Montserrat',
      lineHeight: '15px',
      fontSize: 12,
      textTransform: 'none',
      color: '#444444'
    },
    menu: {
      backgroundColor: '#FFFFFF',
      boxShadow: '0px 0px 30px rgba(0, 0, 0, 0.1)',
      borderRadius: 3
    },
    itemProfile: {
      paddingBottom: 12,
      borderBottom: '1px dashed #E3E3E3'
    },
    itemSignout: {
      borderBottom: '3px solid #00AFC1',
      boxShadow: '0px 0px 30px rgba(0, 0, 0, 0.1)',
      borderRadius: '0px 0px 3px 3px'
    },
    infoSection: {
      paddingLeft: 20
    },
    referButton: {
      border: '1px solid #DBDEE2',
      boxSizing: 'border-box',
      borderRadius: 15,
      height: 30
    },
    rank: {
      display: 'flex',
      alignItems: 'center',
      paddingLeft: 25,
      [theme.breakpoints.down('xs')]: {
        paddingLeft: 15
      }
    },
    rankText: {
      fontWeight: 500,
      fontStyle: 'normal',
      fontFamily: 'Montserrat',
      lineHeight: '14px',
      fontSize: 10,
      textAlign: 'center',
      color: '#FFFFFF',
      textTransform: 'uppercase'
    },
    referCodeLayout: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'flex-end',
      [theme.breakpoints.down('sm')]: {
        marginTop: 15,
        alignItems: 'flex-start'
      }
    }
  });

interface IProps extends WithStyles<typeof styles> {
  history: History;
  profile: IUserProfile;
  role: ROLE;
}

interface IState {
  anchorEl: null | HTMLElement;
  openCopy: boolean;
}

class AgentProfile extends React.Component<IProps, IState> {
  state: IState = {
    anchorEl: null,
    openCopy: false
  };

  handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    this.setState({ anchorEl: event.currentTarget });
  };

  handleClose = () => {
    this.setState({
      anchorEl: null,
      openCopy: false
    });
  };
  handleProfileAccount = () => {
    this.setState({ anchorEl: null });
    const path =
      this.props.role === ROLE.AGENT
        ? PATH.agent.settings
        : PATH.admin.settings;
    this.props.history.push(path);
  };
  public copyAddress = (value: string) => () => {
    const textArea = document.createElement('textarea');
    document.body.appendChild(textArea);
    textArea.value = value;
    textArea.select();
    document.execCommand('copy');
    textArea.remove();
    this.setState({ openCopy: true });
  };

  public caculateValueSlider = () => {
    const { profile } = this.props;
    let rank: number = 1;
    if (profile && profile.commission) {
      const value =
        ((profile.commission.currentTotal / profile.commission.nextTotal) *
          25) /
          5 +
        profile.commission.currentLevel;
      if (value < 10) {
        rank = 0;
      } else if (value >= 10 && value < 15) {
        rank = 1;
      } else if (value >= 15 && value < 20) {
        rank = 2;
      } else if (value >= 20 && value < 25) {
        rank = 3;
      } else {
        rank = 4;
      }
    }
    return rank;
  };

  render() {
    const { classes, profile, role } = this.props;
    const url = role === ROLE.ADMIN ? admin_icon : agent_icon;
    return (
      <React.Fragment>
        <Profile
          role={role}
          avatar={<AvatarComponent url={url} />}
          profileName={
            <Grid container style={{ minHeight: 25 }} data-test="profile-user">
              {profile.username && (
                <Grid item>
                  <Typography variant="h2" className={classes.infoSection}>
                    {profile.username}
                  </Typography>
                </Grid>
              )}

              <Grid item className={classes.rank}>
                <Paper
                  style={{
                    width: 57,
                    height: 14,
                    backgroundColor: '#9BA0A6'
                  }}
                >
                  <Typography className={classes.rankText}>
                    {role === ROLE.AGENT
                      ? 'Rank ' + this.caculateValueSlider()
                      : 'ADMIN'}
                  </Typography>
                </Paper>
              </Grid>
            </Grid>
          }
          address={
            <Grid container alignItems="center" style={{ marginTop: 5 }}>
              <div
                data-test="profile-address"
                className={classNames(classes.tomoText, classes.infoSection)}
              >
                <span style={{ color: '#222222', whiteSpace: 'nowrap' }}>
                  TOMO Address:&nbsp;
                </span>
                {profile.tomoAddress ? (
                  <LinkAddress address={''} text={profile.tomoAddress} />
                ) : (
                  <Grid container alignItems="center">
                    <MediaQuery minDeviceWidth={768}>
                      <span>
                        Your TOMO address has been setup yet. click&nbsp;
                      </span>
                      <LinkAddress
                        text={'here'}
                        address={
                          role === ROLE.AGENT
                            ? PATH.agent.settings
                            : PATH.admin.settings
                        }
                      />
                      &nbsp;
                      <span> to setup your wallet.</span>
                      <IconAddress url={information_icon} />
                    </MediaQuery>
                    <MediaQuery maxDeviceWidth={760}>
                      <LinkAddress
                        text={'click here '}
                        address={
                          role === ROLE.AGENT
                            ? PATH.agent.settings
                            : PATH.admin.settings
                        }
                      />
                      <span style={{ paddingLeft: 3 }}>
                        to setup your wallet.
                      </span>
                    </MediaQuery>
                  </Grid>
                )}
              </div>
            </Grid>
          }
          referCode={
            role === ROLE.AGENT ? (
              <Grid container className={classes.referCodeLayout}>
                <Grid>
                  <Typography className={classes.referText}>
                    {
                      'Invite new member with your referral code and get up to 25% commission'
                    }
                  </Typography>
                </Grid>
                <Grid style={{ paddingTop: 7 }}>
                  <Button
                    data-test="profile-code"
                    className={classes.referButton}
                    onClick={this.copyAddress(profile.invationCode)}
                  >
                    <Typography
                      className={classes.referCode}
                    >{`Referral Code : ${profile.invationCode}`}</Typography>
                    <img
                      src={copy_icon}
                      alt=""
                      width={10}
                      height={10}
                      style={{ paddingLeft: 10 }}
                    />
                  </Button>
                </Grid>
              </Grid>
            ) : null
          }
        />
        <Snackbar
          anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
          open={this.state.openCopy}
          onClose={this.handleClose}
          autoHideDuration={2000}
          ContentProps={{
            'aria-describedby': 'message-id'
          }}
          message="Copied to Clipboard"
        />
      </React.Fragment>
    );
  }
}

export default withStyles(styles)(AgentProfile);
