export const GET_USER_PROFILE = 'GET_USER_PROFILE';
export type GET_USER_PROFILE = typeof GET_USER_PROFILE;

export const SET_USER_PROFILE = 'SET_USER_PROFILE';
export type SET_USER_PROFILE = typeof SET_USER_PROFILE;

export const GET_COUNTRIES = 'GET_COUNTRIES';
export type GET_COUNTRIES = typeof GET_COUNTRIES;

export const SET_COUNTRIES = 'SET_COUNTRIES';
export type SET_COUNTRIES = typeof SET_COUNTRIES;

export const GET_QR_IMAGE = 'GET_QR_IMAGE';
export type GET_QR_IMAGE = typeof GET_QR_IMAGE;

export const SET_QR_IMAGE = 'SET_QR_IMAGE';
export type SET_QR_IMAGE = typeof SET_QR_IMAGE;

export const UPDATE_USER_PROFILE = 'UPDATE_USER_PROFILE';
export type UPDATE_USER_PROFILE = typeof UPDATE_USER_PROFILE;

export const SET_SECOND_FACTOR = 'SET_SECOND_FACTOR';
export type SET_SECOND_FACTOR = typeof SET_SECOND_FACTOR;

export const VERIFY_ACCOUNT = 'VERIFY_ACCOUNT';
export type VERIFY_ACCOUNT = typeof VERIFY_ACCOUNT;

export const UPDATE_AVATAR = 'UPDATE_AVATAR';
export type UPDATE_AVATAR = typeof UPDATE_AVATAR;

export const UPDATE_USER_BY_ID = 'UPDATE_USER_BY_ID';
export type UPDATE_USER_BY_ID = typeof UPDATE_USER_BY_ID;
