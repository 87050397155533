export enum FEE {
  ADMIN_CHARGE = 'Administration Charge',
  ASSET_MANAGEMENT_FEE = 'Asset Management Fee',
  INITIAL_KICKBACK = 'Init Kickback',
  TERM_KICKBACK = 'Term Kickback'
}

export enum MINIMUM_CONTRIBUTE {
  LEVEL1 = 10000,
  LEVEL2 = 30000,
  LEVEL3 = 50000,
  LEVEL4 = 100000,
  LEVEL5 = 500000,
  LEVEL6 = 1000000
}

export enum CONFIRM_MESSAGES {
  CHANGES_CONFIRM = 'Are you sure you want to save changes ?'
}
