import * as React from 'react';
import {
  WithStyles,
  withStyles,
  createStyles,
  Theme
} from '@material-ui/core/styles';
import { Grid, Button } from '@material-ui/core';
import iconLogo from '../assets/images/iconLogoLogin.svg';
import signout_icon from '../assets/images/signout.svg';
import { Dispatch } from 'redux';
import { ILogoutAction, logoutAction } from '../scenes/auth/actions/auth';
import { connect } from 'react-redux';
import MediaQuery from 'react-responsive';
interface IProps extends WithStyles<typeof styles> {
  logout: () => void;
}

const token = localStorage.getItem('token');

const styles = (theme: Theme) =>
  createStyles({
    backgroundHeader: {
      backgroundColor: '#000000',
      height: 70,
      position: 'relative',
      [theme.breakpoints.down('xs')]: {
        height: 50,
        display: 'flex',
        justify: 'center',
        alignItems: 'center'
      }
    },
    logout: {
      border: '1px solid #36383C',
      boxSizing: 'border-box',
      borderRadius: 3,
      marginRight: 60,
      [theme.breakpoints.down('sm')]: {
        marginRight: 30,
        [theme.breakpoints.down('xs')]: {
          border: 'none',
          marginRight: 0
        }
      }
    },
    labelButtonNoActive: {
      textTransform: 'capitalize',
      color: '#7F8485'
    },
    textButton: {
      padding: '6px 20px',
      [theme.breakpoints.down('xs')]: {
        padding: 0
      }
    },
    logo: {
      [theme.breakpoints.down('xs')]: {
        height: 30,
        width: 50
      }
    }
  });

class LogoHeader extends React.Component<IProps> {
  public render() {
    const { classes } = this.props;
    return (
      <>
        <Grid
          container
          justify="center"
          alignItems="center"
          className={classes.backgroundHeader}
        >
          <img
            className={classes.logo}
            style={{ flex: 1 }}
            src={iconLogo}
            alt="logo header"
            width={150}
            height={50}
          />
          {token && (
            <Grid className={classes.logout}>
              <Button
                classes={{
                  text: classes.textButton
                }}
                onClick={this.props.logout}
                endIcon={
                  <img
                    src={signout_icon}
                    width={12}
                    height={12}
                    alt=""
                    style={{ paddingLeft: 10, color: 'red' }}
                  />
                }
              >
                <MediaQuery minDeviceWidth={760}>
                  <span className={classes.labelButtonNoActive}>
                    {'Sign out'}
                  </span>
                </MediaQuery>
              </Button>
            </Grid>
          )}
        </Grid>
      </>
    );
  }
}

const mapDispatchToProps = (dispatch: Dispatch<ILogoutAction>) => ({
  logout: () => dispatch(logoutAction())
});
export default connect(
  undefined,
  mapDispatchToProps
)(withStyles(styles)(LogoHeader));
