export const PATH = {
  home: '/',
  notFound: '/not-found',
  login: '/login',
  forgotPassword: '/forgot-password',
  resetPassword: '/reset-password',

  agent: {
    verify: '/agent/verify',
    settings: '/agent/settings',
    dashboard: '/agent/dashboard',
    paymentHistory: '/agent/payment-history',
    signup: '/signup-agent',
    chart: '/chart'
  },
  admin: {
    top: '/admin/top',
    signup: '/signup-trading',
    trading: '/admin/trading',
    summary: '/admin/summary',
    paymentHistory: '/admin/payment-history',
    settings: '/admin/settings',
    agentManagment: '/admin/agent',
    configs: '/admin/configs'
  }
};
