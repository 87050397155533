import React from 'react';
import {
  Paper,
  createStyles,
  withStyles,
  WithStyles,
  Grid,
  Typography
} from '@material-ui/core';
import classNames from 'classnames';
import { INFTPayment } from '../types';
import { formatDate, formatNumberToFixed } from '../../../helper';

const styles = () =>
  createStyles({
    '@keyframes fadeInDown': {
      '0%': {
        opacity: 0,
        transform: 'translateY(-20px)'
      },
      '100%': {
        opacity: 1,
        transform: 'translateY(0)'
      }
    },
    container: {
      width: '100%',
      marginTop: 20,
      animation: `$fadeInDown 1500ms ease-in-out`
    },
    rootLayout: {
      padding: 15,
      width: '100%',
      height: 170,
      display: 'flex',
      flexDirection: 'column',
      boxSizing: 'border-box'
    },
    itemRoot: {
      flex: 1
    },
    item: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center'
    },
    title: {
      fontSize: 10,
      lineHeight: '18px',
      fontWeight: 600,
      color: '#9BA0A6',
      textTransform: 'uppercase'
    },
    value: {
      fontSize: 12,
      lineHeight: '20px',
      fontWeight: 500,
      color: '#222222'
    },
    floatRight: {
      alignItems: 'flex-end'
    },
    fadeIn: {
      opacity: 1,
      transform: 'translateY(0)'
    }
  });

interface IProps extends WithStyles<typeof styles> {
  nftData: INFTPayment;
}

class NftCard extends React.Component<IProps> {
  render() {
    const { classes, nftData } = this.props;
    return (
      <Paper elevation={0} className={classes.container}>
        <Grid className={classes.rootLayout}>
          <Grid container className={classes.itemRoot}>
            <Grid item xs={6}>
              <Grid className={classes.item}>
                <Typography className={classes.title}>bought volume</Typography>
                <Typography
                  className={classes.value}
                  style={{ color: '#00AFC1', textTransform: 'uppercase' }}
                >
                  {nftData.nft && formatNumberToFixed(nftData.nft.priceTomo, 2)}
                  {' TOMO'}
                </Typography>
              </Grid>
            </Grid>
            <Grid item xs={6}>
              <Grid className={classNames(classes.item, classes.floatRight)}>
                <Typography className={classes.title}>NFT No</Typography>
                <Typography
                  style={{ fontWeight: 'bold' }}
                  className={classes.value}
                >
                  {nftData.nft && nftData.nft.name}
                </Typography>
              </Grid>
            </Grid>
          </Grid>
          <Grid container className={classes.itemRoot}>
            <Grid item xs={12} className={classes.item}>
              <Typography className={classes.title}>Buyer</Typography>
              <Typography
                style={{ fontWeight: 'bold' }}
                className={classes.value}
              >
                {nftData.user && nftData.user.username}
              </Typography>
            </Grid>
          </Grid>
          <Grid container className={classes.itemRoot}>
            <Grid item xs={6}>
              <Grid className={classes.item}>
                <Typography className={classes.title}>Join date</Typography>
                <Typography className={classes.value}>
                  {formatDate(nftData.boughtDate)}
                </Typography>
              </Grid>
            </Grid>
            <Grid item xs={6}>
              <Grid className={classNames(classes.item, classes.floatRight)}>
                <Typography className={classes.title}>expire date</Typography>
                <Typography className={classes.value}>
                  {formatDate(nftData.expireDate)}
                </Typography>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Paper>
    );
  }
}

export default withStyles(styles)(NftCard);
