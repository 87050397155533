import { Styles } from 'jss';
import { Theme, createStyles } from '@material-ui/core';

export interface ITableStoreState {
  tableData: IResponseData;
  loading: boolean;
}

export interface ISortColumn {
  key: string;
  order?: string;
  type: 'number' | 'date' | 'string';
}
export interface ITableHeader {
  label: string;
  key: string;
  styles?: Styles;
  postFix?: string;
  preFix?: string;
  sortable?: ISortColumn;
  width?: string;
  format?: (data?: any) => void;
  align?: 'right' | 'center' | 'left';
  link?: boolean;
  hash?: string;
  editing?: boolean;
}

export interface ITableSettings {
  tableColumn: ITableHeader[];
  pagination?: IPagination;
}

export interface IMetaData {
  total: number;
  pageCount: number;
  limit: number;
  offset: number;
  currentPage: number;
}

export interface IPagination {
  show: boolean;
  option: IPaginationOption;
}

export interface IPaginationOption {
  rowsPerPage: number;
}
export interface IResponseData {
  data: object[];
  meta: IMetaData;
}

export interface ISearchInfo {
  key: string;
  text: string;
}

export const tableStyles = (theme: Theme) =>
  createStyles({
    root: {
      width: '100%'
    },
    tableWrapper: {
      // minHeight: 561,
      overflow: 'auto'
    },
    tableHead: {
      background: '#FFFFFF',
      borderRadius: '5px 5px 0px 0px'
    },
    text: {
      fontFamily: 'Montserrat',
      fontStyle: 'normal'
    },
    headerText: {
      fontWeight: 600,
      fontSize: 12,
      lineHeight: '15px',
      textTransform: 'uppercase',
      color: '#000000',
      [theme.breakpoints.up(1020)]: {
        [theme.breakpoints.down(1371)]: {
          fontSize: 10
        }
      }
    },
    firstColumn: {
      fontWeight: 500,
      fontSize: 14,
      lineHeight: '17px',
      color: '#222222',
      paddingLeft: 20
    },
    labelPagination: {
      fontWeight: 500,
      fontSize: 12,
      lineHeight: '15px',
      color: '#A1A9B4'
    },
    pagination: {
      display: 'flex',
      borderTop: '1px solid #F1F1F4'
    },
    borderBottom: {
      borderBottom: '1px solid #F1F1F4'
    },
    tableValue: {
      paddingRight: 20
    },
    border: {
      borderBottom: 'none'
    },
    iconCenter: {
      marginBottom: -5
    }
  });
