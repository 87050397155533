import { createMuiTheme } from '@material-ui/core/styles';
import slider_thumb from '../assets/images/slider_thumb.jpg';
import rank_icon from '../assets/images/rank_icon.svg';

const theme = createMuiTheme({
  props: {
    MuiTypography: {
      align: 'left'
    }
  },
  direction: 'ltr',
  typography: {
    fontFamily: ['Montserrat', 'Roboto'].join(','),
    fontSize: 12,
    h1: {
      fontStyle: 'normal',
      fontSize: 30,
      fontWeight: 500,
      color: '#222222'
    },
    h2: {
      fontSize: 24,
      color: '#222222',
      fontWeight: 600,
      lineHeight: '29px',
      fontStyle: 'normal'
    },
    h3: {
      fontSize: 20,
      color: '#222222',
      fontWeight: 600,
      lineHeight: '24px'
    },
    h4: {
      color: '#9BA0A6',
      fontSize: 12,
      fontWeight: 400,
      fontStyle: 'normal',
      lineHeight: '15px'
    },
    subtitle2: {
      fontSize: 14,
      color: '#222222',
      fontWeight: 600,
      fontStyle: 'normal',
      lineHeight: '17px'
    },
    subtitle1: {
      color: '#9BA0A6',
      fontSize: 10,
      fontWeight: 600,
      fontStyle: 'normal',
      textTransform: 'uppercase',
      lineHeight: '12px'
    },
    button: {
      fontWeight: 600,
      fontStyle: 'normal',
      fontSize: 12
    }
  },
  palette: {
    primary: {
      main: '#00AFC1'
    },
    secondary: {
      main: '#36BCA4'
    }
  },

  overrides: {
    MuiFormLabel: {
      root: {
        fontWeight: 500
      }
    },

    MuiOutlinedInput: {
      adornedEnd: {
        paddingRight: 0,
        height: '100%'
      }
    },
    MuiButton: {
      root: {
        borderRadius: 3
      },
      containedPrimary: {
        backgroundColor: '#00AFC1',
        '&:hover': {
          backgroundColor: '#0095A5'
        },
        '&:active': {
          backgroundColor: '#006A75'
        },
        '&:disabled': {
          backgroundColor: '#DCE0E4'
        }
      },

      containedSecondary: {
        backgroundColor: '#36BCA4',
        '&:hover': {
          backgroundColor: '#239D87'
        },
        '&:active': {
          backgroundColor: '#127664'
        },
        '&:disabled': {
          backgroundColor: '#DCE0E4'
        }
      },
      sizeLarge: {
        width: 400,
        height: 50,
        fontSize: 14,
        backgroundColor: '#00AFC1'
      },
      disabled: {},
      contained: {
        boxShadow: 'initial',
        '&:hover': {
          boxShadow: 'none'
        }
      },

      label: {
        color: '#FFFFFF',
        fontFamily: 'Montserrat',
        fontWeight: 600,
        fontSize: 12,
        fontStyle: 'normal'
      },

      sizeSmall: {
        width: 140,
        height: 30
      }
    },
    MuiTextField: {
      root: {
        fontFamily: 'Montserrat',
        fontSize: 14,
        fontStyle: 'normal',
        color: '#9BA0A6',
        fontWeight: 600,
        width: 400,
        height: 50,
        borderRadius: 3,
        background: '#FAFAFB'
      }
    },

    MuiPaper: {
      root: {
        backgroundColor: '#FFFFFF'
      },
      elevation0: {
        boxShadow: '0px 0px 20px rgba(0, 0, 0, 0.05)'
      },
      elevation1: {
        boxShadow: '0px 0px 40px rgba(0, 0, 0, 0.1)'
      },
      rounded: {
        borderRadius: 5
      }
    },
    MuiCard: {
      root: {
        background:
          'linear-gradient(150.38deg, #FFE2A8 8.84%, #FFD583 84.66%), #FFD583',
        boxShadow: ' 0px 15px 40px -15px #FFD583',
        borderRadius: '10px'
      }
    },
    MuiSlider: {
      root: {
        padding: '13px 0px !important'
      },
      rail: {
        height: 5,
        width: '100%',
        backgroundColor: '#E4E8EC',
        boxShadow: '0px 20px 40px rgba(0, 0, 0, 0.2)',
        borderRadius: 10
      },
      track: {
        height: 5,
        width: '100%',
        background:
          'linear-gradient(177.24deg, #FFE2A8 8.84%, #FFD583 84.66%), #FFD583',
        boxShadow: '0px 20px 40px rgba(0, 0, 0, 0.2)',
        borderRadius: 10
      },
      mark: {
        width: 15,
        height: 15,
        marginLeft: -6,
        top: 8.5,
        backgroundColor: '#E3E3E3',
        boxShadow: '0px 20px 40px rgba(0, 0, 0, 0.2)',
        borderRadius: 10,
        backgroundImage: `url(${slider_thumb})`
      },
      markLabel: {
        top: 38,
        transform: 'translateX(-25%)',
        fontFamily: 'Montserrat',
        fontSize: 12,
        fontStyle: 'normal',
        color: '#9BA0A6',
        fontWeight: 600,
        lineHeight: '15px'
      },
      markActive: {
        backgroundImage: `url(${rank_icon})`,
        width: 14,
        marginLeft: -6,
        height: 20,
        backgroundColor: 'transparent',
        backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat'
      },
      thumb: {
        width: 14,
        height: 20,
        backgroundColor: 'transparent',
        boxShadow: '0px 20px 40px rgba(0, 0, 0, 0.2)',
        '&:hover': {
          boxShadow: 'none'
        },
        '&.MuiSlider-active': {
          boxShadow: 'none'
        }
      }
    },
    MuiTooltip: {
      tooltip: {
        height: 35,
        width: 87,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        backgroundColor: '#FFFFFF',
        boxShadow: '0px 0px 15px rgba(0, 0, 0, 0.1)'
      },
      popper: {
        zIndex: 100
      },
      tooltipArrow: {
        width: 57,
        height: 16,
        background: '#9BA0A6'
      }
    },
    MuiDialog: {
      paper: {
        margin: 0
      },
      paperWidthSm: {
        width: 500,
        maxWidth: '500px !important',
        height: 750
      },
      paperWidthMd: {
        width: 500,
        maxWidth: '500px !important'
      },
      paperWidthXs: {
        width: 500,
        maxWidth: '500px !important',
        height: 600
      },
      paperWidthXl: {
        width: 500,
        maxWidth: '500px !important',
        height: 300
      }
    },
    MuiTableCell: {
      root: {
        borderBottom: '1px dashed #E3E3E3',
        padding: 0,
        height: 50
      }
    },
    MuiSelect: {
      select: {
        '&:focus': {
          backgroundColor: 'inherit'
        }
      },

      selectMenu: {
        textTransform: 'capitalize'
      },
      root: {
        '&$disabled': {
          color: '#000000'
        }
      }
    },
    MuiTablePagination: {
      toolbar: {
        width: '100%'
      },
      spacer: {
        flex: 'none'
      },
      caption: {
        fontWeight: 500,
        fontSize: 12,
        lineHeight: '15px',
        color: '#A1A9B4'
      },
      selectRoot: {
        marginRight: 20
      },
      select: {
        fontWeight: 'bold',
        fontSize: 12,
        lineHeight: '15px',
        color: '#A1A9B4'
      }
    },
    MuiList: {
      padding: {
        paddingTop: 0,
        paddingBottom: 0
      }
    },

    MuiInputLabel: {
      root: {
        backgroundColor: '#FFFFFF',
        paddingRight: 5
      }
    },

    MuiSwitch: {
      root: {
        width: 50,
        height: 26,
        padding: 0,
        borderRadius: 20
      },
      switchBase: {
        top: '-6px',
        left: '-4px',
        color: '#FFFFFF !important',
        '&$track': {
          opacity: 1,
          background:
            'linear-gradient(118.38deg, #9BA0A6 9.63%, #C4C4C4 85.81%)'
        },
        '&$checked': {
          color: 'red',
          '& + $track': {
            opacity: 1,
            background:
              'linear-gradient(118.38deg, #47CAB2 9.63%, #36BCA4 85.81%)'
          }
        }
      }
    }
  }
});

export default theme;
