import React, { Dispatch } from 'react';
import {
  createStyles,
  WithStyles,
  withStyles,
  Grid,
  Theme
} from '@material-ui/core';
import { History } from 'history';
import CardWithDrawComponent from '../../../../../components/ProfitDashboard';
import ListTradingComponent from '../../../../../components/ListTrading.component';
import { IGetRoleId, getRoleId } from '../../../../auth/actions/auth';
import { connect } from 'react-redux';

const styles = (theme: Theme) =>
  createStyles({
    listTrading: {
      paddingLeft: 10,
      [theme.breakpoints.down('sm')]: {
        marginTop: 30,
        paddingLeft: 0
      }
    },
    info: {
      paddingRight: 10,
      [theme.breakpoints.down('sm')]: {
        paddingRight: 0
      }
    }
  });

interface IProps extends WithStyles<typeof styles> {
  history: History;
  getRoleIdAction: () => void;
}

class Top extends React.Component<IProps> {
  componentDidMount() {
    this.props.getRoleIdAction();
  }
  public render() {
    const { classes } = this.props;
    return (
      <Grid container>
        <Grid className={classes.info} item xs={12} lg={6} md={6}>
          <CardWithDrawComponent history={this.props.history} />
        </Grid>
        <Grid className={classes.listTrading} item xs={12} lg={6} md={6}>
          <ListTradingComponent />
        </Grid>
      </Grid>
    );
  }
}

const mapDispatchToProps = (dispatch: Dispatch<IGetRoleId>) => {
  return {
    getRoleIdAction: () => dispatch(getRoleId())
  };
};
export default connect(undefined, mapDispatchToProps)(withStyles(styles)(Top));
