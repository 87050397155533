import { Store } from 'redux';
import { IStoreState } from '../../../../../types';
import { Dispatch } from 'react';
import {
  IGetConfigAction,
  setConfigAction,
  IUpdateConfigAction,
  getConfigAction
} from '../../../../dashboard/actions/payment.action';
import {
  GET_CONFIG,
  UPDATE_CONFIG
} from '../../../../dashboard/constants/payment.constant';
import Axios from 'axios';
import { fireNotification } from '../../../../../actions/Notification.action';
import {
  MESSAGE_NOTIFICATION_SUCCESS,
  MESSAGE_NOTIFICATION_ERROR
} from '../../../../../constants/Notification.constant';
import Variant from '../../../../../types/Variant.type';
import { PATH } from '../../../../../constants/Path.constant';

export const configMiddleware = (store: Store<IStoreState>) => (
  dispatch: Dispatch<IGetConfigAction | IUpdateConfigAction>
) => (action: IGetConfigAction | IUpdateConfigAction) => {
  switch (action.type) {
    case GET_CONFIG: {
      Axios.get('/configs')
        .then(res => {
          if (res.data) {
            store.dispatch(setConfigAction(res.data));
          }
        })
        .catch(err => {
          const statusCode =
            err &&
            err.response &&
            err.response.data &&
            err.response.data.error &&
            err.response.data.error.statusCode;
          const message =
            err &&
            err.response &&
            err.response.data &&
            err.response.data.error &&
            err.response.data.error.message;
          if (statusCode === 403 || statusCode === 401) {
            store.dispatch(
              fireNotification({
                message: statusCode === 403 ? message : 'Invalid Token',
                variant: Variant.ERROR,
                link: statusCode === 403 ? PATH.notFound : PATH.login
              })
            );
          }
        });
      break;
    }
    case UPDATE_CONFIG: {
      Axios.patch('/configs', action.payload)
        .then(() => {
          store.dispatch(getConfigAction());
          store.dispatch(
            fireNotification({
              message: MESSAGE_NOTIFICATION_SUCCESS.UPDATE_CONFIG,
              variant: Variant.SUCCESS
            })
          );
        })
        .catch(err => {
          store.dispatch(getConfigAction());
          const error =
            err.response &&
            err.response.data &&
            err.response.data.error &&
            err.response.data.error.message;
          store.dispatch(
            fireNotification({
              message: error ? error : MESSAGE_NOTIFICATION_ERROR.EDIT_PROFILE,
              variant: Variant.ERROR
            })
          );
        });
    }
  }
  return dispatch(action);
};
