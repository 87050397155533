import { IStoreState, IRefreshTokenResponse } from '../../../types';
import {
  ILoginAction,
  IForgotPasswordAction,
  IResetPasswordAction,
  ILogoutAction,
  IVerifyCodeAction,
  IChangePassword,
  IGetRoleId,
  setRoleId
} from '../actions/auth';
import { Dispatch, Store } from 'redux';
import {
  LOGIN,
  FORGOT_PASSWORD,
  RESET_PASSWORD,
  LOGOUT,
  VERIFYCODE,
  CHANGE_PASSWORD,
  GET_ROLE_ID
} from '../actions/constant';
import Axios, { AxiosResponse } from 'axios';
import { PATH } from '../../../constants/Path.constant';
import { push } from 'connected-react-router';
import { fireNotification } from '../../../actions/Notification.action';
import {
  MESSAGE_NOTIFICATION_SUCCESS,
  MESSAGE_NOTIFICATION_ERROR
} from '../../../constants/Notification.constant';
import Variant from '../../../types/Variant.type';
import { ROLE, REFRESH_TOKEN } from '../../../constants/auth.constant';
import { IRefreshToken } from '../../../actions/auth.action';
import { IRoleId } from '../types';

export const loginMiddleware = (store: Store<IStoreState>) => (
  dispatch: Dispatch<
    | ILoginAction
    | IForgotPasswordAction
    | IResetPasswordAction
    | ILogoutAction
    | IVerifyCodeAction
    | IChangePassword
    | IRefreshToken
    | IGetRoleId
  >
) => (
  action:
    | ILoginAction
    | IForgotPasswordAction
    | IResetPasswordAction
    | ILogoutAction
    | IVerifyCodeAction
    | IChangePassword
    | IRefreshToken
    | IGetRoleId
) => {
  switch (action.type) {
    case LOGIN: {
      Axios.post('/auth', action.payload)
        .then(res => {
          const token = res.data;
          if (token.token) {
            localStorage.setItem('token', token.token);
            localStorage.setItem('refresh-token', token.refreshToken);
            localStorage.setItem('exp', token.exp);
            localStorage.setItem('role', token.role);

            // store.dispatch(getUserProfileAction());
            // store.dispatch(getCountries());
            if (token.role === ROLE.AGENT) {
              window.location.href = PATH.agent.dashboard;
            } else if (token.role === ROLE.ADMIN) {
              window.location.href = PATH.admin.top;
            }
          }
          if (token.twoFactorEnabled) {
            store.dispatch(
              push(PATH.agent.verify, { loginRequest: action.payload })
            );
          } else {
            if (token.role === ROLE.AGENT) {
              window.location.href = PATH.agent.dashboard;
            } else if (token.role === ROLE.ADMIN) {
              window.location.href = PATH.admin.top;
            }
          }
        })
        .catch(err => {
          const error =
            err.response &&
            err.response.data &&
            err.response.data.error &&
            err.response.data.error.message;
          store.dispatch(
            fireNotification({
              message: error ? error : MESSAGE_NOTIFICATION_ERROR.EDIT_PROFILE,
              variant: Variant.ERROR
            })
          );
        });
      break;
    }
    case FORGOT_PASSWORD: {
      Axios.post('/forgot/password', { email: action.payload })
        .then(() =>
          store.dispatch(
            fireNotification({
              message: MESSAGE_NOTIFICATION_SUCCESS.RESET_PASSWORD,
              variant: Variant.SUCCESS,
              link: PATH.login
            })
          )
        )
        .catch(err =>
          store.dispatch(
            fireNotification({
              message: MESSAGE_NOTIFICATION_ERROR.EDIT_PROFILE,
              variant: Variant.ERROR
            })
          )
        );
      break;
    }
    case CHANGE_PASSWORD: {
      Axios.post('/users/change-password', action.payload)
        .then(() =>
          store.dispatch(
            fireNotification({
              message: MESSAGE_NOTIFICATION_SUCCESS.RESET_PASSWORD,
              variant: Variant.SUCCESS
            })
          )
        )
        .catch(err => {
          const error =
            err.response &&
            err.response.data &&
            err.response.data.error &&
            err.response.data.error.message;
          store.dispatch(
            fireNotification({
              message: error
                ? error
                : MESSAGE_NOTIFICATION_ERROR.VERIFY_CODE_INVALID,
              variant: Variant.ERROR
            })
          );
        });
      break;
    }

    case RESET_PASSWORD: {
      Axios.post(`/reset-password/${action.payload.token}`, {
        password: action.payload.password,
        repassword: action.payload.repassword
      })
        .then(() => {
          store.dispatch(
            fireNotification({
              message: MESSAGE_NOTIFICATION_SUCCESS.RESET_PASSWORD,
              variant: Variant.SUCCESS,
              link: PATH.login
            })
          );
        })
        .catch(err => {
          const error =
            err.response &&
            err.response.data &&
            err.response.data.error &&
            err.response.data.error.message;
          store.dispatch(
            fireNotification({
              message: error ? error : MESSAGE_NOTIFICATION_ERROR.EDIT_PROFILE,
              variant: Variant.ERROR
            })
          );
        });
      break;
    }
    case LOGOUT: {
      localStorage.clear();
      window.location.href = PATH.login;
      break;
    }
    case VERIFYCODE: {
      Axios.post('/auth/2nd-factor', action.payload)
        .then(res => {
          const token = res.data;
          if (token) {
            localStorage.setItem('token', token.token);
            localStorage.setItem('refresh-token', token.refreshToken);
            localStorage.setItem('exp', token.exp);
            localStorage.setItem('role', token.role);
          }
          if (token.role === ROLE.AGENT) {
            window.location.href = PATH.agent.dashboard;
          } else if (token.role === ROLE.ADMIN) {
            window.location.href = PATH.admin.top;
          }
        })
        .catch(err => {
          const message =
            (err.response &&
              err.response.data &&
              err.response.data.error &&
              err.response.data.error.message) ||
            MESSAGE_NOTIFICATION_ERROR.VERIFY_CODE_INVALID;
          store.dispatch(
            fireNotification({
              message,
              variant: Variant.ERROR
            })
          );
        });
      break;
    }

    case REFRESH_TOKEN: {
      Axios.post(`/refresh-token`, action.payload).then(
        (data: AxiosResponse<IRefreshTokenResponse>) => {
          if (data.data) {
            localStorage.setItem('token', data.data.token);
            localStorage.setItem('refresh-token', data.data.refreshToken);
            localStorage.setItem('exp', data.data.exp);
            localStorage.setItem('role', data.data.role);
          }
          window.location.reload();
        }
      );
      break;
    }

    case GET_ROLE_ID: {
      Axios.get(`/roles`)
        .then((res: AxiosResponse<IRoleId[]>) => {
          store.dispatch(setRoleId(res.data));
        })
        .catch(err => console.log(err));
      break;
    }
  }
  return dispatch(action);
};
