import { IConfigInfo } from '../../../../dashboard/types';
import { ISetConfigAction } from '../../../../dashboard/actions/payment.action';
import { SET_CONFIG } from '../../../../dashboard/constants/payment.constant';

export const configs = (
  state: IConfigInfo[] = [],
  action: ISetConfigAction
): IConfigInfo[] => {
  switch (action.type) {
    case SET_CONFIG: {
      return action.payload;
    }
  }
  return state;
};
