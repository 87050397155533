import {
  LibrarySymbolInfo,
  ResolutionString,
  Bar
} from '../../../../../../charting_library/datafeed-api';

import Axios from 'axios';

export default {
  getBars: (
    symbolInfo: LibrarySymbolInfo,
    resolution: ResolutionString,
    rangeStartDate: number,
    rangeEndDate: number,
    isFirstCall: boolean
  ) => {
    console.log(symbolInfo);
    // const splitSymbol = symbolInfo.full_name.split(':');
    // const url =
    //   resolution === 'D'
    //     ? '/data/v2/histoday'
    //     : Number(resolution) >= 60
    //     ? '/data/v2/histohour'
    //     : '/data/v2/histominute';
    // const qs = {
    //   e: splitSymbol[0],
    //   fsym: splitSymbol[1],
    //   tsym: 'USD',
    //   toTs: rangeEndDate ? rangeEndDate : '',
    //   limit: 2000
    // };

    // return Axios.get(`${api_root}${url}`, { params: qs }).then((data: any) => {
    //   console.log('ddddddddddd', data.data.Data);
    //   if (data.Response && data.Response === 'Error') {
    //     console.log('CryptoCompare API error:', data.Message);
    //     return [];
    //   }
    //   if (data.data.Data.Data.length) {
    //     // console.log(
    //     //   `Actually returned: ${new Date(
    //     //     data.TimeFrom * 1000
    //     //   ).toISOString()} - ${new Date(data.TimeTo * 1000).toISOString()}`
    //     // );
    //     const bars = data.data.Data.Data.map((el: any) => {
    //       return {
    //         time: el.time * 1000, //TradingView requires bar time in ms
    //         low: el.low,
    //         high: el.high,
    //         open: el.open,
    //         close: el.close,
    //         volume: el.volumefrom
    //       };
    //     });
    //     // if (first) {
    //     //   var lastBar = bars[bars.length - 1];
    //     //   history[symbolInfo.name] = { lastBar: lastBar };
    //     // }
    //     console.log(bars);
    //     return bars;
    //   } else {
    //     return [];
    //   }
    // });
    return Axios.get('/trade', {
      params: {
        limit: 2000,
        type: 'MIN_1'
      }
    })
      .then(data => {
        const dataRes = data.data.reverse();
        const result: Bar[] = dataRes.map((e: any) => {
          return {
            time: new Date(e.timestamp).getTime(),
            open: e.open,
            high: e.high,
            low: e.low,
            close: e.close,
            volume: e.volume
          };
        });
        // if (isFirstCall) {
        //   resolve(result[result.length - 1]);
        // }
        return result;
      })
      .catch(() => []);
  }
  // return new Promise((solve, reject) => {
  //   socket.socket.emit('registerClient', 'client');
  //   socket.socket.on('TRADE_BIN_1M', (data: any) => {
  //     console.log(data);
  //     const result = {
  //       time: new Date(data.timestamp).getTime(),
  //       open: data.open,
  //       high: data.high,
  //       low: data.low,
  //       close: data.close,
  //       volume: data.volume
  //     };
  //     history.push(result);
  //     console.log(history);
  //     solve(history);
  //   });
  // });
};
