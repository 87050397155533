import React from 'react';
import { Tooltip } from '@material-ui/core';
import { Link } from 'react-router-dom';
import MediaQuery from 'react-responsive';
interface IProps {
  address: string;
  text?: string;
  tooltip?: string;
}
function LinkAddress(props: IProps) {
  const tooltipTitle = props.tooltip ? props.tooltip : '';
  const sliceText = (text?: string) => {
    return text
      ? text
          .slice(0, 4)
          .concat('...')
          .concat(text.slice(-4))
      : '';
  };
  return (
    <Tooltip title={tooltipTitle}>
      <Link
        to={{
          pathname:
            props.address ||
            `${process.env.REACT_APP_SCAN_URL}/address/${props.text}`,
          state: { editAddress: true }
        }}
        style={{ textDecoration: 'none', wordBreak: 'break-all' }}
        target={props.address ? undefined : 'true'}
        rel="noopener"
      >
        <MediaQuery maxDeviceWidth={760}>{sliceText(props.text)}</MediaQuery>
        <MediaQuery minDeviceWidth={768}>{props.text}</MediaQuery>
      </Link>
    </Tooltip>
  );
}

export default LinkAddress;
