import * as React from 'react';
import {
  Dialog,
  Grid,
  Typography,
  Button,
  makeStyles
} from '@material-ui/core/';

import iconsuccesDialog from '../../assets/images/iconsuccesDialog.svg';

const styles = makeStyles({
  dialogTitle: {
    height: 100,
    padding: 0
  },
  dialogContent: {
    height: 'calc(100% - 200px )',
    paddingLeft: 30,
    paddingRight: 30
  },
  textWithDraw: {
    fontSize: 20,
    color: '#000000',
    lineHeight: '24px',
    fontWeight: 600
  },
  dialogActions: {
    height: 100,
    borderTop: '1px solid #DCE0E4'
  },
  buttonYesWithdraw: {
    width: 255,
    height: 50,
    textTransform: 'capitalize'
  },
  buttonNoWithdraw: {
    width: 255,
    height: 50,
    textTransform: 'capitalize',
    backgroundColor: '#FFFFFF',
    border: '1px solid #DCE0E4',
    boxSizing: 'border-box',
    '&:hover': {
      backgroundColor: '#F4F4F4',
      border: '1px solid #E6E6E6'
    }
  }
});

interface IProps {
  showDialog: boolean;
  closeNotification: () => void;
  message: string;
}

export const NotificationClearTokenDialog = (props: IProps) => {
  const { showDialog, closeNotification, message } = props;
  const classes = styles();
  return (
    <React.Fragment>
      <Dialog
        aria-labelledby="customized-dialog-title"
        open={showDialog}
        maxWidth="xl"
      >
        <Grid
          container
          style={{ height: 90 }}
          alignItems="center"
          justify="center"
        >
          <img
            src={iconsuccesDialog}
            alt="icon succes"
            style={{ marginRight: 10 }}
          />
          <Typography
            variant="subtitle2"
            style={{ fontWeight: 500, color: '#36BCA4' }}
          >
            {' '}
            Success!
          </Typography>
        </Grid>

        <Grid
          container
          alignItems="center"
          justify="center"
          style={{ height: 'calc(100% - 190px)' }}
        >
          <Typography
            data-test="notification-msg"
            variant="subtitle2"
            style={{ fontWeight: 500, textAlign: 'center' }}
          >
            {' '}
            {message}
          </Typography>
        </Grid>
        <Grid
          container
          alignItems="center"
          justify="center"
          style={{ height: 100, paddingBottom: 50 }}
        >
          <Button
            data-test="Ok"
            className={classes.buttonNoWithdraw}
            variant="contained"
            color="secondary"
            onClick={closeNotification}
          >
            <Typography variant="subtitle2"> Ok</Typography>
          </Button>
        </Grid>
      </Dialog>
    </React.Fragment>
  );
};
