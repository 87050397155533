import INotificationInfo from '../types/Notification.type';
import Variant from '../types/Variant.type';
import {
  IUpdateNotification,
  IEaseNotification
} from '../actions/Notification.action';
import {
  UPDATE_NOTIFICATION,
  EASE_NOTIFICATION
} from '../constants/Notification.constant';

const initState: INotificationInfo = {
  isOpen: false,
  variant: Variant.SUCCESS,
  message: ''
};

export function notification(
  state = initState,
  action: IUpdateNotification | IEaseNotification
): INotificationInfo {
  switch (action.type) {
    case UPDATE_NOTIFICATION:
      return action.payload;
    case EASE_NOTIFICATION:
      return {
        ...state,
        isOpen: false
      };
  }
  return state;
}
