import React from 'react';
import ListNFTComponent from '../../../components/dialog/ListNFT.dialog.component';
import { Dispatch } from 'redux';
import { IGetNftListAction, getNftListAction } from '../actions/payment.action';
import { INftRequest, INftData } from '../types';
import NftHeader from './Nft.header.component';
import { connect } from 'react-redux';
import { IStoreState } from '../../../types';
import { Grid, Typography, Paper } from '@material-ui/core';
import NftTableComponent from './Nft.table.component';
import MediaQuery from 'react-responsive';
import NftCardComponent from './NftCard.component';

interface IState {
  isShow: boolean;
  limit: number;
  offset: number;
}

interface IProps {
  nfts: INftData;
  getNftsAction: (params: INftRequest) => void;
}
class NftContainer extends React.PureComponent<IProps, IState> {
  state: IState = {
    isShow: false,
    limit: 3,
    offset: 0
  };
  componentDidMount() {
    const params: INftRequest = {
      limit: 3,
      offset: 0
    };
    this.props.getNftsAction(params);
  }

  handleDialog = (isShow: boolean) => {
    this.setState({ isShow });
  };

  loadMore = () => {
    const { limit, offset } = this.state;
    const newLimit = limit + 5;
    this.setState({ limit: newLimit });
    this.props.getNftsAction({ limit: newLimit, offset });
  };

  render() {
    const { nfts } = this.props;
    return (
      <>
        <NftHeader nfts={nfts && nfts.data} handleDialog={this.handleDialog} />
        <Grid style={{ marginTop: 9, width: '100%' }}>
          <MediaQuery minDeviceWidth={768}>
            <NftTableComponent nfts={nfts && nfts} />
          </MediaQuery>
          <MediaQuery maxDeviceWidth={760}>
            {nfts && nfts.data.length ? (
              nfts.data.map(e => <NftCardComponent key={e.id} nftData={e} />)
            ) : (
              <Paper
                elevation={0}
                style={{
                  marginTop: 20,
                  height: 60,
                  width: '100%',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center'
                }}
              >
                <Typography variant="h3">No data</Typography>
              </Paper>
            )}
            {this.props.nfts &&
              this.props.nfts.meta &&
              this.props.nfts.meta.total > this.state.limit && (
                <Grid
                  container
                  style={{ flexDirection: 'column', marginTop: 30 }}
                  alignItems={'center'}
                >
                  <Typography
                    variant="h4"
                    style={{ color: '#00AFC1', fontSize: 14 }}
                    onClick={this.loadMore}
                  >
                    Load more...
                  </Typography>
                </Grid>
              )}
          </MediaQuery>
        </Grid>
        <ListNFTComponent
          showListNFT={this.state.isShow}
          handleCloseDialog={this.handleDialog}
        />
      </>
    );
  }
}

const mapDisPatchToProps = (dispatch: Dispatch<IGetNftListAction>) => ({
  getNftsAction: (params: INftRequest) => dispatch(getNftListAction(params))
});

const mapStateToProps = ({ nftPayment }: IStoreState) => ({
  nfts: nftPayment
});
export default connect(mapStateToProps, mapDisPatchToProps)(NftContainer);
