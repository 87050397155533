import React from 'react';
import { IStoreState } from '../../../types';
import { connect } from 'react-redux';
import { IHolder, IHolderRequest, IHolderContainer } from '../types';
import {
  IGetCardHolderAction,
  getCardHolderAction
} from '../actions/holder.action';
import { Dispatch } from 'redux';
import ListHolderComponent from '../../../components/dialog/ListHolder.dialog.component';
import HolderHeaderComponent from './Holder.header.component';
import HolderComponent from './Listholder.component';

interface IProps {
  holders: IHolderContainer;
  getCardHolderAction: (request: IHolderRequest) => void;
}

interface IState {
  nftCheckbox: boolean;
  expiredDateCheckbox: boolean;
  holders: IHolder[];
  isShow: boolean;
  limit: number;
}
class CardHolder extends React.Component<IProps, IState> {
  state: IState = {
    nftCheckbox: false,
    expiredDateCheckbox: false,
    isShow: false,
    holders: this.props.holders.data,
    limit: 10
  };

  topNFTSort = (a: IHolder, b: IHolder) => {
    return Number(b.nfts) - Number(a.nfts);
  };

  handleDialog = (isShow: boolean) => () => {
    this.setState({ isShow });
  };

  componentWillReceiveProps(nextProps: IProps) {
    if (nextProps.holders !== this.props.holders) {
      this.setState({ holders: nextProps.holders.data });
    }
  }
  dateSort = (a: IHolder, b: IHolder) => {
    const date1 = new Date(a.expireSoon).getTime();
    const date2 = new Date(b.expireSoon).getTime();
    return date2 - date1;
  };

  handleChange = (key: string, resetKey: string) => (
    event: React.ChangeEvent<HTMLInputElement>,
    checked: boolean
  ) => {
    if (checked) {
      this.setState({
        [key]: checked,
        [resetKey]: !checked
      } as any);
    } else {
      this.setState({ [key]: checked } as any);
    }
  };

  componentDidMount() {
    this.props.getCardHolderAction({ limit: 10, offset: 0 });
  }

  loadMoreHolder = () => {
    const { limit } = this.state;
    const newLimit = limit + 5;
    this.props.getCardHolderAction({ limit: newLimit, offset: 0 });
  };

  render() {
    const { nftCheckbox, expiredDateCheckbox, holders, limit } = this.state;
    return (
      <React.Fragment>
        <HolderHeaderComponent
          nftCheckbox={nftCheckbox}
          holders={holders && holders}
          expiredDateCheckbox={expiredDateCheckbox}
          handleDialog={this.handleDialog}
          handleChange={this.handleChange}
        />
        <HolderComponent
          expiredDateCheckbox={expiredDateCheckbox}
          holders={holders && holders}
          nftCheckbox={nftCheckbox}
          isLoadMore={
            this.props.holders &&
            this.props.holders.meta &&
            this.props.holders.meta.total > limit
          }
          getListHoder={this.loadMoreHolder}
        />
        <ListHolderComponent
          showListHolder={this.state.isShow}
          handleCloseDialog={this.handleDialog(false)}
        />
      </React.Fragment>
    );
  }
}

const mapStateToProps = ({ holders }: IStoreState) => {
  return {
    holders
  };
};

const mapDispatchToProps = (dispatch: Dispatch<IGetCardHolderAction>) => {
  return {
    getCardHolderAction: (request: IHolderRequest) =>
      dispatch(getCardHolderAction(request))
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(CardHolder);
