import React from 'react';
import {
  createStyles,
  WithStyles,
  withStyles,
  Grid,
  Theme
} from '@material-ui/core';
import { ROLE } from '../../../constants/auth.constant';

interface IProps extends WithStyles<typeof styles> {
  avatar: React.ReactNode;
  profileName: React.ReactNode;
  startDay?: React.ReactNode;
  address: React.ReactNode;
  referCode?: React.ReactNode;
  role: ROLE;
}

const styles = (theme: Theme) =>
  createStyles({
    root: {
      width: '100%'
    },
    container: {
      display: 'flex',
      alignItems: 'center',
      flexDirection: 'row'
    }
  });

class Profile extends React.Component<IProps> {
  render() {
    const { classes, role } = this.props;
    return (
      <div className={classes.root}>
        <Grid container className={classes.container}>
          <Grid
            style={{ flexWrap: 'nowrap' }}
            container
            xs={12}
            sm={12}
            item
            md={role === ROLE.AGENT ? 6 : 12}
            lg={6}
          >
            <Grid item md={1} lg={1}>
              {this.props.avatar}
            </Grid>
            <Grid
              item
              md={11}
              lg={11}
              style={{ flexDirection: 'column', width: '100%' }}
            >
              <Grid>{this.props.profileName}</Grid>
              <Grid>{this.props.startDay}</Grid>
              <Grid>{this.props.address}</Grid>
            </Grid>
          </Grid>
          <Grid item sm={12} xs={12} md={role === ROLE.AGENT ? 6 : 12} lg={6}>
            {this.props.referCode}
          </Grid>
        </Grid>
      </div>
    );
  }
}

export default withStyles(styles)(Profile);
