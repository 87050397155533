import React from 'react';
import { Dialog, Grid } from '@material-ui/core';
import TitlePageComponent from '../TitlePage.component';
import TableComponent from '../shared/table';
import { nftTable } from '../../scenes/dashboard/types';

interface IProps {
  showListNFT: boolean;
  handleCloseDialog: (isShow: boolean) => void;
}
class ListNFTComponent extends React.Component<IProps> {
  renderTable = () => {
    return <TableComponent settings={nftTable} api="/nfts" />;
  };

  closeDialog = () => this.props.handleCloseDialog(false);
  render() {
    return (
      <Dialog
        data-test="nft-dialog"
        maxWidth="lg"
        fullWidth
        BackdropProps={{
          style: {
            backgroundColor: 'rgba(0, 0, 0, 0.8)'
          }
        }}
        open={this.props.showListNFT}
        onClose={this.closeDialog}
      >
        <Grid
          style={{
            padding: '0px 60px'
          }}
        >
          <Grid
            container
            style={{
              marginBottom: 5,
              paddingTop: 20,
              display: 'flex',
              alignItems: 'center'
            }}
          >
            <Grid
              item
              style={{ justifyContent: 'flex-start', display: 'flex' }}
            >
              <TitlePageComponent title="List NFT" />
            </Grid>
          </Grid>
          <Grid container style={{ marginTop: 20 }}>
            <Grid item style={{ paddingRight: 10, width: '100%' }}>
              {this.renderTable()}
            </Grid>
          </Grid>
        </Grid>
      </Dialog>
    );
  }
}

export default ListNFTComponent;
