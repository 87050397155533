import React from 'react';
import {
  Paper,
  createStyles,
  withStyles,
  WithStyles,
  Grid,
  Typography
} from '@material-ui/core';
import classNames from 'classnames';
import { Link } from 'react-router-dom';
import { IPaymentHistory } from '../dashboard/types';
import { formatDate } from '../../helper';

const styles = () =>
  createStyles({
    '@keyframes fadeInDown': {
      '0%': {
        opacity: 0,
        transform: 'translateY(-20px)'
      },
      '100%': {
        opacity: 1,
        transform: 'translateY(0)'
      }
    },
    container: {
      width: '100%',
      marginTop: 20,
      animation: `$fadeInDown 1500ms ease-in-out`
    },
    rootLayout: {
      padding: 15,
      width: '100%',
      height: 170,
      display: 'flex',
      flexDirection: 'column',
      boxSizing: 'border-box'
    },
    itemRoot: {
      flex: 1
    },
    item: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center'
    },
    title: {
      fontSize: 12,
      lineHeight: '18px',
      fontWeight: 600,
      color: '#9BA0A6',
      textTransform: 'uppercase'
    },
    value: {
      fontSize: 14,
      lineHeight: '20px',
      fontWeight: 500,
      color: '#222222'
    },
    floatRight: {
      alignItems: 'flex-end'
    },
    fadeIn: {
      opacity: 1,
      transform: 'translateY(0)'
    }
  });

interface IProps extends WithStyles<typeof styles> {
  paymentHistory: IPaymentHistory;
}

class HistoryCard extends React.Component<IProps> {
  formatTransactionHash = (hash: string) => {
    const hashSlice =
      hash &&
      hash
        .slice(0, 4)
        .concat('...')
        .concat(hash.slice(-4));
    return (
      <Link
        style={{
          color: '#3DACEB ',
          textDecoration: 'none'
        }}
        to={{
          pathname: `
          ${process.env.REACT_APP_SCAN_URL}/${hash}`
        }}
        target="true"
      >
        {hashSlice}
      </Link>
    );
  };

  render() {
    const { classes, paymentHistory } = this.props;
    return (
      <Paper elevation={0} className={classes.container}>
        <Grid className={classes.rootLayout}>
          <Grid container className={classes.itemRoot}>
            <Grid item xs={12} className={classes.item}>
              <Typography className={classes.title}>Payment volume</Typography>
              <Typography
                style={{ color: '#00AFC1', textTransform: 'uppercase' }}
                className={classes.value}
              >
                {paymentHistory.total} TOMO
              </Typography>
            </Grid>
          </Grid>
          <Grid container className={classes.itemRoot}>
            <Grid item xs={6}>
              <Grid className={classes.item}>
                <Typography className={classes.title}>Payment date</Typography>
                <Typography className={classes.value}>
                  {formatDate(paymentHistory.paymentDate)}
                </Typography>
              </Grid>
            </Grid>
            <Grid item xs={6}>
              <Grid className={classNames(classes.item, classes.floatRight)}>
                <Typography className={classes.title}>NFT No</Typography>
                <Typography
                  style={{ fontWeight: 'bold' }}
                  className={classes.value}
                >
                  {paymentHistory.nftNo}
                </Typography>
              </Grid>
            </Grid>
          </Grid>
          <Grid container className={classes.itemRoot}>
            <Grid item xs={6}>
              <Grid className={classes.item}>
                <Typography className={classes.title}>Issues</Typography>
                <Typography
                  style={{ fontWeight: 'bold' }}
                  className={classes.value}
                >
                  {paymentHistory.transactionType}
                </Typography>
              </Grid>
            </Grid>
            <Grid item xs={6}>
              <Grid className={classNames(classes.item, classes.floatRight)}>
                <Typography className={classes.title}>transaction</Typography>
                <Typography className={classes.value}>
                  {this.formatTransactionHash(paymentHistory.hash)}
                </Typography>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Paper>
    );
  }
}

export default withStyles(styles)(HistoryCard);
