import * as React from 'react';
import {
  Typography,
  Paper,
  createStyles,
  Grid,
  TextField,
  Button,
  Theme
} from '@material-ui/core';
import { WithStyles, withStyles } from '@material-ui/styles';
import ExternalAgentLayout from '../../../components/ExternalAgentLayout';
import { Dispatch } from 'redux';
import { IResetPassword } from '../types';
import { IResetPasswordAction, resetPasswordAction } from '../actions/auth';
import { connect } from 'react-redux';
import {
  verifyAccountAction,
  IVerifyAccountAction
} from '../../setting/actions/user';
import { ACCOUNT_TYPE } from '../../../constants/auth.constant';
import { NotificationClearTokenDialog } from '../../../components/dialog/NotificationClearTokenDialog.component';
interface IProps extends WithStyles<typeof styles> {
  resetPasswordAction: (request: IResetPassword) => void;
  verifyAccountAction: (request: IResetPassword) => void;
}

interface IState {
  password: string;
  repassword: string;
  showDialogLogout: boolean;
  error: {
    isError: boolean;
    message: string;
  };
}

const styles = (theme: Theme) =>
  createStyles({
    rootLogin: {
      width: 500,
      [theme.breakpoints.down('xs')]: {
        width: '80%',
        padding: '0 25px',
        height: 450
      }
    },
    titleHeader: {
      padding: '30px 0',
      borderBottom: '1px solid #F1F1F4'
    },
    buttonLogin: {
      textTransform: 'capitalize',
      marginTop: 50
    }
  });

class ResetPassword extends React.Component<IProps, IState> {
  state: IState = {
    password: '',
    repassword: '',
    showDialogLogout: false,
    error: {
      isError: false,
      message: ''
    }
  };

  handleTextChange = (key: string) => (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const value = event.target.value;
    this.setState({
      [key]: value
    } as any);
  };
  componentDidMount = () => {
    const token = localStorage.getItem('token');
    if (token) {
      this.setState({
        showDialogLogout: true
      });
    } else {
      this.setState({
        showDialogLogout: false
      });
    }
  };

  handleResetPassword = () => {
    const { password, repassword } = this.state;
    const location = window.location.pathname;
    if (!location) {
      return;
    }
    if (password !== repassword) {
      return;
    }
    const token = location.slice(location.lastIndexOf('/') + 1);
    const request: IResetPassword = {
      password,
      repassword,
      token
    };
    if (location.includes('reset-password')) {
      this.props.resetPasswordAction(request);
    } else if (location.includes('signup-agent')) {
      this.props.verifyAccountAction(request);
    } else if (location.includes('signup-trading')) {
      request.type = ACCOUNT_TYPE.TRADER;
      this.props.verifyAccountAction(request);
    }
  };

  closeNotification = () => {
    localStorage.clear();
    this.setState({
      showDialogLogout: false
    });
  };

  public render() {
    const { classes } = this.props;
    const { password, repassword } = this.state;
    const [title, text] = window.location.pathname.includes('reset-password')
      ? ['Reset Password', 'New password']
      : ['Create Password', 'Password'];
    const inValidPassword = password.length > 0 && password.length < 6;
    const missMatchPassword = repassword.length > 0 && password !== repassword;
    return (
      <ExternalAgentLayout>
        <Paper elevation={1} className={classes.rootLogin}>
          <Grid
            container
            alignItems="center"
            justify="center"
            className={classes.titleHeader}
          >
            <Typography variant="h2">{title}</Typography>
          </Grid>
          <Grid
            container
            justify="center"
            alignContent="center"
            style={{ height: 390 }}
          >
            <TextField
              label={text}
              variant="outlined"
              error={inValidPassword}
              helperText={
                inValidPassword
                  ? 'The password must contain at least 6 characters'
                  : ''
              }
              type="password"
              onChange={this.handleTextChange('password')}
              value={password}
            />
            <TextField
              error={missMatchPassword}
              helperText={
                missMatchPassword
                  ? 'Your password and confirmation password do not match'
                  : ''
              }
              label="Confirm Password"
              value={repassword}
              type="password"
              onChange={this.handleTextChange('repassword')}
              variant="outlined"
              style={{ marginTop: 30 }}
            />
            <Button
              variant="contained"
              color="primary"
              disabled={password.length < 6 || password !== repassword}
              onClick={this.handleResetPassword}
              size="large"
              className={classes.buttonLogin}
            >
              Submit
            </Button>
          </Grid>
        </Paper>
        <NotificationClearTokenDialog
          showDialog={this.state.showDialogLogout}
          message="Your account has been logged out"
          closeNotification={this.closeNotification}
        />
      </ExternalAgentLayout>
    );
  }
}

const mapDispatchToProps = (
  dispatch: Dispatch<IResetPasswordAction | IVerifyAccountAction>
) => {
  return {
    resetPasswordAction: (request: IResetPassword) =>
      dispatch(resetPasswordAction(request)),
    verifyAccountAction: (request: IResetPassword) =>
      dispatch(verifyAccountAction(request))
  };
};
export default connect(
  undefined,
  mapDispatchToProps
)(withStyles(styles)(ResetPassword));
