import { Store, Dispatch } from 'redux';
import { IStoreState } from '../../../types';
import {
  IGetUserProfile,
  setUserProfileAction,
  IGetCountries,
  setCountries,
  IGetQrImage,
  setQrImageAction,
  IUpdateUserProfile,
  ISetSecondFactorCode,
  getUserProfileAction,
  IVerifyAccountAction,
  IUpdateAvatarAction,
  IUpdateUserById
} from '../actions/user';
import Axios from 'axios';
import {
  GET_USER_PROFILE,
  GET_COUNTRIES,
  GET_QR_IMAGE,
  UPDATE_USER_PROFILE,
  SET_SECOND_FACTOR,
  VERIFY_ACCOUNT,
  UPDATE_AVATAR,
  UPDATE_USER_BY_ID
} from '../actions/constant';
import { ICountry, IQrImage, IUserProfile } from '../types';
import { fireNotification } from '../../../actions/Notification.action';
import Variant from '../../../types/Variant.type';
import {
  MESSAGE_NOTIFICATION_SUCCESS,
  MESSAGE_NOTIFICATION_ERROR
} from '../../../constants/Notification.constant';
import { PATH } from '../../../constants/Path.constant';
import { getAccountAction } from '../../admin/components/Top/actions';

export const userMiddleware = (store: Store<IStoreState>) => (
  dispatch: Dispatch<
    | IGetUserProfile
    | IGetCountries
    | IGetQrImage
    | IUpdateUserProfile
    | ISetSecondFactorCode
    | IVerifyAccountAction
    | IUpdateAvatarAction
    | IUpdateUserById
  >
) => (
  action:
    | IGetUserProfile
    | IGetCountries
    | IGetQrImage
    | IUpdateUserProfile
    | ISetSecondFactorCode
    | IVerifyAccountAction
    | IUpdateAvatarAction
    | IUpdateUserById
) => {
  const config = {
    headers: {
      'content-type': 'multipart/form-data'
    }
  };
  switch (action.type) {
    case GET_USER_PROFILE: {
      Axios.get('/users')
        .then(res => {
          const userProfile: IUserProfile = res.data;
          store.dispatch(setUserProfileAction(userProfile));
        })
        .catch(err => console.log(err.response));
      break;
    }
    case GET_COUNTRIES: {
      Axios.get('/countries')
        .then(res => {
          const countries: ICountry[] = res.data;
          store.dispatch(setCountries(countries));
        })
        .catch(err => console.log(err.response));
      break;
    }
    case GET_QR_IMAGE: {
      Axios.get('/users/2nd-factor')
        .then(res => {
          const qrImage: IQrImage = res.data;
          store.dispatch(setQrImageAction(qrImage));
        })
        .catch(err => console.log(err.response));
      break;
    }
    case UPDATE_USER_PROFILE: {
      Axios.patch('/users', action.payload)
        .then(() => {
          store.dispatch(getUserProfileAction());
          store.dispatch(
            fireNotification({
              message: MESSAGE_NOTIFICATION_SUCCESS.EDIT_PROFILE,
              variant: Variant.SUCCESS
            })
          );
        })
        .catch(err => {
          const message =
            (err.response &&
              err.response.data &&
              err.response.data.error &&
              err.response.data.error.message) ||
            MESSAGE_NOTIFICATION_ERROR.EDIT_PROFILE;
          store.dispatch(
            fireNotification({
              message,
              variant: Variant.ERROR
            })
          );
        });
      break;
    }
    case SET_SECOND_FACTOR: {
      Axios.post('/users/2nd-factor', action.payload)
        .then(() => {
          store.dispatch(
            fireNotification({
              message: MESSAGE_NOTIFICATION_SUCCESS.SETUP_TWOFACTOR,
              variant: Variant.SUCCESS
            })
          );
          store.dispatch(getUserProfileAction());
        })
        .catch(err => {
          const error =
            err.response &&
            err.response.data &&
            err.response.data.error &&
            err.response.data.error.message;
          store.dispatch(
            fireNotification({
              message: error ? error : MESSAGE_NOTIFICATION_ERROR.EDIT_PROFILE,
              variant: Variant.ERROR
            })
          );
        });
      break;
    }
    case VERIFY_ACCOUNT: {
      Axios.post(`/users/verify/${action.payload.token}`, {
        password: action.payload.password,
        repassword: action.payload.repassword,
        type: action.payload.type ? action.payload.type : ''
      })
        .then(() => {
          store.dispatch(
            fireNotification({
              message: MESSAGE_NOTIFICATION_SUCCESS.CREATE_PASSWORD,
              variant: Variant.SUCCESS,
              link: PATH.login
            })
          );
        })
        .catch(err => {
          const error =
            err.response &&
            err.response.data &&
            err.response.data.error &&
            err.response.data.error.message;
          store.dispatch(
            fireNotification({
              message: error ? error : MESSAGE_NOTIFICATION_ERROR.EDIT_PROFILE,
              variant: Variant.ERROR
            })
          );
        });
      break;
    }
    case UPDATE_AVATAR: {
      Axios.put(`/users/avatar`, action.payload.formData, config)
        .then(() => {
          store.dispatch(
            fireNotification({
              message: MESSAGE_NOTIFICATION_SUCCESS.EDIT_AVATAR,
              variant: Variant.SUCCESS
            })
          );
          store.dispatch(getUserProfileAction());
        })
        .catch((err: Error) => {
          store.dispatch(
            fireNotification({
              message: MESSAGE_NOTIFICATION_ERROR.EDIT_PROFILE,
              variant: Variant.ERROR
            })
          );
        });
      break;
    }
    case UPDATE_USER_BY_ID: {
      Axios.patch(`/users/${action.payload.id}`, action.payload.data)
        .then(() => {
          store.dispatch(
            getAccountAction({
              type: action.payload.type,
              limit: action.payload.limit,
              offset: action.payload.offset
            })
          );
          store.dispatch(
            fireNotification({
              message: MESSAGE_NOTIFICATION_SUCCESS.UPDATE_USER,
              variant: Variant.SUCCESS
            })
          );
        })
        .catch(err =>
          store.dispatch(
            fireNotification({
              message: MESSAGE_NOTIFICATION_ERROR.EDIT_PROFILE,
              variant: Variant.ERROR
            })
          )
        );
      break;
    }
  }
  return dispatch(action);
};
