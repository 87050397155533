export const UPDATE_NOTIFICATION = 'UPDATE_NOTIFICATION';
export type UPDATE_NOTIFICATION = typeof UPDATE_NOTIFICATION;

export const EASE_NOTIFICATION = 'EASE_NOTIFICATION';
export type EASE_NOTIFICATION = typeof EASE_NOTIFICATION;

export enum MESSAGE_NOTIFICATION_SUCCESS {
  EDIT_PROFILE = ' Your Profile has been successfully updated.',
  RESET_PASSWORD = 'Your password has been successfully updated',
  CREATE_PASSWORD = 'Your password has been successfully created',
  SETUP_TWOFACTOR = 'Your second factor has been successfully updated',
  FORGOT_PASSWORD = 'Please check your email and click on the provided link to reset your password.',
  EDIT_AVATAR = 'Your Avatar has been successfully updated.',
  ADD_TRADING_ACCOUNT = 'Your account add success, it need to verify',
  ADD_AGENT_ACCOUNT = 'Add Agent Successfully!',
  UPDATE_CONFIG = 'Your configs has been successfully updated.',
  UPDATE_USER = 'User profile has been successfully updated.'
}

export enum MESSAGE_NOTIFICATION_ERROR {
  EDIT_PROFILE = ' Sorry, something went wrong. Please try Again.',
  VERIFY_CODE_INVALID = 'Verification code has already been used or has expired. Please try again with a new code.'
}
