import { Action } from 'redux';
import { GET_CARD_HOLDER, SET_CARD_HOLDER } from '../constants/hoder.constant';
import { IHolderRequest, IHolderContainer } from '../types';

export interface IGetCardHolderAction extends Action<GET_CARD_HOLDER> {
  payload: IHolderRequest;
}

export const getCardHolderAction = (
  request: IHolderRequest
): IGetCardHolderAction => ({
  type: GET_CARD_HOLDER,
  payload: request
});

export interface ISetCardHolderAction extends Action<SET_CARD_HOLDER> {
  payload: IHolderContainer;
}

export const setCardHolderAction = (
  holders: IHolderContainer
): ISetCardHolderAction => ({
  type: SET_CARD_HOLDER,
  payload: holders
});
