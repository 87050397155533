import * as React from 'react';
import {
  createStyles,
  Paper,
  Grid,
  Typography,
  Button,
  TextField,
  Theme
} from '@material-ui/core';
import { WithStyles, withStyles } from '@material-ui/styles';
import ExternalAgentLayout from '../../../components/ExternalAgentLayout';
import { History } from 'history';
import { PATH } from '../../../constants/Path.constant';
import { ILoginRequest, IVerifyCode } from '../types';
import { Dispatch } from 'redux';
import { IVerifyCodeAction, verifyCodeAction } from '../actions/auth';
import { connect } from 'react-redux';
import { IStoreState } from '../../../types';
import INotificationInfo from '../../../types/Notification.type';
import {
  fireNotification,
  IUpdateNotification
} from '../../../actions/Notification.action';
import { MESSAGE_NOTIFICATION_ERROR } from '../../../constants/Notification.constant';
import Variant from '../../../types/Variant.type';
const styles = (theme: Theme) =>
  createStyles({
    rootLogin: {
      width: 500,
      height: 400,
      [theme.breakpoints.down('xs')]: {
        width: '80%',
        padding: '0 25px',
        display: 'flex',
        flexDirection: 'column'
      }
    },
    titleHeader: {
      height: 100,
      [theme.breakpoints.down('xs')]: {
        height: 60
      },
      borderBottom: '1px solid #F1F1F4'
    },
    buttonLogin: {
      width: 195,
      textTransform: 'capitalize',
      marginLeft: 5,
      [theme.breakpoints.down('xs')]: {
        flex: 1
      }
    },
    textAllYou: {
      color: '#222222',
      fontWeight: 500
    },
    textGoogle: {
      color: '#00AFC1',
      fontWeight: 500,
      cursor: 'pointer'
    },

    bodyPager: {
      height: 'calc(100% - 220px )'
    },
    code: {
      textAlign: 'center',
      color: '#222222',
      fontFamily: 'Montserrat',
      fontStyle: 'normal',
      fontWeight: 'normal',
      fontSize: 40,
      lineHeight: '40px',
      letterSpacing: '0.3em',
      [theme.breakpoints.down('xs')]: {
        fontSize: 20
      }
    },
    buttonCanel: {
      color: '#222222',
      border: '1px solid #DCE0E4',
      width: 195,
      textTransform: 'capitalize',
      marginRight: 5,
      backgroundColor: '#FFFFFF',
      '&:hover': {
        backgroundColor: '#FFFFFF'
      },
      [theme.breakpoints.down('xs')]: {
        flex: 1
      }
    },
    headerText: {
      [theme.breakpoints.down('xs')]: {
        fontSize: 18
      }
    },
    textFiled: {
      marginTop: 23,
      height: 70,
      [theme.breakpoints.down('xs')]: {
        height: 40
      }
    },
    inputFiled: {
      height: '100%',
      backgroundColor: '#FAFAFB'
    }
  });

interface IProps extends WithStyles<typeof styles> {
  history: History<any>;
  code: string;
  fireNotification: (noti: INotificationInfo) => void;
  loginRequest: ILoginRequest;
  verifyCodeAction: (request: IVerifyCode) => void;
}

interface IState {
  code: string;
}

class VerifyLogin extends React.Component<IProps, IState> {
  state: IState = {
    code: ''
  };

  public redirectTo = (path: string) => (event: any) => {
    this.props.history.push(path);
  };

  handleTextChange = (event: any) => {
    const code: string = event.target.value;
    if (code.length > 6 || code.match(/\D/g)) {
      return;
    }

    this.setState({ code });
  };

  handleVerify = () => {
    const { loginRequest } = this.props.history.location.state;
    if (this.props.code === this.state.code) {
      return this.props.fireNotification({
        message: MESSAGE_NOTIFICATION_ERROR.VERIFY_CODE_INVALID,
        variant: Variant.ERROR
      });
    }
    const request: IVerifyCode = {
      code: this.state.code,
      password: loginRequest.password,
      username: loginRequest.username
    };
    this.props.verifyCodeAction(request);
  };
  public render() {
    const { classes } = this.props;
    return (
      <ExternalAgentLayout>
        <Paper elevation={1} className={classes.rootLogin}>
          <Grid
            container
            alignItems="center"
            justify="center"
            className={classes.titleHeader}
          >
            <Typography className={classes.headerText} variant="h2">
              Two-Factor Authentication
            </Typography>
          </Grid>
          <Grid
            container
            justify="center"
            alignContent="center"
            className={classes.bodyPager}
          >
            <Typography style={{ textAlign: 'center' }} variant="subtitle2">
              Enter the two-step Authentication code
            </Typography>

            <TextField
              autoFocus={true}
              className={classes.textFiled}
              InputProps={{
                className: classes.inputFiled
              }}
              value={this.state.code}
              inputProps={{
                className: classes.code
              }}
              placeholder="_ _ _ _ _ _"
              onChange={this.handleTextChange}
              variant="outlined"
            />
          </Grid>
          <Grid
            container
            justify="center"
            alignItems="center"
            style={{ borderTop: '1px solid #F1F1F4', height: 120 }}
          >
            <Button
              variant="contained"
              color="primary"
              size="large"
              className={classes.buttonCanel}
              onClick={this.redirectTo(PATH.login)}
            >
              <span style={{ color: '#222222' }}> Cancel</span>
            </Button>
            <Button
              variant="contained"
              color="primary"
              disabled={this.state.code.length !== 6}
              size="large"
              className={classes.buttonLogin}
              onClick={this.handleVerify}
            >
              Verify
            </Button>
          </Grid>
        </Paper>
      </ExternalAgentLayout>
    );
  }
}

const mapDispatchToProps = (
  dispatch: Dispatch<IVerifyCodeAction | IUpdateNotification>
) => {
  return {
    verifyCodeAction: (request: IVerifyCode) =>
      dispatch(verifyCodeAction(request)),
    fireNotification: (noti: INotificationInfo) =>
      dispatch(fireNotification(noti))
  };
};

const mapStateToProps = ({ code }: IStoreState) => {
  return { code };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(VerifyLogin));
