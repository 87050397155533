import * as React from 'react';
import {
  Typography,
  Paper,
  createStyles,
  Grid,
  TextField,
  Button,
  Theme,
  FormControl
} from '@material-ui/core';
import { WithStyles, withStyles } from '@material-ui/styles';
import { History } from 'history';
import { PATH } from '../../../constants/Path.constant';
import ExternalAgentLayout from '../../../components/ExternalAgentLayout';
import { Dispatch } from 'redux';
import { loginAction, ILoginAction } from '../actions/auth';
import { ILoginRequest } from '../types';
import { connect } from 'react-redux';
import { IStoreState } from '../../../types';
import { withRouter } from 'react-router';
import { validateEmail } from '../../../helper';
import { MESSAGE } from '../../../constants/Message.constant';
import { NotificationClearTokenDialog } from '../../../components/dialog/NotificationClearTokenDialog.component';
interface IProps extends WithStyles<typeof styles> {
  history: History;
  loginAction: (request: ILoginRequest) => void;
}

const styles = (theme: Theme) =>
  createStyles({
    rootLogin: {
      width: 500,
      height: 500,
      [theme.breakpoints.down('xs')]: {
        width: '80%',
        padding: '0 25px',
        height: 450
      }
    },
    titleHeader: {
      height: 100,
      borderBottom: '1px solid #F1F1F4',
      [theme.breakpoints.down('xs')]: {
        height: 60
      }
    },
    buttonLogin: {
      textTransform: 'capitalize'
      // marginTop: 30
    },
    textForgot: {
      fontSize: 14,
      fontWeight: 500,
      color: '#00AFC1',
      lineHeight: '17px',
      cursor: 'pointer',
      marginTop: 64
    },
    textFiled: {
      [theme.breakpoints.down('xs')]: {
        width: '100%'
      }
    }
  });

interface IState {
  username: string;
  password: string;
  authenticationType?: string;
  showDialogLogout: boolean;
}
class LoginAgent extends React.Component<IProps, IState> {
  state: IState = {
    username: '',
    password: '',
    showDialogLogout: false
  };

  handleTextChange = (key: string) => (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const value = event.target.value;
    this.setState({ [key]: value } as any);
  };
  public redirectTo = (path: string) => (
    event: React.MouseEvent<HTMLElement, MouseEvent>
  ) => {
    this.props.history.push(path);
  };

  handleLogin = () => {
    const { username, password } = this.state;
    if (!username || !password) {
      return;
    }
    const requestData: ILoginRequest = {
      username,
      password,
      authenticationType: 'agent'
    };
    this.props.loginAction(requestData);
  };

  handleKeyPress = (event: any) => {
    if (event.key === 'Enter') {
      this.handleLogin();
    }
  };

  componentDidMount = () => {
    const token = localStorage.getItem('token');
    if (token) {
      this.setState({
        showDialogLogout: true
      });
    } else {
      this.setState({
        showDialogLogout: false
      });
    }
  };
  closeNotification = () => {
    localStorage.clear();
    this.setState({
      showDialogLogout: false
    });
  };

  public render() {
    const { classes } = this.props;
    const { username, password } = this.state;
    return (
      <ExternalAgentLayout>
        <Paper elevation={1} className={classes.rootLogin}>
          <Grid
            container
            alignItems="center"
            justify="center"
            className={classes.titleHeader}
          >
            <Typography variant="h2"> Login</Typography>
          </Grid>
          <Grid
            container
            justify="center"
            alignContent="center"
            style={{ padding: '50px 0' }}
          >
            <FormControl required className={classes.textFiled}>
              <TextField
                className={classes.textFiled}
                name="Email"
                label="Email*"
                variant="outlined"
                error={username !== '' && !username.match(validateEmail)}
                helperText={
                  username !== '' && !username.match(validateEmail)
                    ? MESSAGE.ERROR.INVALID_EMAIL_FORMAT
                    : ''
                }
                value={username}
                onChange={this.handleTextChange('username')}
              />
            </FormControl>
            <FormControl required className={classes.textFiled}>
              <TextField
                className={classes.textFiled}
                name="Password"
                label="Password*"
                type="password"
                variant="outlined"
                value={password}
                onKeyPress={this.handleKeyPress}
                onChange={this.handleTextChange('password')}
                style={{ marginTop: 30 }}
              />
            </FormControl>
          </Grid>
          <Grid container alignItems="center" justify="center">
            <Button
              disabled={!password || !username}
              variant="contained"
              color="primary"
              data-test="Login"
              size="large"
              onClick={this.handleLogin}
              className={classes.buttonLogin}
            >
              Login
            </Button>
            <Typography
              className={classes.textForgot}
              data-test="forgot-password"
              onClick={this.redirectTo(PATH.forgotPassword)}
            >
              I forgot my password
            </Typography>
          </Grid>
        </Paper>
        <NotificationClearTokenDialog
          showDialog={this.state.showDialogLogout}
          message="Your account has been logged out"
          closeNotification={this.closeNotification}
        />
      </ExternalAgentLayout>
    );
  }
}

const mapDispatchToProps = (dispatch: Dispatch<ILoginAction>) => {
  return {
    loginAction: (request: ILoginRequest) => dispatch(loginAction(request))
  };
};

const mapStateToProps = ({ user }: IStoreState) => {
  return {
    profile: user.profile
  };
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(LoginAgent))
);
