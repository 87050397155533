import * as React from 'react';
import {
  WithStyles,
  withStyles,
  createStyles,
  Theme
} from '@material-ui/core/styles';
import { Typography } from '@material-ui/core';

interface IProps extends WithStyles<typeof styles> {
  title: string;
}

const styles = (theme: Theme) =>
  createStyles({
    textTitle: {
      color: '#333333',
      [theme.breakpoints.down('xs')]: {
        fontSize: 20
      }
    }
  });

class TitlePage extends React.PureComponent<IProps> {
  public render() {
    const { title, classes } = this.props;
    return (
      <Typography className={classes.textTitle} variant="h2">
        {title}
      </Typography>
    );
  }
}

export default withStyles(styles)(TitlePage);
