import { ROLE } from '../../../constants/auth.constant';

export interface IUserProfile {
  id: string;
  name: string;
  roleId: string;
  countryId: string;
  email: string;
  tomoAddress: string;
  tomoPaymentAddressId: string;
  username: string;
  phone: string;
  dob: any;
  invationCode: string;
  profitRate: string;
  initialRate: string;
  avatar: string;
  ethAddress: string;
  twoFactorEnabled: false;
  createdAt: string;
  updatedAt: string;
  commission: {
    currentLevel: number;
    currentTotal: number;
    nextLevel: number;
    nextTotal: number;
  };
  description: string;
  traderPosition?: ITraderPosition[];
  isActive: boolean;
  traderMargin?: ITraderMargin[];
}

export interface ITraderPosition {
  id: string;
  userId: string;
  account: string;
  currency: string;
  quoteCurrency: string;
  commission: string;
  initMarginReq: string;
  maintMarginReq: string;
  leverage: number;
  rebalancedPnl: number;
  prevRealisedPnl: number;
  currentQty: number;
  currentCost: string;
  currentComm: string;
  realisedCost: string;
  unrealisedCost: string;
  realisedPnl: number;
  unrealisedGrossPnl: number;
  unrealisedPnl: number;
  liquidationPrice: string;
  isOpen: boolean;
  timestamp: string;
  bitmexApiKey: string;
}

export interface ITraderMargin {
  id: string;
  userId: string;
  account: string;
  amount: string;
  prevRealisedPnl: number;
  prevUnRealisedPnl: number;
  realisedPnl: number;
  unrealisedPnl: number;
  walletBalance: number;
  currency: string;
  timestamp: string;
  bitmexApiKey: string;
}

export interface ICountry {
  id: string;
  name: string;
  isoCode2: string;
  isoCode3: string;
}
export interface IUser {
  profile: IUserProfile;
  qr: IQrImage;
  countries: ICountry[];
}
export const initUserProfile: IUserProfile = {
  id: '',
  name: '',
  roleId: '',
  countryId: '',
  email: '',
  tomoAddress: '',
  tomoPaymentAddressId: '',
  username: '',
  phone: '',
  dob: '',
  invationCode: '',
  profitRate: '',
  initialRate: '',
  avatar: '',
  ethAddress: '',
  twoFactorEnabled: false,
  createdAt: '',
  updatedAt: '',
  commission: {
    currentLevel: 0,
    currentTotal: 0,
    nextLevel: 0,
    nextTotal: 0
  },
  description: '',
  isActive: true
};

export interface IQrImage {
  secret: string;
  dataURI: string;
}

export const initQrImage: IQrImage = {
  secret: '',
  dataURI: ''
};

export interface ISecondFactorCode {
  code: string;
  off: boolean;
}

export const initUser: IUser = {
  countries: [],
  profile: initUserProfile,
  qr: initQrImage
};

export interface IUpdateAvatarRequest {
  formData: any;
}

export interface IUserIdInfo {
  id: string;
  data: any;
  type: ROLE;
  limit?: number;
  offset?: number;
}
