import React from 'react';
import {
  Typography,
  Theme,
  createStyles,
  WithStyles,
  withStyles,
  Dialog,
  Paper,
  Grid,
  Button,
  TextField
} from '@material-ui/core';
import { IUserProfile } from '../../scenes/setting/types';

const styles = (theme: Theme) =>
  createStyles({
    rootLogin: {
      width: 500,
      height: 600,
      [theme.breakpoints.down('xs')]: {
        width: '80%',
        padding: '0 10%',
        height: 500
      }
    },
    titleHeader: {
      height: 100,
      [theme.breakpoints.down('xs')]: {
        height: 60
      },
      borderBottom: '1px solid #F1F1F4'
    },
    bodyPager: {
      height: 'calc(100% - 280px )',
      marginTop: 60,
      backgroundColor: '#FFFFFF',
      [theme.breakpoints.down('xs')]: {
        marginTop: 30,
        height: 290
      }
    },
    buttonCanel: {
      color: '#222222',
      border: '1px solid #DCE0E4',
      width: 195,
      textTransform: 'capitalize',
      marginRight: 5,
      backgroundColor: '#FFFFFF',
      '&:hover': {
        backgroundColor: '#FFFFFF'
      },
      [theme.breakpoints.down('xs')]: {
        width: '80%',
        flex: 1
      }
    },
    buttonLogin: {
      width: 195,
      textTransform: 'capitalize',
      marginLeft: 5,
      [theme.breakpoints.down('xs')]: {
        width: '80%',
        flex: 1
      }
    },
    dialogLayout: {
      [theme.breakpoints.down('sm')]: {
        width: '80%',
        padding: '0 10%'
      }
    }
  });

interface IProps extends WithStyles<typeof styles> {
  showDialog: boolean;
  user: IUserProfile;
  handleCloseDialog: () => void;
  handleUpdateButton: (description: any) => void;
}

interface IState {
  description: string;
}
class EditTrading extends React.Component<IProps, IState> {
  state: IState = {
    description: this.props.user.description || ''
  };

  handleTextChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({ description: e.target.value });
  };

  handleOkButton = () => {
    const { description } = this.state;
    this.props.handleUpdateButton({ description });
  };
  public render() {
    const { showDialog, classes } = this.props;
    const { description } = this.state;
    return (
      <Dialog
        onClose={this.props.handleCloseDialog}
        aria-labelledby="customized-dialog-title"
        open={showDialog}
        className={classes.dialogLayout}
        maxWidth="md"
      >
        <Paper elevation={1} className={classes.rootLogin}>
          <Grid
            container
            alignItems="center"
            justify="center"
            className={classes.titleHeader}
          >
            <Typography variant="h2">Edit Description</Typography>
          </Grid>
          <Grid container justify="center" className={classes.bodyPager}>
            <TextField
              InputLabelProps={{
                style: {
                  backgroundColor: '#FFFFFF',
                  paddingRight: 5
                }
              }}
              onChange={this.handleTextChange}
              error={description.length > 1500}
              helperText={
                description.length > 1500
                  ? 'The maximum length of description that you can edit is 1500 characters'
                  : ''
              }
              label="Please enter Description"
              rows="14"
              multiline
              value={this.state.description}
              variant="outlined"
              style={{ height: '100%' }}
            />
          </Grid>
          <Grid
            container
            justify="center"
            alignItems="center"
            style={{
              borderTop: '1px solid #F1F1F4',
              height: 120
            }}
          >
            <Button
              variant="contained"
              color="primary"
              size="large"
              className={classes.buttonCanel}
              onClick={this.props.handleCloseDialog}
            >
              <span style={{ color: '#222222' }}> Cancel</span>
            </Button>
            <Button
              variant="contained"
              color="primary"
              disabled={
                this.state.description.length > 1500 ||
                description === this.props.user.description
              }
              size="large"
              onClick={this.handleOkButton}
              className={classes.buttonLogin}
            >
              Save
            </Button>
          </Grid>
        </Paper>
      </Dialog>
    );
  }
}

export default withStyles(styles)(EditTrading);
