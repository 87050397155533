import * as React from 'react';
import {
  WithStyles,
  withStyles,
  createStyles,
  Theme
} from '@material-ui/core/styles';
import { Grid, Button } from '@material-ui/core';
import { History } from 'history';
import LogoHeaderComponent from './LogoHeader.component';
export interface IMenuHeader {
  id: number;
  text: string;
  link: string;
}
interface IProps extends WithStyles<typeof styles> {
  history: History;
  isHideAppBar?: boolean;
  listMenu: IMenuHeader[];
}

const styles = (theme: Theme) =>
  createStyles({
    backgroundHeader: {
      backgroundColor: '#000000',
      height: 70
    },
    backgroundBody: {
      borderTop: '1px solid #333333',
      backgroundColor: '#171717',
      height: 60,
      [theme.breakpoints.down(325)]: {
        overflowX: 'scroll',
        display: 'inline-block',
        flex: '1 1 auto',
        position: 'relative',
        whiteSpace: 'nowrap'
      }
    },

    hoverButton: {
      height: '100%',
      '&:after': {
        content: "''",
        backgroundColor: '#00AFC1',
        position: 'absolute',
        width: 70,
        height: 5,
        bottom: 0,
        borderTopLeftRadius: 5,
        borderTopRightRadius: 5
      }
    },
    labelButtonNoActive: {
      textTransform: 'capitalize',
      color: '#7F8485'
    },
    labelButtonActive: {
      textTransform: 'capitalize',
      color: '#FFFFFF'
    },
    textButton: {
      padding: '6px 20px'
    }
  });

class Header extends React.Component<IProps> {
  public redirectTo = (path: string) => (event: any) => {
    this.props.history.push(path);
  };

  public render() {
    const { classes } = this.props;
    return (
      <>
        <LogoHeaderComponent />
        {!this.props.isHideAppBar && (
          <Grid container justify="center" className={classes.backgroundBody}>
            {this.props.listMenu.map((el, key) => (
              <Button
                key={key}
                onClick={this.redirectTo(el.link)}
                className={
                  el.link === this.props.history.location.pathname
                    ? classes.hoverButton
                    : ''
                }
                classes={{
                  text: classes.textButton
                }}
              >
                <span
                  className={
                    el.link === this.props.history.location.pathname
                      ? classes.labelButtonActive
                      : classes.labelButtonNoActive
                  }
                >
                  {' '}
                  {el.text}
                </span>{' '}
              </Button>
            ))}
          </Grid>
        )}
      </>
    );
  }
}

export default withStyles(styles)(Header);
