import React from 'react';
import { ITableStoreState, ITableSettings, ISearchInfo } from './redux/types';
import CustomTable from './component/Table.component';
import { tableData, loading } from './redux/reducers';
import { combineReducers, createStore, applyMiddleware } from 'redux';
import { Provider } from 'react-redux';
import { getTableData } from './redux/actions';

// tslint:disable-next-line: no-var-requires
const middlewares = require('./redux/middlewares/index');
const midws = Object.keys(middlewares).map(key => middlewares[key]);

interface IProps {
  api: string;
  params?: {};
  settings: ITableSettings;
  searchInfo?: ISearchInfo;
  reload?: boolean;
}

class TableComponent extends React.Component<IProps> {
  public store: any;
  constructor(props: IProps) {
    super(props);
    this.store = createStore<ITableStoreState, any, any, any>(
      combineReducers<ITableStoreState>({ tableData, loading }),
      applyMiddleware(...midws)
    );
  }
  UNSAFE_componentWillMount() {
    this.store.dispatch(getTableData(this.props.api, this.props.params));
  }
  componentWillReceiveProps(nextProps: IProps) {
    if (
      JSON.stringify(nextProps.params) !== JSON.stringify(this.props.params)
    ) {
      this.store.dispatch(getTableData(nextProps.api, nextProps.params));
    }
  }
  render() {
    return (
      <Provider store={this.store}>
        <CustomTable
          searchInfo={this.props.searchInfo}
          settings={this.props.settings}
          api={this.props.api}
          params={this.props.params}
          reload={this.props.reload}
        />
      </Provider>
    );
  }
}

export default TableComponent;
