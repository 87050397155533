import React from 'react';
import { IPaymentSumary } from '../types';
import {
  Grid,
  Paper,
  Typography,
  createStyles,
  withStyles,
  Button,
  Theme
} from '@material-ui/core';
import {
  TableDataRawComponent,
  ITEM_TYPE
} from '../../../components/shared/TableRaw.component';
import { WithStyles } from '@material-ui/styles';
import iconActiveNft from '../../../assets/images/tomo.svg';
import iconCurrent from '../../../assets/images/icon_info.svg';
import iconPaymentHistory from '../../../assets/images/iconPaymentHistory.svg';
import { PATH } from '../../../constants/Path.constant';
import { History } from 'history';
import { IStoreState } from '../../../types';
import { Dispatch } from 'redux';
import {
  IGetPaymentSumaryAction,
  getPaymentSumaryAction
} from '../actions/payment.action';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { formatNumberToFixed } from '../../../helper';

const styles = (theme: Theme) =>
  createStyles({
    backgroundPaymentHistory: {
      background: 'linear-gradient(134.17deg, #FFC046 1.33%, #C2570A 100%)',
      height: 370
    },
    backgroundActiveNft: {
      background: 'linear-gradient(152.37deg, #63FDF4 -18.04%, #0A54C2 100%)',
      height: 180,
      [theme.breakpoints.down('sm')]: {
        flex: 1,
        marginRight: 5
      }
    },
    backgroundActiveNftVolume: {
      marginTop: 10,
      background: 'linear-gradient(153.4deg, #506BCA 1.33%, #13266C 100%)',
      height: 180,
      [theme.breakpoints.down('sm')]: {
        flex: 1,
        marginTop: 0,
        marginLeft: 5
      }
    },
    textActiveNft: {
      color: '#FFFFFF'
    },
    textTotal: {
      fontSize: 24,
      color: '#FFFFFF',
      lineHeight: '39px',
      fontWeight: 500,
      [theme.breakpoints.down('xs')]: {
        fontSize: 12
      }
      // position: 'absolute',
      // bottom: 0
    },
    buttonPaymentHistory: {
      border: '1px solid #F1F1F1',
      width: 160,
      height: 30,
      borderRadius: 15,
      cursor: 'pointer',
      position: 'absolute',
      bottom: 0
    },
    activeNftVolume: {
      height: '100%',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'space-between',
      padding: 26,
      [theme.breakpoints.down('xs')]: {
        padding: 15
      }
    },
    cardCurrent: {
      padding: 26,
      [theme.breakpoints.down('xs')]: {
        padding: 15
      },
      height: 'calc(100% - 52px)'
    },
    cardTotal: {
      height: '50%',
      borderBottom: '1px dashed rgba(255, 255, 255, 0.6)'
    },
    cardCommission: {
      height: '50%',
      position: 'relative'
    },
    containerCard: {
      [theme.breakpoints.down('xs')]: {
        height: 60
      }
    },
    activeSummaryLayout: {
      paddingRight: 5,
      width: '100%',
      [theme.breakpoints.down('sm')]: {
        display: 'flex',
        paddingRight: 0
      }
    },
    profitLayout: {
      paddingLeft: 5,
      [theme.breakpoints.down('sm')]: {
        width: '100%',
        marginTop: 20,
        paddingLeft: 0
      }
    }
  });

interface IProps extends WithStyles<typeof styles> {
  sumary: IPaymentSumary;
  history: History;
  getPaymentSumaryAction: () => void;
}
class AgentPaymentSumary extends React.Component<IProps> {
  goToPaymentHistory = () => {
    this.props.history.push(PATH.agent.paymentHistory);
  };
  componentDidMount() {
    this.props.getPaymentSumaryAction();
  }
  render() {
    const { classes, sumary } = this.props;
    console.log(
      sumary.activeProfit,
      formatNumberToFixed(sumary.activeProfit, 2)
    );
    return (
      <React.Fragment>
        <Grid item md={6} lg={6} className={classes.activeSummaryLayout}>
          <Paper elevation={0} className={classes.backgroundActiveNft}>
            {/* <Grid className={classes.activeNftVolume}> */}
            <Grid
              container
              className={classes.activeNftVolume}
              data-test="sumary-active-nft"
            >
              <TableDataRawComponent
                items={{
                  title: 'Active nft',
                  value:
                    sumary.activeNFT +
                    ' NFT' +
                    (sumary.activeNFT > 1 ? 's' : '')
                }}
                type={ITEM_TYPE.DEFAULT}
              />
              {/* <Grid style={{ marginTop: 25 }} data-test="sumary-active-profit"> */}
              <TableDataRawComponent
                items={{
                  title: 'Active profit',
                  value: formatNumberToFixed(sumary.activeProfit, 2) + ' TOMO'
                }}
                type={ITEM_TYPE.DEFAULT}
              />
              {/* </Grid> */}
              {/* </Grid> */}
            </Grid>
          </Paper>
          <Paper elevation={0} className={classes.backgroundActiveNftVolume}>
            {/* <Grid className={classes.activeNftVolume}> */}
            <Grid
              container
              className={classes.activeNftVolume}
              style={{ display: 'flex', flexDirection: 'column' }}
            >
              {/* <Grid item style={{ position: 'relative' }}> */}
              <Typography variant="subtitle1" className={classes.textActiveNft}>
                Active nft volume
              </Typography>
              <Grid container justify="flex-end" item>
                <img
                  style={{ width: 100, height: 70 }}
                  src={iconActiveNft}
                  alt="icon active"
                />
              </Grid>
              <Typography
                variant="subtitle1"
                className={classes.textTotal}
                data-test="sumary-active-nftvolume"
              >
                {formatNumberToFixed(sumary.activeNFTVolume, 2)}
              </Typography>
              {/* </Grid> */}
            </Grid>
            {/* </Grid> */}
          </Paper>
        </Grid>
        <Grid item md={6} lg={6} className={classes.profitLayout}>
          <Paper elevation={0} className={classes.backgroundPaymentHistory}>
            <Grid className={classes.cardCurrent}>
              <Grid className={classes.cardTotal}>
                <Grid container>
                  <Grid item xs={6} md={6} lg={6} data-test="sumary-total-nft">
                    <TableDataRawComponent
                      items={{
                        title: 'Total NFT',
                        value:
                          sumary.totalNFT +
                          ' NFT' +
                          (sumary.totalNFT > 1 ? 's' : '')
                      }}
                      type={ITEM_TYPE.DEFAULT}
                    />
                  </Grid>
                  <Grid
                    container
                    justify="center"
                    item
                    xs={6}
                    md={6}
                    lg={6}
                    data-test="sumary-total-nftVolume"
                  >
                    <TableDataRawComponent
                      items={{
                        title: 'Total volume',
                        value: formatNumberToFixed(sumary.totalNFTVolume, 2)
                      }}
                      type={ITEM_TYPE.ICON_VALUE}
                    />
                  </Grid>
                </Grid>
                <Grid container style={{ marginTop: 25 }}>
                  <TableDataRawComponent
                    items={{
                      title: 'Total profit',
                      value:
                        formatNumberToFixed(sumary.totalProfit, 2) + ' TOMO'
                    }}
                    type={ITEM_TYPE.DEFAULT}
                  />
                </Grid>
              </Grid>
              <Grid className={classes.cardCommission}>
                <Grid container item lg={12} style={{ paddingTop: 35 }}>
                  <Typography variant="subtitle1" style={{ color: '#222222' }}>
                    current commission
                  </Typography>
                </Grid>
                <Grid container item lg={12} style={{ marginTop: 5 }}>
                  <Typography
                    variant="subtitle2"
                    style={{ color: '#FFFFFF' }}
                    data-test="sumary-commission-current"
                  >
                    {sumary.currentLevel + '%'}
                  </Typography>
                  <Typography
                    variant="subtitle2"
                    style={{ color: '#FFFFFF', marginLeft: 30 }}
                    data-test="sumary-commission-next"
                  >
                    {`Next level: ${sumary.nextLevel}%`}
                  </Typography>
                  <img
                    src={iconCurrent}
                    alt="icon current"
                    style={{ marginLeft: 6 }}
                  />
                </Grid>
                <Grid />
                <Grid
                  container
                  alignContent="center"
                  justify="center"
                  className={classes.buttonPaymentHistory}
                >
                  <Button
                    onClick={this.goToPaymentHistory}
                    data-test="sumary-payment-history"
                  >
                    <Typography
                      variant="h4"
                      style={{
                        color: '#FFFFFF',
                        fontWeight: 600,
                        textTransform: 'none'
                      }}
                    >
                      Payment History
                    </Typography>
                    <img
                      src={iconPaymentHistory}
                      alt="icon payment"
                      style={{ marginLeft: 6 }}
                    />
                  </Button>
                </Grid>
              </Grid>
            </Grid>
          </Paper>
        </Grid>
      </React.Fragment>
    );
  }
}

const mapStateToProps = ({ paymentSumary }: IStoreState) => {
  return {
    sumary: paymentSumary
  };
};

const mapDispatchToProps = (dispatch: Dispatch<IGetPaymentSumaryAction>) => {
  return {
    getPaymentSumaryAction: () => dispatch(getPaymentSumaryAction())
  };
};
export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(withStyles(styles)(AgentPaymentSumary))
);
