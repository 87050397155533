import React from 'react';
import {
  Paper,
  Theme,
  createStyles,
  WithStyles,
  withStyles,
  Tooltip,
  Typography,
  IconButton,
  Table,
  TableHead,
  TableRow,
  TableBody,
  TablePagination,
  TableCell,
  Button,
  Snackbar
} from '@material-ui/core';
import {
  ITableSettings,
  IMetaData,
  ITableHeader
} from '../../../../components/shared/table/redux/types';
import { formatDate } from '../../../../helper';
import { Dispatch } from 'redux';
import { IGetAccountAction, getAccountAction } from '../Top/actions';
import { IParams, IStoreState } from '../../../../types';
import { connect } from 'react-redux';
import memoizeOne from 'memoize-one';
import { IUserProfile, IUserIdInfo } from '../../../setting/types';
import { Link } from 'react-router-dom';
import CheckCircleOutlineSharpIcon from '@material-ui/icons/CheckCircleOutlineSharp';
import classNames from 'classnames';
import _ from 'lodash';
import PaginationComponent from '../../../../components/shared/table/component/Pagination.component';
import { IUpdateUserById, updateUserById } from '../../../setting/actions/user';
import { ROLE } from '../../../../constants/auth.constant';
import ConfirmDialog from '../../../../components/dialog/Confirm.dialog';
import copy_icon from '../../../../assets/images/copy_icon.svg';

const styles = (theme: Theme) =>
  createStyles({
    root: {
      width: '100%'
    },
    tableWrapper: {
      // minHeight: 561,
      overflow: 'auto'
    },
    tableHead: {
      background: '#FFFFFF',
      borderRadius: '5px 5px 0px 0px'
    },
    text: {
      fontFamily: 'Montserrat',
      fontStyle: 'normal'
    },
    headerText: {
      fontWeight: 600,
      fontSize: 12,
      lineHeight: '15px',
      textTransform: 'uppercase',
      color: '#000000',
      [theme.breakpoints.up(1020)]: {
        [theme.breakpoints.down(1371)]: {
          fontSize: 10
        }
      }
    },
    firstColumn: {
      fontWeight: 500,
      fontSize: 14,
      lineHeight: '17px',
      color: '#222222',
      paddingLeft: 20
    },
    labelPagination: {
      fontWeight: 500,
      fontSize: 12,
      lineHeight: '15px',
      color: '#A1A9B4'
    },
    pagination: {
      display: 'flex',
      borderTop: '1px solid #F1F1F4'
    },
    borderBottom: {
      borderBottom: '1px solid #F1F1F4'
    },
    tableValue: {
      paddingRight: 20
    },
    border: {
      borderBottom: 'none'
    },
    iconCenter: {
      marginBottom: -5
    }
  });

const invationCodeKey = 'invationCode';
const tableSettings: ITableSettings = {
  pagination: {
    show: true,
    option: {
      rowsPerPage: 10
    }
  },
  tableColumn: [
    {
      label: 'EMAIL ADDRESS',
      key: `email`,
      width: '20%',
      styles: {
        fontFamily: 'Montserrat',
        fontWeight: '600',
        fontSize: '14px',
        lineHeight: '15px',
        color: '#222222'
      }
    },
    {
      label: 'DATE ADD',
      key: `createdAt`,
      format: formatDate,
      width: '15%',
      styles: {
        fontFamily: 'Montserrat',
        fontWeight: '500',
        fontSize: '14px',
        lineHeight: '15px',
        color: '#222222'
      },
      sortable: {
        key: 'createdAt',
        order: 'desc',
        type: 'date'
      }
    },
    {
      label: 'Invation Code',
      key: invationCodeKey,
      width: '10%',
      styles: {
        fontFamily: 'Montserrat',
        fontWeight: '500',
        fontSize: '14px',
        lineHeight: '15px',
        color: '#222222'
      }
    },
    {
      label: 'TOMO ADDRESS',
      key: 'tomoAddress',
      width: '15%',
      styles: {
        fontFamily: 'Montserrat',
        fontWeight: '500',
        fontSize: '14px',
        lineHeight: '12px',
        color: '#222222'
      },
      align: 'right',
      link: true,
      hash: 'address'
    },

    {
      label: 'ACTIVE',
      key: 'isActive',
      width: '20%',
      styles: {
        fontFamily: 'Montserrat',
        fontWeight: '500',
        fontSize: '14px',
        lineHeight: '12px',
        color: '#222222'
      },
      align: 'right',
      editing: true,
      sortable: {
        key: 'isActive',
        order: 'desc',
        type: 'number'
      }
    }
  ]
};

interface IProps extends WithStyles<typeof styles> {
  searchText: string;
  users: {
    data: IUserProfile[];
    meta: IMetaData;
  };
  getAgentAccountAction: (params: IParams) => void;
  updateUserStatus: (request: IUserIdInfo) => void;
}

interface IState {
  page: number;
  rowsPerPage: number;
  showConfirm: boolean;
  userId: string;
  status: boolean;
  openCopy: boolean;
}

class TableAgent extends React.PureComponent<IProps, IState> {
  state: IState = {
    page: 0,
    rowsPerPage: 10,
    showConfirm: false,
    userId: '',
    status: false,
    openCopy: false
  };
  componentDidMount() {
    const params: IParams = {
      limit: 10,
      offset: 0,
      type: 'Agent'
    };

    this.props.getAgentAccountAction(params);
  }

  handleChangePage = (event: unknown, newPage: number) => {
    const params: IParams = {
      limit: this.state.rowsPerPage,
      offset: newPage * this.state.rowsPerPage,
      type: 'Agent'
    };

    this.props.getAgentAccountAction(params);
    this.setState({ page: newPage });
  };

  renderNoDataText = () => {
    return (
      <TableRow>
        <TableCell colSpan={tableSettings.tableColumn.length + 1}>
          <Typography
            variant="h2"
            style={{ padding: '30px 0', textAlign: 'center' }}
          >
            No data
          </Typography>
        </TableCell>
      </TableRow>
    );
  };

  handleCloseConfirm = () => {
    this.setState({
      showConfirm: false
    });
  };
  handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    const params: IParams = {
      limit: +event.target.value,
      offset: 0,
      type: 'Agent'
    };
    this.props.getAgentAccountAction(params);
    this.setState({ rowsPerPage: +event.target.value, page: 0 });
  };

  formatHash = (hash: string) => {
    return (
      hash &&
      hash
        .slice(0, 6)
        .concat('...')
        .concat(hash.slice(-6))
    );
  };

  public copyAddress = (value: string) => () => {
    const textArea = document.createElement('textarea');
    document.body.appendChild(textArea);
    textArea.value = value;
    textArea.select();
    document.execCommand('copy');
    textArea.remove();
    this.setState({ openCopy: true });
  };
  handleCloseSnackbar = () => {
    this.setState({
      openCopy: false
    });
  };

  renderValue = (header: ITableHeader, value: any, userId: string) => {
    return header.link ? (
      <Link
        style={{
          color: '#3DACEB ',
          textDecoration: 'none'
        }}
        to={{
          pathname: `${process.env.REACT_APP_SCAN_URL}/${header.hash}/${
            header.format ? header.format(value) : value
          }`
        }}
        target="true"
      >
        {this.formatHash(value)}
      </Link>
    ) : header.editing ? (
      value ? (
        <Tooltip
          title={
            <Typography style={{ color: '#9BA0A6' }} variant="subtitle2">
              {' '}
              Block user
            </Typography>
          }
          style={{ color: '#222222' }}
        >
          <IconButton onClick={this.handleUserStatus(false, userId)}>
            <CheckCircleOutlineSharpIcon color={'primary'} />
          </IconButton>
        </Tooltip>
      ) : (
        <Tooltip
          title={<Typography variant="subtitle2"> active user</Typography>}
          placement="bottom"
        >
          <IconButton onClick={this.handleUserStatus(true, userId)}>
            <CheckCircleOutlineSharpIcon color={'disabled'} />
          </IconButton>
        </Tooltip>
      )
    ) : (
      <div>
        {header.preFix} {header.format ? header.format(value) : value}{' '}
        {header.postFix}
      </div>
    );
  };

  filterData = memoizeOne((users: IUserProfile[], searchText: string) =>
    users.filter(e => e.email.includes(searchText))
  );

  handleUserStatus = (status: boolean, id: string) => (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    this.setState({
      userId: id,
      status,
      showConfirm: true
    });
  };
  handleSave = () => {
    const { userId, status, page, rowsPerPage } = this.state;
    const request: IUserIdInfo = {
      data: {
        isActive: status
      },
      id: userId,
      type: ROLE.AGENT,
      limit: rowsPerPage,
      offset: rowsPerPage * page
    };
    this.props.updateUserStatus(request);
    this.handleCloseConfirm();
  };
  render() {
    const { classes, users, searchText } = this.props;
    const { rowsPerPage } = this.state;
    const data = this.filterData(users.data, searchText);
    return (
      <Paper elevation={0}>
        <div className={classes.tableWrapper}>
          <Table stickyHeader>
            <TableHead>
              <TableRow>
                <TableCell
                  className={classNames(
                    classes.borderBottom,
                    classes.tableHead
                  )}
                  align={'center'}
                  style={{ minWidth: '30px', paddingLeft: '0 20px' }}
                >
                  {'#'}
                </TableCell>
                {tableSettings.tableColumn.map((e, index) => (
                  <TableCell
                    valign="middle"
                    className={classNames(
                      classes.text,
                      classes.headerText,
                      classes.borderBottom,
                      classes.tableHead
                    )}
                    key={index}
                    align={e.align ? e.align : 'left'}
                    style={{
                      minWidth: e.width,
                      paddingRight: 20
                    }}
                  >
                    {e.label}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {data && data.length
                ? data.map((row, rowIndex) => {
                    return (
                      <TableRow key={rowIndex}>
                        <TableCell
                          className={classNames(
                            classes.text,
                            classes.firstColumn,
                            rowIndex + 1 === rowsPerPage ? classes.border : ''
                          )}
                        >
                          {rowIndex +
                            (users.meta.currentPage - 1) * users.meta.limit +
                            1}
                        </TableCell>
                        {tableSettings.tableColumn.map((e, index) => {
                          const value = _.get(row, e.key);
                          if (e.key === invationCodeKey) {
                            return (
                              <TableCell
                                className={classNames(
                                  rowIndex + 1 === rowsPerPage
                                    ? classes.border
                                    : '',
                                  classes.tableValue
                                )}
                                align={e.align}
                                style={e.styles}
                                key={index}
                              >
                                <Button
                                  data-test="profile-code"
                                  style={{
                                    border: '1px solid #DBDEE2',
                                    boxSizing: 'border-box',
                                    borderRadius: 15,
                                    height: 30
                                  }}
                                  onClick={this.copyAddress(value)}
                                >
                                  <Typography
                                    style={{
                                      fontWeight: 500,
                                      fontStyle: 'normal',
                                      fontFamily: 'Montserrat',
                                      lineHeight: '15px',
                                      fontSize: 12,
                                      textTransform: 'none',
                                      color: '#444444'
                                    }}
                                  >{`Referral Code : ${value}`}</Typography>
                                  <img
                                    src={copy_icon}
                                    alt=""
                                    width={10}
                                    height={10}
                                    style={{ paddingLeft: 10 }}
                                  />
                                </Button>
                              </TableCell>
                            );
                          }
                          return (
                            <TableCell
                              className={classNames(
                                rowIndex + 1 === rowsPerPage
                                  ? classes.border
                                  : '',
                                classes.tableValue
                              )}
                              align={e.align}
                              style={e.styles}
                              key={index}
                            >
                              {this.renderValue(e, value, row.id)}
                            </TableCell>
                          );
                        })}
                      </TableRow>
                    );
                  })
                : this.renderNoDataText()}
            </TableBody>
          </Table>
        </div>
        {data.length > 0 ? (
          <TablePagination
            rowsPerPageOptions={[10, 25, 100]}
            component="div"
            className={classes.pagination}
            count={users.meta.total}
            // rowsPerPage={rowsPerPage}
            // labelRowsPerPage={
            //   <Typography
            //     component="span"
            //     className={classNames(classes.text, classes.labelPagination)}
            //   >
            //     {'Residents per page:'}
            //   </Typography>
            // }
            // page={page}
            rowsPerPage={users.meta.limit ? users.meta.limit : 10}
            page={users.meta.currentPage ? users.meta.currentPage - 1 : 0}
            onChangePage={this.handleChangePage}
            onChangeRowsPerPage={this.handleChangeRowsPerPage}
            ActionsComponent={PaginationComponent}
          />
        ) : null}
        <ConfirmDialog
          showDialog={this.state.showConfirm}
          handleCloseDialog={this.handleCloseConfirm}
          textHeader="Change agent status"
          textContent={`Are you sure you want to save changes ?`}
          handleOkButton={this.handleSave}
        />
        <Snackbar
          anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
          open={this.state.openCopy}
          onClose={this.handleCloseSnackbar}
          autoHideDuration={2000}
          ContentProps={{
            'aria-describedby': 'message-id'
          }}
          message="Copied to Clipboard"
        />
      </Paper>
    );
  }
}

const mapDispatchToProps = (
  dispatch: Dispatch<IGetAccountAction | IUpdateUserById>
) => ({
  getAgentAccountAction: (params: IParams) =>
    dispatch(getAccountAction(params)),
  updateUserStatus: (request: IUserIdInfo) => dispatch(updateUserById(request))
});

const mapStateToProps = ({ accounts }: IStoreState) => ({
  users: accounts.Agent
});
export const TableAgentComponent = connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(TableAgent));
