import {
  IGetCardHolderAction,
  setCardHolderAction
} from '../actions/holder.action';
import { GET_CARD_HOLDER } from '../constants/hoder.constant';
import Axios from 'axios';
import { IStoreState } from '../../../types';
import { Dispatch, Store } from 'redux';
import { fireNotification } from '../../../actions/Notification.action';
import Variant from '../../../types/Variant.type';
import { PATH } from '../../../constants/Path.constant';

export const holderMiddleware = (store: Store<IStoreState>) => (
  dispatch: Dispatch<IGetCardHolderAction>
) => (action: IGetCardHolderAction) => {
  if (action.type === GET_CARD_HOLDER) {
    Axios.get(`/users/holders`, { params: action.payload })
      .then(res => {
        store.dispatch(
          setCardHolderAction({ data: res.data.data, meta: res.data.meta })
        );
      })
      .catch(err => {
        const statusCode =
          err &&
          err.response &&
          err.response.data &&
          err.response.data.error &&
          err.response.data.error.statusCode;
        console.log(statusCode, typeof statusCode);

        const message =
          err &&
          err.response &&
          err.response.data &&
          err.response.data.error &&
          err.response.data.error.message;
        if (statusCode === 403) {
          store.dispatch(
            fireNotification({
              message,
              variant: Variant.ERROR,
              link: statusCode === 403 ? PATH.notFound : undefined
            })
          );
        }
      });
  }

  return dispatch(action);
};
