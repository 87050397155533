import React from 'react';

interface IProps {
  children: React.ReactElement;
  value: number;
  open: boolean;
}

function SliderTooltip(props: IProps) {
  return <div>{props.children}</div>;
}

export default SliderTooltip;
