export const LOGIN = 'LOGIN';
export type LOGIN = typeof LOGIN;

export const SET_TOKEN = 'SET_TOKEN';
export type SET_TOKEN = typeof SET_TOKEN;

export const FORGOT_PASSWORD = 'FORGOT_PASSWORD';
export type FORGOT_PASSWORD = typeof FORGOT_PASSWORD;

export const RESET_PASSWORD = 'RESET_PASSWORD';
export type RESET_PASSWORD = typeof RESET_PASSWORD;

export const LOGOUT = 'LOGOUT';
export type LOGOUT = typeof LOGOUT;

export const VERIFYCODE = 'VERIFYCODE';
export type VERIFYCODE = typeof VERIFYCODE;

export const CHANGE_PASSWORD = 'CHANGE_PASSWORD';
export type CHANGE_PASSWORD = typeof CHANGE_PASSWORD;

export const GET_ROLE_ID = 'GET_ROLE_ID';
export type GET_ROLE_ID = typeof GET_ROLE_ID;

export const SET_ROLE_ID = 'SET_ROLE_ID';
export type SET_ROLE_ID = typeof SET_ROLE_ID;

export const SET_CODE = 'SET_CODE';
export type SET_CODE = typeof SET_CODE;
