import {
  ISetTableData,
  SET_TABLE_DATA,
  ISetLoadingAction,
  SET_LOADING
} from '../actions';
import { IResponseData } from '../types';

const initResponseData: IResponseData = {
  data: [],
  meta: {
    currentPage: 0,
    limit: 0,
    offset: 0,
    pageCount: 0,
    total: 0
  }
};
export const tableData = (
  state: IResponseData = initResponseData,
  action: ISetTableData
): IResponseData => {
  switch (action.type) {
    case SET_TABLE_DATA: {
      return action.payload;
    }
  }
  return state;
};

export const loading = (
  state: boolean = false,
  action: ISetLoadingAction
): boolean => {
  if (action.type === SET_LOADING) {
    return action.payload;
  }
  return state;
};
