import React from 'react';
import { Grid, Typography, IconButton, TextField } from '@material-ui/core';
import iconEditAdress from '../../../assets/images/iconEditAdress.svg';
import SliderComponent from './Slider.component';

class TradingContract extends React.Component {
  render() {
    return (
      <Grid container style={{ flexDirection: 'column' }}>
        <Grid item style={{ display: 'flex', marginTop: 8 }}>
          <Grid style={{ flex: 1 }}>
            <Typography variant="subtitle1" style={{ color: '#222222' }}>
              0
              <span style={{ color: '#9BA0A6', marginLeft: 8 }}>Contracts</span>
            </Typography>
          </Grid>
          <Grid>
            <Typography variant="subtitle1" style={{ color: '#222222' }}>
              0.00%
              <span style={{ color: '#9BA0A6', marginLeft: 3 }}>ROE</span>
            </Typography>
          </Grid>
        </Grid>
        <Grid
          style={{
            height: 90,
            background: '#FAFAFB',
            boxShadow: 'inset 0px 0px 10px rgba(0, 0, 0, 0.05)',
            borderRadius: 10,
            marginTop: 11
          }}
        >
          <SliderComponent usdVolume={0} />
        </Grid>
        <Grid
          item
          style={{ display: 'flex', alignItems: 'center', marginTop: 18 }}
        >
          <Grid style={{ flex: 1, display: 'flex' }}>
            <Typography
              variant="subtitle1"
              style={{ fontSize: 12, lineHeight: '15px' }}
            >
              Risk Limit
            </Typography>
          </Grid>
          <Grid
            style={{
              flex: 1,
              display: 'flex',
              justifyContent: 'flex-end',
              alignItems: 'center'
            }}
          >
            <TextField
              style={{
                width: '100%',
                height: 30,
                backgroundColor: 'transparent'
              }}
              InputProps={{
                disableUnderline: true
              }}
              inputProps={{
                style: {
                  textAlign: 'right'
                }
              }}
              value={'0.0000 / 200 XBT'}
            />
            <IconButton
              aria-label="Close"
              style={{ padding: 0 }}
              // onClick={this.handleOpenEditAdress}
              // classes={{
              //   root: classes.editIconButton
              // }}
            >
              <img src={iconEditAdress} alt="icon Adress" />
            </IconButton>
          </Grid>
        </Grid>
      </Grid>
    );
  }
}

export default TradingContract;
