import React from 'react';
import {
  Paper,
  createStyles,
  withStyles,
  WithStyles,
  Grid,
  Typography,
  NativeSelect,
  Theme
} from '@material-ui/core';

import { IConfigInfo } from '../../../../dashboard/types';
import { FEE } from '../../../../../constants/Config.constant';
import { groupByValues, formatNumberToFixed } from '../../../../../helper';
import EditComponent from './EditComponent';
import { ITableFeeData } from '..';
import TitlePageComponent from '../../../../../components/TitlePage.component';
import ConfirmDialog from '../../../../../components/dialog/Confirm.dialog';

const styles = (theme: Theme) =>
  createStyles({
    '@keyframes fadeInDown': {
      '0%': {
        opacity: 0,
        transform: 'translateY(-20px)'
      },
      '100%': {
        opacity: 1,
        transform: 'translateY(0)'
      }
    },
    container: {
      width: '100%',
      height: '100%',
      padding: 20,
      boxSizing: 'border-box',
      [theme.breakpoints.down('xs')]: {
        padding: 10
      }
    },
    rootLayout: {
      padding: 15,
      width: '100%',
      height: 110,
      display: 'flex',
      flexDirection: 'column',
      boxSizing: 'border-box'
    },
    itemRoot: {
      flex: 1
    },

    title: {
      fontSize: 10,
      lineHeight: '15px',
      fontWeight: 600,
      color: '#9BA0A6',
      textTransform: 'uppercase'
    },
    value: {
      fontSize: 12,
      lineHeight: '17px',
      fontWeight: 600,
      color: '#222222',
      textTransform: 'capitalize'
    },
    floatRight: {
      alignItems: 'flex-end'
    },
    fadeIn: {
      opacity: 1,
      transform: 'translateY(0)'
    },
    opotionText: {
      fontFamily: 'Montserrat',
      fontStyle: 'normal',
      fontWeight: 500,
      fontSize: 14,
      lineHeight: '17px',
      color: '#222222'
    },
    selectLayout: {
      flexDirection: 'column',
      flex: 1
    },
    select: {
      height: 40,
      width: '60%',
      [theme.breakpoints.up('sm')]: {
        width: '40%'
      }
    },
    item: {
      display: 'flex',
      flexDirection: 'row',
      marginTop: 20,
      alignItems: 'center'
    }
  });

interface IProps extends WithStyles<typeof styles> {
  configs: IConfigInfo[];
  getConfigAction: () => void;
  updateConfigAction: (configs: IConfigInfo[]) => void;
}

interface IState {
  isEditing: boolean;
  currentOption: string;
  configData: IFeeData[];
  adminCharge: string;
  adminChargeWithCode: string;
  assetFee: string;
  assetFeeWithCode: string;
  editingField: IConfigInfo[];
  open: boolean;
}

interface IFeeData extends ITableFeeData {
  id: string;
}

class ConfigCard extends React.Component<IProps> {
  getConfigData = (configs: IConfigInfo[]) => {
    const feeConfigs = configs.filter(
      e =>
        (e.amount && e.name === FEE.ADMIN_CHARGE) ||
        e.name === FEE.ASSET_MANAGEMENT_FEE
    );
    const groupValues = groupByValues(feeConfigs, 'amount');
    const keys = Object.keys(groupValues);

    // create records table
    const configRecords = keys.map((e: any) => {
      const tableData: IFeeData = Object.create(null);
      groupValues[e].forEach((config: IConfigInfo) => {
        if (config.name === FEE.ADMIN_CHARGE) {
          config.withAgent
            ? (tableData.adminChargeWithCode = {
                id: config.id,
                value: config.rate
              })
            : (tableData.adminCharge = {
                id: config.id,
                value: config.rate
              });
        } else {
          config.withAgent
            ? (tableData.assetFeeWithCode = {
                id: config.id,
                value: config.rate
              })
            : (tableData.assetFee = {
                id: config.id,
                value: config.rate
              });
        }
      });
      tableData.totalFee = {
        id: '',
        value: parseFloat(
          (
            (tableData.adminCharge ? tableData.adminCharge.value : 0) +
            (tableData.assetFee ? tableData.assetFee.value : 0)
          ).toPrecision(5)
        )
      };

      tableData.totalFeeWithCode = {
        id: '',
        value: parseFloat(
          (
            (tableData.adminChargeWithCode
              ? tableData.adminChargeWithCode.value
              : 0) +
            (tableData.assetFeeWithCode ? tableData.assetFeeWithCode.value : 0)
          ).toPrecision(5)
        )
      };
      tableData.id = e;
      return tableData;
    });
    return configRecords;
  };

  state: IState = {
    isEditing: false,
    currentOption: '',
    adminCharge: '',
    adminChargeWithCode: '',
    assetFee: '',
    assetFeeWithCode: '',
    configData: this.getConfigData(this.props.configs),
    editingField: [],
    open: false
  };

  componentWillReceiveProps(nextProps: IProps) {
    if (nextProps.configs !== this.props.configs) {
      this.setState({ configData: this.getConfigData(nextProps.configs) });
    }
  }

  renderSelectOption = (value: string) => {
    const { classes } = this.props;
    return (
      <option key={value} className={classes.opotionText} value={value}>
        {`US$ ${formatNumberToFixed(value || 0, 0)}`}
      </option>
    );
  };

  handleOptionChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    this.setState({ currentOption: e.target.value });
  };

  handleOpenEditConfig = () => {
    const { isEditing } = this.state;
    this.setState({ isEditing: !isEditing });
  };

  handleFocus = (event: React.FocusEvent<HTMLInputElement>) => {
    event.target.select();
  };

  handleShowConfirm = () => {
    this.setState({ open: true });
  };

  handleCloseDialog = () => {
    this.props.getConfigAction();
    this.setState({ open: false, editingField: [] });
  };
  handleSaveEdit = () => {
    this.props.updateConfigAction(this.state.editingField);
    this.setState({ editingField: [], open: false });
  };

  handleCompleteEdit = (config: IConfigInfo) => {
    const { editingField } = this.state;

    // update list editing
    editingField.push(config);
    this.setState({ open: true, editingField });
  };

  renderFeeConfig = (data: IFeeData) => {
    const { classes } = this.props;
    if (!data) {
      return null;
    }
    return (
      <>
        <Grid item xs={12} className={classes.item}>
          <Grid item xs={4}>
            <Typography className={classes.title}>Invest term</Typography>
            <Typography
              className={classes.title}
              style={{ color: '#00AFC1', textTransform: 'capitalize' }}
            >
              1 year
            </Typography>
          </Grid>
          <Grid item xs={4}>
            <Typography
              className={classes.title}
              style={{ fontWeight: 'bold', color: '#222222' }}
            >
              With code
            </Typography>
          </Grid>
          <Grid item xs={4}>
            <Typography
              className={classes.title}
              style={{ fontWeight: 'bold', color: '#222222' }}
            >
              Without code
            </Typography>
          </Grid>
        </Grid>
        <Grid item xs={12} className={classes.item}>
          <Grid item xs={4}>
            <Typography className={classes.title}>Admin charge</Typography>
          </Grid>
          <Grid item xs={4}>
            <EditComponent
              handleEditComplete={this.handleCompleteEdit}
              key={data.adminChargeWithCode.id}
              config={this.props.configs.find(
                e => e.id === data!.adminChargeWithCode.id
              )}
            />
          </Grid>
          <Grid item xs={4}>
            <EditComponent
              handleEditComplete={this.handleCompleteEdit}
              key={data.adminCharge.id}
              config={this.props.configs.find(
                e => e.id === data!.adminCharge.id
              )}
            />
          </Grid>
        </Grid>
        <Grid item xs={12} className={classes.item}>
          <Grid item xs={4}>
            <Typography className={classes.title}>Asset fee</Typography>
          </Grid>
          <Grid item xs={4}>
            <EditComponent
              handleEditComplete={this.handleCompleteEdit}
              key={data.assetFeeWithCode.id}
              config={this.props.configs.find(
                e => e.id === data!.assetFeeWithCode.id
              )}
            />
          </Grid>
          <Grid item xs={4}>
            <EditComponent
              handleEditComplete={this.handleCompleteEdit}
              key={data.assetFee.id}
              config={this.props.configs.find(e => e.id === data!.assetFee.id)}
            />
          </Grid>
        </Grid>
        <Grid item xs={12} className={classes.item}>
          <Grid item xs={4}>
            <Typography className={classes.title}>total fee</Typography>
          </Grid>
          <Grid item xs={4}>
            <Typography className={classes.value}>
              {data!.totalFeeWithCode.value}%
            </Typography>
          </Grid>
          <Grid item xs={4}>
            <Typography className={classes.value}>
              {data!.totalFee.value}%
            </Typography>
          </Grid>
        </Grid>
        <Grid item xs={12} className={classes.item}>
          <Grid item xs={4}>
            <Typography className={classes.title}>SUrrender charge</Typography>
          </Grid>
          <Grid item xs={4}>
            <Typography style={{ color: '#B61F1F' }} className={classes.value}>
              20%
            </Typography>
          </Grid>
          <Grid item xs={4}>
            <Typography style={{ color: '#B61F1F' }} className={classes.value}>
              20%
            </Typography>
          </Grid>
        </Grid>
      </>
    );
  };
  render() {
    const { classes } = this.props;
    const { configData, currentOption } = this.state;
    const currentValue = configData.find(e => e.id === currentOption);
    return (
      <>
        <Grid style={{ marginBottom: 9 }}>
          <TitlePageComponent title="Contribute" />
        </Grid>
        <Paper elevation={0} className={classes.container}>
          <Grid
            container
            style={{
              flexDirection: 'row',
              justifyContent: 'center',
              alignContent: 'center',
              borderBottom: '1px solid #DCE0E4',
              paddingBottom: 20
            }}
          >
            <Grid container className={classes.selectLayout}>
              <Typography className={classes.title}>
                Minimum Contribute
              </Typography>
              <NativeSelect
                className={classes.select}
                disableUnderline
                value={currentOption}
                onChange={this.handleOptionChange}
                inputProps={{
                  style: {
                    height: 40,
                    padding: 0,
                    paddingLeft: 10,
                    fontFamily: 'Montserrat',
                    fontStyle: 'normal',
                    fontWeight: 500,
                    fontSize: 14,
                    lineHeight: '17px',
                    color: '#222222',
                    backgroundColor: 'transparent'
                  }
                }}
                data-test={'nft-type-select'}
              >
                {this.state.configData.map(e => this.renderSelectOption(e.id))}
              </NativeSelect>
            </Grid>
          </Grid>
          {this.renderFeeConfig(currentValue || configData[0])}
        </Paper>
        <ConfirmDialog
          showDialog={this.state.open}
          handleCloseDialog={this.handleCloseDialog}
          textHeader="Confirm save changes"
          textContent="Are you sure you want to save changes ?"
          handleOkButton={this.handleSaveEdit}
        />
      </>
    );
  }
}

export default withStyles(styles)(ConfigCard);
