import {
  GET_RANK_INFO,
  SET_RANK_INFO,
  GET_PAYMENT_SUMARY,
  SET_PAYMENT_SUMARY,
  GET_NFT_LIST,
  SET_NFT_LIST,
  GET_TOMO_PRICE,
  SET_TOMO_PRICE,
  GET_CONFIG,
  SET_CONFIG,
  UPDATE_CONFIG,
  GET_PAYMENT_HISTORY
} from '../constants/payment.constant';
import { Action } from 'redux';
import {
  IPaymentSumary,
  INftRequest,
  IConfigInfo,
  INftData,
  IPaymentHistoryContainer
} from '../types';
import { IParams } from '../../../types';

export interface IGetPaymentSumaryAction extends Action<GET_PAYMENT_SUMARY> {}

export const getPaymentSumaryAction = (): IGetPaymentSumaryAction => ({
  type: GET_PAYMENT_SUMARY
});

export interface ISetPaymentSumaryAction extends Action<SET_PAYMENT_SUMARY> {
  payload: IPaymentSumary;
}

export const setPaymentSumaryAction = (
  payment: IPaymentSumary
): ISetPaymentSumaryAction => ({
  type: SET_PAYMENT_SUMARY,
  payload: payment
});

export interface IGetRankInfo extends Action<GET_RANK_INFO> {}

export const getRankInfoAction = (): IGetRankInfo => ({
  type: GET_RANK_INFO
});

export interface ISetRankInfo extends Action<SET_RANK_INFO> {
  payload: IConfigInfo[];
}

export const setRankInfoAction = (rank: IConfigInfo[]): ISetRankInfo => ({
  type: SET_RANK_INFO,
  payload: rank
});

export interface IGetNftListAction extends Action<GET_NFT_LIST> {
  payload: INftRequest;
}

export const getNftListAction = (request: INftRequest): IGetNftListAction => ({
  type: GET_NFT_LIST,
  payload: request
});

export interface ISetNftListAction extends Action<SET_NFT_LIST> {
  payload: INftData;
}

export const setNftListAction = (nfts: INftData): ISetNftListAction => ({
  type: SET_NFT_LIST,
  payload: nfts
});

export interface IGetTomoPriceAction extends Action<GET_TOMO_PRICE> {}

export const getTomoPrice = (): IGetTomoPriceAction => ({
  type: GET_TOMO_PRICE
});

export interface ISetTomoPriceAction extends Action<SET_TOMO_PRICE> {
  payload: IConfigInfo;
}

export const setTomoPriceAction = (
  tomoPrice: IConfigInfo
): ISetTomoPriceAction => ({
  type: SET_TOMO_PRICE,
  payload: tomoPrice
});

export interface IGetConfigAction extends Action<GET_CONFIG> {}

export const getConfigAction = (): IGetConfigAction => ({
  type: GET_CONFIG
});

export interface ISetConfigAction extends Action<SET_CONFIG> {
  payload: IConfigInfo[];
}

export const setConfigAction = (configs: IConfigInfo[]): ISetConfigAction => ({
  type: SET_CONFIG,
  payload: configs
});

export interface IUpdateConfigAction extends Action<UPDATE_CONFIG> {
  payload: IConfigInfo[];
}

export const updateConfigAction = (
  configs: IConfigInfo[]
): IUpdateConfigAction => ({
  type: UPDATE_CONFIG,
  payload: configs
});

export interface IGetPaymentHistoryAction extends Action<GET_PAYMENT_HISTORY> {
  payload: IParams;
}

export const getPaymentHistoryAction = (
  params: IParams
): IGetPaymentHistoryAction => ({
  type: GET_PAYMENT_HISTORY,
  payload: params
});

export interface ISetPaymentHistoryAction extends Action<SET_PAYMENT_SUMARY> {
  payload: IPaymentHistoryContainer;
}

export const setPaymentHistoryAction = (
  payment: IPaymentHistoryContainer
): ISetPaymentHistoryAction => ({
  type: SET_PAYMENT_SUMARY,
  payload: payment
});
