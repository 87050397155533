import React from 'react';
import {
  Theme,
  createStyles,
  WithStyles,
  withStyles,
  Typography,
  Paper,
  Grid,
  Button
} from '@material-ui/core';
import {
  TableDataRawAdminComponent,
  ITEM_TYPE_ADMIN
} from './shared/TableRawAdmin.component';
import { LightTooltip } from '../constants/Tooltip.constant';
import EditTradingDialogComponent from '../components/dialog/EditTrading.dialog.component';
import {
  IUserProfile,
  ITraderPosition,
  ITraderMargin
} from '../scenes/setting/types';
import { Dispatch } from 'redux';
import {
  IUpdateUserById,
  updateUserById
} from '../scenes/setting/actions/user';
import { connect } from 'react-redux';
import { ROLE } from '../constants/auth.constant';
import { formatNumberToFixed } from '../helper';
const styles = (theme: Theme) =>
  createStyles({
    textContent: {
      color: '#9BA0A6',
      textTransform: 'capitalize',
      fontWeight: 500,
      minHeight: 25,
      overflow: 'hidden',
      wordBreak: 'break-all',
      textOverflow: 'ellipsis',
      display: '-webkit-box',
      '-webkit-line-clamp': '2',
      '-webkit-box-orient': 'vertical'
    },
    root: {
      height: 290,
      minWidth: 290,
      width: '100%',
      [theme.breakpoints.down('sm')]: {
        minWidth: '100%'
      }
    }
  });
interface IProps extends WithStyles<typeof styles> {
  user: IUserProfile;
  updateUserById: (data: any) => void;
}

interface IState {
  showDialogEditTrading: boolean;
}

class CardTrading extends React.Component<IProps, IState> {
  public state: IState = {
    showDialogEditTrading: false
  };

  public handleClose = () => {
    this.setState({
      showDialogEditTrading: false
    });
  };

  public handleShowDialog = () => {
    this.setState({
      showDialogEditTrading: true
    });
  };

  handleUpdateButton = (description: any) => {
    this.props.updateUserById({
      id: this.props.user.id,
      data: description,
      type: ROLE.TRADING
    });
    this.handleClose();
  };

  getPositionText = (traderPosition?: ITraderPosition) => {
    return traderPosition && traderPosition.currentQty > 0
      ? 'Long Term'
      : traderPosition && traderPosition.currentQty < 0
      ? 'Short Term'
      : 'None';
  };

  getTraderMargin = (traderMargin?: ITraderMargin) => {
    console.log(traderMargin);
    return (
      (traderMargin && formatNumberToFixed(traderMargin.walletBalance, 8)) || 0
    );
  };

  public render() {
    const { classes, user } = this.props;
    const traderPosition =
      user.traderPosition && user.traderPosition.length
        ? user.traderPosition[0]
        : undefined;
    const traderMargin =
      user.traderMargin && user.traderMargin.length
        ? user.traderMargin[0]
        : undefined;
    return (
      <>
        <Paper elevation={0} className={classes.root}>
          <Grid container style={{ padding: 18 }}>
            <Typography
              variant="h3"
              style={{ color: '#000000', wordBreak: 'break-word' }}
            >
              {' '}
              {(user.traderMargin && user.traderMargin[0].account) ||
                user.description}
            </Typography>
            <Grid container alignItems="center" style={{ margin: 5 }}>
              <Grid item md={8} lg={8}>
                <LightTooltip title={`${user.description}`}>
                  <Typography
                    variant="subtitle1"
                    className={classes.textContent}
                  >
                    {user.description}
                  </Typography>
                </LightTooltip>
              </Grid>
              <Grid item md={4} lg={4} container justify="flex-end">
                <Button
                  style={{
                    width: 57,
                    height: 18,
                    padding: 0,
                    backgroundColor: '#FFFFFF',
                    border: '1px solid #E6E6E6'
                  }}
                  onClick={this.handleShowDialog}
                  variant="contained"
                  color="primary"
                >
                  <span
                    style={{
                      fontSize: 10,
                      textTransform: 'capitalize',
                      color: '#9BA0A6'
                    }}
                  >
                    Edit
                  </span>{' '}
                </Button>
              </Grid>
            </Grid>
            <Grid container style={{ marginTop: 20 }}>
              <TableDataRawAdminComponent
                items={{
                  title: 'Current position',
                  value:
                    traderPosition && traderPosition.isOpen === true
                      ? this.getPositionText(traderPosition)
                      : 'None'
                }}
                type={ITEM_TYPE_ADMIN.COLOR_VALUE}
              />
            </Grid>
            <Grid container style={{ marginTop: 30 }}>
              <TableDataRawAdminComponent
                items={{
                  title: 'Current Volume',
                  value: this.getTraderMargin(traderMargin)
                }}
                type={ITEM_TYPE_ADMIN.ICON_VALUE}
              />
            </Grid>
            <Grid container style={{ marginTop: 30 }}>
              <TableDataRawAdminComponent
                items={{
                  title: 'Magnigification',
                  value:
                    traderPosition && traderPosition.isOpen === true
                      ? traderPosition.leverage
                      : ''
                }}
                type={ITEM_TYPE_ADMIN.COLOR_VALUE_GREEN}
              />
            </Grid>
          </Grid>
        </Paper>
        <EditTradingDialogComponent
          user={this.props.user}
          showDialog={this.state.showDialogEditTrading}
          handleCloseDialog={this.handleClose}
          handleUpdateButton={this.handleUpdateButton}
        />
      </>
    );
  }
}

const mapDispatchToProps = (dispatch: Dispatch<IUpdateUserById>) => ({
  updateUserById: (data: any) => dispatch(updateUserById(data))
});

export default connect(
  undefined,
  mapDispatchToProps
)(withStyles(styles)(CardTrading));
