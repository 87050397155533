import { Tooltip } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';

export const LightTooltip = withStyles(() => ({
  tooltip: {
    backgroundColor: '#FFFFFF',
    boxShadow: '0px 0px 40px rgba(0, 0, 0, 0.15)',
    color: '#9BA0A6',
    fontSize: 10,
    fontFamily: 'Montserrat',
    fontWeight: 500,
    lineHeight: '15px',
    width: 268,
    height: 121,
    padding: 16,
    margin: 0,
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    display: '-webkit-box',
    '-webkit-line-clamp': '2',
    '-webkit-box-orient': 'vertical'
  },
  popper: {
    left: '65px !important'
  }
}))(Tooltip);
