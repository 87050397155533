import React from 'react';
import iconEditAdress from '../../../../../assets/images/iconEditAdress.svg';
import iconSaveAddress from '../../../../../assets/images/iconSaveAccount.svg';
import { TextField, InputAdornment, IconButton } from '@material-ui/core';
import { IConfigInfo, initConfigInfo } from '../../../../dashboard/types';

interface IProps {
  config?: IConfigInfo;
  handleEditComplete: (config: IConfigInfo) => void;
}

interface IState {
  config: IConfigInfo;
  isEditing: boolean;
}
class EditComponent extends React.Component<IProps, IState> {
  state: IState = {
    isEditing: false,
    config: this.props.config || initConfigInfo
  };

  handleEdit = () => {
    if (
      this.state.isEditing &&
      JSON.stringify(this.props.config) !== JSON.stringify(this.state.config)
    ) {
      this.props.handleEditComplete(this.state.config);
    }
    this.setState({ isEditing: !this.state.isEditing });
  };
  UNSAFE_componentWillReceiveProps(nextProps: IProps) {
    if (
      JSON.stringify(nextProps.config) !== JSON.stringify(this.state.config)
    ) {
      this.setState({ config: nextProps.config! });
    }
  }
  handleTextChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const validRate = /^[0-9]{1,2}(\.[0-9]{1,3})?$/;
    if (e.target.value === '' || e.target.value.match(validRate)) {
      this.setState({
        config: {
          ...this.state.config,
          rate: Number(e.target.value)
        }
      });
    }
  };

  handleFocus = (event: React.FocusEvent<HTMLInputElement>) => {
    event.target.select();
  };
  render() {
    return (
      <TextField
        type="number"
        value={this.state.config.rate.toString()}
        style={{
          width: '100%',
          height: 'auto',
          backgroundColor: 'transparent'
        }}
        onFocus={this.handleFocus}
        onChange={this.handleTextChange}
        disabled={!this.state.isEditing}
        InputProps={{
          disableUnderline: true,
          endAdornment: (
            <InputAdornment position="end">
              <IconButton
                onClick={this.handleEdit}
                aria-label="Toggle save visibility"
              >
                {!this.state.isEditing ? (
                  <img
                    src={iconEditAdress}
                    alt="error tomo"
                    width={25}
                    height={25}
                  />
                ) : (
                  <img
                    src={iconSaveAddress}
                    alt="error tomo"
                    width={25}
                    height={25}
                  />
                )}
              </IconButton>
            </InputAdornment>
          )
        }}
      />
    );
  }
}
export default EditComponent;
