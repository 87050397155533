import React from 'react';
import {
  createStyles,
  withStyles,
  WithStyles,
  Grid,
  Typography
} from '@material-ui/core';
import { connect } from 'react-redux';

import { Dispatch } from 'redux';
import { IPaymentHistory, IPaymentHistoryContainer } from '../dashboard/types';
import PaymentHistoryCardComponent from './PaymentHistoryCard.component';
import { IStoreState, IParams } from '../../types';
import {
  IGetPaymentHistoryAction,
  getPaymentHistoryAction
} from '../dashboard/actions/payment.action';

const styles = () =>
  createStyles({
    container: {
      width: '100%',
      minHeight: '40vh'
    },
    rootLayout: {
      padding: 15,
      width: '100%',
      height: 170,
      display: 'flex',
      flexDirection: 'column',
      boxSizing: 'border-box'
    },
    itemRoot: {
      flex: 1
    },
    item: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center'
    },
    title: {
      fontSize: 12,
      lineHeight: '18px',
      fontWeight: 600,
      color: '#9BA0A6',
      textTransform: 'uppercase'
    },
    value: {
      fontSize: 14,
      lineHeight: '20px',
      fontWeight: 500,
      color: '#222222'
    },
    floatRight: {
      alignItems: 'flex-end'
    }
  });

interface IProps extends WithStyles<typeof styles> {
  params: {
    limit: number;
    offset: number;
    transactionType: string;
  };
  paymentHistory: IPaymentHistoryContainer;
  filterBox: {
    top: boolean;
    date: boolean;
  };
  loadingMore: () => void;
  getPaymentHistory: (params: IParams) => void;
}
class PaymentHistoryMobileVersion extends React.Component<IProps> {
  componentDidMount() {
    this.props.getPaymentHistory(this.props.params);
  }

  componentWillReceiveProps(nextProps: IProps) {
    console.log(nextProps.params, this.props.params);
    if (JSON.stringify(nextProps) !== JSON.stringify(this.props)) {
      console.log('render');
      this.props.getPaymentHistory(nextProps.params);
    }
  }

  dateSort = (a: IPaymentHistory, b: IPaymentHistory) => {
    const date1 = new Date(a.paymentDate).getTime();
    const date2 = new Date(b.paymentDate).getTime();
    return date2 - date1;
  };

  topNFTSort = (a: IPaymentHistory, b: IPaymentHistory) => {
    return Number(b.total) - Number(a.total);
  };

  getListSorted = (
    data: IPaymentHistory[],
    topCheckbox: boolean,
    dateCheckbox: boolean
  ) => {
    const array = data && [...data];
    if (topCheckbox || dateCheckbox) {
      const sort = dateCheckbox ? this.dateSort : this.topNFTSort;
      return array.sort(sort);
    }
    return data;
  };
  render() {
    const { classes, paymentHistory, filterBox } = this.props;

    const data = this.getListSorted(
      paymentHistory.data,
      filterBox.top,
      filterBox.date
    );

    return (
      <Grid className={classes.container}>
        {data && data.length ? (
          data.map(e => (
            <PaymentHistoryCardComponent paymentHistory={e} key={e.id} />
          ))
        ) : (
          <Typography
            variant="h3"
            style={{ textAlign: 'center', marginTop: 30 }}
          >
            No Data
          </Typography>
        )}
        {paymentHistory.meta &&
          paymentHistory.meta.total > this.props.params.limit && (
            <Grid
              container
              style={{ flexDirection: 'column', marginTop: 30 }}
              alignItems={'center'}
            >
              <Typography
                variant="h4"
                style={{ color: '#00AFC1', fontSize: 18 }}
                onClick={this.props.loadingMore}
              >
                Load more...
              </Typography>
            </Grid>
          )}
      </Grid>
    );
  }
}

const mapStateToProps = ({ paymentHistory }: IStoreState) => {
  return { paymentHistory };
};
const mapDispatchToProps = (dispatch: Dispatch<IGetPaymentHistoryAction>) => {
  return {
    getPaymentHistory: (params: IParams) =>
      dispatch(getPaymentHistoryAction(params))
  };
};
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(PaymentHistoryMobileVersion));
