import {
  ADD_TRADING_ACCOUNT,
  ADD_AGENT_ACCOUNT,
  GET_ACCOUNT,
  SET_ACCOUNT,
  GET_HIDDEN_RATE,
  SET_HIDDEN_RATE,
  ADD_HIDDEN_RATE,
  GET_PROFIT_RATE,
  SET_PROFIT_RATE
} from '../constants';
import { Action } from 'redux';
import { IAddTradingAccountRequest, IAddAgentAccountRequest } from '../types';
import { IUserProfile } from '../../../../setting/types';
import { IMetaData } from '../../../../../components/shared/table/redux/types';
import { IParams } from '../../../../../types';

export interface IAddTradingAccountAction extends Action<ADD_TRADING_ACCOUNT> {
  payload: IAddTradingAccountRequest;
}

export const addTradingAccountAction = (
  request: IAddTradingAccountRequest
): IAddTradingAccountAction => ({
  type: ADD_TRADING_ACCOUNT,
  payload: request
});

export interface IAddAgentAccountAction extends Action<ADD_AGENT_ACCOUNT> {
  payload: IAddAgentAccountRequest;
}

export const addAgentAccountAction = (
  request: IAddAgentAccountRequest
): IAddAgentAccountAction => ({
  type: ADD_AGENT_ACCOUNT,
  payload: request
});

export interface IGetAccountAction extends Action<GET_ACCOUNT> {
  payload: IParams;
}

export const getAccountAction = (type: IParams): IGetAccountAction => ({
  type: GET_ACCOUNT,
  payload: type
});

export interface ISetAccountAction extends Action<SET_ACCOUNT> {
  payload: { type: string; users: { data: IUserProfile[]; meta: IMetaData } };
}

export const setAccountAction = (request: {
  type: string;
  users: { data: IUserProfile[]; meta: IMetaData };
}): ISetAccountAction => ({
  type: SET_ACCOUNT,
  payload: request
});
export interface IGetHiddenRateAction extends Action<GET_HIDDEN_RATE> {}

export const getHiddenRateAction = (): IGetHiddenRateAction => ({
  type: GET_HIDDEN_RATE
});

export interface ISetHiddenRateAction extends Action<SET_HIDDEN_RATE> {
  payload: number;
}

export const setHiddenRateAction = (rate: number): ISetHiddenRateAction => ({
  type: SET_HIDDEN_RATE,
  payload: rate
});

export interface IAddHiddenRateAction extends Action<ADD_HIDDEN_RATE> {
  payload: number;
}

export const addHiddenRateAction = (rate: number): IAddHiddenRateAction => ({
  type: ADD_HIDDEN_RATE,
  payload: rate
});

export interface IGetProfitRateAction extends Action<GET_PROFIT_RATE> {}

export const getProfitRateAction = (): IGetProfitRateAction => ({
  type: GET_PROFIT_RATE
});

export interface ISetProfitRateAction extends Action<SET_PROFIT_RATE> {
  payload: number;
}

export const setProfitRateAction = (rate: number): ISetProfitRateAction => ({
  type: SET_PROFIT_RATE,
  payload: rate
});
