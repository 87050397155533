import axios, { AxiosRequestConfig } from 'axios';
import { Dispatch, Store } from 'redux';
import { IStoreState } from '../types';
import { INIT_AXIOS_INTERCEPTOR } from '../constants/auth.constant';
import { IInitialAxiosInterceptorAction } from '../actions/auth.action';

export const initAxiosInterceptor = (store: Store<IStoreState>) => (
  dispatch: Dispatch<IInitialAxiosInterceptorAction>
) => (action: IInitialAxiosInterceptorAction) => {
  if (action.type === INIT_AXIOS_INTERCEPTOR) {
    axios.interceptors.request.use(
      (config: AxiosRequestConfig) => {
        const token = localStorage.getItem('token');
        if (token) {
          config.headers.Authorization = `Bearer ${token}`;
        }
        config.baseURL = process.env.REACT_APP_API_URL;
        return config;
      },
      error => Promise.reject(error)
    );
    // axios.interceptors.response.use(
    // 	(response: AxiosResponse<any>) => {
    // 		store.dispatch(loadingAction(false));
    // 		return response;
    // 	},
    // 	(error: AxiosError) => {
    // 		store.dispatch(loadingAction(false));
    // 		return Promise.reject(error);
    // 	}
    // );
  }
  return dispatch(action);
};
