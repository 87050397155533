export const validateEmail = /^[a-zA-Z0-9_.]{1,32}@[a-zA-Z0-9]{2,}(\.[a-z0-9]{2,4}){1,4}$/g;

export function formatAddYear(date: Date) {
  const year = date.getFullYear();
  const month = date.getMonth();
  const day = date.getDate();
  const addYear = new Date(year + 1, month, day);
  return addYear;
}

export const formatNumberToFixed = (
  n: number | string,
  fix: number
): string => {
  if (n === '') {
    return '';
  }

  if (n === '0' || n === 0) {
    return '0';
  }

  const formatedN = parseFloat(
    typeof n === 'string' ? parseFloat(n).toFixed(fix) : n.toFixed(fix)
  )
    .toString()
    .replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,');
  const parts = formatedN.split('.');
  if (parts[1]) {
    return parts[0] + '.' + parts[1].replace(/,/g, '');
  } else {
    return parts[0];
  }
};

export function formatDate(date: string) {
  const data = new Date(date);
  const montNames = [
    'Jan',
    'Feb',
    'Mar',
    'Apr',
    'May',
    'Jun',
    'Jul',
    'Aug',
    'Sep',
    'Oct',
    'Nov',
    'Dec'
  ];

  const day = data.getDate();
  const monthIndex = data.getMonth();
  const year = data.getFullYear();

  return montNames[monthIndex] + ' ' + day + ' ' + year;
}

export const validateBtcAddress = /.{26,35}/g;
export function groupByValues(input: any[], key: string) {
  return input.reduce((rv: any, x: any) => {
    (rv[x[key]] = rv[x[key]] || []).push(x);
    return rv;
  }, {});
}

export const validateTomoAddress = /^0x[a-fA-F0-9]{40}$/;
