import React from 'react';
import {
  Paper,
  createStyles,
  WithStyles,
  withStyles,
  Grid,
  Typography,
  Theme
} from '@material-ui/core';
import { IHolder } from '../../scenes/dashboard/types';
import { formatDate } from '../../helper';

const styles = (theme: Theme) =>
  createStyles({
    root: {
      height: 60,
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      justifyContent: 'center'
    },
    responsiveText: {
      [theme.breakpoints.down('xs')]: {
        fontSize: 10
      }
    },
    expiredColumn: {
      display: 'flex',
      justifyContent: 'flex-end',
      paddingRight: 21,
      [theme.breakpoints.down('xs')]: {
        paddingRight: 10
      }
    },
    holderText: {
      paddingLeft: 14,
      [theme.breakpoints.down('xs')]: {
        paddingLeft: 10
      }
    }
  });

export interface IHolderInfo {
  holder: {
    name: string;
    startDate: string;
  };
  nft: string;
  expiredDate: string;
}
interface IProps extends WithStyles<typeof styles> {
  holder: IHolder;
}

class HolderInfo extends React.Component<IProps> {
  render() {
    const { classes, holder } = this.props;
    const postfixNFT = Number(holder.nfts) > 1 ? 'NFTs' : 'NFT';
    return (
      <Paper elevation={0} className={classes.root} data-test="holder-info">
        <Grid item xs={5} className={classes.holderText}>
          <Typography
            variant="subtitle2"
            className={classes.responsiveText}
            style={{ fontWeight: 'bold', marginBottom: 2 }}
          >
            {holder.name}
          </Typography>
          <Typography
            className={classes.responsiveText}
            variant="subtitle1"
          >{`Join date:${' '}${formatDate(holder.joinDate)}`}</Typography>
        </Grid>
        <Grid item xs={2}>
          <Typography className={classes.responsiveText} variant="subtitle2">
            {holder.nfts} {postfixNFT}
          </Typography>
        </Grid>
        <Grid item xs={5} className={classes.expiredColumn}>
          <Typography
            variant="subtitle2"
            className={classes.responsiveText}
            style={{ fontWeight: 300 }}
          >
            {`Expire Date:`}
          </Typography>
          <Typography
            variant="subtitle2"
            className={classes.responsiveText}
            style={{ fontWeight: 'normal', marginLeft: 3 }}
          >
            {`${formatDate(holder.expireSoon)}`}
          </Typography>
        </Grid>
      </Paper>
    );
  }
}

export default withStyles(styles)(HolderInfo);
