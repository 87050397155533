import React from 'react';
import {
  Grid,
  Theme,
  createStyles,
  WithStyles,
  withStyles
} from '@material-ui/core';
import CardHolder from './CardHolder.component';
import Agentpaymentsumary from './Payment.sumary.component';
import NftContainer from './Nft.component';

const styles = (theme: Theme) =>
  createStyles({
    container: {
      paddingRight: 10,
      paddingTop: 32,
      [theme.breakpoints.down('sm')]: {
        padding: 0
      }
    },
    nftHolderLayout: {
      paddingLeft: 10,
      [theme.breakpoints.down('sm')]: {
        paddingLeft: 0,
        marginTop: 30
      }
    }
  });

interface IProps extends WithStyles<typeof styles> {}
class Payment extends React.Component<IProps> {
  public render() {
    const { classes } = this.props;
    return (
      <React.Fragment>
        <Grid container style={{ width: '100%' }}>
          <Grid
            container
            item
            xs={12}
            lg={7}
            md={7}
            sm={12}
            className={classes.container}
          >
            <Agentpaymentsumary />
            <NftContainer />
          </Grid>
          <Grid
            item
            sm={12}
            xs={12}
            md={5}
            lg={5}
            className={classes.nftHolderLayout}
          >
            <CardHolder />
          </Grid>
        </Grid>
      </React.Fragment>
    );
  }
}

export default withStyles(styles)(Payment);
