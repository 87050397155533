import * as React from 'react';
import {
  WithStyles,
  withStyles,
  createStyles,
  Theme
} from '@material-ui/core/styles';
import {
  Paper,
  Grid,
  Typography,
  FormControl,
  TextField,
  IconButton,
  Switch,
  FormControlLabel,
  Button
} from '@material-ui/core';
import iconEditAdress from '../assets/images/iconEditAdress.svg';
import iconSaveAccount from '../assets/images/iconSaveAccount.svg';
import PasswordChangeDialogComponent from './dialog/PasswordChange.dialog.component';
import TwoAuthenticationDialogComponent from './dialog/TwoAuthentication.dialog.component';
import { History } from 'history';
import { IStoreState } from '../types';
import { connect } from 'react-redux';
import { IUserProfile, ISecondFactorCode } from '../scenes/setting/types';
import { Dispatch } from 'redux';
import {
  IUpdateUserProfile,
  updateUserProfile,
  IGetQrImage,
  getQrImageAction,
  IGetUserProfile,
  getUserProfileAction,
  setSecondFactorCode,
  ISetSecondFactorCode
} from '../scenes/setting/actions/user';
import OffTwoFactorDialogComponent from './dialog/OffTwoFactor.dialog.component';
import Axios from 'axios';
import {
  IUpdateNotification,
  fireNotification
} from '../actions/Notification.action';
import INotificationInfo from '../types/Notification.type';
import {
  MESSAGE_NOTIFICATION_SUCCESS,
  MESSAGE_NOTIFICATION_ERROR
} from '../constants/Notification.constant';
import Variant from '../types/Variant.type';
import { ROLE } from '../constants/auth.constant';
import { setCodeAction, ISetCodeAction } from '../scenes/auth/actions/auth';
import { validateTomoAddress } from '../helper';

interface IProps extends WithStyles<typeof styles> {
  role: ROLE;
  history: History<any>;
  profile: IUserProfile;
  updateUserProfile: (info: any) => void;
  getUserProfileAction: () => void;
  setSecondFactorCode: (code: ISecondFactorCode) => void;
  getQrImage: () => void;
  updateNotification: (noti: INotificationInfo) => void;
  setCodeAction: (code: string) => void;
}

interface IState {
  openEditAdress: boolean;
  openEditPasswrod: boolean;
  openEditName: boolean;
  openAuthen: boolean;
  open: boolean;
  userProfile: IUserProfile;
  erroTomoAdress: boolean;
}

const styles = (theme: Theme) =>
  createStyles({
    root: {
      height: 425,
      [theme.breakpoints.down('xs')]: {
        height: '100%',
        marginBottom: 30
      }
    },
    contentAccount: {
      height: 70,
      paddingLeft: 26,
      paddingRight: 26,
      borderBottom: '1px solid #F1F1F4'
    },
    inputAccount: {
      padding: '29px 29px 0px 29px'
    },
    textField: {
      width: '100%',
      backgroundColor: '#FFFFFF',
      textTransform: 'uppercase',
      '& :disabled': {
        color: '#000000'
      }
    },
    textFieldAdress: {
      width: '100%',
      backgroundColor: '#FFFFFF',
      textTransform: 'uppercase',
      '& :disabled': {
        color: '#3DACEB'
      }
    },
    formControl: {
      marginTop: 20
    },
    editIconButton: {
      padding: 0
    },
    inputLabelProps: {
      color: '#9BA0A6 !important',
      fontSize: 12,
      fontWeight: 600,
      lineHeight: '12px',
      textTransform: 'uppercase'
    },
    inputProps: {
      color: '#3DACEB',
      fontWeight: 500,
      fontSize: 14,
      lineHeight: '17px'
    },
    buttonChangPassword: {
      width: 150,
      height: 30,
      padding: 0,
      textTransform: 'capitalize',
      backgroundColor: '#FFFFFF',
      border: '1px solid #E6E6E6',
      '&:hover': {
        backgroundColor: '#F4F4F4',
        border: '1px solid #E6E6E6',
        boxSizing: 'border-box'
      },
      [theme.breakpoints.down('sm')]: {
        width: 130
      }
    }
  });

class CardAccount extends React.Component<IProps, IState> {
  public state: IState = {
    openEditAdress:
      this.props.history.location.state &&
      this.props.history.location.state.editAdress
        ? false
        : true,
    openEditPasswrod: false,
    openEditName: true,
    open: false,
    openAuthen: this.props.profile.twoFactorEnabled,
    userProfile: this.props.profile,
    erroTomoAdress: false
  };

  componentWillReceiveProps(nextProps: IProps) {
    this.setState({
      userProfile: nextProps.profile,
      openAuthen: nextProps.profile.twoFactorEnabled
    });
  }
  public handleOpenEditAdress = () => {
    if (!this.state.openEditAdress) {
      if (
        this.state.userProfile !== this.props.profile &&
        this.state.userProfile.tomoAddress !== ''
      ) {
        this.props.updateUserProfile({
          tomoAddress: this.state.userProfile.tomoAddress
        });
      }
    }
    this.setState({
      openEditAdress: !this.state.openEditAdress
    });
  };

  public handleOpenEditPassword = () => {
    this.setState({
      openEditPasswrod: true
    });
  };

  public handleOpenEditName = () => {
    if (!this.state.openEditName) {
      if (this.state.userProfile !== this.props.profile) {
        this.props.updateUserProfile({
          username: this.state.userProfile.username
        });
      }
    }
    this.setState({
      openEditName: !this.state.openEditName
    });
  };

  public handleOpenEditAuth = () => {
    if (!this.state.userProfile.twoFactorEnabled) {
      this.props.getQrImage();
    }

    this.setState({
      open: true
    });
  };

  handleVerifyCode = (code: ISecondFactorCode) => {
    Axios.post('/users/2nd-factor', code)
      .then(() => {
        this.props.setCodeAction(code.code);
        this.props.getUserProfileAction();
        this.setState({ openAuthen: !this.state.openAuthen, open: false });
        this.props.updateNotification({
          message: MESSAGE_NOTIFICATION_SUCCESS.SETUP_TWOFACTOR,
          variant: Variant.SUCCESS
        });
      })
      .catch(err => {
        // this.setState({ open: false });
        const message =
          (err.response &&
            err.response.data &&
            err.response.data.error &&
            err.response.data.error.message) ||
          MESSAGE_NOTIFICATION_ERROR.VERIFY_CODE_INVALID;
        this.props.updateNotification({
          message,
          variant: Variant.ERROR
        });
      });
  };
  public handleClose = () => {
    this.setState({
      openEditPasswrod: false,
      open: false
    });
  };

  handleTextChange = (key: string) => (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    this.setState({
      userProfile: { ...this.state.userProfile, [key]: event.target.value }
    } as any);

    if (key === 'tomoAddress') {
      if (validateTomoAddress.test(event.target.value)) {
        this.setState({
          erroTomoAdress: false
        });
      } else if (event.target.value === '') {
        this.setState({
          erroTomoAdress: false
        });
      } else {
        this.setState({
          erroTomoAdress: true
        });
      }
    }
  };

  public render() {
    const { classes, role } = this.props;
    const {
      openEditAdress,
      openEditPasswrod,
      openEditName,
      openAuthen,
      userProfile,
      open,
      erroTomoAdress
    } = this.state;
    return (
      <>
        <Paper elevation={0} className={classes.root}>
          <Grid
            container
            alignItems="center"
            className={classes.contentAccount}
          >
            <Typography variant="h3">Account</Typography>
          </Grid>
          <Grid className={classes.inputAccount}>
            <Grid container>
              <FormControl
                style={{ width: 'calc(100% - 40px)' }}
                required={true}
                className={classes.formControl}
              >
                <TextField
                  InputLabelProps={{
                    className: classes.inputLabelProps
                  }}
                  className={classes.textField}
                  value={userProfile.username ? userProfile.username : ''}
                  onChange={this.handleTextChange('username')}
                  label="user name"
                  data-test="setting-username"
                  disabled={openEditName ? true : false}
                  InputProps={{
                    disableUnderline: openEditName ? true : false
                  }}
                  inputProps={{
                    'data-test': 'username'
                  }}
                />
              </FormControl>
              <Grid style={{ marginLeft: 10, marginTop: 35 }}>
                <IconButton
                  aria-label="Close"
                  data-test="setting-save"
                  onClick={this.handleOpenEditName}
                  classes={{
                    root: classes.editIconButton
                  }}
                >
                  <img
                    src={openEditName ? iconEditAdress : iconSaveAccount}
                    alt="icon Adress"
                  />
                </IconButton>
              </Grid>
            </Grid>
            {role === ROLE.AGENT ? (
              <Grid container alignItems="center">
                <FormControl
                  style={{ flex: 1 }}
                  required={true}
                  className={classes.formControl}
                >
                  <TextField
                    InputLabelProps={{
                      className: classes.inputLabelProps
                    }}
                    className={classes.textField}
                    type="password"
                    defaultValue={12345678}
                    label="Password"
                    disabled
                    InputProps={{
                      disableUnderline: true
                    }}
                  />
                </FormControl>
                <Button
                  variant="contained"
                  data-test="setting-btn-changepassword"
                  className={classes.buttonChangPassword}
                  onClick={this.handleOpenEditPassword}
                >
                  <span style={{ color: '#9BA0A6' }}> Change Password</span>
                </Button>
              </Grid>
            ) : null}

            {role === ROLE.AGENT ? (
              <Grid container style={{ marginTop: 20 }}>
                <Grid container item xs={12}>
                  <Typography variant="subtitle1">
                    Two-Factor Authentication
                  </Typography>
                </Grid>
                <Grid container item xs={12} style={{ marginTop: 10 }}>
                  <FormControlLabel
                    style={{ marginLeft: 0 }}
                    control={
                      <Switch
                        checked={openAuthen}
                        onChange={this.handleOpenEditAuth}
                      />
                    }
                    label=""
                  />
                </Grid>
              </Grid>
            ) : null}

            <Grid container>
              <FormControl
                style={{ width: 'calc(100% - 40px)' }}
                required={true}
                className={classes.formControl}
              >
                <TextField
                  data-test="setting-tomoaddress"
                  InputLabelProps={{
                    className: classes.inputLabelProps
                  }}
                  inputProps={{
                    className: classes.inputProps
                  }}
                  error={erroTomoAdress}
                  className={classes.textFieldAdress}
                  value={userProfile.tomoAddress ? userProfile.tomoAddress : ''}
                  onChange={this.handleTextChange('tomoAddress')}
                  label="TOMO address"
                  disabled={openEditAdress ? true : false}
                  InputProps={{
                    disableUnderline: openEditAdress ? true : false
                  }}
                />
              </FormControl>
              <Grid style={{ marginLeft: 10, marginTop: 35 }}>
                <IconButton
                  data-test="tomoaddress-save"
                  aria-label="Close"
                  onClick={this.handleOpenEditAdress}
                  classes={{
                    root: classes.editIconButton
                  }}
                >
                  <img
                    src={openEditAdress ? iconEditAdress : iconSaveAccount}
                    alt="icon Adress"
                  />
                </IconButton>
              </Grid>
            </Grid>
          </Grid>
        </Paper>
        <PasswordChangeDialogComponent
          showDialog={openEditPasswrod}
          handleCloseDialog={this.handleClose}
          isCode={this.state.userProfile.twoFactorEnabled}
        />
        <TwoAuthenticationDialogComponent
          showDialog={
            open && !openAuthen && !this.state.userProfile.twoFactorEnabled
          }
          setSecondFactorCode={this.handleVerifyCode}
          handleCloseDialog={this.handleClose}
        />
        <OffTwoFactorDialogComponent
          showDialog={
            open && openAuthen && this.state.userProfile.twoFactorEnabled
          }
          setSecondFactorCode={this.handleVerifyCode}
          handleCloseDialog={this.handleClose}
        />
      </>
    );
  }
}

const mapStateToProps = ({ user }: IStoreState) => ({
  profile: user.profile
});
const mapDispatchToProps = (
  dispatch: Dispatch<
    | IUpdateUserProfile
    | IGetQrImage
    | ISetSecondFactorCode
    | IGetUserProfile
    | IUpdateNotification
    | ISetCodeAction
  >
) => {
  return {
    updateUserProfile: (info: any) => dispatch(updateUserProfile(info)),
    setSecondFactorCode: (code: ISecondFactorCode) =>
      dispatch(setSecondFactorCode(code)),
    getQrImage: () => dispatch(getQrImageAction()),
    getUserProfileAction: () => dispatch(getUserProfileAction()),
    updateNotification: (noti: INotificationInfo) =>
      dispatch(fireNotification(noti)),
    setCodeAction: (code: string) => dispatch(setCodeAction(code))
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(CardAccount));
