import React, { Dispatch } from 'react';
import {
  createStyles,
  withStyles,
  WithStyles,
  Grid,
  Typography
} from '@material-ui/core';

import { IParams, IStoreState } from '../../../../types';
import { IGetAccountAction, getAccountAction } from '../Top/actions';
import { IUpdateUserById, updateUserById } from '../../../setting/actions/user';
import { IUserIdInfo, IUserProfile } from '../../../setting/types';
import { connect } from 'react-redux';
import { IMetaData } from '../../../../components/shared/table/redux/types';
import AgentCardComponent from './Agent.card.component';
import ConfirmDialog from '../../../../components/dialog/Confirm.dialog';
import { ROLE } from '../../../../constants/auth.constant';
import memoizeOne from 'memoize-one';

const styles = () =>
  createStyles({
    '@keyframes fadeInDown': {
      '0%': {
        opacity: 0,
        transform: 'translateY(-20px)'
      },
      '100%': {
        opacity: 1,
        transform: 'translateY(0)'
      }
    },
    container: {
      width: '100%',
      marginTop: 20,
      animation: `$fadeInDown 1500ms ease-in-out`
    },
    rootLayout: {
      padding: 15,
      width: '100%',
      height: 170,
      display: 'flex',
      flexDirection: 'column',
      boxSizing: 'border-box'
    },
    itemRoot: {
      flex: 1
    },
    item: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center'
    },
    title: {
      fontSize: 10,
      lineHeight: '18px',
      fontWeight: 600,
      color: '#9BA0A6',
      textTransform: 'uppercase'
    },
    value: {
      fontSize: 12,
      lineHeight: '20px',
      fontWeight: 500,
      color: '#222222'
    },
    floatRight: {
      alignItems: 'flex-end'
    },
    fadeIn: {
      opacity: 1,
      transform: 'translateY(0)'
    }
  });

interface IProps extends WithStyles<typeof styles> {
  searchText: string;
  users: {
    data: IUserProfile[];
    meta: IMetaData;
  };
  getAgentAccountAction: (params: IParams) => void;
  updateUserStatus: (request: IUserIdInfo) => void;
}

interface IState {
  params: IParams;
  showConfirm: boolean;
  userId: string;
  status: boolean;
}

class AgentMobile extends React.Component<IProps, IState> {
  state: IState = {
    params: {
      limit: 10,
      offset: 0,
      type: 'Agent'
    },
    showConfirm: false,
    userId: '',
    status: false
  };

  componentDidMount() {
    this.props.getAgentAccountAction(this.state.params);
  }

  loadingMore = () => {
    const { limit } = this.state.params;
    const newLimit = limit! + 10;
    this.props.getAgentAccountAction({ ...this.state.params, limit: newLimit });
    this.setState({
      params: {
        ...this.state.params,
        limit: limit! + 10
      }
    });
  };

  handleUserStatus = (status: boolean, id: string) => () => {
    this.setState({
      userId: id,
      status,
      showConfirm: true
    });
  };

  handleCloseConfirm = () => {
    this.setState({
      showConfirm: false
    });
  };

  handleSave = () => {
    const { userId, status, params } = this.state;
    const request: IUserIdInfo = {
      data: {
        isActive: status
      },
      id: userId,
      type: ROLE.AGENT,
      limit: params.limit!,
      offset: params.offset!
    };
    this.props.updateUserStatus(request);
    this.handleCloseConfirm();
  };

  filterData = memoizeOne((users: IUserProfile[], searchText: string) =>
    users.filter(e => e.email.includes(searchText))
  );

  render() {
    const { users, searchText } = this.props;
    const data =
      (users && users.data && this.filterData(users.data, searchText)) || [];
    return (
      <>
        {data && data.length ? (
          data.map(e => (
            <AgentCardComponent
              handleUserStatus={this.handleUserStatus}
              key={e.id}
              data={e}
            />
          ))
        ) : (
          <Typography
            variant="h3"
            style={{ textAlign: 'center', marginTop: 30 }}
          >
            No Data
          </Typography>
        )}
        {users && users.meta && users.meta.total > this.state.params.limit! && (
          <Grid
            container
            style={{ flexDirection: 'column', marginTop: 30 }}
            alignItems={'center'}
          >
            <Typography
              variant="h4"
              style={{ color: '#00AFC1', fontSize: 13 }}
              onClick={this.loadingMore}
            >
              Load more...
            </Typography>
          </Grid>
        )}
        <ConfirmDialog
          showDialog={this.state.showConfirm}
          handleCloseDialog={this.handleCloseConfirm}
          textHeader="Change agent status"
          textContent={`Are you sure you want to save changes ?`}
          handleOkButton={this.handleSave}
        />
      </>
    );
  }
}

const mapDispatchToProps = (
  dispatch: Dispatch<IGetAccountAction | IUpdateUserById>
) => ({
  getAgentAccountAction: (params: IParams) =>
    dispatch(getAccountAction(params)),
  updateUserStatus: (request: IUserIdInfo) => dispatch(updateUserById(request))
});

const mapStateToProps = ({ accounts }: IStoreState) => ({
  users: accounts.Agent
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(AgentMobile));
