import React, { Dispatch } from 'react';
import { Switch, withRouter, Route } from 'react-router-dom';
import { RouteComponentProps } from 'react-router';
import { PATH } from './constants/Path.constant';
import { connect } from 'react-redux';
import { History } from 'history';
import LoginAgent from './scenes/auth/components/Login.component';
import ForgotPasswordComponent from './scenes/auth/components/ForgotPassword.component';
import VerifyLoginComponent from './scenes/auth/components/VerifyLogin.component';
import ResetPasswordComponent from './scenes/auth/components/ResetPassword.component';
import AgentLayoutComponent from './components/AgentLayout.component';
import NotificationComponent from './components/Notification.component';
import { IStoreState } from './types';
import AdminLayoutComponent from './components/AdminLayout.component';
import { ROLE } from './constants/auth.constant';
import IdleTimer from 'react-idle-timer';
import { IRefreshTokenRequest } from './scenes/auth/types';
import { IRefreshToken, refreshTokenAction } from './actions/auth.action';
import { REFRESH_TOKEN_TIME } from './constants/auth.constant';
import _ from 'lodash';

const mapStateToProps = (
  { router }: IStoreState,
  { history }: RouteComponentProps
) => {
  return { history };
};

const mapDispatchToProps = (dispatch: Dispatch<IRefreshToken>) => {
  return {
    refreshTokenAction: (request: IRefreshTokenRequest) =>
      dispatch(refreshTokenAction(request))
  };
};

interface IProps {
  history: History;
  refreshTokenAction: (request: IRefreshTokenRequest) => void;
}

const token = localStorage.getItem('token');
const role = localStorage.getItem('role');
const expries = localStorage.getItem('exp');

class App extends React.Component<IProps> {
  constructor(props: any) {
    super(props);
    this.idleTimer = null;
  }

  public idleTimer: IdleTimer | null;

  public onAction = (e: any) => {
    // console.log('user did something', e);
  };

  public onActive = (e: any) => {
    // console.log('user is active', e);
  };

  public onIdle = (e: any) => {
    const refresh = localStorage.getItem('refresh-token');
    const request: IRefreshTokenRequest = {
      refreshToken: refresh ? refresh : ''
    };
    this.props.refreshTokenAction(request);
  };

  // tslint:disable-next-line
  UNSAFE_componentWillMount = () => {
    this.checkAuth();
  };

  checkAuth = () => {
    const { pathname } = this.props.history.location;
    if (!token) {
      if (
        !pathname.match(PATH.agent.signup) &&
        !pathname.match(PATH.admin.signup) &&
        !pathname.match(PATH.resetPassword) &&
        !pathname.match(PATH.agent.verify) &&
        !pathname.match(PATH.forgotPassword)
      ) {
        return this.props.history.push(PATH.login);
      }
    } else {
      if (
        _.includes(
          [
            PATH.agent.signup,
            PATH.admin.signup,
            PATH.resetPassword,
            PATH.agent.verify,
            PATH.forgotPassword,
            PATH.login,
            PATH.home
          ],
          pathname
        )
      ) {
        if (role === ROLE.AGENT) {
          return this.props.history.push(PATH.agent.dashboard);
        } else {
          return this.props.history.push(PATH.admin.top);
        }
      }
    }
  };

  getComponent = () => {
    if (token) {
      if (role === ROLE.AGENT) {
        return AgentLayoutComponent;
      }
      return AdminLayoutComponent;
    }
    return undefined;
  };

  render() {
    let cruExpries = 0;
    if (expries !== null) {
      cruExpries = parseFloat(expries) * 1000 - new Date().getTime();
    }
    return (
      <div>
        <IdleTimer
          ref={ref => {
            this.idleTimer = ref;
          }}
          element={document}
          onActive={this.onActive}
          onIdle={this.onIdle}
          onAction={this.onAction}
          debounce={250}
          timeout={
            cruExpries ? cruExpries - REFRESH_TOKEN_TIME : 60 * 60 * 1000
          }
        >
          <Switch>
            <Route path={PATH.login} component={LoginAgent} />
            <Route
              path={PATH.forgotPassword}
              component={ForgotPasswordComponent}
            />
            <Route
              path={[PATH.agent.signup, PATH.admin.signup]}
              component={ResetPasswordComponent}
            />
            <Route path={PATH.agent.verify} component={VerifyLoginComponent} />
            <Route
              path={PATH.resetPassword}
              component={ResetPasswordComponent}
            />
            <Route component={this.getComponent()} />
          </Switch>
          <NotificationComponent />
        </IdleTimer>
      </div>
    );
  }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(App));
