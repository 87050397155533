import React from 'react';
import {
  Paper,
  createStyles,
  withStyles,
  WithStyles,
  Grid,
  Typography,
  IconButton,
  Tooltip
} from '@material-ui/core';
import classNames from 'classnames';
import { formatDate } from '../../../../helper';
import CheckCircleOutlineSharpIcon from '@material-ui/icons/CheckCircleOutlineSharp';
import { Link } from 'react-router-dom';

import { IUserProfile } from '../../../setting/types';

const styles = () =>
  createStyles({
    '@keyframes fadeInDown': {
      '0%': {
        opacity: 0,
        transform: 'translateY(-20px)'
      },
      '100%': {
        opacity: 1,
        transform: 'translateY(0)'
      }
    },
    container: {
      width: '100%',
      marginTop: 20,
      animation: `$fadeInDown 1500ms ease-in-out`
    },
    rootLayout: {
      padding: 15,
      width: '100%',
      height: 110,
      display: 'flex',
      flexDirection: 'column',
      boxSizing: 'border-box'
    },
    itemRoot: {
      flex: 1
    },
    item: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center'
    },
    title: {
      fontSize: 10,
      lineHeight: '18px',
      fontWeight: 600,
      color: '#9BA0A6',
      textTransform: 'uppercase'
    },
    value: {
      fontSize: 12,
      lineHeight: '20px',
      fontWeight: 500,
      color: '#222222'
    },
    floatRight: {
      alignItems: 'flex-end'
    },
    fadeIn: {
      opacity: 1,
      transform: 'translateY(0)'
    }
  });

interface IProps extends WithStyles<typeof styles> {
  data: IUserProfile;
  handleUserStatus: (status: boolean, id: string) => () => void;
}

class AgentCard extends React.Component<IProps> {
  formatTransactionHash = (hash: string) => {
    const hashSlice =
      hash &&
      hash
        .slice(0, 4)
        .concat('...')
        .concat(hash.slice(-4));
    return (
      <Link
        style={{
          color: '#3DACEB ',
          textDecoration: 'none'
        }}
        to={{
          pathname: `${process.env.REACT_APP_SCAN_URL}/${hash}`
        }}
        target="true"
      >
        {hashSlice}
      </Link>
    );
  };
  render() {
    const { classes, data } = this.props;
    return (
      <Paper elevation={0} className={classes.container}>
        <Grid className={classes.rootLayout}>
          <Grid container className={classes.itemRoot}>
            <Grid item xs={6}>
              <Grid className={classes.item}>
                <Typography className={classes.title}>Email Address</Typography>
                <Typography
                  className={classes.value}
                  style={{ color: '#00AFC1' }}
                >
                  {data && data.email}
                </Typography>
              </Grid>
            </Grid>
            <Grid item xs={6}>
              <Grid className={classNames(classes.item, classes.floatRight)}>
                {data.isActive ? (
                  <Tooltip
                    title={
                      <Typography
                        style={{ color: '#9BA0A6' }}
                        variant="subtitle2"
                      >
                        {' '}
                        Block user
                      </Typography>
                    }
                    style={{ color: '#222222' }}
                  >
                    <IconButton
                      onClick={this.props.handleUserStatus(
                        false,
                        data && data.id
                      )}
                    >
                      <CheckCircleOutlineSharpIcon color={'primary'} />
                    </IconButton>
                  </Tooltip>
                ) : (
                  <Tooltip
                    title={
                      <Typography variant="subtitle2"> active user</Typography>
                    }
                    placement="bottom"
                  >
                    <IconButton
                      onClick={this.props.handleUserStatus(
                        true,
                        (data && data.id) || ''
                      )}
                    >
                      <CheckCircleOutlineSharpIcon color={'disabled'} />
                    </IconButton>
                  </Tooltip>
                )}
              </Grid>
            </Grid>
          </Grid>

          <Grid container className={classes.itemRoot}>
            <Grid item xs={6}>
              <Grid className={classes.item}>
                <Typography className={classes.title}>Tomo address</Typography>
                <Typography className={classes.value}>
                  {data && this.formatTransactionHash(data.tomoAddress)}
                </Typography>
              </Grid>
            </Grid>
            <Grid item xs={6}>
              <Grid className={classNames(classes.item, classes.floatRight)}>
                <Typography className={classes.title}>Date add</Typography>
                <Typography className={classes.value}>
                  {data && formatDate(data.createdAt)}
                </Typography>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Paper>
    );
  }
}

export default withStyles(styles)(AgentCard);
