import { ISetCardHolderAction } from '../actions/holder.action';
import { SET_CARD_HOLDER } from '../constants/hoder.constant';
import { IHolderContainer, initMetaData } from '../types';

export const holders = (
  state: IHolderContainer = {
    data: [],
    meta: initMetaData
  },
  action: ISetCardHolderAction
): IHolderContainer => {
  if (action.type === SET_CARD_HOLDER) {
    return action.payload;
  }
  return state;
};
