import { ISetTokenAction, ISetRoleId, ISetCodeAction } from '../actions/auth';
import { IToken, initToken, IRoleId } from '../types';
import { SET_TOKEN, SET_ROLE_ID, SET_CODE } from '../actions/constant';

export const token = (
  state: IToken = initToken,
  action: ISetTokenAction
): IToken => {
  if (action.type === SET_TOKEN) {
    return action.payload;
  }
  return state;
};

export const roleIds = (
  state: IRoleId[] = [],
  action: ISetRoleId
): IRoleId[] => {
  if (action.type === SET_ROLE_ID) {
    return action.payload;
  }
  return state;
};

export const code = (state: string = '', action: ISetCodeAction): string => {
  if (action.type === SET_CODE) {
    return action.payload;
  }
  return state;
};
