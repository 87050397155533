import React, { Dispatch } from 'react';
import {
  Typography,
  Theme,
  createStyles,
  WithStyles,
  withStyles,
  Dialog,
  Paper,
  Grid,
  Button,
  TextField
} from '@material-ui/core';
import {
  IAddTradingAccountAction,
  addTradingAccountAction
} from '../../scenes/admin/components/Top/actions';
import { IAddTradingAccountRequest } from '../../scenes/admin/components/Top/types';
import { connect } from 'react-redux';

const styles = (theme: Theme) =>
  createStyles({
    rootLogin: {
      width: 500,
      height: 450,
      [theme.breakpoints.down('sm')]: {
        width: '80%',
        padding: '0 10%',
        height: 400,
        [theme.breakpoints.down('xs')]: {
          height: '100%'
        }
      }
    },
    titleHeader: {
      height: 100,
      [theme.breakpoints.down('sm')]: {
        height: 60
      },
      borderBottom: '1px solid #F1F1F4'
    },
    bodyPager: {
      height: 'calc(100% - 220px )',
      [theme.breakpoints.down('sm')]: {
        height: 'calc(100% - 180px)',
        [theme.breakpoints.down('xs')]: {
          height: 200
        }
      }
    },
    buttonCanel: {
      color: '#222222',
      border: '1px solid #DCE0E4',
      width: 195,
      textTransform: 'capitalize',
      marginRight: 5,
      backgroundColor: '#FFFFFF',
      '&:hover': {
        backgroundColor: '#FFFFFF'
      },
      [theme.breakpoints.down('xs')]: {
        width: '80%',
        flex: 1
      }
    },
    buttonLogin: {
      width: 195,
      textTransform: 'capitalize',
      marginLeft: 5,
      [theme.breakpoints.down('xs')]: {
        width: '80%',
        flex: 1
      }
    },
    dialogLayout: {
      [theme.breakpoints.down('sm')]: {
        width: '80%',
        padding: '0 10%'
      }
    },
    textFiled: {
      marginTop: 50,
      [theme.breakpoints.down('xs')]: {
        marginTop: 30,
        height: 70,
        backgroundColor: 'transparent'
      }
    },
    responsiveText: {
      [theme.breakpoints.down('xs')]: {
        fontSize: 10
      }
    }
  });

interface IProps extends WithStyles<typeof styles> {
  showDialog: boolean;
  addTradingAccountAction: (request: IAddTradingAccountRequest) => void;
  handleCloseDialog: () => void;
  title: string;
}

interface IState {
  id: string;
  secret: string;
}

class AddTrading extends React.Component<IProps> {
  state: IState = {
    id: '',
    secret: ''
  };
  handleTextChange = (key: string) => (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    this.setState({ [key]: event.target.value } as any);
  };
  public handleCloseDialogAddTrader = () => {
    this.setState({
      id: '',
      secret: ''
    });
    this.props.handleCloseDialog();
  };
  addTradingAccount = () => {
    this.props.addTradingAccountAction({
      id: this.state.id,
      secret: this.state.secret
    });
    this.props.handleCloseDialog();
  };
  public render() {
    const { showDialog, classes } = this.props;
    const { id, secret } = this.state;
    return (
      <Dialog
        onClose={this.handleCloseDialogAddTrader}
        aria-labelledby="customized-dialog-title"
        open={showDialog}
        className={classes.dialogLayout}
        maxWidth="md"
      >
        <Paper elevation={1} className={classes.rootLogin}>
          <Grid
            container
            alignItems="center"
            justify="center"
            className={classes.titleHeader}
          >
            <Typography variant="h2">{this.props.title}</Typography>
          </Grid>
          <Grid
            container
            justify="center"
            alignContent="center"
            className={classes.bodyPager}
          >
            <TextField
              style={{ backgroundColor: 'transparent' }}
              label="ID"
              variant="outlined"
              value={id}
              error={id.length > 0 && id.length !== 18}
              helperText={
                id.length > 0 && id.length !== 18
                  ? 'Bybit api must contain 18 characters'
                  : undefined
              }
              onChange={this.handleTextChange('id')}
            />
            <TextField
              label="Secret"
              onChange={this.handleTextChange('secret')}
              value={secret}
              error={secret.length > 0 && secret.length !== 36}
              helperText={
                secret.length > 0 && secret.length !== 36
                  ? 'Bybit secret must contain 36 characters'
                  : undefined
              }
              variant="outlined"
              className={classes.textFiled}
            />
          </Grid>
          <Grid
            container
            justify="center"
            alignItems="center"
            style={{ borderTop: '1px solid #F1F1F4', height: 120 }}
          >
            <Button
              variant="contained"
              color="primary"
              size="large"
              className={classes.buttonCanel}
              onClick={this.handleCloseDialogAddTrader}
            >
              <span
                className={classes.responsiveText}
                style={{ color: '#222222' }}
              >
                {' '}
                Cancel
              </span>
            </Button>
            <Button
              variant="contained"
              color="primary"
              size="large"
              disabled={
                !id || !secret || id.length !== 18 || secret.length !== 36
              }
              onClick={this.addTradingAccount}
              className={classes.buttonLogin}
            >
              <span className={classes.responsiveText}>Add Account</span>
            </Button>
          </Grid>
        </Paper>
      </Dialog>
    );
  }
}

const mapDispatchToProps = (dispatch: Dispatch<IAddTradingAccountAction>) => {
  return {
    addTradingAccountAction: (request: IAddTradingAccountRequest) =>
      dispatch(addTradingAccountAction(request))
  };
};

export default connect(
  undefined,
  mapDispatchToProps
)(withStyles(styles)(AddTrading));
