import React from 'react';
import { createStyles, WithStyles, withStyles } from '@material-ui/core';

const styles = createStyles({
  image: {
    width: 50,
    height: 50,
    overflow: 'hidden',
    position: 'relative'
  },
  hidden: {
    display: 'none'
  },
  logoHoverOrg: {
    position: 'absolute',
    top: 25,
    left: 0,
    cursor: 'pointer'
  },
  logo: {
    width: 50,
    height: 50,
    borderRadius: '50%'
  }
});

interface IProps extends WithStyles<typeof styles> {
  url: string;
}
class AvatarComponent extends React.Component<IProps> {
  render() {
    const { classes } = this.props;
    return (
      <div className={classes.image}>
        <img
          src={this.props.url}
          className={classes.logo}
          alt="logo"
          height={40}
        />
      </div>
    );
  }
}

export default withStyles(styles)(AvatarComponent);
