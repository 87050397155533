import React from 'react';
import {
  widget,
  ChartingLibraryWidgetOptions,
  IChartingLibraryWidget
} from '../../../../../charting_library/charting_library.min';
import { Grid } from '@material-ui/core';
import DataFeed from './api/index';
import { TradingBoxComponent } from '../../../../../components/admin/component.tsx/tradingbox.component';

interface IProps {
  symbol: ChartingLibraryWidgetOptions['symbol'];
  interval: ChartingLibraryWidgetOptions['interval'];
  libraryPath: ChartingLibraryWidgetOptions['library_path'];
  chartsStorageUrl: ChartingLibraryWidgetOptions['charts_storage_url'];
  chartsStorageApiVersion: ChartingLibraryWidgetOptions['charts_storage_api_version'];
  clientId: ChartingLibraryWidgetOptions['client_id'];
  userId: ChartingLibraryWidgetOptions['user_id'];
  fullscreen: ChartingLibraryWidgetOptions['fullscreen'];
  autosize: ChartingLibraryWidgetOptions['autosize'];
  studiesOverrides: ChartingLibraryWidgetOptions['studies_overrides'];
  containerId: ChartingLibraryWidgetOptions['container_id'];
}

// function getLanguageFromURL(): LanguageCode | null {
//   const regex = new RegExp('[\\?&]lang=([^&#]*)');
//   const results = regex.exec(location.search);
//   return results === null
//     ? null
//     : (decodeURIComponent(results[1].replace(/\+/g, ' ')) as LanguageCode);
// }

class ChartContainer extends React.PureComponent<IProps> {
  public static defaultProps: IProps = {
    symbol: 'BITMEX:XBTUSD',
    interval: 'W',
    containerId: 'chart_container',
    libraryPath: '../../../charting_library/',
    chartsStorageUrl: 'https://saveload.tradingview.com',
    chartsStorageApiVersion: '1.1',
    clientId: 'tradingview.com',
    userId: 'public_user_id',
    fullscreen: false,
    autosize: true,
    studiesOverrides: {}
  };
  private tvWidget: IChartingLibraryWidget | null = null;

  public componentDidMount(): void {
    const widgetOptions: ChartingLibraryWidgetOptions = {
      symbol: this.props.symbol as string,
      datafeed: DataFeed,
      interval: this.props.interval as ChartingLibraryWidgetOptions['interval'],
      container_id: this.props
        .containerId as ChartingLibraryWidgetOptions['container_id'],
      library_path: this.props.libraryPath as string,

      locale: 'en',
      theme: 'Dark',
      disabled_features: ['use_localstorage_for_settings'],
      enabled_features: ['study_templates'],
      charts_storage_url: this.props.chartsStorageUrl,
      charts_storage_api_version: this.props.chartsStorageApiVersion,
      client_id: this.props.clientId,
      user_id: this.props.userId,
      fullscreen: this.props.fullscreen,
      autosize: this.props.autosize,
      studies_overrides: this.props.studiesOverrides
    };

    const tvWidget = new widget(widgetOptions);
    this.tvWidget = tvWidget;

    tvWidget.onChartReady(() => {
      tvWidget.headerReady().then(() => {
        const button = tvWidget.createButton();
        button.setAttribute('title', 'Click to show a notification popup');
        button.classList.add('apply-common-tooltip');
        button.innerHTML = 'Check API';
      });
    });
  }

  public componentWillUnmount(): void {
    if (this.tvWidget !== null) {
      this.tvWidget.remove();
      this.tvWidget = null;
    }
  }
  render() {
    return (
      <Grid container>
        <Grid item xs={4}>
          <TradingBoxComponent
            tabAccount={[
              { label: 'Account 1', index: 0 },
              { label: 'Account 2', index: 1 },
              { label: 'Account 3', index: 2 }
            ]}
          />
        </Grid>
        <Grid
          item
          xs={8}
          id={this.props.containerId}
          style={{ width: '100%', paddingLeft: 20 }}
        />
      </Grid>
    );
  }
}

export default ChartContainer;
