import * as React from 'react';
import { WithStyles, withStyles, createStyles } from '@material-ui/core/styles';
import {
  Paper,
  Grid,
  Typography,
  Theme,
  TextField,
  IconButton
} from '@material-ui/core';
import {
  TableDataRawAdminComponent,
  ITEM_TYPE_ADMIN
} from './shared/TableRawAdmin.component';
import { History } from 'history';
import iconEditAdress from '../assets/images/iconEditAdress.svg';
import iconSaveAddress from '../assets/images/iconSaveAccount.svg';
import ConfirmDialog from './dialog/Confirm.dialog';
import { CONFIRM_MESSAGES } from '../constants/Config.constant';
import {
  IGetHiddenRateAction,
  IAddHiddenRateAction,
  getHiddenRateAction,
  addHiddenRateAction,
  getProfitRateAction,
  IGetProfitRateAction
} from '../scenes/admin/components/Top/actions';
import { Dispatch } from 'redux';
import { IStoreState } from '../types';
import { connect } from 'react-redux';
import {
  IGetPaymentSumaryAction,
  getPaymentSumaryAction
} from '../scenes/dashboard/actions/payment.action';
import { IPaymentSumary } from '../scenes/dashboard/types';

import { formatNumberToFixed } from '../helper';

interface IProps extends WithStyles<typeof styles> {
  history: History;
  hiddenRate: number;
  profitRate: number;
  summary: IPaymentSumary;
  getHiddenRateAction: () => void;
  getProfitRateAction: () => void;
  addHiddenRateAction: (rate: number) => void;
  getPaymentSummaryAction: () => void;
}

interface IState {
  isConfirm: boolean;
  hiddenRate: string | number;
  isEditing: boolean;
}

const styles = (theme: Theme) =>
  createStyles({
    heightPager: {
      height: 530,
      padding: 30,
      display: 'flex',
      flexDirection: 'column',
      [theme.breakpoints.down('sm')]: {
        padding: 20,
        height: 400
      }
    },
    borderPager: {
      paddingRight: 30,
      display: 'flex',
      flexDirection: 'column',
      borderRight: '1px dotted rgba(0, 0, 0, 0.4)',
      [theme.breakpoints.down('sm')]: {
        paddingRight: 10
      }
    },
    tableTotalProfit: {
      marginTop: 30,
      marginBottom: 22
    },
    paperTip: {
      height: 70,
      backgroundColor: '#F8F8F8',
      boxShadow: 'inherit',
      display: 'flex',
      alignItems: 'center'
    },
    textTip: {
      fontWeight: 600
    },
    textTipTypography: {
      padding: '0px 10px 0px 15px'
    },
    itemsPaymentDate: {
      marginTop: 39,
      [theme.breakpoints.up(1020)]: {
        [theme.breakpoints.down(1279)]: {
          marginTop: 20
        }
      }
    },

    buyNft: {
      fontWeight: 600,
      borderBottom: '1px solid #9BA0A6',
      cursor: 'pointer'
    },
    itemsActivedNFT: {
      marginTop: 45
    },

    marginTable: {
      marginBottom: 0,
      [theme.breakpoints.up(1020)]: {
        [theme.breakpoints.down(1279)]: {
          marginBottom: 20
        }
      }
    },
    tableActive: {
      paddingLeft: 30,
      display: 'flex',
      flexDirection: 'column',
      [theme.breakpoints.down('xs')]: {
        paddingLeft: 10
      }
    },
    titleText: {
      [theme.breakpoints.down('sm')]: {
        fontSize: 18
      }
    }
  });

class Profit extends React.Component<IProps, IState> {
  public state: IState = {
    isConfirm: false,
    hiddenRate: this.props.hiddenRate,
    isEditing: false
  };

  componentDidMount() {
    this.props.getHiddenRateAction();
    this.props.getProfitRateAction();
    this.props.getPaymentSummaryAction();
  }

  public hanleCloseDialog = () => {
    this.setState({
      isConfirm: false,
      hiddenRate: this.props.hiddenRate
    });
  };

  onFocus = (event: React.FocusEvent<HTMLInputElement>) => {
    event.preventDefault();
    const { target } = event;
    target.focus();
    target.select();
  };

  handleSaveProfit = () => {
    this.props.addHiddenRateAction(Number(this.state.hiddenRate));
    this.setState({ isConfirm: false });
  };

  componentWillReceiveProps(nextProps: IProps) {
    if (nextProps.hiddenRate !== this.props.hiddenRate) {
      this.setState({ hiddenRate: nextProps.hiddenRate });
    }
  }

  handleCompleteEdit = () => {
    let isConfirm = false;
    if (
      this.state.isEditing &&
      this.state.hiddenRate !== this.props.hiddenRate
    ) {
      isConfirm = true;
    }
    this.setState({ isEditing: !this.state.isEditing, isConfirm });
  };
  handleTextChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    let value = Number(e.target.value);
    if (value < 0) {
      value = 0;
    } else if (value > 100) {
      value = 100;
    }
    // if (value > this.props.profitRate) {
    //   value = this.props.profitRate;
    // } else if (value < -this.props.profitRate) {
    //   value = -this.props.profitRate;
    // }
    this.setState({ hiddenRate: value });
  };

  public render() {
    const { classes, profitRate, summary } = this.props;
    const { isEditing } = this.state;
    return (
      <React.Fragment>
        <Paper elevation={0} className={classes.heightPager}>
          <Grid
            container
            direction="column"
            justify="space-between"
            style={{ height: '100%', flex: 1, width: '100%' }}
          >
            <Grid container alignContent="center">
              <Grid item xs={6} className={classes.borderPager}>
                {/* <Grid container className={classes.marginTable}> */}
                <Grid item>
                  <TableDataRawAdminComponent
                    items={{
                      title: 'ACTIVE profit',
                      value: `${formatNumberToFixed(
                        summary.activeProfit || 0,
                        2
                      )} TOMO`
                    }}
                    type={ITEM_TYPE_ADMIN.BIG_VALUE}
                  />
                </Grid>
                <Grid item className={classes.tableTotalProfit}>
                  <TableDataRawAdminComponent
                    items={{
                      title: 'total profit',
                      value: `${formatNumberToFixed(
                        summary.totalProfit || 0,
                        2
                      )} TOMO`
                    }}
                    type={ITEM_TYPE_ADMIN.BIG_VALUE}
                  />
                </Grid>
                {/* </Grid> */}
              </Grid>

              <Grid className={classes.tableActive} item xs={6}>
                <Grid container>
                  <TableDataRawAdminComponent
                    items={{
                      title: 'Total NFT',
                      value: `${summary.totalNFT || 0} NFT`
                    }}
                    type={ITEM_TYPE_ADMIN.DEFAULT}
                  />
                </Grid>
                <Grid container className={classes.itemsActivedNFT}>
                  <TableDataRawAdminComponent
                    items={{
                      title: 'total nft volume',
                      value: `${formatNumberToFixed(
                        summary.totalNFTVolume || 0,
                        2
                      )}`
                    }}
                    type={ITEM_TYPE_ADMIN.ICON_VALUE}
                  />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          <Grid
            container
            style={{
              flex: 1,
              paddingTop: 50,
              flexDirection: 'column'
            }}
          >
            <Grid item>
              <Typography variant="subtitle1">{'profit rate'}</Typography>
              <Typography
                className={classes.titleText}
                variant="h2"
              >{`${profitRate}%`}</Typography>
            </Grid>
            <Grid item style={{ marginTop: 20 }}>
              <Typography variant="subtitle1">{'show profit rate'}</Typography>
              <TextField
                onFocus={this.onFocus}
                disabled={!isEditing}
                style={{
                  marginTop: 10,
                  width: 140,
                  height: 50
                }}
                variant="outlined"
                value={this.state.hiddenRate.toString()}
                type="number"
                onChange={this.handleTextChange}
                inputProps={{
                  style: {
                    fontSize: 24,
                    color: '#222222',
                    fontWeight: 600,
                    lineHeight: '29px',
                    fontStyle: 'normal'
                  }
                }}
                InputProps={{
                  endAdornment: (
                    <Grid
                      container
                      style={{
                        flexDirection: 'row',
                        justifyContent: 'center',
                        alignItems: 'center',
                        flexWrap: 'nowrap'
                      }}
                    >
                      <Typography variant="h2">%</Typography>
                      <IconButton
                        onClick={this.handleCompleteEdit}
                        aria-label="Toggle save visibility"
                      >
                        {!isEditing ? (
                          <img
                            src={iconEditAdress}
                            alt="error tomo"
                            width={25}
                            height={25}
                          />
                        ) : (
                          <img
                            src={iconSaveAddress}
                            alt="error tomo"
                            width={25}
                            height={25}
                          />
                        )}
                      </IconButton>
                    </Grid>
                  )
                }}
              />
            </Grid>
          </Grid>
        </Paper>
        <ConfirmDialog
          handleOkButton={this.handleSaveProfit}
          showDialog={this.state.isConfirm}
          handleCloseDialog={this.hanleCloseDialog}
          textHeader="Change hidden rate confirm"
          textContent={CONFIRM_MESSAGES.CHANGES_CONFIRM}
        />
      </React.Fragment>
    );
  }
}

const mapDispatchToProps = (
  dispatch: Dispatch<
    | IGetHiddenRateAction
    | IAddHiddenRateAction
    | IGetProfitRateAction
    | IGetPaymentSumaryAction
  >
) => ({
  getHiddenRateAction: () => dispatch(getHiddenRateAction()),
  getProfitRateAction: () => dispatch(getProfitRateAction()),
  addHiddenRateAction: (rate: number) => dispatch(addHiddenRateAction(rate)),
  getPaymentSummaryAction: () => dispatch(getPaymentSumaryAction())
});

const mapStateToProps = ({
  hiddenRate,
  profitRate,
  paymentSumary
}: IStoreState) => ({
  hiddenRate,
  profitRate,
  summary: paymentSumary
});
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(Profit));
