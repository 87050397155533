import * as React from 'react';
import {
  WithStyles,
  withStyles,
  createStyles,
  Theme
} from '@material-ui/core/styles';
import {
  Grid,
  FormControl,
  NativeSelect,
  FormControlLabel,
  Checkbox
} from '@material-ui/core';
import TitlePageComponent from '../../components/TitlePage.component';
import { ITableSettings } from '../../components/shared/table/redux/types';
import { formatDate } from '../../helper';
import TableComponent from '../../components/shared/table';
import MediaQuery from 'react-responsive';
import classNames from 'classnames';
import PaymentHistoryMobileComponent from './PaymentHistory.mobile.component';
interface IProps extends WithStyles<typeof styles> {}

const tableSettings: ITableSettings = {
  pagination: {
    show: true,
    option: {
      rowsPerPage: 10
    }
  },
  tableColumn: [
    {
      label: 'Payment Date',
      key: `paymentDate`,
      format: formatDate,
      width: '18%',
      styles: {
        fontFamily: 'Montserrat',
        fontWeight: '500',
        fontSize: '14px',
        lineHeight: '15px',
        color: '#222222'
      },
      sortable: {
        key: 'paymentDate',
        order: 'desc',
        type: 'date'
      }
    },
    {
      label: 'NFT No',
      key: `nftNo`,
      width: '18%',
      styles: {
        fontFamily: 'Montserrat',
        fontWeight: '600',
        fontSize: '14px',
        lineHeight: '15px',
        color: '#222222'
      }
    },
    {
      label: 'Issues',
      key: `transactionType`,
      width: '23%',
      styles: {
        fontFamily: 'Montserrat',
        fontWeight: '600',
        fontSize: '14px',
        lineHeight: '12px',
        color: '#222222'
      }
    },
    {
      label: 'Payment Volume',
      key: 'total',
      width: '18%',
      postFix: 'TOMO',
      styles: {
        fontFamily: 'Montserrat',
        fontWeight: '500',
        fontSize: '14px',
        lineHeight: '12px',
        color: '#00AFC1'
      },
      sortable: {
        key: 'total',
        order: 'desc',
        type: 'string'
      }
    },
    {
      label: 'Transaction',
      key: 'hash',
      width: '18%',
      styles: {
        fontFamily: 'Montserrat',
        fontWeight: '500',
        fontSize: '14px',
        lineHeight: '12px',
        color: '#222222'
      },
      align: 'right',
      link: true,
      hash: 'txs'
    }
  ]
};

const styles = (theme: Theme) =>
  createStyles({
    opotionText: {
      fontFamily: 'Montserrat',
      fontStyle: 'normal',
      fontWeight: 500,
      fontSize: 14,
      lineHeight: '17px',
      color: '#222222'
    },
    filterBox: {
      justifyContent: 'flex-end',
      display: 'flex',
      paddingRight: 10,
      [theme.breakpoints.down('xs')]: {
        paddingRight: 0,
        justifyContent: 'flex-start',
        flexDirection: 'row',
        marginTop: 20
      }
    },
    formControl: {
      width: 250,
      height: 40,
      marginBottom: 15,
      background: '#FAFAFB',
      border: ' 1px solid #DCE0E4',
      borderRadius: 3
    },
    filterLayout: {
      flex: 1,
      flexDirection: 'row',
      height: 40,
      flexWrap: 'nowrap',
      marginLeft: 10,
      alignItems: 'center',
      display: 'flex',
      justifyContent: 'flex-end',
      padding: 0
    },
    filterImg: {
      marginRight: 5,
      width: 25,
      height: 23,
      WebkitFilter: 'brightness(0.2)',
      filter: 'brightness(0.2)'
    },
    icon: {
      background: '#F9F9FA',
      border: '1px solid #EAEAEA',
      width: 12,
      height: 12,
      borderRadius: 3
    },
    checkedIcon: {
      width: 12,
      borderRadius: 3,
      background: '#F9F9FA',
      border: '1px solid #EAEAEA',
      height: 12,
      position: 'relative',
      '&:before': {
        width: 8,
        borderRadius: 1,
        transform: 'translate(-50%,-50%)',
        position: 'absolute',
        top: '50%',
        left: '50%',
        height: 8,
        background: '#00AFC1',
        content: '""'
      }
    }
  });

interface IState {
  params: {
    limit: number;
    offset: number;
    transactionType: string;
  };
  filterBox: {
    top: boolean;
    date: boolean;
  };
}
class PaymentHistory extends React.Component<IProps, IState> {
  state: IState = {
    params: {
      limit: 10,
      offset: 0,
      transactionType: ''
    },
    filterBox: {
      top: false,
      date: false
    }
  };

  handleIssueChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const transactionType = e.target.value;
    let { params } = this.state;
    params = {
      limit: 10,
      offset: 0,
      transactionType
    };
    this.setState({ params });
  };

  handleFilterChange = (key: string, resetKey: string) => (
    event: React.ChangeEvent<HTMLInputElement>,
    checked: boolean
  ) => {
    if (checked) {
      this.setState({
        filterBox: { [key]: checked, [resetKey]: false } as any
      });
    } else {
      this.setState({
        filterBox: { ...this.state.filterBox, [key]: checked } as any
      });
    }
  };

  renderTable = () => {
    return (
      <TableComponent
        settings={tableSettings}
        api="/payments"
        params={this.state.params}
      />
    );
  };

  loadingMore = () => {
    const { limit } = this.state.params;
    this.setState({
      params: {
        ...this.state.params,
        limit: limit + 10
      }
    });
  };

  renderPaymentMobile = () => (
    <PaymentHistoryMobileComponent
      loadingMore={this.loadingMore}
      filterBox={this.state.filterBox}
      params={this.state.params}
    />
  );

  render() {
    const { classes } = this.props;
    return (
      <Grid>
        <Grid
          container
          style={{
            marginBottom: 5,
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center'
          }}
        >
          <Grid
            item
            sm={6}
            xs={12}
            style={{ justifyContent: 'flex-start', display: 'flex' }}
          >
            <TitlePageComponent title="Payment History" />
          </Grid>
          <Grid sm={6} xs={12} item className={classes.filterBox}>
            <FormControl
              data-test="nft-type"
              style={{
                width: 250,
                height: 40,
                marginBottom: 15,
                background: '#FAFAFB',
                border: ' 1px solid #DCE0E4',
                borderRadius: 3
              }}
            >
              <NativeSelect
                style={{ height: 40 }}
                disableUnderline
                value={this.state.params.transactionType}
                onChange={this.handleIssueChange}
                inputProps={{
                  style: { height: 40, padding: 0, paddingLeft: 10 }
                }}
                data-test={'nft-type-select'}
              >
                <option className={classes.opotionText} value="">
                  All
                </option>
                <option className={classes.opotionText} value={'Term Kickback'}>
                  Term Kickback
                </option>
                <option className={classes.opotionText} value={'Init Kickback'}>
                  Initial Kickback
                </option>
              </NativeSelect>
            </FormControl>
            <MediaQuery maxDeviceWidth={760}>
              <Grid container className={classes.filterLayout}>
                <FormControlLabel
                  control={
                    <Checkbox
                      checkedIcon={
                        <span className={classNames(classes.checkedIcon)} />
                      }
                      icon={<span className={classes.icon} />}
                      checked={this.state.filterBox.top}
                      onChange={this.handleFilterChange('top', 'date')}
                      value="checked"
                    />
                  }
                  label="Volume"
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      checkedIcon={
                        <span className={classNames(classes.checkedIcon)} />
                      }
                      icon={<span className={classes.icon} />}
                      checked={this.state.filterBox.date}
                      onChange={this.handleFilterChange('date', 'top')}
                      value="checkedA"
                    />
                  }
                  style={{ marginRight: 0 }}
                  label="Date"
                />
              </Grid>
            </MediaQuery>
          </Grid>
        </Grid>
        <Grid container>
          <Grid item style={{ width: '100%' }}>
            <MediaQuery minDeviceWidth={768}>{this.renderTable()}</MediaQuery>
            <MediaQuery maxDeviceWidth={760}>
              {this.renderPaymentMobile()}
            </MediaQuery>
          </Grid>
        </Grid>
      </Grid>
    );
  }
}

export default withStyles(styles)(PaymentHistory);
