import { Action } from 'redux';
import {
  INIT_AXIOS_INTERCEPTOR,
  REFRESH_TOKEN
} from '../constants/auth.constant';
import { IRefreshTokenRequest } from '../scenes/auth/types/index';

export interface IInitialAxiosInterceptorAction
  extends Action<INIT_AXIOS_INTERCEPTOR> {}
export const initialAxiosInterceptorAction = (): IInitialAxiosInterceptorAction => ({
  type: INIT_AXIOS_INTERCEPTOR
});

export interface IRefreshToken extends Action<REFRESH_TOKEN> {
  payload: IRefreshTokenRequest;
}

export function refreshTokenAction(
  request: IRefreshTokenRequest
): IRefreshToken {
  return {
    type: REFRESH_TOKEN,
    payload: request
  };
}
