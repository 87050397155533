import React from 'react';
import {
  Slider,
  Grid,
  Typography,
  withStyles,
  WithStyles,
  createStyles,
  createMuiTheme,
  MuiThemeProvider
} from '@material-ui/core';
import SliderTooltip from './slider.tooltip.component';
import slider_thumb from '../../../assets/images/slider_thumb.jpg';
import slider_thumb_active from '../../../assets/images/slider_thumb_active.jpg';
import warning from '../../../assets/images/warning.svg';

const styles = createStyles({
  input: {
    textAlign: 'right'
  },
  icon: {
    padding: '6px 5px 7px'
  }
});

const muiTheme = createMuiTheme({
  overrides: {
    MuiSlider: {
      root: {
        padding: '13px 0px !important'
      },
      rail: {
        height: 5,
        width: '100%',
        backgroundColor: '#01DEC8',
        boxShadow: '0px 20px 40px rgba(0, 0, 0, 0.2)',
        borderRadius: 10
      },
      track: {
        height: 5,
        width: '100%',
        background:
          'linear-gradient(164.09deg, #00E3C8 0.18%, #00AFC1 84.82%), #00AFC1',
        boxShadow: '0px 20px 40px rgba(0, 0, 0, 0.2)',
        borderRadius: 10
      },
      mark: {
        width: 15,
        height: 15,
        marginLeft: -6,
        top: 8.5,
        backgroundColor: '#E3E3E3',
        boxShadow: '0px 20px 40px rgba(0, 0, 0, 0.2)',
        borderRadius: 10,
        backgroundImage: `url(${slider_thumb})`
      },
      markLabel: {
        marginTop: 10
      },
      markActive: {
        backgroundImage: `url(${slider_thumb_active})`,
        width: 15,
        marginLeft: -6,
        height: 15,
        backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat'
      },
      thumb: {
        width: 17,
        height: 17,
        background: 'linear-gradient(146.5deg, #00CEE3 0.72%, #00AFC1 130.79%)',
        boxShadow: '0px 20px 40px rgba(0, 0, 0, 0.2)'
      }
    },
    MuiTypography: {
      subtitle1: {
        color: '#9BA0A6',
        fontSize: 10,
        fontWeight: 600,
        fontStyle: 'normal',
        textTransform: 'uppercase',
        lineHeight: '12px'
      }
    }
  }
});
interface IProps extends WithStyles<typeof styles> {
  usdVolume: number;
}

interface IState {
  value: string;
  xValue: number;
}

const removeSemicolonRex: RegExp = /[,]/g;
class SliderComponent extends React.Component<IProps, IState> {
  formatValue = (value: string) => {
    const values = value.replace(removeSemicolonRex, '');
    if (values === '' || values === '00.00' || values === '0.000') {
      return '00.00';
    }
    return Number(values).toLocaleString('en', {
      minimumFractionDigits: 2,
      maximumFractionDigits: 3
    });
  };
  state: IState = {
    value: '0',
    xValue: 0
  };

  handleFocus = (event: React.FocusEvent<HTMLInputElement>) => {
    event.target.select();
  };

  handleSliderChange = (event: any, newValue: number | number[]) => {
    let xValue = 0;
    if (newValue <= 37.5) {
      xValue = Number(newValue) / 12.5;
    } else if (newValue > 37.5 && newValue <= 50) {
      xValue = Number(newValue) / 10;
    } else if (newValue > 50 && newValue <= 62.5) {
      xValue = Number(newValue) / 6.25;
    } else if (newValue > 62.5 && newValue <= 75) {
      xValue = Number(newValue) / 3;
    } else if (newValue > 75 && newValue <= 87.5) {
      xValue = Number(newValue) / 1.75;
    } else {
      xValue = Number(newValue);
    }
    this.setState({
      value: newValue.toString(),
      xValue
    });
  };

  marks = [
    {
      label: (
        <Typography
          variant="subtitle1"
          style={{ color: '#222222', marginTop: 3 }}
        >
          Cross
        </Typography>
      ),
      value: 0
    },
    { label: <Typography variant="subtitle1">1x</Typography>, value: 12.5 },
    { label: <Typography variant="subtitle1">2x</Typography>, value: 25 },
    { label: <Typography variant="subtitle1">3x</Typography>, value: 37.5 },
    { label: <Typography variant="subtitle1">5x</Typography>, value: 50 },
    { label: <Typography variant="subtitle1">10x</Typography>, value: 62.5 },
    { label: <Typography variant="subtitle1">25x</Typography>, value: 75 },
    { label: <Typography variant="subtitle1">50x</Typography>, value: 87.5 },
    { label: <Typography variant="subtitle1">100x</Typography>, value: 100 }
  ];

  render() {
    return (
      <Grid container style={{ flexDirection: 'column' }}>
        <Grid
          item
          style={{
            display: 'flex',
            flexDirection: 'row',
            height: 25,
            margin: '10px 16px 0 16px',
            background:
              'linear-gradient(90deg, #71AE75 0%, #D57758 100%), #E7E7E7',
            borderRadius: 15
          }}
        >
          <Grid
            style={{
              display: 'flex',
              flex: 1,
              justifyContent: 'flex-start',
              paddingLeft: 10,
              alignItems: 'center'
            }}
          >
            <Typography
              variant="subtitle1"
              style={{ color: '#FFFFFF', fontWeight: 'bold' }}
            >
              {this.state.xValue.toFixed(2)}X
            </Typography>
          </Grid>
          <Grid
            style={{
              display: 'flex',
              flex: 1,
              justifyContent: 'center',
              alignItems: 'center'
            }}
          >
            {' '}
            <Typography
              variant="subtitle1"
              style={{ color: '#FFFFFF', fontWeight: 'bold' }}
            >
              leverage
            </Typography>
          </Grid>
          <Grid
            style={{
              display: 'flex',
              flex: 1,
              justifyContent: 'flex-end',
              paddingRight: 10,
              alignItems: 'center'
            }}
          >
            <img src={warning} alt="warning icon" />
          </Grid>
        </Grid>
        <Grid
          item
          style={{
            height: 30,
            margin: '0 20px'
          }}
        >
          <MuiThemeProvider theme={muiTheme}>
            <Slider
              min={0}
              step={0.001}
              aria-labelledby="discrete-slider"
              valueLabelDisplay="auto"
              max={100}
              marks={this.marks}
              value={Number(this.state.value)}
              name="test"
              onChange={this.handleSliderChange}
              ValueLabelComponent={SliderTooltip}
            />
          </MuiThemeProvider>
        </Grid>
      </Grid>
    );
  }
}

export default withStyles(styles)(SliderComponent);
