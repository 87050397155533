import React from 'react';
import { Tooltip, Typography, Grid } from '@material-ui/core';
import { createStyles, withStyles, WithStyles } from '@material-ui/styles';
import { IStoreState } from '../../types';
import { connect } from 'react-redux';
import { IConfigInfo } from '../../scenes/dashboard/types';

const styles = createStyles({
  arrow: {
    color: '#9BA0A6'
  },
  tooltipArrow: {
    width: 57,
    height: 12
  }
});

interface IProps extends WithStyles<typeof styles> {
  children: React.ReactElement;
  value: number;
  open: boolean;
  rankInfo: IConfigInfo[];
}

class SliderTooltip extends React.Component<IProps> {
  renderTooltipLabel = (value: number) => {
    const { rankInfo } = this.props;
    const rank: number = rankInfo.findIndex(r => r.rate >= value) || 0;

    return (
      <Grid style={{ backgroundColor: '#9BA0A6' }}>
        <Typography
          variant={'subtitle1'}
          style={{ fontWeight: 500, color: '#FFFFFF' }}
        >
          RANK {rank}
        </Typography>
      </Grid>
    );
  };
  render() {
    const { classes } = this.props;
    return (
      <div>
        <Tooltip
          arrow
          open
          placement="top"
          classes={{
            arrow: classes.arrow,
            tooltipArrow: classes.tooltipArrow
          }}
          title={this.renderTooltipLabel(this.props.value)}
          aria-labelledby="range-slider"
        >
          {this.props.children}
        </Tooltip>
      </div>
    );
  }
}

export const mapStateToProps = ({ rankInfo }: IStoreState) => {
  return {
    rankInfo
  };
};

export const mapDispatchToProps = () => ({});
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(SliderTooltip));
