export const GET_PAYMENT_SUMARY = 'GET_PAYMENT_SUMARY';
export type GET_PAYMENT_SUMARY = typeof GET_PAYMENT_SUMARY;

export const SET_PAYMENT_SUMARY = 'SET_PAYMENT_SUMARY';
export type SET_PAYMENT_SUMARY = typeof SET_PAYMENT_SUMARY;

export const GET_RANK_INFO = 'GET_RANK_INFO';
export type GET_RANK_INFO = typeof GET_RANK_INFO;

export const SET_RANK_INFO = 'SET_RANK_INFO';
export type SET_RANK_INFO = typeof SET_RANK_INFO;

export const GET_NFT_LIST = 'GET_NFT_LIST';
export type GET_NFT_LIST = typeof GET_NFT_LIST;

export const SET_NFT_LIST = 'SET_NFT_LIST';
export type SET_NFT_LIST = typeof SET_NFT_LIST;

export const GET_TOMO_PRICE = 'GET_TOMO_PRICE';
export type GET_TOMO_PRICE = typeof GET_TOMO_PRICE;

export const SET_TOMO_PRICE = 'SET_TOMO_PRICE';
export type SET_TOMO_PRICE = typeof SET_TOMO_PRICE;

export const GET_CONFIG = 'GET_CONFIGs';
export type GET_CONFIG = typeof GET_CONFIG;

export const SET_CONFIG = 'SET_CONFIG';
export type SET_CONFIG = typeof SET_CONFIG;

export const UPDATE_CONFIG = 'UPDATE_CONFIG';
export type UPDATE_CONFIG = typeof UPDATE_CONFIG;

export const GET_PAYMENT_HISTORY = 'GET_PAYMENT_HISTORY';
export type GET_PAYMENT_HISTORY = typeof GET_PAYMENT_HISTORY;

export const SET_PAYMENT_HISTORY = 'SET_PAYMENT_HISTORY';
export type SET_PAYMENT_HISTORY = typeof SET_PAYMENT_HISTORY;
