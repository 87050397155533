import {
  ILoginRequest,
  IToken,
  IResetPassword,
  IVerifyCode,
  IChangePasswordRequest,
  IRoleId
} from '../types';
import {
  LOGIN,
  SET_TOKEN,
  FORGOT_PASSWORD,
  RESET_PASSWORD,
  LOGOUT,
  VERIFYCODE,
  CHANGE_PASSWORD,
  GET_ROLE_ID,
  SET_ROLE_ID,
  SET_CODE
} from './constant';
import { Action } from 'redux';

export interface ILoginAction extends Action<LOGIN> {
  payload: ILoginRequest;
}

export const loginAction = (request: ILoginRequest): ILoginAction => ({
  type: LOGIN,
  payload: request
});

export interface ISetTokenAction extends Action<SET_TOKEN> {
  payload: IToken;
}

export interface ILogoutAction extends Action<LOGOUT> {}

export const logoutAction = (): ILogoutAction => ({
  type: LOGOUT
});
export const setTokenAction = (token: IToken): ISetTokenAction => ({
  type: SET_TOKEN,
  payload: token
});

export interface IForgotPasswordAction extends Action<FORGOT_PASSWORD> {
  payload: string;
}

export const forgotPasswordAction = (email: string): IForgotPasswordAction => ({
  type: FORGOT_PASSWORD,
  payload: email
});

export interface IResetPasswordAction extends Action<RESET_PASSWORD> {
  payload: IResetPassword;
}

export const resetPasswordAction = (
  request: IResetPassword
): IResetPasswordAction => ({
  type: RESET_PASSWORD,
  payload: request
});

export interface IVerifyCodeAction extends Action<VERIFYCODE> {
  payload: IVerifyCode;
}

export const verifyCodeAction = (code: IVerifyCode): IVerifyCodeAction => ({
  type: VERIFYCODE,
  payload: code
});

export interface ISetCodeAction extends Action<SET_CODE> {
  payload: string;
}

export const setCodeAction = (code: string): ISetCodeAction => ({
  type: SET_CODE,
  payload: code
});

export interface IChangePassword extends Action<CHANGE_PASSWORD> {
  payload: IChangePasswordRequest;
}

export const changePasswordAction = (
  request: IChangePasswordRequest
): IChangePassword => ({
  type: CHANGE_PASSWORD,
  payload: request
});

export interface IGetRoleId extends Action<GET_ROLE_ID> {}

export const getRoleId = (): IGetRoleId => ({
  type: GET_ROLE_ID
});

export interface ISetRoleId extends Action<SET_ROLE_ID> {
  payload: IRoleId[];
}

export const setRoleId = (roleIds: IRoleId[]): ISetRoleId => ({
  type: SET_ROLE_ID,
  payload: roleIds
});
