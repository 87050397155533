import { Action } from 'redux';
import {
  GET_USER_PROFILE,
  SET_USER_PROFILE,
  GET_COUNTRIES,
  SET_COUNTRIES,
  GET_QR_IMAGE,
  SET_QR_IMAGE,
  UPDATE_USER_PROFILE,
  SET_SECOND_FACTOR,
  VERIFY_ACCOUNT,
  UPDATE_AVATAR,
  UPDATE_USER_BY_ID
} from './constant';
import {
  ICountry,
  IQrImage,
  IUserProfile,
  ISecondFactorCode,
  IUpdateAvatarRequest,
  IUserIdInfo
} from '../types';
import { IResetPassword } from '../../auth/types';

export interface IGetUserProfile extends Action<GET_USER_PROFILE> {}

export const getUserProfileAction = (): IGetUserProfile => ({
  type: GET_USER_PROFILE
});

export interface ISetUserProfile extends Action<SET_USER_PROFILE> {
  payload: IUserProfile;
}

export const setUserProfileAction = (
  profile: IUserProfile
): ISetUserProfile => ({
  type: SET_USER_PROFILE,
  payload: profile
});

export interface IGetCountries extends Action<GET_COUNTRIES> {}

export const getCountries = (): IGetCountries => ({
  type: GET_COUNTRIES
});

export interface ISetCountries extends Action<SET_COUNTRIES> {
  payload: ICountry[];
}

export const setCountries = (countries: ICountry[]): ISetCountries => ({
  type: SET_COUNTRIES,
  payload: countries
});

export interface IGetQrImage extends Action<GET_QR_IMAGE> {}
export const getQrImageAction = (): IGetQrImage => ({
  type: GET_QR_IMAGE
});

export interface ISetQrImage extends Action<SET_QR_IMAGE> {
  payload: IQrImage;
}
export const setQrImageAction = (qrImage: IQrImage): ISetQrImage => ({
  type: SET_QR_IMAGE,
  payload: qrImage
});

export interface IUpdateUserProfile extends Action<UPDATE_USER_PROFILE> {
  payload: any;
}
export const updateUserProfile = (info: any): IUpdateUserProfile => ({
  type: UPDATE_USER_PROFILE,
  payload: info
});

export interface ISetSecondFactorCode extends Action<SET_SECOND_FACTOR> {
  payload: ISecondFactorCode;
}
export const setSecondFactorCode = (
  code: ISecondFactorCode
): ISetSecondFactorCode => ({
  type: SET_SECOND_FACTOR,
  payload: code
});

export interface IVerifyAccountAction extends Action<VERIFY_ACCOUNT> {
  payload: IResetPassword;
}

export const verifyAccountAction = (
  request: IResetPassword
): IVerifyAccountAction => ({
  type: VERIFY_ACCOUNT,
  payload: request
});

export interface IUpdateUserById extends Action<UPDATE_USER_BY_ID> {
  payload: IUserIdInfo;
}
export const updateUserById = (info: IUserIdInfo): IUpdateUserById => ({
  type: UPDATE_USER_BY_ID,
  payload: info
});

export interface IUpdateAvatarAction extends Action<UPDATE_AVATAR> {
  payload: IUpdateAvatarRequest;
}

export const updateAvatar = (
  request: IUpdateAvatarRequest
): IUpdateAvatarAction => ({
  type: UPDATE_AVATAR,
  payload: request
});
