import React from 'react';
import {
  Grid,
  Typography,
  TextField,
  createStyles,
  WithStyles,
  Button,
  Theme
} from '@material-ui/core';
import { withStyles } from '@material-ui/styles';
import TradingContract from './tradingSlider.component';

const styles = (theme: Theme) =>
  createStyles({
    input: {
      width: '100%',
      flexDirection: 'row',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      height: 50,
      background: '#FAFAFB',
      border: '1px solid #DCE0E4',
      borderRadius: '3px'
    },
    textFiled: {
      width: '100%',
      display: 'flex',
      alignItems: 'flex-end',
      justifyContent: 'center'
    },
    text: {
      fontFamily: 'Montserrat',
      fontStyle: 'normal',
      fontWeight: 'bold',
      fontSize: 12,
      lineHeight: '15px',
      textTransform: 'uppercase',
      color: '#9BA0A6'
    },
    value: {
      fontFamily: 'Montserrat',
      fontStyle: 'normal',
      fontWeight: 500,
      fontSize: 16,
      lineHeight: '20px',
      color: '#222222'
    }
  });

interface IProps extends WithStyles<typeof styles> {}
class TradingCore extends React.Component<IProps> {
  renderInput = (text: string) => {
    const { classes } = this.props;
    return (
      <Grid className={classes.input}>
        <Grid item xs={3}>
          <Typography style={{ paddingLeft: 10 }} variant="subtitle1">
            {text}
          </Typography>
        </Grid>
        <Grid
          xs={8}
          item
          style={{
            margin: '0 20px'
          }}
        >
          <TextField
            className={classes.textFiled}
            InputProps={{
              disableUnderline: true,
              className: classes.value
            }}
            inputProps={{
              style: {
                textAlign: 'right'
              }
            }}
          />
        </Grid>
        <Grid style={{ paddingRight: 10 }}>
          <Typography className={classes.text}>USD</Typography>
        </Grid>
      </Grid>
    );
  };
  render() {
    return (
      <Grid>
        <Grid style={{ margin: '20px 30px 0 30px' }}>
          <Grid>
            {this.renderInput('Quantity:')}
            <Grid style={{ marginTop: 10 }}>
              {this.renderInput('limit price:')}
            </Grid>
          </Grid>
          <Grid
            container
            style={{ marginTop: 10, display: 'flex', flexDirection: 'column' }}
          >
            <Grid item style={{ display: 'flex' }}>
              <Button
                style={{
                  flex: 1,
                  height: 50,
                  marginRight: 5,
                  backgroundColor: '#36BCA4',
                  borderRadius: 3
                }}
              >
                <Typography
                  variant="h4"
                  style={{
                    fontWeight: 'bold',
                    textTransform: 'capitalize',
                    color: '#FFFFFF'
                  }}
                >
                  Buy/Long
                </Typography>
              </Button>
              <Button
                style={{
                  flex: 1,
                  height: 50,
                  marginLeft: 5,
                  backgroundColor: '#C4021C',
                  borderRadius: 3
                }}
              >
                <Typography
                  variant="h4"
                  style={{
                    fontWeight: 'bold',
                    textTransform: 'capitalize',
                    color: '#FFFFFF'
                  }}
                >
                  Sell/Short
                </Typography>
              </Button>
            </Grid>
            <Grid item style={{ display: 'flex', marginTop: 8 }}>
              <Grid style={{ flex: 1 }}>
                <Typography variant="h4" style={{ fontWeight: 600 }}>
                  Cost:{' '}
                  <span style={{ color: '#222222', marginLeft: 5 }}>
                    0.0001 XBT
                  </span>
                </Typography>
              </Grid>
              <Grid
                style={{ flex: 1, justifyContent: 'flex-end', display: 'flex' }}
              >
                <Typography variant="h4" style={{ fontWeight: 600 }}>
                  Cost:
                  <span style={{ color: '#222222', marginLeft: 5 }}>
                    0.0001 XBT
                  </span>
                </Typography>
              </Grid>
            </Grid>
            <Grid container style={{ marginTop: 10, flexDirection: 'column' }}>
              <Grid item style={{ display: 'flex' }}>
                <Grid style={{ flex: 1 }}>
                  <Typography variant="h4" style={{ fontWeight: 600 }}>
                    Order Value
                  </Typography>
                </Grid>
                <Grid>
                  <Typography
                    variant="h4"
                    style={{ fontWeight: 600, color: '#222222' }}
                  >
                    0.0000 XBT
                  </Typography>
                </Grid>
              </Grid>
              <Grid item style={{ display: 'flex' }}>
                <Grid style={{ flex: 1 }}>
                  <Typography variant="h4" style={{ fontWeight: 600 }}>
                    Available Balance
                  </Typography>
                </Grid>
                <Grid>
                  <Typography
                    variant="h4"
                    style={{ fontWeight: 600, color: '#222222' }}
                  >
                    0.0000 XBT
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Grid style={{ marginTop: 12, borderBottom: '1px solid #F1F1F4' }} />
        <Grid style={{ margin: '20px 30px 0 30px', paddingBottom: 20 }}>
          <TradingContract />
        </Grid>
      </Grid>
    );
  }
}

export const TradingCoreComponent = withStyles(styles)(TradingCore);
