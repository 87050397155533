import React from 'react';
import { Theme, createStyles, Grid, Typography } from '@material-ui/core';
import { WithStyles, withStyles } from '@material-ui/styles';
import LogoHeaderComponent from './LogoHeader.component';

const styles = (theme: Theme) =>
  createStyles({
    container: {
      minHeight: '100vh',
      flexDirection: 'column'
    },
    rootPage: {
      flex: 1,
      padding: '60px 0',
      position: 'relative'
    },
    footer: {
      marginBottom: 60,
      [theme.breakpoints.down('md')]: {
        marginBottom: 30
      }
    },
    textFooter: {
      color: '#9BA0A6',
      fontWeight: 600,
      fontSize: 10,
      lineHeight: '12px',
      textTransform: 'uppercase'
    }
  });
interface IProps extends WithStyles<typeof styles> {}
class ExternalAgentLayout extends React.Component<IProps> {
  public render() {
    const { classes, children } = this.props;
    return (
      <Grid container className={classes.container}>
        <LogoHeaderComponent />
        <Grid
          container
          justify="center"
          alignItems="center"
          className={classes.rootPage}
        >
          {children}
        </Grid>
        <Grid container justify="center" className={classes.footer}>
          <Typography className={classes.textFooter}>
            {' '}
            © 2019 - 2020 united axes. All rights reserved.
          </Typography>
        </Grid>
      </Grid>
    );
  }
}

export default withStyles(styles)(ExternalAgentLayout);
