import React, { PureComponent } from 'react';
import {
  Table,
  Paper,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  TablePagination,
  Typography,
  WithStyles,
  withStyles,
  Grid,
  Theme,
  IconButton,
  createStyles
} from '@material-ui/core';
import Tooltip from '@material-ui/core/Tooltip';
import PaginationComponent from './Pagination.component';
import classNames from 'classnames';
import { connect } from 'react-redux';
import _ from 'lodash';
import {
  ITableStoreState,
  ITableSettings,
  ISortColumn,
  ITableHeader,
  IMetaData,
  ISearchInfo,
  tableStyles
} from '../redux/types';
import ArrowDropUp from '@material-ui/icons/ArrowDropUp';
import ArrowDropDown from '@material-ui/icons/ArrowDropDown';
import { Dispatch } from 'redux';
import { IGetTableData, getTableData } from '../redux/actions';
import { Link } from 'react-router-dom';
import Skeleton from 'react-loading-skeleton';
import CheckCircleOutlineSharpIcon from '@material-ui/icons/CheckCircleOutlineSharp';

const styles = (theme: Theme) =>
  createStyles({
    root: {
      width: '100%'
    },
    tableWrapper: {
      // minHeight: 561,
      overflow: 'auto'
    },
    tableHead: {
      background: '#FFFFFF',
      borderRadius: '5px 5px 0px 0px'
    },
    text: {
      fontFamily: 'Montserrat',
      fontStyle: 'normal'
    },
    headerText: {
      fontWeight: 600,
      fontSize: 12,
      lineHeight: '15px',
      textTransform: 'uppercase',
      color: '#000000',
      [theme.breakpoints.up(1020)]: {
        [theme.breakpoints.down(1371)]: {
          fontSize: 10
        }
      }
    },
    firstColumn: {
      fontWeight: 500,
      fontSize: 14,
      lineHeight: '17px',
      color: '#222222',
      paddingLeft: 20
    },
    labelPagination: {
      fontWeight: 500,
      fontSize: 12,
      lineHeight: '15px',
      color: '#A1A9B4'
    },
    pagination: {
      display: 'flex',
      borderTop: '1px solid #F1F1F4'
    },
    borderBottom: {
      borderBottom: '1px solid #F1F1F4'
    },
    tableValue: {
      // paddingRight: 20
    },
    border: {
      borderBottom: 'none'
    },
    iconCenter: {
      marginBottom: -5
    }
  });

interface IProps extends WithStyles<typeof styles> {
  settings: ITableSettings;
  api: string;
  data: object[];
  meta: IMetaData;
  params?: any;
  searchInfo?: ISearchInfo;
  getTableData: (url: string, params: {}) => void;
  loading: boolean;
  reload?: boolean;
}

interface IState {
  page: number;
  rowsPerPage: number;
  data: object[];
  order: boolean;
}

class CustomTable extends PureComponent<IProps, IState> {
  state: IState = {
    page: 0,
    rowsPerPage: 10,
    data: this.props.data,
    order: true // down = true, up = false\
  };

  handleChangePage = (event: unknown, newPage: number) => {
    this.setState({ page: newPage });
    let params;
    if (this.props.params) {
      params = this.props.params;
      params.limit = this.state.rowsPerPage;
      params.offset = newPage * this.state.rowsPerPage;
    } else {
      params = {
        limit: this.state.rowsPerPage,
        offset: newPage * this.state.rowsPerPage
      };
    }
    this.props.getTableData(this.props.api, params);
  };

  handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({ rowsPerPage: +event.target.value, page: 0 });
    let params;
    if (this.props.params) {
      params = this.props.params;
      params.limit = +event.target.value;
      params.offset = 0;
    } else {
      params = {
        limit: +event.target.value,
        offset: 0
      };
    }
    this.props.getTableData(this.props.api, params);
  };

  componentWillReceiveProps(nextProps: IProps) {
    if (
      nextProps.searchInfo &&
      JSON.stringify(nextProps.searchInfo) !==
        JSON.stringify(this.props.searchInfo)
    ) {
      return this.searchText(
        nextProps.searchInfo.text,
        nextProps.searchInfo.key
      );
    }
    if (
      JSON.stringify(nextProps.params) !== JSON.stringify(this.props.params)
    ) {
      return this.setState({ data: nextProps.data, page: 0, rowsPerPage: 10 });
    }

    if (JSON.stringify(nextProps.data) !== JSON.stringify(this.props.data)) {
      this.setState({ data: nextProps.data });
    }
    if (nextProps.reload) {
      this.forceUpdate();
    }
  }

  sortTable = (sortable: ISortColumn) => (a: any, b: any) => {
    if (sortable.type === 'string') {
      return this.state.order
        ? Number(_.get(a, sortable.key)) - Number(_.get(b, sortable.key))
        : Number(_.get(b, sortable.key)) - Number(_.get(a, sortable.key));
    } else if (sortable.type === 'date') {
      const date1 = new Date(_.get(a, sortable.key)).getTime();
      const date2 = new Date(_.get(b, sortable.key)).getTime();
      return this.state.order ? date1 - date2 : date2 - date1;
    } else {
      const first = _.get(a, sortable.key);
      const second = _.get(b, sortable.key);
      return this.state.order ? first - second : second - first;
    }
  };

  searchText = (text: string, key: string) => {
    const data = [...this.props.data];
    const filterData: object[] = data.filter(
      row => _.get(row, key) && _.get(row, key).includes(text)
    );
    this.setState({ data: filterData });
  };

  formatHash = (hash: string) => {
    return (
      hash &&
      hash
        .slice(0, 6)
        .concat('...')
        .concat(hash.slice(-6))
    );
  };
  renderIcon = (column: ITableHeader) => {
    if (column.sortable) {
      return this.state.order ? (
        <ArrowDropDown
          className={this.props.classes.iconCenter}
          onClick={this.handleSortTable(column.sortable)}
        />
      ) : (
        <ArrowDropUp
          className={this.props.classes.iconCenter}
          onClick={this.handleSortTable(column.sortable)}
        />
      );
    }
  };

  handleSortTable = (sortable: ISortColumn) => (
    event: React.MouseEvent<SVGSVGElement, MouseEvent>
  ) => {
    const data = [...this.props.data];
    const sorted = data.sort(this.sortTable(sortable));
    this.setState({ order: !this.state.order, data: sorted });
  };

  renderLoading = () => {
    return [1].map(e => (
      <TableRow key={e}>
        <TableCell style={{ padding: '8px' }}>
          <Skeleton height="100%" duration={3} />
        </TableCell>
        {this.props.settings.tableColumn.map(key => {
          return (
            <TableCell key={key.key} style={{ padding: '8px' }}>
              {' '}
              <Skeleton height="100%" key={key.key} duration={3} />
            </TableCell>
          );
        })}
      </TableRow>
    ));
  };

  renderNoDataText = () => {
    return (
      <TableRow>
        <TableCell colSpan={this.props.settings.tableColumn.length + 1}>
          <Typography
            variant="h2"
            style={{ padding: '30px 0', textAlign: 'center' }}
          >
            No data
          </Typography>
        </TableCell>
      </TableRow>
    );
  };

  renderValue = (header: ITableHeader, value: any) => {
    return header.link ? (
      <Link
        style={{
          color: '#3DACEB ',
          textDecoration: 'none'
        }}
        to={{
          pathname: `${process.env.REACT_APP_SCAN_URL}/${header.hash}/${
            header.format ? header.format(value) : value
          }`
        }}
        target="true"
      >
        {/* // {header.preFix} {header.format ? header.format(value) : value} //{' '}
        {header.postFix} */}
        {this.formatHash(value)}
      </Link>
    ) : header.editing ? (
      value ? (
        <Tooltip
          title={<Typography variant="subtitle1"> Block user</Typography>}
          style={{ color: '#222222' }}
        >
          <IconButton>
            <CheckCircleOutlineSharpIcon color={'primary'} />
          </IconButton>
        </Tooltip>
      ) : (
        <Tooltip
          title={<Typography variant="subtitle2"> active user</Typography>}
          placement="bottom"
        >
          <IconButton>
            <CheckCircleOutlineSharpIcon color={'disabled'} />
          </IconButton>
        </Tooltip>
      )
    ) : (
      <div>
        {header.preFix} {header.format ? header.format(value) : value}{' '}
        {header.postFix}
      </div>
    );
  };
  render() {
    const { classes, meta } = this.props;
    const { page, rowsPerPage, data } = this.state;
    return (
      <Paper elevation={0} className={classes.root}>
        <Grid className={classes.tableWrapper}>
          <Table stickyHeader style={{ transition: 'heigth 2s transform' }}>
            <TableHead>
              <TableRow>
                <TableCell
                  className={classNames(
                    classes.borderBottom,
                    classes.tableHead
                  )}
                  align={'left'}
                  style={{ minWidth: '30px', padding: '0 20px' }}
                >
                  {'#'}
                </TableCell>
                {this.props.settings.tableColumn.map((e, index) => (
                  <TableCell
                    valign="middle"
                    className={classNames(
                      classes.text,
                      classes.headerText,
                      classes.borderBottom,
                      classes.tableHead
                    )}
                    key={index}
                    align={e.align ? e.align : 'left'}
                    style={{
                      minWidth: e.width,
                      paddingRight: e.key === 'hash' ? 10 : 0
                    }}
                  >
                    {e.label}
                    {this.renderIcon(e)}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody data-test="table-body">
              {data && data.length
                ? data.map((row, rowIndex) => {
                    return (
                      <TableRow key={rowIndex}>
                        <TableCell
                          className={classNames(
                            classes.text,
                            classes.firstColumn,
                            rowIndex + 1 === rowsPerPage
                              ? classes.border
                              : undefined
                          )}
                        >
                          {rowIndex + rowsPerPage * page + 1}
                        </TableCell>
                        {this.props.settings.tableColumn.map((e, index) => {
                          const value = _.get(row, e.key);
                          return (
                            <TableCell
                              className={classNames(
                                rowIndex + 1 === rowsPerPage
                                  ? classes.border
                                  : '',
                                classes.tableValue
                              )}
                              align={e.align}
                              style={e.styles}
                              key={index}
                            >
                              {this.renderValue(e, value)}
                            </TableCell>
                          );
                        })}
                      </TableRow>
                    );
                  })
                : this.props.loading
                ? this.renderLoading()
                : this.renderNoDataText()}
            </TableBody>
          </Table>
        </Grid>
        {this.props.settings.pagination &&
        this.props.settings.pagination.show ? (
          <TablePagination
            data-test="table-pagination"
            rowsPerPageOptions={[10, 25, 100]}
            component="div"
            className={classes.pagination}
            count={meta.total}
            rowsPerPage={rowsPerPage}
            labelRowsPerPage={
              <Typography
                component="span"
                className={classNames(classes.text, classes.labelPagination)}
              >
                {'Residents per page:'}
              </Typography>
            }
            page={page}
            onChangePage={this.handleChangePage}
            onChangeRowsPerPage={this.handleChangeRowsPerPage}
            ActionsComponent={PaginationComponent}
          />
        ) : null}
      </Paper>
    );
  }
}

const mapStateToProps = ({ tableData, loading }: ITableStoreState) => {
  return {
    data: tableData.data,
    meta: tableData.meta,
    loading
  };
};

const mapDispatchToProps = (dispatch: Dispatch<IGetTableData>) => {
  return {
    getTableData: (url: string, params: {}) =>
      dispatch(getTableData(url, params))
  };
};
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(tableStyles)(CustomTable));
