import { ILoading } from '../actions/loading.action';
import { LOADING } from '../constants/loading.constant';

export const loading = (state: boolean = false, action: ILoading): boolean => {
  switch (action.type) {
    case LOADING: {
      return action.payload;
    }
  }
  return state;
};
