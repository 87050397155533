import React from 'react';
import { Tooltip, Icon } from '@material-ui/core';

interface IProps {
  tooltip?: string;
  onClick?: () => void;
  url: string;
}

class IconAddress extends React.Component<IProps> {
  render() {
    const tooltipTitle = this.props.tooltip ? this.props.tooltip : '';
    return (
      <Tooltip title={tooltipTitle}>
        <Icon onClick={this.props.onClick}>
          <img src={this.props.url} alt="icon adress" />
        </Icon>
      </Tooltip>
    );
  }
}

export default IconAddress;
