import React from 'react';
import { createStyles, Grid, Theme } from '@material-ui/core';
import { WithStyles, withStyles } from '@material-ui/styles';
import CardProfileComponent from '../../../components/CardProfile.component';
import CardAccountComponent from '../../../components/CardAccount.component';
import TitlePageComponent from '../../../components/TitlePage.component';
import { History } from 'history';
import { withRouter } from 'react-router';
import { connect } from 'react-redux';
import { ROLE } from '../../../constants/auth.constant';
const styles = (theme: Theme) =>
  createStyles({
    profile: {
      paddingRight: 10,
      [theme.breakpoints.down('xs')]: {
        paddingRight: 0,
        marginTop: 20
      }
    },
    account: {
      paddingLeft: 10,
      paddingRight: 10,
      [theme.breakpoints.down('xs')]: {
        padding: 0,
        marginTop: 30
      }
    }
  });
interface IProps extends WithStyles<typeof styles> {
  history: History;
}

class SettingAdmin extends React.Component<IProps> {
  public render() {
    const { classes } = this.props;
    return (
      <>
        <Grid style={{ marginBottom: 9 }}>
          <TitlePageComponent title="Profile & Account" />
        </Grid>
        <Grid container>
          <Grid className={classes.profile} item xs={12} sm={6} md={4}>
            <CardProfileComponent />
          </Grid>
          <Grid item xs={12} sm={6} md={4} className={classes.account}>
            <CardAccountComponent
              role={ROLE.ADMIN}
              history={this.props.history}
            />
          </Grid>
        </Grid>
      </>
    );
  }
}

export default withRouter(
  connect(undefined, undefined)(withStyles(styles)(SettingAdmin))
);
