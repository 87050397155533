import React from 'react';
import {
  Paper,
  Grid,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  withStyles,
  WithStyles,
  Typography
} from '@material-ui/core';
import classNames from 'classnames';
import { nftTable, INftData } from '../types';
import { tableStyles } from '../../../components/shared/table/redux/types';
import _ from 'lodash';

interface IProps extends WithStyles<typeof tableStyles> {
  nfts: INftData;
}

class NftTableComponent extends React.Component<IProps> {
  renderNoDataText = () => {
    return (
      <TableRow>
        <TableCell colSpan={nftTable.tableColumn.length + 1}>
          <Typography
            variant="h2"
            style={{ padding: '30px 0', textAlign: 'center' }}
          >
            No data
          </Typography>
        </TableCell>
      </TableRow>
    );
  };

  render() {
    const { classes, nfts } = this.props;
    const limitDataRender =
      nfts && nfts.data.length
        ? nfts.data.length > 3
          ? nfts.data.slice(0, 3)
          : nfts.data
        : false;
    return (
      <Paper elevation={0} className={classes.root}>
        <Grid className={classes.tableWrapper}>
          <Table stickyHeader>
            <TableHead>
              <TableRow>
                <TableCell
                  className={classNames(
                    classes.borderBottom,
                    classes.tableHead
                  )}
                  align={'left'}
                  style={{ minWidth: '30px', paddingLeft: 20 }}
                >
                  {'#'}
                </TableCell>
                {nftTable.tableColumn.map((e, index) => (
                  <TableCell
                    valign="middle"
                    className={classNames(
                      classes.text,
                      classes.headerText,
                      classes.borderBottom,
                      classes.tableHead
                    )}
                    key={index}
                    align={e.align ? e.align : 'left'}
                    style={{
                      minWidth: e.width,
                      paddingRight: 20
                    }}
                  >
                    {e.label}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody data-test="nft-table">
              {limitDataRender
                ? limitDataRender.map((row, rowIndex) => {
                    return (
                      <TableRow key={rowIndex}>
                        <TableCell
                          className={classNames(
                            classes.text,
                            classes.firstColumn,
                            classes.border
                          )}
                        >
                          {rowIndex + 1}
                        </TableCell>
                        {nftTable.tableColumn.map((e, index) => {
                          const value = _.get(row, e.key);
                          return (
                            <TableCell
                              className={classNames(
                                classes.border,
                                classes.tableValue
                              )}
                              align={e.align}
                              style={e.styles}
                              key={index}
                            >
                              {e.preFix} {e.format ? e.format(value) : value}{' '}
                              {e.postFix}
                            </TableCell>
                          );
                        })}
                      </TableRow>
                    );
                  })
                : this.renderNoDataText()}
            </TableBody>
          </Table>
        </Grid>
      </Paper>
    );
  }
}

export default withStyles(tableStyles)(NftTableComponent);
