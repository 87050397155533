import React from 'react';
import {
  Tabs,
  Tab,
  Typography,
  WithStyles,
  createStyles,
  withStyles,
  Grid
} from '@material-ui/core';
import { ITabAccount } from './tradingbox.component';
import { TradingCoreComponent } from './trading.component';

const styles = createStyles({
  tabRoot: {
    textTransform: 'capitalize',
    textAlign: 'center'
  }
});
interface IState {
  value: number;
}
interface IProps extends WithStyles<typeof styles> {}

const defaultTab: ITabAccount[] = [
  {
    label: 'Limit',
    index: 0
  },
  {
    label: 'Market',
    index: 1
  },
  {
    label: 'Stop Market',
    index: 2
  }
];
class TradingAccountTab extends React.Component<IProps, IState> {
  state: IState = {
    value: 0
  };

  handleChange = (event: React.ChangeEvent<{}>, value: number) => {
    this.setState({ value });
  };

  renderTabAccount = () => {
    return defaultTab.map(e => (
      <Tab
        style={
          this.state.value === e.index
            ? {
                backgroundColor: '#FFFFFF',
                minWidth: 0
              }
            : {
                minWidth: 0
              }
        }
        key={e.index}
        label={
          <Typography
            variant="h4"
            style={
              this.state.value === e.index
                ? {
                    textTransform: 'capitalize',
                    color: '#222222',
                    fontWeight: 600
                  }
                : {
                    color: '#9BA0A6',
                    textTransform: 'capitalize',
                    fontWeight: 600
                  }
            }
          >
            {e.label}
          </Typography>
        }
      />
    ));
  };

  render() {
    const { classes } = this.props;
    return (
      <Grid>
        <Grid>
          <Tabs
            TabIndicatorProps={{
              style: {
                height: 3,
                background: '#00AFC1',
                borderRadius: '5px 5px 0px 0px'
              }
            }}
            style={{ borderBottom: '1px solid #F1F1F4' }}
            centered
            className={classes.tabRoot}
            value={this.state.value}
            onChange={this.handleChange}
            aria-label="simple tabs example"
          >
            {this.renderTabAccount()}
          </Tabs>
        </Grid>
        <Grid>
          <TradingCoreComponent />
        </Grid>
      </Grid>
    );
  }
}

export const TraddingAccountTab = withStyles(styles)(TradingAccountTab);
