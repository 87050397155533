import { Action } from 'redux';
import { IResponseData } from '../types';

export const GET_TABLE_DATA = 'GET_TABLE_DATA';
export type GET_TABLE_DATA = typeof GET_TABLE_DATA;

export const SET_TABLE_DATA = 'SET_TABLE_DATA';
export type SET_TABLE_DATA = typeof SET_TABLE_DATA;

export const SET_LOADING = 'SET_LOADING';
export type SET_LOADING = typeof SET_LOADING;

export interface IGetTableData extends Action<GET_TABLE_DATA> {
  payload: string;
  params?: {};
}

export const getTableData = (url: string, params?: {}): IGetTableData => ({
  type: GET_TABLE_DATA,
  payload: url,
  params
});

export interface ISetTableData extends Action<SET_TABLE_DATA> {
  payload: IResponseData;
}

export const setTableData = (data: IResponseData): ISetTableData => ({
  type: SET_TABLE_DATA,
  payload: data
});

export interface ISetLoadingAction extends Action<SET_LOADING> {
  payload: boolean;
}

export const setLoadingAction = (loading: boolean): ISetLoadingAction => ({
  type: SET_LOADING,
  payload: loading
});
