import React from 'react';
import {
  Tooltip,
  Grid,
  Typography,
  makeStyles,
  Paper
} from '@material-ui/core';

interface IProps {
  value: string;
  totalNeed: string;
  rank?: string;
}

const styles = makeStyles({
  root: {
    backgroundColor: '#FFFFFF',
    boxShadow: '0px 0px 30px rgba(0, 0, 0, 0.1)',
    borderRadius: 3,
    width: 200,
    top: -30,
    height: 158,
    borderBottom: '3px solid #00AFC1'
  },
  arrow: {
    color: '#FFFFFF'
  }
});
export default function RankTooltip(props: IProps) {
  const classes = styles();
  return (
    <Tooltip
      arrow
      classes={{
        tooltipArrow: classes.root,
        arrow: classes.arrow
      }}
      title={
        <Grid container style={{ height: '100%' }}>
          <Grid
            item
            xs={12}
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              paddingTop: 10
            }}
          >
            <Paper
              style={{ width: 57, height: 16, backgroundColor: '#CBB282' }}
            >
              <Typography
                variant="subtitle1"
                style={{
                  color: '#FFFFFF',
                  textAlign: 'center',
                  lineHeight: '16px'
                }}
              >
                {props.rank}
              </Typography>
            </Paper>
          </Grid>
          <Grid item xs={12} style={{ paddingTop: 20, paddingLeft: 10 }}>
            {' '}
            <Typography variant="subtitle1">{'Commission'}</Typography>{' '}
            <Typography variant="subtitle2" style={{ color: '#F9AA62' }}>
              {props.value}
            </Typography>
          </Grid>
          <Grid item xs={12} style={{ paddingLeft: 10 }}>
            {' '}
            <Typography variant="subtitle1">
              {'Total NFT volume need:'}
            </Typography>{' '}
            <Typography variant="subtitle2">{props.totalNeed} USD</Typography>
          </Grid>
        </Grid>
      }
    >
      <Grid
        style={{
          width: 25,
          height: 60,
          position: 'absolute',
          top: -40,
          left: -10,
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'flex-end'
        }}
      >
        <Typography>{props.value}</Typography>
      </Grid>
    </Tooltip>
  );
}
