import * as React from 'react';
import {
  WithStyles,
  withStyles,
  createStyles,
  Theme
} from '@material-ui/core/styles';
import { Grid } from '@material-ui/core';
import { Switch, Route, withRouter } from 'react-router-dom';
import { History } from 'history';
import { PATH } from '../constants/Path.constant';
import AgentProfileComponent from './shared/profile/Agent.profile.component';
import { Dispatch } from 'redux';
import {
  IGetUserProfile,
  getUserProfileAction
} from '../scenes/setting/actions/user';
import { connect } from 'react-redux';
import { IStoreState } from '../types';
import { IUserProfile } from '../scenes/setting/types';
import TopComponent from '../scenes/admin/components/Top/components/Top.component';
import SettingAdminComponent from '../scenes/admin/components/Setting.component';
import PaymentHistoryAdminComponent from '../scenes/admin/components/PaymentHisroty.component';
import { ROLE } from '../constants/auth.constant';
import ChartContainer from '../scenes/admin/components/Trading/chart';
import { AgentManagementComponent } from '../scenes/admin/components/AddAgent/AgentManagement.component';
import { ConfigComponent } from '../scenes/admin/components/configs';
import { NotFoundComponent } from './NotFound.component';
import HeaderComponent from './Header.component';
import _ from 'lodash';
interface IProps extends WithStyles<typeof styles> {
  history: History;
  getUserProfile: () => void;
  profile: IUserProfile;
}

const listMenu = [
  {
    id: 0,
    text: 'Top',
    link: PATH.admin.top
  },
  // {
  //   id: 1,
  //   text: 'Trading',
  //   link: PATH.admin.trading
  // },
  // {
  //   id: 2,
  //   text: 'Summary',
  //   link: ''
  // },
  // {
  //   id: 3,
  //   text: 'Payment History',
  //   link: PATH.admin.paymentHistory
  // },
  {
    id: 5,
    text: 'Agent',
    link: PATH.admin.agentManagment
  },
  {
    id: 6,
    text: 'Configs',
    link: PATH.admin.configs
  },
  {
    id: 4,
    text: 'Settings',
    link: PATH.admin.settings
  }
];

const styles = (theme: Theme) =>
  createStyles({
    childrenRoot: {
      marginTop: 34,
      marginBottom: 30
    },
    headerRoot: {
      padding: '0px 60px',
      marginTop: 41,
      marginBottom: 20,
      [theme.breakpoints.down('sm')]: {
        padding: '0px 30px'
      }
    }
  });

class AdminLayout extends React.Component<IProps> {
  UNSAFE_componentWillMount() {
    const { pathname } = this.props.history.location;
    if (
      !_.includes(
        [
          PATH.admin.agentManagment,
          PATH.admin.top,
          PATH.admin.configs,
          PATH.admin.settings
        ],
        pathname
      )
    ) {
      this.props.history.push(PATH.notFound);
    }
    this.props.getUserProfile();
  }
  public render() {
    const { classes, history } = this.props;
    return (
      <div>
        <HeaderComponent
          isHideAppBar={history.location.pathname === PATH.notFound}
          history={history}
          listMenu={listMenu}
        />
        <Grid className={classes.headerRoot}>
          {this.props.history.location.pathname !== PATH.notFound && (
            <Grid style={{ marginBottom: 27 }}>
              <AgentProfileComponent
                profile={this.props.profile}
                role={ROLE.ADMIN}
                history={this.props.history}
              />
            </Grid>
          )}

          <Switch>
            <Route path={PATH.admin.top} component={TopComponent} />
            <Route
              path={PATH.admin.paymentHistory}
              component={PaymentHistoryAdminComponent}
            />
            <Route path={PATH.admin.trading} component={ChartContainer} />
            <Route
              path={PATH.admin.settings}
              component={SettingAdminComponent}
            />
            <Route
              path={PATH.admin.agentManagment}
              component={AgentManagementComponent}
            />
            <Route path={PATH.admin.configs} component={ConfigComponent} />
            <Route path={PATH.notFound} component={NotFoundComponent} />
          </Switch>
        </Grid>
      </div>
    );
  }
}

const mapDisPatchToProps = (dispatch: Dispatch<IGetUserProfile>) => {
  return {
    getUserProfile: () => dispatch(getUserProfileAction())
  };
};

const mapStateToProps = ({ user }: IStoreState) => {
  return {
    profile: user.profile
  };
};

export default withRouter(
  connect(mapStateToProps, mapDisPatchToProps)(withStyles(styles)(AdminLayout))
);
