import React from 'react';
import {
  Paper,
  createStyles,
  withStyles,
  WithStyles,
  Grid,
  Typography,
  NativeSelect,
  Theme
} from '@material-ui/core';

import { IConfigInfo } from '../../../../dashboard/types';
import { FEE } from '../../../../../constants/Config.constant';
import { groupByValues, formatNumberToFixed } from '../../../../../helper';
import EditComponent from './EditComponent';
import TitlePageComponent from '../../../../../components/TitlePage.component';
import { ITableKickBackData } from '..';

import ConfirmDialog from '../../../../../components/dialog/Confirm.dialog';

const styles = (theme: Theme) =>
  createStyles({
    '@keyframes fadeInDown': {
      '0%': {
        opacity: 0,
        transform: 'translateY(-20px)'
      },
      '100%': {
        opacity: 1,
        transform: 'translateY(0)'
      }
    },
    container: {
      width: '100%',
      height: '100%',
      padding: 20,
      boxSizing: 'border-box',
      [theme.breakpoints.down('xs')]: {
        padding: 10
      }
    },
    rootLayout: {
      padding: 15,
      width: '100%',
      height: 110,
      display: 'flex',
      flexDirection: 'column',
      boxSizing: 'border-box'
    },
    itemRoot: {
      flex: 1
    },

    title: {
      fontSize: 10,
      lineHeight: '15px',
      fontWeight: 600,
      color: '#9BA0A6',
      textTransform: 'uppercase'
    },
    value: {
      fontSize: 12,
      lineHeight: '17px',
      fontWeight: 600,
      color: '#222222',
      textTransform: 'capitalize'
    },
    floatRight: {
      alignItems: 'flex-end'
    },
    fadeIn: {
      opacity: 1,
      transform: 'translateY(0)'
    },
    opotionText: {
      fontFamily: 'Montserrat',
      fontStyle: 'normal',
      fontWeight: 500,
      fontSize: 14,
      lineHeight: '17px',
      color: '#222222'
    },
    selectLayout: {
      flexDirection: 'column',
      flex: 1
    },
    select: {
      height: 40,
      width: '40%',
      [theme.breakpoints.up('sm')]: {
        width: '40%'
      }
    },
    item: {
      display: 'flex',
      flexDirection: 'row',
      marginTop: 20,
      alignItems: 'center'
    }
  });

interface IProps extends WithStyles<typeof styles> {
  configs: IConfigInfo[];
  getConfigAction: () => void;
  updateConfigAction: (configs: IConfigInfo[]) => void;
}

interface IState {
  isEditing: boolean;
  currentOption: string;
  configData: IKickbackConfig[];
  editingField: IConfigInfo[];
  open: boolean;
}

interface IKickbackConfig extends ITableKickBackData {
  id: string;
}

class KickbackCard extends React.Component<IProps> {
  getConfigData = (configs: IConfigInfo[]) => {
    const kickbackConfigs = configs.filter(
      e => e.name === FEE.INITIAL_KICKBACK || e.name === FEE.TERM_KICKBACK
    );

    const groupValues = groupByValues(kickbackConfigs, 'amount');
    const keys = Object.keys(groupValues);

    // create records table
    const configRecords = keys.map((e: any) => {
      const tableData: IKickbackConfig = Object.create(null);
      groupValues[e].forEach((config: IConfigInfo) => {
        config.name === FEE.INITIAL_KICKBACK
          ? (tableData.initKickback = {
              id: config.id,
              value: config.rate
            })
          : (tableData.termKickback = {
              id: config.id,
              value: config.rate
            });
      });
      tableData.id = e;
      return tableData;
    });
    return configRecords;
  };

  state: IState = {
    isEditing: false,
    currentOption: '0',
    open: false,
    editingField: [],
    configData: this.getConfigData(this.props.configs)
  };

  componentWillReceiveProps(nextProps: IProps) {
    if (nextProps.configs !== this.props.configs) {
      this.setState({ configData: this.getConfigData(nextProps.configs) });
    }
  }

  renderSelectOption = (value: string, index: number) => {
    const { classes } = this.props;
    return (
      <option key={value} className={classes.opotionText} value={value}>
        {`Rank ${index + 1}`}
      </option>
    );
  };

  handleOptionChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    this.setState({ currentOption: e.target.value });
  };

  handleOpenEditConfig = () => {
    const { isEditing } = this.state;
    this.setState({ isEditing: !isEditing });
  };

  handleFocus = (event: React.FocusEvent<HTMLInputElement>) => {
    event.target.select();
  };

  handleShowConfirm = () => {
    this.setState({ open: true });
  };

  handleCloseDialog = () => {
    this.props.getConfigAction();
    this.setState({ open: false, editingField: [] });
  };
  handleSaveEdit = () => {
    this.props.updateConfigAction(this.state.editingField);
    this.setState({ editingField: [], open: false });
  };

  handleCompleteEdit = (config: IConfigInfo) => {
    const { editingField } = this.state;

    // update list editing
    editingField.push(config);
    this.setState({ open: true, editingField });
  };

  renderFeeConfig = (data: IKickbackConfig) => {
    const { classes } = this.props;
    if (!data) {
      return null;
    }
    return (
      <>
        <Grid
          item
          xs={12}
          className={classes.item}
          style={{ flexDirection: 'column', alignItems: 'flex-start' }}
        >
          <Typography className={classes.title}>
            investor total buy about
          </Typography>
          <Typography className={classes.value}>
            {formatNumberToFixed(data!.id, 3)}
          </Typography>
        </Grid>
        <Grid item xs={12} className={classes.item}>
          <Grid item xs={6}>
            <Typography className={classes.title}>init kickback</Typography>

            <EditComponent
              handleEditComplete={this.handleCompleteEdit}
              key={data.initKickback.id}
              config={this.props.configs.find(
                e => e.id === data!.initKickback.id
              )}
            />
          </Grid>
          <Grid item xs={6}>
            <Typography className={classes.title}>Term kickback</Typography>
            <EditComponent
              handleEditComplete={this.handleCompleteEdit}
              key={data.termKickback.id}
              config={this.props.configs.find(
                e => e.id === data!.termKickback.id
              )}
            />
          </Grid>
        </Grid>
      </>
    );
  };
  render() {
    const { classes } = this.props;
    const { configData, currentOption } = this.state;
    const currentValue = configData.find(e => e.id === currentOption);
    return (
      <>
        <Grid style={{ marginBottom: 9 }}>
          <TitlePageComponent title="Ranks" />
        </Grid>
        <Paper elevation={0} className={classes.container}>
          <Grid
            container
            style={{
              flexDirection: 'row',
              justifyContent: 'center',
              alignContent: 'center',
              borderBottom: '1px solid #DCE0E4'
            }}
          >
            <Grid container className={classes.selectLayout}>
              <NativeSelect
                className={classes.select}
                disableUnderline
                value={currentOption}
                onChange={this.handleOptionChange}
                inputProps={{
                  style: {
                    height: 40,
                    padding: 0,
                    paddingLeft: 10,
                    fontFamily: 'Montserrat',
                    fontStyle: 'normal',
                    fontWeight: 500,
                    fontSize: 14,
                    lineHeight: '17px',
                    color: '#222222',
                    backgroundColor: 'transparent'
                  }
                }}
                data-test={'nft-type-select'}
              >
                {this.state.configData.map((e, index) =>
                  this.renderSelectOption(e.id, index)
                )}
              </NativeSelect>
            </Grid>
          </Grid>
          {this.renderFeeConfig(currentValue || configData[0])}
        </Paper>
        <ConfirmDialog
          showDialog={this.state.open}
          handleCloseDialog={this.handleCloseDialog}
          textHeader="Confirm save changes"
          textContent="Are you sure you want to save changes ?"
          handleOkButton={this.handleSaveEdit}
        />
      </>
    );
  }
}

export default withStyles(styles)(KickbackCard);
