import * as React from 'react';
import {
  WithStyles,
  withStyles,
  createStyles,
  Theme
} from '@material-ui/core/styles';
import { Grid } from '@material-ui/core';
import { Switch, Route, withRouter } from 'react-router-dom';
import { History } from 'history';
import { PATH } from '../constants/Path.constant';
import { Dispatch } from 'redux';
import {
  IGetUserProfile,
  getUserProfileAction
} from '../scenes/setting/actions/user';
import { connect } from 'react-redux';
import { IStoreState } from '../types';
import { IUserProfile } from '../scenes/setting/types';
import { ROLE } from '../constants/auth.constant';
import { NotFoundComponent } from './NotFound.component';
import HeaderComponent from './Header.component';
import AgentProfileComponent from './shared/profile/Agent.profile.component';
import PaymentComponent from '../scenes/dashboard/components/Payment.component';
import PaymentHistoryComponent from '../scenes/payment-history/PaymentHistory.component';
import SettingComponent from '../scenes/setting/components/ProfileAccount.component';
import _ from 'lodash';
import { TransitionGroup } from 'react-transition-group';
interface IProps extends WithStyles<typeof styles> {
  history: History;
  getUserProfile: () => void;
  profile: IUserProfile;
}
const listMenu = [
  {
    id: 0,
    text: 'Top',
    link: PATH.agent.dashboard
  },
  {
    id: 1,
    text: 'Payment History',
    link: PATH.agent.paymentHistory
  },
  {
    id: 2,
    text: 'Settings',
    link: PATH.agent.settings
  }
];

const styles = (theme: Theme) =>
  createStyles({
    childrenRoot: {
      marginTop: 34,
      marginBottom: 30
    },
    headerRoot: {
      padding: '0px 60px',
      marginTop: 41,
      marginBottom: 20,

      [theme.breakpoints.down('md')]: {
        padding: '0px 20px'
      }
    }
  });

class AgentLayout extends React.Component<IProps> {
  UNSAFE_componentWillMount() {
    const { pathname } = this.props.history.location;
    if (
      !_.includes(
        [PATH.agent.dashboard, PATH.agent.paymentHistory, PATH.agent.settings],
        pathname
      )
    ) {
      this.props.history.push(PATH.notFound);
    }
    this.props.getUserProfile();
  }
  public render() {
    const { classes, history } = this.props;
    return (
      <>
        <HeaderComponent
          isHideAppBar={history.location.pathname === PATH.notFound}
          history={history}
          listMenu={listMenu}
        />
        <Grid className={classes.headerRoot}>
          {this.props.history.location.pathname !== PATH.notFound && (
            <Grid style={{ marginBottom: 27 }}>
              <AgentProfileComponent
                role={ROLE.AGENT}
                profile={this.props.profile}
                history={this.props.history}
              />
            </Grid>
          )}
          <TransitionGroup>
            <Switch>
              <Route path={PATH.agent.dashboard} component={PaymentComponent} />
              <Route path={PATH.agent.settings} component={SettingComponent} />
              {/* <CSSTransition
                timeout={300}
                classNames="page"
                unmountOnExit
                key={this.props.history.location.key}
              >
                <SettingComponent />
              </CSSTransition> */}
              <Route
                path={PATH.agent.paymentHistory}
                component={PaymentHistoryComponent}
              />
              <Route path={PATH.notFound} component={NotFoundComponent} />
            </Switch>
          </TransitionGroup>
        </Grid>
      </>
    );
  }
}

const mapDisPatchToProps = (dispatch: Dispatch<IGetUserProfile>) => {
  return {
    getUserProfile: () => dispatch(getUserProfileAction())
  };
};

const mapStateToProps = ({ user }: IStoreState) => {
  return {
    profile: user.profile
  };
};

export default withRouter(
  connect(mapStateToProps, mapDisPatchToProps)(withStyles(styles)(AgentLayout))
);
