import * as React from 'react';
import {
  createStyles,
  Paper,
  Grid,
  Typography,
  TextField,
  Button,
  FormControl,
  Theme
} from '@material-ui/core';
import { withStyles, WithStyles } from '@material-ui/styles';
import ExternalAgentLayout from '../../../components/ExternalAgentLayout';
import { History } from 'history';
import { PATH } from '../../../constants/Path.constant';
import { Dispatch } from 'redux';
import { IForgotPasswordAction, forgotPasswordAction } from '../actions/auth';
import { connect } from 'react-redux';
import { IError } from '../types';
import { validateEmail } from '../../../helper';
import Axios from 'axios';
import {
  IUpdateNotification,
  fireNotification
} from '../../../actions/Notification.action';
import Variant from '../../../types/Variant.type';
import { MESSAGE_NOTIFICATION_SUCCESS } from '../../../constants/Notification.constant';
import { MESSAGE } from '../../../constants/Message.constant';
const styles = (theme: Theme) =>
  createStyles({
    rootLogin: {
      width: 500,
      height: 420,
      [theme.breakpoints.down('xs')]: {
        width: '80%',
        padding: '0 25px',
        display: 'flex',
        flexDirection: 'column'
      }
    },
    titleHeader: {
      height: 100,
      borderBottom: '1px solid #F1F1F4',
      [theme.breakpoints.down('xs')]: {
        height: 60
      }
    },
    buttonLogin: {
      textTransform: 'inherit',
      marginTop: 35,
      [theme.breakpoints.down('xs')]: {
        width: '100%'
      }
    },
    textForgot: {
      fontSize: 14,
      fontWeight: 500,
      color: '#00AFC1',
      lineHeight: '17px',
      cursor: 'pointer',
      marginTop: 47,
      [theme.breakpoints.down('xs')]: {
        marginTop: 30,
        padding: '20px 0'
      }
    },
    textFiled: {
      [theme.breakpoints.down('xs')]: {
        width: '100%'
      }
    },
    content: {
      height: 320,
      [theme.breakpoints.down('xs')]: {
        flex: 1
      }
    },
    text: {
      textAlign: 'center'
    }
  });

interface IProps extends WithStyles<typeof styles> {
  history: History;
  forgotPasswordAction: (email: string) => void;
  updateNotification: (message: string, variant: Variant) => void;
}

interface IState {
  email: string;
  error: IError;
}
class ForgotPassword extends React.Component<IProps, IState> {
  state: IState = {
    email: '',
    error: {
      isError: false,
      errorMsg: ''
    }
  };

  handleTextChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const email = event.target.value;
    this.setState({ email });
  };
  public redirectTo = (path: string) => (event: any) => {
    this.props.history.push(path);
  };

  handleSentMail = () => {
    const { email } = this.state;
    if (!email.match(validateEmail)) {
      return this.setState({
        error: {
          isError: true,
          errorMsg: MESSAGE.ERROR.INVALID_EMAIL_FORMAT
        }
      });
    }
    // this.props.forgotPasswordAction(email);
    Axios.post('/forgot/password', {
      email,
      urlCallback: `${process.env.REACT_APP_MEMBER_URL}/reset-password`
    })
      .then(() => {
        this.props.updateNotification(
          MESSAGE_NOTIFICATION_SUCCESS.FORGOT_PASSWORD,
          Variant.SUCCESS
        );
        this.props.history.push(PATH.login);
      })
      .catch(err => {
        const errorMsg =
          err.response &&
          err.response.data &&
          err.response.data.error &&
          err.response.data.error.message;
        this.setState({
          error: {
            isError: true,
            errorMsg
          }
        });
      });
    this.setState({
      error: {
        isError: false,
        errorMsg: ''
      }
    });
  };

  handleKeyPress = (event: any) => {
    if (event.key === 'Enter') {
      this.handleSentMail();
    }
  };

  public render() {
    const { classes } = this.props;
    const { email, error } = this.state;
    return (
      <ExternalAgentLayout>
        <Paper elevation={1} className={classes.rootLogin}>
          <Grid
            container
            alignItems="center"
            justify="center"
            className={classes.titleHeader}
          >
            <Typography variant="h2">Reset Password</Typography>
          </Grid>
          <Grid
            container
            justify="center"
            alignContent="center"
            className={classes.content}
          >
            <Typography className={classes.text} variant="subtitle2">
              Enter the email address you used when you joined and
            </Typography>

            <Typography
              style={{ marginTop: 8 }}
              className={classes.text}
              variant="subtitle2"
            >
              we’ll send you instructions to reset your password.
            </Typography>
            <FormControl className={classes.textFiled}>
              <TextField
                className={classes.textFiled}
                label="Email address"
                data-test="input-email"
                variant="outlined"
                error={error.isError}
                helperText={error.errorMsg}
                value={email}
                onKeyPress={this.handleKeyPress}
                onChange={this.handleTextChange}
                style={{ marginTop: 44 }}
              />
              <Button
                data-test="submit"
                type="submit"
                variant="contained"
                color="primary"
                size="large"
                className={classes.buttonLogin}
                onClick={this.handleSentMail}
              >
                Send reset password email
              </Button>
            </FormControl>
            <Typography
              className={classes.textForgot}
              data-test="back-to-signin"
              onClick={this.redirectTo(PATH.login)}
            >
              Back to Log in
            </Typography>
          </Grid>
        </Paper>
      </ExternalAgentLayout>
    );
  }
}

const mapDispatchToProps = (
  dispatch: Dispatch<IForgotPasswordAction | IUpdateNotification>
) => {
  return {
    forgotPasswordAction: (email: string) =>
      dispatch(forgotPasswordAction(email)),
    updateNotification: (message: string, variant: Variant) =>
      dispatch(fireNotification({ message, variant }))
  };
};

export default connect(
  undefined,
  mapDispatchToProps
)(withStyles(styles)(ForgotPassword));
