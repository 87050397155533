import INotificationInfo from '../types/Notification.type';
import {
  UPDATE_NOTIFICATION,
  EASE_NOTIFICATION
} from '../constants/Notification.constant';
import { Action } from 'redux';

export interface IUpdateNotification extends Action<UPDATE_NOTIFICATION> {
  payload: INotificationInfo;
}
export function fireNotification(noti: INotificationInfo): IUpdateNotification {
  return {
    type: UPDATE_NOTIFICATION,
    payload: { ...noti, isOpen: true }
  };
}

export interface IEaseNotification extends Action<EASE_NOTIFICATION> {}

export function ceaseNotification(): IEaseNotification {
  return {
    type: EASE_NOTIFICATION
  };
}
