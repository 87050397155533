import React from 'react';
import {
  createStyles,
  WithStyles,
  withStyles,
  Paper,
  Grid,
  Typography
} from '@material-ui/core';
import {
  TableDataRawAdminComponent,
  ITEM_TYPE_ADMIN
} from './shared/TableRawAdmin.component';
import { ICardPaymentHistoryInfo } from '../scenes/dashboard/types/PaymentHistoryAdmin.type';

const styles = () => createStyles({});

interface IProps extends WithStyles<typeof styles> {
  data: ICardPaymentHistoryInfo;
}

class CardPaymentHistoryAdmin extends React.Component<IProps> {
  public render() {
    const { data } = this.props;
    return (
      <Paper
        elevation={0}
        style={{
          height: 220
        }}
      >
        <Grid
          container
          style={{
            height: 70,
            borderBottom: '1px solid #F1F1F4'
          }}
          alignItems="center"
        >
          <Typography variant="h3" style={{ marginLeft: 26 }}>
            {data.name}
          </Typography>
        </Grid>
        <Grid
          container
          alignItems="center"
          style={{
            paddingLeft: 26,
            height: 'calc(100% - 70px )'
          }}
        >
          <Grid container>
            <Grid item md={6} lg={6}>
              <TableDataRawAdminComponent
                items={{
                  title: 'Total holders',
                  value: data.totalHolders
                }}
                type={ITEM_TYPE_ADMIN.DEFAULT}
              />
            </Grid>
            <Grid item md={6} lg={6}>
              <TableDataRawAdminComponent
                items={{
                  title: 'Holder total volume',
                  value: data.totalHolderVolume + ' XBT'
                }}
                type={ITEM_TYPE_ADMIN.ICON_VALUE}
              />
            </Grid>
          </Grid>

          <Grid container>
            <Grid item md={6} lg={6}>
              <TableDataRawAdminComponent
                items={{
                  title: 'Fit profit',
                  value: data.fitProfit + ' XBT'
                }}
                type={ITEM_TYPE_ADMIN.ICON_VALUE}
              />
            </Grid>
            <Grid item md={6} lg={6}>
              <TableDataRawAdminComponent
                items={{
                  title: 'Profit rate',
                  value: '+' + data.profitRate + '%'
                }}
                type={ITEM_TYPE_ADMIN.COLOR_VALUE_GREEN}
              />
            </Grid>
          </Grid>
        </Grid>
      </Paper>
    );
  }
}

export default withStyles(styles)(CardPaymentHistoryAdmin);
