import React, { Dispatch } from 'react';
import {
  Paper,
  Grid,
  createStyles,
  WithStyles,
  Table,
  TableHead,
  TableCell,
  TableRow,
  TableBody,
  Theme,
  Typography
} from '@material-ui/core';
import {
  IGetConfigAction,
  getConfigAction,
  updateConfigAction,
  IUpdateConfigAction
} from '../../../dashboard/actions/payment.action';
import { IStoreState } from '../../../../types';
import { connect } from 'react-redux';
import { IConfigInfo } from '../../../dashboard/types';
import { withStyles } from '@material-ui/styles';
import classNames from 'classnames';
import _ from 'lodash';
import { ITableSettings } from '../../../../components/shared/table/redux/types';
import { FEE } from '../../../../constants/Config.constant';
import { groupByValues, formatNumberToFixed } from '../../../../helper';
import EditComponent from './component/EditComponent';
import ConfirmDialog from '../../../../components/dialog/Confirm.dialog';
import { tableFeeSettings, tableKickbackSettings } from './styles';
import MediaQuery from 'react-responsive';
import ConfigCardComponent from './component/Config.card.component';
import KickbackCardComponent from './component/Kickback.card.component';

const styles = (theme: Theme) =>
  createStyles({
    headerStyle: {
      textAlign: 'center',
      whiteSpace: 'pre-line',
      width: '10%'
    },
    root: {
      width: '100%'
    },
    tableWrapper: {
      // minHeight: 561,
      overflow: 'auto'
    },
    tableHead: {
      background: '#FFFFFF',
      borderRadius: '5px 5px 0px 0px'
    },
    text: {
      fontFamily: 'Montserrat',
      fontStyle: 'normal'
    },
    headerText: {
      fontWeight: 600,
      fontSize: 12,
      lineHeight: '15px',
      textTransform: 'uppercase',
      color: '#000000',
      [theme.breakpoints.up(1020)]: {
        [theme.breakpoints.down(1371)]: {
          fontSize: 10
        }
      }
    },
    firstColumn: {
      fontWeight: 500,
      fontSize: 14,
      lineHeight: '17px',
      color: '#222222',
      paddingLeft: 20
    },
    labelPagination: {
      fontWeight: 500,
      fontSize: 12,
      lineHeight: '15px',
      color: '#A1A9B4'
    },
    pagination: {
      display: 'flex',
      borderTop: '1px solid #F1F1F4'
    },
    borderBottom: {
      borderBottom: '1px solid #F1F1F4'
    },
    tableValue: {
      paddingRight: 20
    },
    border: {
      borderBottom: 'none'
    },
    iconCenter: {
      marginBottom: -5
    }
  });

interface IProps extends WithStyles<typeof styles> {
  configs: IConfigInfo[];
  getConfigAction: () => void;
  updateConfigAction: (configs: IConfigInfo[]) => void;
}

interface IState {
  open: boolean;
  editingField: IConfigInfo[];
}

interface IData {
  id: string;
  value: number;
}
export interface ITableFeeData {
  adminCharge: IData;
  adminChargeWithCode: IData;
  assetFee: IData;
  assetFeeWithCode: IData;
  totalFee: IData;
  totalFeeWithCode: IData;
}

export interface ITableKickBackData {
  initKickback: IData;
  termKickback: IData;
}

class Config extends React.Component<IProps, IState> {
  componentDidMount() {
    this.props.getConfigAction();
  }
  state: IState = {
    open: false,
    editingField: []
  };

  handleShowConfirm = () => {
    this.setState({ open: true });
  };

  handleCloseDialog = () => {
    this.props.getConfigAction();
    this.setState({ open: false, editingField: [] });
  };
  handleSaveEdit = () => {
    this.props.updateConfigAction(this.state.editingField);
    this.setState({ editingField: [], open: false });
  };

  handleCompleteEdit = (config: IConfigInfo) => {
    const { editingField } = this.state;

    // update list editing
    editingField.push(config);
    this.setState({ open: true, editingField });
  };

  shouldComponentUpdate(nextProps: IProps, nextState: IState) {
    return (
      !_.isEqual(nextProps.configs, this.props.configs) ||
      nextState.open !== this.state.open ||
      JSON.stringify(nextState.editingField) !==
        JSON.stringify(this.state.editingField)
    );
  }
  renderTable = (
    headerText: string[],
    getHeaderValue: (index: number) => string[],
    records: any,
    settings: ITableSettings
  ) => {
    const { classes } = this.props;
    // const { configs } = this.state;
    return (
      <Table stickyHeader>
        <TableHead>
          <TableRow>
            {headerText &&
              headerText.map(e => (
                <TableCell
                  key={e}
                  className={classNames(
                    classes.borderBottom,
                    classes.tableHead
                  )}
                  style={{ minWidth: '15%', padding: '0 20px' }}
                >
                  <Typography className={classes.headerText}>{e}</Typography>
                </TableCell>
              ))}
            {settings.tableColumn.map((e, index) => (
              <TableCell
                valign="middle"
                className={classNames(
                  classes.text,
                  classes.headerText,
                  classes.borderBottom,
                  classes.tableHead
                )}
                key={index}
                align={e.align ? e.align : 'left'}
                style={{
                  minWidth: e.width,
                  paddingRight: 20
                }}
              >
                {e.label}
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {records && records.length
            ? records.map((row: any, rowIndex: any) => {
                return (
                  <TableRow key={rowIndex}>
                    {getHeaderValue(rowIndex) &&
                      getHeaderValue(rowIndex).map(e => (
                        <TableCell
                          key={e}
                          className={classNames(
                            classes.text,
                            classes.firstColumn
                          )}
                        >
                          {e}
                        </TableCell>
                      ))}
                    {settings.tableColumn.map((e, index) => {
                      const value = _.get(row, e.key);
                      return (
                        <TableCell
                          className={classNames(classes.tableValue)}
                          align={e.align}
                          style={e.styles}
                          key={index}
                        >
                          {e.editing ? (
                            <EditComponent
                              key={value.id}
                              config={this.props.configs.find(
                                config => config.id === value.id
                              )}
                              handleEditComplete={this.handleCompleteEdit}
                            />
                          ) : (
                            value.value
                          )}
                        </TableCell>
                      );
                    })}
                  </TableRow>
                );
              })
            : null}
        </TableBody>
      </Table>
    );
  };

  renderFeeTable = () => {
    const { configs } = this.props;
    const feeConfigs = configs.filter(
      e =>
        (e.amount && e.name === FEE.ADMIN_CHARGE) ||
        e.name === FEE.ASSET_MANAGEMENT_FEE
    );
    const groupValues = groupByValues(feeConfigs, 'amount');
    const keys = Object.keys(groupValues);

    // create records table
    const configRecords = keys.map((e: any) => {
      const tableData: ITableFeeData = Object.create(null);
      groupValues[e].forEach((config: IConfigInfo) => {
        if (config.name === FEE.ADMIN_CHARGE) {
          config.withAgent
            ? (tableData.adminChargeWithCode = {
                id: config.id,
                value: config.rate
              })
            : (tableData.adminCharge = {
                id: config.id,
                value: config.rate
              });
        } else {
          config.withAgent
            ? (tableData.assetFeeWithCode = {
                id: config.id,
                value: config.rate
              })
            : (tableData.assetFee = {
                id: config.id,
                value: config.rate
              });
        }
      });
      tableData.totalFee = {
        id: '',
        value: parseFloat(
          (
            (tableData.adminCharge ? tableData.adminCharge.value : 0) +
            (tableData.assetFee ? tableData.assetFee.value : 0)
          ).toPrecision(5)
        )
      };

      tableData.totalFeeWithCode = {
        id: '',
        value: parseFloat(
          (
            (tableData.adminChargeWithCode
              ? tableData.adminChargeWithCode.value
              : 0) +
            (tableData.assetFeeWithCode ? tableData.assetFeeWithCode.value : 0)
          ).toPrecision(5)
        )
      };

      return tableData;
    });

    const headerText: string[] = [
      'Minimum Contribute',
      'Invest term',
      `SURRENDER CHARGE 
      (Also apply to withdraw charge) %`
    ];

    const getHeaderValue = (index: number) => {
      return [
        `US$ ${formatNumberToFixed(keys ? keys[index] : 0, 0)}`,
        '1 Year',
        '20%'
      ];
    };

    return this.renderTable(
      headerText,
      getHeaderValue,
      configRecords,
      tableFeeSettings
    );
  };
  renderKickbackTable = () => {
    const { configs } = this.props;
    const kickbackConfigs = configs.filter(
      e => e.name === FEE.INITIAL_KICKBACK || e.name === FEE.TERM_KICKBACK
    );

    const groupValues = groupByValues(kickbackConfigs, 'amount');
    const keys = Object.keys(groupValues);
    console.log(groupValues);
    // create records table
    const configRecords = keys.map((e: any) => {
      const tableData: ITableKickBackData = Object.create(null);
      groupValues[e].forEach((config: IConfigInfo) => {
        config.name === FEE.INITIAL_KICKBACK
          ? (tableData.initKickback = {
              id: config.id,
              value: config.rate
            })
          : (tableData.termKickback = {
              id: config.id,
              value: config.rate
            });
      });
      return tableData;
    });

    const headerText: string[] = ['Rank', `Total of members' investment`];

    const getHeaderValue = (index: number) => {
      return [
        `Rank${index + 1}`,
        `${
          keys[index] === '0'
            ? `Under  ${formatNumberToFixed(keys ? keys[1] : 0, 0)} USD`
            : `Over ${formatNumberToFixed(keys ? keys[index] : 0, 0)} USD`
        }`
      ];
    };

    return this.renderTable(
      headerText,
      getHeaderValue,
      configRecords,
      tableKickbackSettings
    );
  };

  render() {
    return (
      <React.Fragment>
        <Grid container style={{ flexDirection: 'column' }}>
          <MediaQuery minDeviceWidth={1024}>
            <Grid style={{ flex: 1 }}>
              <Paper elevation={0}>{this.renderFeeTable()}</Paper>
            </Grid>
            <Grid style={{ flex: 1, paddingTop: 20 }}>
              <Paper elevation={0}>{this.renderKickbackTable()}</Paper>
            </Grid>
          </MediaQuery>
          <MediaQuery maxDeviceWidth={1020}>
            <Grid style={{ flex: 1 }}>
              <ConfigCardComponent
                getConfigAction={this.props.getConfigAction}
                updateConfigAction={this.props.updateConfigAction}
                configs={this.props.configs}
              />
            </Grid>
            <Grid style={{ flex: 1, marginTop: 30 }}>
              <KickbackCardComponent
                getConfigAction={this.props.getConfigAction}
                updateConfigAction={this.props.updateConfigAction}
                configs={this.props.configs}
              />
            </Grid>
          </MediaQuery>
        </Grid>
        <ConfirmDialog
          showDialog={this.state.open}
          handleCloseDialog={this.handleCloseDialog}
          textHeader="Confirm save changes"
          textContent="Are you sure you want to save changes ?"
          handleOkButton={this.handleSaveEdit}
        />
      </React.Fragment>
    );
  }
}

export const mapDispatchToProps = (
  dispatch: Dispatch<IGetConfigAction | IUpdateConfigAction>
) => {
  return {
    getConfigAction: () => dispatch(getConfigAction()),
    updateConfigAction: (configs: IConfigInfo[]) =>
      dispatch(updateConfigAction(configs))
  };
};

export const mapStateToProps = ({ configs }: IStoreState) => ({
  configs
});

export const ConfigComponent = withStyles(styles)(
  connect(mapStateToProps, mapDispatchToProps)(Config)
);
