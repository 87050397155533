import * as React from 'react';
import {
  Theme,
  createStyles,
  WithStyles,
  withStyles,
  Dialog,
  Grid,
  Typography,
  Button
} from '@material-ui/core/';
import INotificationInfo from '../types/Notification.type';
import { connect } from 'react-redux';
import { IStoreState } from '../types';
import {
  ceaseNotification,
  IEaseNotification
} from '../actions/Notification.action';
import { Dispatch } from 'react';
import iconsuccesDialog from '../assets/images/iconsuccesDialog.svg';
import iconErrorDialog from '../assets/images/iconErrorDialog.svg';
import Variant from '../types/Variant.type';

const styles = (theme: Theme) =>
  createStyles({
    dialogLayout: {
      [theme.breakpoints.down('xs')]: {
        width: '80%',
        padding: '0 10%'
      }
    },
    dialogTitle: {
      height: 100,
      padding: 0
    },
    dialogContent: {
      height: 'calc(100% - 200px )',
      paddingLeft: 30,
      paddingRight: 30
    },
    textWithDraw: {
      fontSize: 20,
      color: '#000000',
      lineHeight: '24px',
      fontWeight: 600
    },
    dialogActions: {
      height: 100,
      borderTop: '1px solid #DCE0E4'
    },
    buttonYesWithdraw: {
      width: 255,
      height: 50,
      textTransform: 'capitalize'
    },
    buttonNoWithdraw: {
      width: 255,
      height: 50,
      textTransform: 'capitalize',
      backgroundColor: '#FFFFFF',
      border: '1px solid #DCE0E4',
      boxSizing: 'border-box',
      '&:hover': {
        backgroundColor: '#F4F4F4',
        border: '1px solid #E6E6E6'
      },
      [theme.breakpoints.down('xs')]: {
        width: '80%'
      }
    }
  });

interface IProps extends WithStyles<typeof styles> {
  notification: INotificationInfo;
  closeNotification: () => void;
}

class NotificationComponent extends React.Component<IProps> {
  handleCloseNotification = () => {
    if (this.props.notification.link) {
      window.location.href = this.props.notification.link;
    }
    this.props.closeNotification();
  };
  public render() {
    const { classes, notification } = this.props;
    return (
      <React.Fragment>
        <Dialog
          className={classes.dialogLayout}
          onClose={this.handleCloseNotification}
          aria-labelledby="customized-dialog-title"
          open={!!notification.isOpen}
          maxWidth="xl"
        >
          {notification.variant === Variant.SUCCESS ? (
            <Grid
              container
              style={{ height: 90 }}
              alignItems="center"
              justify="center"
            >
              <img
                src={iconsuccesDialog}
                alt="icon succes"
                style={{ marginRight: 10 }}
              />
              <Typography
                variant="subtitle2"
                style={{ fontWeight: 500, color: '#36BCA4' }}
              >
                {' '}
                Success!
              </Typography>
            </Grid>
          ) : (
            <Grid
              container
              style={{ height: 90 }}
              alignItems="center"
              justify="center"
            >
              <img
                src={iconErrorDialog}
                alt="icon succes"
                style={{ marginRight: 10 }}
              />
              <Typography
                variant="subtitle2"
                style={{ fontWeight: 500, color: '#E3001E' }}
              >
                {' '}
                Error!
              </Typography>
            </Grid>
          )}

          <Grid
            container
            alignItems="center"
            justify="center"
            style={{ height: 'calc(100% - 190px)', padding: '0 20px' }}
          >
            <Typography
              data-test="notification-msg"
              variant="subtitle2"
              style={{ fontWeight: 500, textAlign: 'center' }}
            >
              {' '}
              {notification.message}
            </Typography>
          </Grid>
          <Grid
            container
            alignItems="center"
            justify="center"
            style={{ height: 100, paddingBottom: 50 }}
          >
            <Button
              data-test="Ok"
              className={classes.buttonNoWithdraw}
              variant="contained"
              color="secondary"
              onClick={this.handleCloseNotification}
            >
              <Typography variant="subtitle2"> Ok</Typography>
            </Button>
          </Grid>
        </Dialog>
      </React.Fragment>
    );
  }
}

function mapStateToProps({ notification }: IStoreState) {
  return {
    notification
  };
}

function mapDispatchToProps(dispatch: Dispatch<IEaseNotification>) {
  return {
    closeNotification: () => {
      dispatch(ceaseNotification());
    }
  };
}

export default withStyles(styles)(
  connect(mapStateToProps, mapDispatchToProps)(NotificationComponent)
);
