import historyProvider from './history.component';
import {
  SearchSymbolsCallback,
  ErrorCallback,
  LibrarySymbolInfo,
  ResolutionString,
  SubscribeBarsCallback,
  ResolutionBackValues,
  GetMarksCallback,
  TimescaleMark
} from '../../../../../../charting_library/charting_library.min';
import {
  ResolveCallback,
  HistoryCallback,
  Mark,
  ServerTimeCallback,
  HistoryDepth
} from '../../../../../../charting_library/datafeed-api';

const supportedResolutions = [
  '1',
  '3',
  '5',
  '15',
  '30',
  '60',
  '120',
  '240',
  'D'
];

const config = {
  supported_resolutions: supportedResolutions
};

export default {
  onReady: (cb: any) => {
    console.log('=====onReady running');
    setTimeout(() => cb(config), 0);
  },
  searchSymbols: (
    userInput: string,
    exchange: string,
    symbolType: string,
    onResult: SearchSymbolsCallback
  ) => {
    console.log('====Search Symbols running');
  },
  resolveSymbol: (
    symbolName: string,
    onResolve: ResolveCallback,
    onError: ErrorCallback
  ) => {
    // expects a symbolInfo object in response
    console.log('======resolveSymbol running');
    // console.log('resolveSymbol:',{symbolName})
    const splitData = symbolName.split(':');
    symbolName = (splitData.length > 1
      ? splitData[1]
      : symbolName
    ).toUpperCase();
    // console.log({split_data})
    const symbolStub: LibrarySymbolInfo = {
      name: symbolName,
      description: '',
      type: 'cryptocurrency',
      session: '24x7',
      timezone: 'Etc/UTC',
      ticker: symbolName,
      exchange: splitData[0],
      minmov: 1,
      pricescale: 100,
      has_intraday: true,
      full_name: '',
      listed_exchange: splitData[0],
      format: 'volume',
      intraday_multipliers: ['1', '60'],
      supported_resolutions: supportedResolutions,
      volume_precision: 8,
      data_status: 'streaming'
    };

    setTimeout(() => {
      onResolve(symbolStub);
    }, 0);

    // onResolveErrorCallback('Not feeling it today')
  },
  getBars: (
    symbolInfo: LibrarySymbolInfo,
    resolution: ResolutionString,
    rangeStartDate: number,
    rangeEndDate: number,
    onResult: HistoryCallback,
    onError: ErrorCallback,
    isFirstCall: boolean
  ) => {
    console.log(
      '=====getBars running',
      new Date(rangeStartDate * 1000),
      new Date(rangeEndDate * 1000)
    );
    // console.log('function args',arguments)
    // console.log(`Requesting bars between ${new Date(from * 1000).toISOString()} and ${new Date(to * 1000).toISOString()}`)
    historyProvider
      .getBars(
        symbolInfo,
        resolution,
        rangeStartDate,
        rangeEndDate,
        isFirstCall
      )
      .then((bars: any) => {
        console.log('Nar', bars);
        if (bars.length) {
          console.log(bars);
          onResult(bars, { noData: false });
        } else {
          onResult(bars, { noData: true });
        }
      })
      .catch((err: any) => {
        console.log({ err });
        onError(err);
      });
  },
  subscribeBars: (
    symbolInfo: LibrarySymbolInfo,
    resolution: ResolutionString,
    onTick: SubscribeBarsCallback,
    listenerGuid: string,
    onResetCacheNeededCallback: () => void
  ) => {
    console.log('=====subscribeBars runnning', listenerGuid);
    // SocketCLient.subscribeBars(
    //   symbolInfo,
    //   resolution,
    //   onTick,
    //   listenerGuid,
    //   onResetCacheNeededCallback
    // );
  },
  unsubscribeBars: (subscriberUID: string) => {
    console.log('=====unsubscribeBars running', subscriberUID);
  },
  calculateHistoryDepth: (
    resolution: string,
    resolutionBack: ResolutionBackValues,
    intervalBack: number
  ): HistoryDepth | undefined => {
    // optional
    console.log('=====calculateHistoryDepth running');
    return Number(resolution) < 60
      ? { resolutionBack: 'D', intervalBack: 1 }
      : undefined;
  },
  getMarks: (
    symbolInfo: LibrarySymbolInfo,
    from: number,
    to: number,
    onDataCallback: GetMarksCallback<Mark>,
    resolution: ResolutionString
  ) => {
    // optional
    console.log('=====getMarks running');
  },
  getTimeScaleMarks: (
    symbolInfo: LibrarySymbolInfo,
    from: number,
    to: number,
    onDataCallback: GetMarksCallback<TimescaleMark>,
    resolution: ResolutionString
  ) => {
    // optional
    console.log('=====getTimeScaleMarks running');
  },
  getServerTime: (callback: ServerTimeCallback) => {
    // callback(new Date().getTime() / 1000);
    console.log('=====getServerTime running');
  }
};
