import React from 'react';
import {
  Grid,
  Typography,
  TextField,
  FormControl,
  createStyles,
  WithStyles,
  Theme
} from '@material-ui/core';
import { ISearchInfo } from '../../../../components/shared/table/redux/types';
import { withStyles } from '@material-ui/styles';
import AddAccount from '../../../../components/AddAccount.component';
import AddAgentAccountDialogComponent from '../../../../components/dialog/AddAgentAccountDialog.component';
import { TableAgentComponent } from './AgentTable.component';
import TitlePageComponent from '../../../../components/TitlePage.component';
import MediaQuery from 'react-responsive';
import AgentMobileComponent from './Agent.mobile.component';

const style = (theme: Theme) =>
  createStyles({
    textField: {
      width: '100%',
      backgroundColor: '#FFFFFF',
      textTransform: 'uppercase',
      '& :disabled': {
        color: '#000000'
      }
    },
    inputLabelProps: {
      color: '#9BA0A6 !important',
      fontSize: 12,
      fontWeight: 600,
      lineHeight: '12px',
      textTransform: 'uppercase'
    },
    inputProps: {
      color: '#3DACEB',
      fontWeight: 500,
      fontSize: 14,
      lineHeight: '17px'
    },
    root: {
      width: '100%'
    },
    tableWrapper: {
      // minHeight: 561,
      overflow: 'auto'
    },
    tableHead: {
      background: '#FFFFFF',
      borderRadius: '5px 5px 0px 0px'
    },
    text: {
      fontFamily: 'Montserrat',
      fontStyle: 'normal'
    },
    headerText: {
      fontWeight: 600,
      fontSize: 12,
      lineHeight: '15px',
      textTransform: 'uppercase',
      color: '#000000',
      [theme.breakpoints.up(1020)]: {
        [theme.breakpoints.down(1371)]: {
          fontSize: 10
        }
      }
    },
    firstColumn: {
      fontWeight: 500,
      fontSize: 14,
      lineHeight: '17px',
      color: '#222222',
      paddingLeft: 20
    },
    labelPagination: {
      fontWeight: 500,
      fontSize: 12,
      lineHeight: '15px',
      color: '#A1A9B4'
    },
    pagination: {
      display: 'flex',
      borderTop: '1px solid #F1F1F4'
    },
    borderBottom: {
      borderBottom: '1px solid #F1F1F4'
    },
    tableValue: {
      paddingRight: 20
    },
    border: {
      borderBottom: 'none'
    },
    iconCenter: {
      marginBottom: -5
    },
    contentLayout: {
      padding: '27px 0 0 20px',
      [theme.breakpoints.down('xs')]: {
        padding: 0,
        marginTop: 30
      }
    }
  });

interface IProps extends WithStyles<typeof style> {}

interface IState {
  showDialog: boolean;
  emailText: string;
  searchInfo: ISearchInfo | undefined;
  reloadTable: boolean;
}
class AgentManagement extends React.Component<IProps, IState> {
  state: IState = {
    showDialog: false,
    emailText: '',
    searchInfo: undefined,
    reloadTable: false
  };
  public handleCloseDialog = () => {
    this.setState({
      showDialog: false,
      reloadTable: true
    });
  };
  public handleShowDialog = () => {
    this.setState({
      showDialog: true
    });
  };

  handleTextChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({
      emailText: event.target.value,
      searchInfo: { text: event.target.value, key: 'email' }
    });
  };

  render() {
    const { classes } = this.props;
    return (
      <React.Fragment>
        <Grid style={{ marginBottom: 9 }}>
          <TitlePageComponent title="Agents" />
        </Grid>
        <Grid container style={{ paddingTop: 10 }}>
          <Grid
            item
            xs={12}
            md={4}
            lg={3}
            style={{
              display: 'flex',
              flexDirection: 'column'
            }}
          >
            <Grid style={{ flex: 1 }}>
              <Typography variant={'subtitle2'}>Search by Email</Typography>
              <FormControl
                style={{ width: '100%', marginTop: 10 }}
                required={true}
              >
                <TextField
                  variant={'outlined'}
                  InputLabelProps={{
                    className: classes.inputLabelProps
                  }}
                  className={classes.textField}
                  value={this.state.emailText}
                  onChange={this.handleTextChange}
                  placeholder={'Enter email'}
                />
              </FormControl>
            </Grid>
            <MediaQuery minDeviceWidth={768}>
              <Grid
                style={{
                  width: '100%',
                  marginTop: 30
                }}
              >
                <AddAccount
                  handleDialog={this.handleShowDialog}
                  text={'Add new agent'}
                />
              </Grid>
            </MediaQuery>
          </Grid>
          <Grid item xs={12} md={8} lg={9} className={classes.contentLayout}>
            <MediaQuery minDeviceWidth={768}>
              <TableAgentComponent searchText={this.state.emailText} />
            </MediaQuery>
            <MediaQuery maxDeviceWidth={766}>
              <AgentMobileComponent searchText={this.state.emailText} />
            </MediaQuery>
            <MediaQuery maxDeviceWidth={766}>
              <Grid
                style={{
                  width: '100%',
                  marginTop: 30
                }}
              >
                <AddAccount
                  handleDialog={this.handleShowDialog}
                  text={'Add new agent'}
                />
              </Grid>
            </MediaQuery>
          </Grid>
        </Grid>
        <AddAgentAccountDialogComponent
          showDialog={this.state.showDialog}
          handleCloseDialog={this.handleCloseDialog}
        />
      </React.Fragment>
    );
  }
}

export const AgentManagementComponent = withStyles(style)(AgentManagement);
