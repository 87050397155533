import React from 'react';
import { Grid, Typography, Paper } from '@material-ui/core';
import HolderInfoComponent from '../../../components/shared/HolderInfo.component';
import { IHolder } from '../types';
import memoizeOne from 'memoize-one';
import MediaQuery from 'react-responsive';
interface IProps {
  expiredDateCheckbox: boolean;
  nftCheckbox: boolean;
  holders: IHolder[];
  getListHoder?: () => void;
  isLoadMore?: boolean;
}
class HolderComponent extends React.Component<IProps> {
  dateSort = (a: IHolder, b: IHolder) => {
    const date1 = new Date(a.expireSoon).getTime();
    const date2 = new Date(b.expireSoon).getTime();
    return date1 - date2;
  };

  topNFTSort = (a: IHolder, b: IHolder) => {
    return Number(b.nfts) - Number(a.nfts);
  };
  filterHolder = memoizeOne((holders: IHolder[], nftCheckbox, dateCheckbox) => {
    const array = holders && [...holders];
    if (nftCheckbox || dateCheckbox) {
      const sort = nftCheckbox ? this.topNFTSort : this.dateSort;
      return array.sort(sort);
    }
    return holders;
  });
  render() {
    const { expiredDateCheckbox, nftCheckbox, holders } = this.props;
    const filterHolder = this.filterHolder(
      holders,
      nftCheckbox,
      expiredDateCheckbox
    );
    return (
      <Grid container data-test="holder">
        {filterHolder && filterHolder.length ? (
          filterHolder.map((el, index) => (
            <Grid key={index} style={{ width: '100%', marginBottom: 12 }}>
              <HolderInfoComponent holder={el} />
            </Grid>
          ))
        ) : (
          <Paper
            elevation={0}
            style={{
              marginTop: 20,
              height: 'calc(60px + 1vmin)',
              width: '100%',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center'
            }}
          >
            <Typography variant="h2">No data</Typography>
          </Paper>
        )}
        <MediaQuery maxDeviceWidth={760}>
          {this.props.isLoadMore && (
            <Grid
              container
              style={{ flexDirection: 'column', marginTop: 30 }}
              alignItems={'center'}
            >
              <Typography
                variant="h4"
                style={{ color: '#00AFC1', fontSize: 14 }}
                onClick={this.props.getListHoder}
              >
                Load more...
              </Typography>
            </Grid>
          )}
        </MediaQuery>
      </Grid>
    );
  }
}

export default HolderComponent;
