export const INIT_AXIOS_INTERCEPTOR = 'INIT_AXIOS_INTERCEPTOR';
export type INIT_AXIOS_INTERCEPTOR = typeof INIT_AXIOS_INTERCEPTOR;

export enum ROLE {
  ADMIN = 'Admin',
  AGENT = 'Agent',
  TRADING = 'Trading'
}

export const REFRESH_TOKEN = 'REFRESH_TOKEN';
export type REFRESH_TOKEN = typeof REFRESH_TOKEN;

export const REFRESH_TOKEN_TIME = 60 * 60 * 1000; // 1h

export enum ACCOUNT_TYPE {
  AGENT = 'Agent',
  TRADER = 'Trader'
}
