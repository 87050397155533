import { createStyles, IconButton, Typography, Icon } from '@material-ui/core';
import { WithStyles, withStyles } from '@material-ui/styles';
import React from 'react';

const pagination = createStyles({
  root: {
    position: 'absolute',
    right: 10,
    display: 'flex'
  },
  button: {
    width: 30,
    height: 30,
    backgroundColor: '#FFFFFF',
    border: '1px solid #F2F5F8',
    boxSizing: 'border-box',
    borderRadius: 3,
    margin: '0 5px',
    padding: 0
  },
  text: {
    width: 30,
    height: 30,
    backgroundColor: '#FFFFFF',
    margin: '0 3px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  }
});

interface IPagination extends WithStyles<typeof pagination> {
  count: number;
  rowsPerPage: number;
  page: number;
  onChangePage: (
    event: React.MouseEvent<HTMLButtonElement> | null,
    page: number
  ) => void;
}

class PaginationComponent extends React.Component<IPagination> {
  render() {
    const { count, rowsPerPage, page, onChangePage, classes } = this.props;
    return (
      <div className={classes.root} style={{ flexShrink: 0 }}>
        <IconButton
          className={classes.button}
          disabled={page === 0}
          aria-label="First Page"
          // tslint:disable-next-line
          onClick={() => onChangePage(null, 0)}
        >
          <Icon>first_page</Icon>
        </IconButton>

        <IconButton
          className={classes.button}
          disabled={page === 0}
          aria-label="Previous Page"
          // tslint:disable-next-line
          onClick={() => onChangePage(null, page - 1)}
        >
          <Icon>chevron_left</Icon>
        </IconButton>
        <Typography
          variant="subtitle2"
          className={classes.text}
          component="span"
        >
          {page + 1}
        </Typography>
        <IconButton
          className={classes.button}
          disabled={page >= Math.ceil(count / rowsPerPage) - 1}
          aria-label="Next Page"
          // tslint:disable-next-line
          onClick={() => onChangePage(null, page + 1)}
        >
          <Icon>chevron_right</Icon>
        </IconButton>

        <IconButton
          className={classes.button}
          disabled={page >= Math.ceil(count / rowsPerPage) - 1}
          aria-label="Last Page"
          // tslint:disable-next-line
          onClick={() =>
            onChangePage(null, Math.max(0, Math.ceil(count / rowsPerPage) - 1))
          }
        >
          <Icon>last_page</Icon>
        </IconButton>
      </div>
    );
  }
}
export default withStyles(pagination)(PaginationComponent);
