import React from 'react';
import {
  Slider,
  Grid,
  Typography,
  withStyles,
  WithStyles,
  createStyles
} from '@material-ui/core';
import SliderTooltip from './slider.tooltip.component';
import RankTooltip from './tooltip.component';
import { IStoreState } from '../../types';
import { IConfigInfo, IPaymentSumary } from '../../scenes/dashboard/types';
import { connect } from 'react-redux';
import { formatNumberToFixed } from '../../helper';

const styles = createStyles({
  input: {
    textAlign: 'right'
  },
  icon: {
    padding: '6px 0 7px'
  }
});

interface IProps extends WithStyles<typeof styles> {
  rankInfo: IConfigInfo[];
  tomoPrice: IConfigInfo;
  paymentSumary: IPaymentSumary;
}

class SliderComponent extends React.Component<IProps> {
  caculateValueSlider = () => {
    const { paymentSumary, rankInfo } = this.props;
    const value =
      ((paymentSumary.currentTotal / paymentSumary.nextTotal) *
        (rankInfo.length ? rankInfo[3].rate : 25)) /
        5 +
      paymentSumary.currentLevel;
    return value;
  };

  createMarks = (tomoPrice: number, rankInfo: IConfigInfo[]) => [
    {
      label: (
        <RankTooltip
          totalNeed={
            rankInfo.length
              ? formatNumberToFixed(
                  rankInfo[0].amount / tomoPrice,
                  8
                ).toString()
              : '0'
          }
          rank={'Rank 1'}
          value={rankInfo.length ? `${rankInfo[0].rate}%` : '10%'}
        />
      ),
      value: rankInfo.length ? rankInfo[0].rate : 10
    },
    {
      label: (
        <RankTooltip
          totalNeed={
            rankInfo.length
              ? formatNumberToFixed(
                  rankInfo[1].amount / tomoPrice,
                  8
                ).toString()
              : '0'
          }
          rank={'Rank 2'}
          value={rankInfo.length ? `${rankInfo[1].rate}%` : '15%'}
        />
      ),
      value: rankInfo.length ? rankInfo[1].rate : 15
    },
    {
      label: (
        <RankTooltip
          totalNeed={
            rankInfo.length
              ? formatNumberToFixed(
                  rankInfo[2].amount / tomoPrice,
                  8
                ).toString()
              : '0'
          }
          rank={'Rank 3'}
          value={rankInfo.length ? `${rankInfo[2].rate}%` : '20%'}
        />
      ),
      value: rankInfo.length ? rankInfo[2].rate : 20
    },
    {
      label: (
        <RankTooltip
          totalNeed={
            rankInfo.length
              ? formatNumberToFixed(
                  rankInfo[3].amount / tomoPrice,
                  8
                ).toString()
              : '0'
          }
          rank={'Rank 4'}
          value={rankInfo.length ? `${rankInfo[3].rate}%` : '25%'}
        />
      ),
      value: rankInfo.length ? rankInfo[3].rate : 25
    }
  ];

  render() {
    const { paymentSumary, tomoPrice, rankInfo } = this.props;
    const price = tomoPrice ? tomoPrice.amount : 1;
    return (
      <React.Fragment>
        <Grid container>
          <Grid
            item
            style={{
              width: '100%',
              height: 32,
              paddingLeft: 10,
              paddingRight: 15,
              borderRadius: 20
            }}
          >
            <Slider
              min={rankInfo.length ? rankInfo[0].rate : 10}
              ThumbComponent="track"
              max={rankInfo.length ? rankInfo[rankInfo.length - 1].rate : 25}
              radioGroup="group"
              marks={this.createMarks(price, rankInfo)}
              value={this.caculateValueSlider()}
              ValueLabelComponent={SliderTooltip}
            />
          </Grid>
        </Grid>
        <Grid container style={{ marginTop: 30 }}>
          <Grid item xs={12} style={{ paddingTop: 20 }}>
            {' '}
            <Typography variant="subtitle2" style={{ color: '#F9AA62' }}>
              {`Next level: ${paymentSumary.nextLevel}%`}
            </Typography>
          </Grid>
          <Grid item xs={12} style={{ marginTop: 3 }}>
            {' '}
            <Typography variant="subtitle2">
              {`${formatNumberToFixed(
                paymentSumary.nextTotal - paymentSumary.currentTotal,
                2
              )} TOMO more to earn ${paymentSumary.nextLevel}% commission`}
            </Typography>
          </Grid>
        </Grid>
      </React.Fragment>
    );
  }
}

export const mapStateToProps = ({
  rankInfo,
  paymentSumary,
  tomoPrice
}: IStoreState) => {
  return {
    rankInfo,
    paymentSumary,
    tomoPrice
  };
};

export const mapDispatchToProps = () => ({});
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(SliderComponent));
