import React from 'react';
import { createStyles, WithStyles, withStyles, Grid } from '@material-ui/core';
import ListCardPaymentHistoryAdminComponent from '../../../components/ListCardPaymentHistoryAdmin.component';
import { History } from 'history';
import { withRouter } from 'react-router';
import { connect } from 'react-redux';

const styles = () => createStyles({});
interface IProps extends WithStyles<typeof styles> {
  history: History;
}

class PaymentHistoryAdmin extends React.Component<IProps> {
  public render() {
    return (
      <div>
        <Grid container>
          <ListCardPaymentHistoryAdminComponent history={this.props.history} />
        </Grid>
      </div>
    );
  }
}

export default withRouter(
  connect(undefined, undefined)(withStyles(styles)(PaymentHistoryAdmin))
);
