export const ADD_TRADING_ACCOUNT = 'ADD_TRADING_ACCOUNT';
export type ADD_TRADING_ACCOUNT = typeof ADD_TRADING_ACCOUNT;

export const ADD_AGENT_ACCOUNT = 'ADD_AGENT_ACCOUNT';
export type ADD_AGENT_ACCOUNT = typeof ADD_AGENT_ACCOUNT;

export const GET_ACCOUNT = 'GET_ACCOUNT';
export type GET_ACCOUNT = typeof GET_ACCOUNT;

export const SET_ACCOUNT = 'SET_ACCOUNT';
export type SET_ACCOUNT = typeof SET_ACCOUNT;
export const GET_HIDDEN_RATE = 'GET_HIDDEN_RATE';
export type GET_HIDDEN_RATE = typeof GET_HIDDEN_RATE;

export const SET_HIDDEN_RATE = 'SET_HIDDEN_RATE';
export type SET_HIDDEN_RATE = typeof SET_HIDDEN_RATE;

export const ADD_HIDDEN_RATE = 'ADD_HIDDEN_RATE';
export type ADD_HIDDEN_RATE = typeof ADD_HIDDEN_RATE;

export const GET_PROFIT_RATE = 'GET_PROFIT_RATE';
export type GET_PROFIT_RATE = typeof GET_PROFIT_RATE;

export const SET_PROFIT_RATE = 'SET_PROFIT_RATE';
export type SET_PROFIT_RATE = typeof SET_PROFIT_RATE;
