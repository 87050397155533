import React from 'react';
import { Grid, Typography, Button } from '@material-ui/core';
import { INFTPayment } from '../types';
import MediaQuery from 'react-responsive';

interface IProps {
  nfts: INFTPayment[];
  handleDialog: (isShow: boolean) => void;
}

class NftHeader extends React.PureComponent<IProps> {
  handleViewAll = () => this.props.handleDialog(true);
  render() {
    return (
      <Grid container style={{ marginTop: 34 }}>
        <Typography
          variant="subtitle2"
          style={{
            color: '#000000',
            display: 'flex',
            alignItems: 'center'
          }}
        >
          {' '}
          List NFT
        </Typography>
        <MediaQuery minDeviceWidth={768}>
          <Button
            data-test="nft-viewall"
            color="primary"
            style={{
              marginLeft: 12,
              display:
                this.props.nfts && this.props.nfts.length >= 3
                  ? 'inherit'
                  : 'none'
            }}
            disabled={this.props.nfts && this.props.nfts.length < 3}
            onClick={this.handleViewAll}
          >
            <Typography
              variant="subtitle2"
              style={{ textTransform: 'none', color: '#9BA0A6' }}
            >
              View All
            </Typography>
          </Button>
        </MediaQuery>
      </Grid>
    );
  }
}

export default NftHeader;
