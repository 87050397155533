import React, { Dispatch } from 'react';
import { withStyles, WithStyles, Theme } from '@material-ui/core/styles';
import Dialog from '@material-ui/core/Dialog';
import {
  createStyles,
  Grid,
  Button,
  Typography,
  TextField,
  Paper
} from '@material-ui/core';
import { IStoreState } from '../../types';
import { ISecondFactorCode } from '../../scenes/setting/types';
import { connect } from 'react-redux';
import INotificationInfo from '../../types/Notification.type';
import {
  fireNotification,
  IUpdateNotification
} from '../../actions/Notification.action';
import { MESSAGE_NOTIFICATION_ERROR } from '../../constants/Notification.constant';
import Variant from '../../types/Variant.type';

interface IProps extends WithStyles<typeof styles> {
  showDialog: boolean;
  handleCloseDialog: () => void;
  setSecondFactorCode: (code: ISecondFactorCode) => void;
  fireNotification: (noti: INotificationInfo) => void;
  code: string;
}

interface IState {
  code: string;
}
const styles = (theme: Theme) =>
  createStyles({
    dialogTitle: {
      height: 100,
      padding: 0
    },
    closeButton: {
      position: 'absolute',
      right: 30,
      top: 28,
      color: 'black'
    },
    dialogContent: {
      height: 'calc(100% - 310px )',
      paddingLeft: 30,
      paddingRight: 30,
      borderBottom: '1px solid #F1F1F4'
    },
    dialogActions: {
      height: 100,
      borderTop: '1px solid #DCE0E4'
    },
    buttonYesWithdraw: {
      width: 255,
      height: 50,
      textTransform: 'capitalize'
    },
    buttonNoWithdraw: {
      width: 255,
      height: 50,
      textTransform: 'capitalize',
      backgroundColor: '#C5021C',
      '&:hover': {
        backgroundColor: '#C5021C'
      }
    },
    textWithDraw: {
      fontSize: 20,
      color: '#000000',
      lineHeight: '24px',
      fontWeight: 600
    },
    code: {
      textAlign: 'center',
      color: '#222222',
      fontFamily: 'Montserrat',
      fontStyle: 'normal',
      fontWeight: 'normal',
      fontSize: 40,
      lineHeight: '40px',
      letterSpacing: '0.3em',
      [theme.breakpoints.down('xs')]: {
        fontSize: 20
      }
    },
    buttonCanel: {
      color: '#222222',
      border: '1px solid #DCE0E4',
      width: 195,
      textTransform: 'capitalize',
      marginRight: 5,
      backgroundColor: '#FFFFFF',
      '&:hover': {
        backgroundColor: '#FFFFFF'
      },
      [theme.breakpoints.down('xs')]: {
        flex: 1
      }
    },
    buttonLogin: {
      width: 195,
      textTransform: 'capitalize',
      marginLeft: 5,
      [theme.breakpoints.down('xs')]: {
        flex: 1
      }
    },
    textAllYou: {
      color: '#222222',
      fontWeight: 500
    },
    textGoogle: {
      color: '#00AFC1',
      fontWeight: 500,
      cursor: 'pointer'
    },
    imgQrcode: {
      width: 200,
      height: 200,
      border: '1px solid #DCE0E4',
      padding: 15,
      boxSizing: 'border-box',
      borderRadius: 3,
      backgroundColor: '#FAFAFB'
    },
    rootLogin: {
      padding: '0 25px'
    },
    titleHeader: {
      height: 100,
      [theme.breakpoints.only('md')]: {
        height: 60
      },
      borderBottom: '1px solid #F1F1F4'
    },
    bodyPager: {
      height: 'calc(100% - 220px )'
    },
    dialogLayout: {
      height: '100%',
      [theme.breakpoints.down('sm')]: {
        padding: '0 20px'
      }
    },
    textFiled: {
      marginTop: 23,
      height: 70,
      [theme.breakpoints.down('xs')]: {
        height: 40
      }
    },
    inputFiled: {
      height: '100%',
      backgroundColor: '#FAFAFB'
    }
  });

class OffTwoFactorDialog extends React.Component<IProps, IState> {
  public state: IState = {
    code: ''
  };

  public handleTextChange = (event: any) => {
    const code: string = event.target.value;
    if (code.match(/\D/g) || code.length > 6) {
      return;
    }
    this.setState({ code });
  };

  verifyCode = () => {
    if (this.state.code === this.props.code) {
      return this.props.fireNotification({
        message: MESSAGE_NOTIFICATION_ERROR.VERIFY_CODE_INVALID,
        variant: Variant.ERROR
      });
    }
    this.props.setSecondFactorCode({ code: this.state.code, off: true });
    this.clearText();
  };

  clearText = () => {
    this.setState({ code: '' });
  };

  closeDialog = () => {
    this.clearText();
    this.props.handleCloseDialog();
  };

  public render() {
    const { showDialog, classes } = this.props;
    return (
      <Dialog
        className={classes.dialogLayout}
        onClose={this.closeDialog}
        aria-labelledby="customized-dialog-title"
        open={showDialog}
        maxWidth="md"
      >
        <Paper elevation={1} className={classes.rootLogin}>
          <Grid
            container
            alignItems="center"
            justify="center"
            className={classes.titleHeader}
          >
            <Typography style={{ textAlign: 'center' }} variant="h2">
              Two-Factor Authentication
            </Typography>
          </Grid>
          <Grid
            container
            justify="center"
            alignContent="center"
            className={classes.bodyPager}
          >
            <Typography
              style={{ marginTop: 30, textAlign: 'center' }}
              variant="subtitle2"
            >
              Enter the two-step Authentication code
            </Typography>

            <TextField
              className={classes.textFiled}
              autoFocus={true}
              InputProps={{
                className: classes.inputFiled
              }}
              value={this.state.code}
              inputProps={{
                className: classes.code
              }}
              placeholder="_ _ _ _ _ _"
              onChange={this.handleTextChange}
              variant="outlined"
            />
          </Grid>
          <Grid
            container
            justify="center"
            alignItems="center"
            style={{ borderTop: '1px solid #F1F1F4', height: 120 }}
          >
            <Button
              variant="contained"
              color="primary"
              size="large"
              className={classes.buttonCanel}
              onClick={this.closeDialog}
            >
              <span style={{ color: '#222222' }}> Cancel</span>
            </Button>
            <Button
              variant="contained"
              color="primary"
              size="large"
              disabled={this.state.code.length !== 6}
              className={classes.buttonLogin}
              onClick={this.verifyCode}
            >
              Verify
            </Button>
          </Grid>
        </Paper>
      </Dialog>
    );
  }
}

const mapStateToProps = ({ code }: IStoreState) => {
  return { code };
};

const mapDispatchToProps = (dispatch: Dispatch<IUpdateNotification>) => {
  return {
    fireNotification: (noti: INotificationInfo) =>
      dispatch(fireNotification(noti))
  };
};
export default withStyles(styles)(
  connect(mapStateToProps, mapDispatchToProps)(OffTwoFactorDialog)
);
