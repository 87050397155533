import React from 'react';
import {
  Paper,
  Grid,
  Typography,
  Theme,
  createStyles,
  WithStyles,
  withStyles
} from '@material-ui/core';
import iconAddTrading from '../assets/images/iconAddTrading.svg';

const styles = (theme: Theme) =>
  createStyles({
    root: {
      height: 290,
      width: '100%',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      cursor: 'pointer',
      [theme.breakpoints.down('sm')]: {
        height: 180
      }
    }
  });
interface IProps extends WithStyles<typeof styles> {
  handleDialog: () => void;
  text: string;
}
class AddAccount extends React.Component<IProps> {
  render() {
    const { classes } = this.props;
    return (
      <Paper
        elevation={0}
        className={classes.root}
        onClick={this.props.handleDialog}
      >
        <Grid
          container
          style={{ flexDirection: 'column', alignItems: 'center' }}
        >
          <Grid style={{ flex: 1, paddingBottom: 5 }}>
            <img
              src={iconAddTrading}
              width={40}
              height={40}
              alt="icon add trading"
            />
          </Grid>
          <Grid style={{ flex: 1, paddingTop: 5 }}>
            <Typography variant="subtitle1">{this.props.text}</Typography>
          </Grid>
        </Grid>
      </Paper>
    );
  }
}

export default withStyles(styles)(AddAccount);
