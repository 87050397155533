import React from 'react';
import {
  Theme,
  createStyles,
  WithStyles,
  withStyles,
  Grid
} from '@material-ui/core';
import CardTradingComponent from './CardTrading.component';
import AddTradingDialogComponent from './dialog/AddTrading.dialog.component';
import AddAccount from './AddAccount.component';
import { Dispatch } from 'redux';
import { getAccountAction } from '../scenes/admin/components/Top/actions';
import { IStoreState, IParams } from '../types';
import { connect } from 'react-redux';
import { IUserProfile } from '../scenes/setting/types';
import { ROLE } from '../constants/auth.constant';
import memoizeOne from 'memoize-one';

const styles = (theme: Theme) =>
  createStyles({
    root: {
      paddingRight: 10,
      [theme.breakpoints.down('sm')]: {
        paddingRight: 0
      }
    },
    trading: {
      marginBottom: 10,
      paddingRight: 10,
      width: '100%',
      [theme.breakpoints.down('sm')]: {
        paddingRight: 0
      }
    }
  });
interface IProps extends WithStyles<typeof styles> {
  getTradingAccount: (type: IParams) => void;
  tradings: IUserProfile[];
}
interface IState {
  showDialogAddTrading: boolean;
}

class ListTrading extends React.Component<IProps, IState> {
  public state: IState = {
    showDialogAddTrading: false
  };

  componentDidMount() {
    this.props.getTradingAccount({ type: ROLE.TRADING });
  }

  public handleCloseDialog = () => {
    this.setState({
      showDialogAddTrading: false
    });
  };
  public handleShowDialog = () => {
    this.setState({
      showDialogAddTrading: true
    });
  };

  getTradingActive = memoizeOne((tradings: IUserProfile[]) => {
    const data = [...tradings];
    return data.filter(
      e => (e.traderMargin && e.traderMargin[0].account) || e.description
    );
  });

  public render() {
    const { tradings, classes } = this.props;
    const filterUsers = this.getTradingActive(tradings);
    return (
      <React.Fragment>
        <Grid container className={classes.root}>
          {filterUsers &&
            filterUsers.map((el, key) => (
              <Grid item lg={6} md={12} key={key} className={classes.trading}>
                <CardTradingComponent user={el} />
              </Grid>
            ))}
          <Grid item md={12} lg={6} className={classes.trading}>
            <AddAccount
              handleDialog={this.handleShowDialog}
              text={'ADD NEW TRADING'}
            />
          </Grid>
        </Grid>
        <AddTradingDialogComponent
          title={'Add new Trader'}
          showDialog={this.state.showDialogAddTrading}
          handleCloseDialog={this.handleCloseDialog}
        />
      </React.Fragment>
    );
  }
}

const mapDispatchToProps = (dispatch: Dispatch) => ({
  getTradingAccount: (type: IParams) => dispatch(getAccountAction(type))
});

const mapStateToProps = ({ accounts }: IStoreState) => ({
  tradings: accounts.Trading.data
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(ListTrading));
