import React from 'react';
import { Dialog, Grid } from '@material-ui/core';
import TitlePageComponent from '../TitlePage.component';
import TableComponent from '../shared/table';
import { formatDate } from '../../helper';
import { ITableSettings } from '../shared/table/redux/types';

const tableSettings: ITableSettings = {
  pagination: {
    show: true,
    option: {
      rowsPerPage: 10
    }
  },
  tableColumn: [
    {
      label: 'Name',
      key: `name`,
      width: '25%',
      styles: {
        fontFamily: 'Montserrat',
        fontWeight: '600',
        fontSize: '12px',
        lineHeight: '12px',
        color: '#222222'
      }
    },
    {
      label: 'Join Date',
      key: `joinDate`,
      width: '25%',
      format: formatDate,
      styles: {
        fontFamily: 'Montserrat',
        fontWeight: '500',
        fontSize: '14px',
        lineHeight: '17px',
        color: '#222222'
      }
    },
    {
      label: 'NFTs',
      key: `nfts`,
      postFix: 'NFT',
      width: '15%',
      styles: {
        fontFamily: 'Montserrat',
        fontWeight: '500',
        fontSize: '12px',
        lineHeight: '15px',
        color: '#00AFC1'
      }
    },
    {
      label: 'Expired Date',
      key: `expireSoon`,
      format: formatDate,
      width: '25%',
      styles: {
        fontFamily: 'Montserrat',
        fontWeight: '600',
        fontSize: '12px',
        lineHeight: '12px',
        color: '#222222'
      }
    }
  ]
};
interface IProps {
  showListHolder: boolean;
  handleCloseDialog: () => void;
}

const params = {
  limit: 10,
  offset: 0
};
class ListHolderComponent extends React.Component<IProps> {
  renderTable = () => {
    return (
      <TableComponent
        settings={tableSettings}
        api="/users/holders"
        params={params}
      />
    );
  };
  render() {
    return (
      <Dialog
        data-test="holder-dialog"
        fullWidth
        maxWidth="lg"
        BackdropProps={{
          style: {
            backgroundColor: 'rgba(0, 0, 0, 0.8)'
          }
        }}
        open={this.props.showListHolder}
        onClose={this.props.handleCloseDialog}
      >
        <Grid
          style={{
            padding: '0px 60px'
          }}
        >
          <Grid
            container
            style={{
              marginBottom: 5,
              paddingTop: 20,
              display: 'flex',
              alignItems: 'center'
            }}
          >
            <Grid
              item
              style={{ justifyContent: 'flex-start', display: 'flex' }}
            >
              <TitlePageComponent title="List Holder" />
            </Grid>
          </Grid>
          <Grid container style={{ marginTop: 20 }}>
            <Grid item style={{ paddingRight: 10, width: '100%' }}>
              {this.renderTable()}
            </Grid>
          </Grid>
        </Grid>
      </Dialog>
    );
  }
}

export default ListHolderComponent;
