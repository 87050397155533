import React from 'react';
import {
  Typography,
  Grid,
  Button,
  createStyles,
  Theme,
  WithStyles,
  withStyles
} from '@material-ui/core';
import error from '../assets/images/404.svg';
import { History } from 'history';
import { PATH } from '../constants/Path.constant';
import { ROLE } from '../constants/auth.constant';

const styles = (theme: Theme) =>
  createStyles({
    backgroundOpacitySignup: {
      margin: ' 0px 60px',
      height: `calc(100vh - 134px)`,
      position: 'relative',
      alignItems: 'center',
      justifyContent: 'center',
      display: 'flex',
      flexDirection: 'column',
      flexWrap: 'nowrap',
      [theme.breakpoints.down('xs')]: {
        margin: '0 auto',
        height: `calc(100vh - 114px)`
      }
    },
    text: {
      color: '#091628',
      lineHeight: '20px',
      fontSize: 16,
      fontWeight: 500,
      textAlign: 'center',
      [theme.breakpoints.down('xs')]: {
        fontSize: 12
      }
    },
    img: {
      [theme.breakpoints.down('xs')]: {
        width: '80%'
      }
    },
    headerText: {
      color: '#00AFC1',
      lineHeight: '49px',
      fontSize: 40,
      textAlign: 'center',
      textTransform: 'capitalize',
      [theme.breakpoints.down('xs')]: {
        fontSize: 26,
        lineHeight: '30px'
      }
    },
    button: {
      width: 400,
      height: 50,
      [theme.breakpoints.down('xs')]: {
        width: '80%'
      }
    },
    buttonLayout: {
      marginTop: 80,

      [theme.breakpoints.down('xs')]: {
        width: '100%',
        marginTop: 60,
        display: 'flex',
        justifyContent: 'center'
      }
    }
  });

interface IProps extends WithStyles<typeof styles> {
  history: History;
}

const role = localStorage.getItem('role');
class NotFound extends React.Component<IProps> {
  goHomePage = () => {
    role && role === ROLE.AGENT
      ? this.props.history.push(PATH.agent.dashboard)
      : this.props.history.push(PATH.admin.top);
  };

  render() {
    const { classes } = this.props;
    return (
      <Grid className={classes.backgroundOpacitySignup}>
        <img className={classes.img} src={error} alt={'Not found'} />
        <Grid item>
          <Typography variant="h1" className={classes.headerText}>
            Oops Where are we?
          </Typography>
        </Grid>
        <Grid item style={{ marginTop: 30 }}>
          <Typography variant="h4" className={classes.text}>
            The page you are looking for was moved, removed, renamed or
          </Typography>
          <Typography variant="h4" className={classes.text}>
            might never existed
          </Typography>
        </Grid>
        <Grid item className={classes.buttonLayout}>
          <Button
            variant="contained"
            color="primary"
            onClick={this.goHomePage}
            className={classes.button}
          >
            Go home
          </Button>
        </Grid>
      </Grid>
    );
  }
}

export const NotFoundComponent = withStyles(styles)(NotFound);
