import { ACCOUNT_TYPE } from '../../../constants/auth.constant';

export interface ILoginRequest {
  username: string;
  password: string;
  authenticationType?: string;
}

export interface IResetPassword {
  repassword: string;
  password: string;
  token: string;
  type?: ACCOUNT_TYPE;
}

export interface IChangePasswordRequest {
  newPassword: string;
  reNewPassword: string;
  password: string;
  code?: string;
}
export interface IToken {
  token: string;
  refreshToken: string;
  exp: string;
}
export interface IError {
  isError: boolean;
  errorMsg: string;
}

export interface IVerifyCode {
  username: string;
  password: string;
  code: string;
}
export const initToken: IToken = {
  token: '',
  refreshToken: '',
  exp: ''
};

export interface IRefreshTokenRequest {
  refreshToken: string;
}

export interface IRoleId {
  id: string;
  name: string;
  createdAt: string;
  updatedAt: string;
}
