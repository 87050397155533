import Axios from 'axios';
import { IStoreState } from '../../../types';
import { Dispatch, Store } from 'redux';
import {
  setPaymentHistoryAction,
  IGetRankInfo,
  setRankInfoAction,
  IGetPaymentSumaryAction,
  IGetNftListAction,
  setNftListAction,
  IGetTomoPriceAction,
  setTomoPriceAction,
  setPaymentSumaryAction,
  IGetPaymentHistoryAction
} from '../actions/payment.action';
import {
  GET_RANK_INFO,
  GET_PAYMENT_SUMARY,
  GET_NFT_LIST,
  GET_TOMO_PRICE,
  GET_PAYMENT_HISTORY
} from '../constants/payment.constant';

export const payment = (store: Store<IStoreState>) => (
  dispatch: Dispatch<
    IGetPaymentSumaryAction | IGetNftListAction | IGetPaymentHistoryAction
  >
) => (
  action: IGetPaymentSumaryAction | IGetNftListAction | IGetPaymentHistoryAction
) => {
  switch (action.type) {
    case GET_PAYMENT_SUMARY: {
      Axios.get(`/payments/summary`)
        .then(res => {
          const data = res.data;
          store.dispatch(setPaymentSumaryAction(data));
        })
        .catch(error => console.log(error));
      break;
    }
    case GET_NFT_LIST: {
      Axios.get(`/nfts`, {
        params: {
          limit: action.payload.limit,
          offset: action.payload.offset,
          sort: action.payload.sort,
          dir: action.payload.dir
        }
      })
        .then(res => {
          const data = res.data;
          store.dispatch(setNftListAction(data));
        })
        .catch(error => console.log(error));
      break;
    }
    case GET_PAYMENT_HISTORY: {
      Axios.get(`/payments`, { params: action.payload })
        .then(res => {
          const data = res.data;
          store.dispatch(setPaymentHistoryAction(data));
        })
        .catch(error => console.log(error));
      break;
    }
  }
  return dispatch(action);
};

export const rankInfo = (store: Store<IStoreState>) => (
  dispatch: Dispatch<IGetRankInfo>
) => (action: IGetRankInfo) => {
  if (action.type === GET_RANK_INFO) {
    Axios.get(`/configs`, { params: { name: 'Term Kickback' } })
      .then(res => {
        const data = res.data;
        store.dispatch(setRankInfoAction(data));
      })
      .catch(error => console.log(error));
  }
  return dispatch(action);
};

export const tomoPrice = (store: Store<IStoreState>) => (
  dispatch: Dispatch<IGetTomoPriceAction>
) => (action: IGetTomoPriceAction) => {
  if (action.type === GET_TOMO_PRICE) {
    Axios.get(`/configs`, { params: { name: 'TOMO Price' } })
      .then(res => {
        if (res.data) {
          const data = res.data.length ? res.data[0] : null;
          store.dispatch(setTomoPriceAction(data));
        }
      })
      .catch(error => console.log(error));
  }
  return dispatch(action);
};
