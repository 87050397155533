import { IStoreState } from '../../../../../types';
import { Dispatch } from 'react';
import {
  IAddTradingAccountAction,
  IAddAgentAccountAction,
  IGetAccountAction,
  setAccountAction,
  getAccountAction,
  IGetHiddenRateAction,
  IAddHiddenRateAction,
  setHiddenRateAction,
  IGetProfitRateAction,
  setProfitRateAction,
  getHiddenRateAction
} from '../actions';
import {
  ADD_TRADING_ACCOUNT,
  ADD_AGENT_ACCOUNT,
  GET_ACCOUNT,
  GET_HIDDEN_RATE,
  GET_PROFIT_RATE,
  ADD_HIDDEN_RATE
} from '../constants';
import Axios from 'axios';
import { Store } from 'redux';
import { fireNotification } from '../../../../../actions/Notification.action';
import { MESSAGE_NOTIFICATION_SUCCESS } from '../../../../../constants/Notification.constant';
import Variant from '../../../../../types/Variant.type';
import { IHiddenRate } from '../types';
import { ROLE } from '../../../../../constants/auth.constant';
import { PATH } from '../../../../../constants/Path.constant';

export const topMiddleware = (store: Store<IStoreState>) => (
  dispatch: Dispatch<
    | IAddTradingAccountAction
    | IAddAgentAccountAction
    | IGetHiddenRateAction
    | IAddHiddenRateAction
    | IGetProfitRateAction
    | IGetAccountAction
  >
) => (
  action:
    | IAddTradingAccountAction
    | IAddAgentAccountAction
    | IGetHiddenRateAction
    | IAddHiddenRateAction
    | IGetProfitRateAction
    | IGetAccountAction
) => {
  switch (action.type) {
    case ADD_TRADING_ACCOUNT: {
      const { roleIds } = store.getState();
      const tradingRole = roleIds.find(e => e.name === 'Trading');
      Axios.post('/users', {
        email: '',
        tomoAddress: '',
        invationCode: '',
        roleId: tradingRole ? tradingRole.id : '',
        urlCallback: '',
        bitmexId: action.payload.id,
        bitmexSecret: action.payload.secret,
        name: ''
      })
        .then(() => {
          store.dispatch(
            getAccountAction({ type: tradingRole ? tradingRole.name : '' })
          );
          store.dispatch(
            fireNotification({
              message: MESSAGE_NOTIFICATION_SUCCESS.ADD_TRADING_ACCOUNT,
              variant: Variant.SUCCESS
            })
          );
        })
        .catch(err => {
          const message =
            err &&
            err.response &&
            err.response.data &&
            err.response.data.error &&
            err.response.data.error.message;
          store.dispatch(
            fireNotification({
              message,
              variant: Variant.ERROR
            })
          );
        });
      break;
    }
    case ADD_AGENT_ACCOUNT: {
      const { roleIds } = store.getState();
      const roleId = roleIds && roleIds.find(e => e.name === 'Agent');
      Axios.post('/users', {
        email: action.payload.email,
        invationCode: '',
        roleId: roleId ? roleId.id : '',
        urlCallback: `${process.env.REACT_APP_MEMBER_URL}/signup-agent`,
        bitmexId: '',
        bitmexSecret: '',
        name: '',
        tomoAddress: action.payload.tomoAddress
      })
        .then(() => {
          store.dispatch(
            fireNotification({
              message: MESSAGE_NOTIFICATION_SUCCESS.ADD_AGENT_ACCOUNT,
              variant: Variant.SUCCESS
            })
          );
          store.dispatch(
            getAccountAction({
              limit: 10,
              offset: 0,
              type: ROLE.AGENT
            })
          );
        })
        .catch(err => {
          const message =
            err &&
            err.response &&
            err.response.data &&
            err.response.data.error &&
            err.response.data.error.message;
          store.dispatch(
            fireNotification({
              message,
              variant: Variant.ERROR
            })
          );
        });
      break;
    }

    case GET_ACCOUNT: {
      Axios.get('/users', {
        params: action.payload
      })
        .then(res => {
          if (res.data) {
            store.dispatch(
              setAccountAction({
                type: action.payload.type ? action.payload.type : '',
                users: { data: res.data.data, meta: res.data.meta }
              })
            );
          }
        })
        .catch(err => {
          const statusCode =
            err &&
            err.response &&
            err.response.data &&
            err.response.data.error &&
            err.response.data.error.statusCode;
          console.log(statusCode, '=>>>>>123');
          const message =
            err &&
            err.response &&
            err.response.data &&
            err.response.data.error &&
            err.response.data.error.message;
          if (statusCode === 403 || statusCode === 401) {
            store.dispatch(
              fireNotification({
                message: statusCode === 403 ? message : 'Invalid Token',
                variant: Variant.ERROR,
                link: statusCode === 403 ? PATH.notFound : PATH.login
              })
            );
          }
        });
      break;
    }
    case GET_HIDDEN_RATE: {
      Axios.get('/hidden-rates').then(res => {
        if (res) {
          const hiddenRateInfo: IHiddenRate = res.data;
          let hiddenRate = 0;
          if (hiddenRateInfo) {
            hiddenRate = Number(hiddenRateInfo.rate);
          }
          store.dispatch(setHiddenRateAction(hiddenRate));
        }
      });
      break;
    }
    case ADD_HIDDEN_RATE: {
      Axios.post('/hidden-rates', { rate: action.payload })
        .then(res => {
          if (res) {
            store.dispatch(getHiddenRateAction());
            store.dispatch(
              fireNotification({
                message: MESSAGE_NOTIFICATION_SUCCESS.UPDATE_CONFIG,
                variant: Variant.SUCCESS
              })
            );
          }
        })
        .catch(err => {
          const message =
            err &&
            err.response &&
            err.response.data &&
            err.response.data.error &&
            err.response.data.error.message;
          store.dispatch(
            fireNotification({
              message,
              variant: Variant.ERROR
            })
          );
        });
      break;
    }
    case GET_PROFIT_RATE: {
      Axios.get('/profit-rates').then(res => {
        if (res.data) {
          store.dispatch(setProfitRateAction(res.data));
        }
      });
      break;
    }
  }
  return dispatch(action);
};
