import React from 'react';
import {
  Grid,
  Typography,
  Button,
  FormControlLabel,
  Checkbox,
  createStyles,
  WithStyles
} from '@material-ui/core';
import { withStyles } from '@material-ui/styles';
import classNames from 'classnames';
import { IHolder } from '../types';
import MediaQuery from 'react-responsive';

const styles = createStyles({
  icon: {
    background: '#F9F9FA',
    border: '1px solid #EAEAEA',
    width: 14,
    height: 14,
    borderRadius: 3
  },
  checkedIcon: {
    width: 14,
    borderRadius: 3,
    background: '#F9F9FA',
    border: '1px solid #EAEAEA',
    height: 14,
    position: 'relative',
    '&:before': {
      width: 8,
      borderRadius: 1,
      transform: 'translate(-50%,-50%)',
      position: 'absolute',
      top: '50%',
      left: '50%',
      height: 8,
      background: '#00AFC1',
      content: '""'
    }
  }
});

interface IProps extends WithStyles<typeof styles> {
  expiredDateCheckbox: boolean;
  nftCheckbox: boolean;
  holders: IHolder[];
  handleDialog: (isShow: boolean) => () => void;
  handleChange: (
    key: string,
    resetKey: string
  ) => (event: React.ChangeEvent<HTMLInputElement>, checked: boolean) => void;
}

class HolderHeader extends React.Component<IProps> {
  render() {
    const {
      classes,
      nftCheckbox,
      expiredDateCheckbox,
      handleChange,
      handleDialog,
      holders
    } = this.props;
    return (
      <>
        <Grid container style={{ flexDirection: 'row' }}>
          <Grid
            item
            xs={4}
            sm={6}
            style={{
              alignItems: 'center',
              flexDirection: 'row',
              display: 'flex'
            }}
          >
            <Typography variant="subtitle2">Holders</Typography>
            <MediaQuery minDeviceWidth={768}>
              <Button
                data-test="holder-viewall"
                color="primary"
                style={{
                  marginLeft: 10,
                  display:
                    this.props.holders && this.props.holders.length >= 10
                      ? 'inherit'
                      : 'none'
                }}
                disabled={holders && holders.length < 10}
                onClick={handleDialog(true)}
              >
                <Typography
                  variant="subtitle2"
                  style={{
                    textTransform: 'none',
                    color: '#9BA0A6',
                    display: holders && holders.length ? 'inherit' : 'none'
                  }}
                >
                  View All
                </Typography>
              </Button>
            </MediaQuery>
          </Grid>
          <Grid
            item
            xs={8}
            sm={6}
            style={{
              alignItems: 'center',
              justifyContent: 'flex-end',
              display: holders && holders.length ? 'flex' : 'none'
            }}
          >
            <FormControlLabel
              control={
                <Checkbox
                  checkedIcon={
                    <span className={classNames(classes.checkedIcon)} />
                  }
                  icon={<span className={classes.icon} />}
                  checked={nftCheckbox}
                  onChange={handleChange('nftCheckbox', 'expiredDateCheckbox')}
                  value="checked"
                />
              }
              label="Top"
            />
            <FormControlLabel
              control={
                <Checkbox
                  checkedIcon={
                    <span className={classNames(classes.checkedIcon)} />
                  }
                  icon={<span className={classes.icon} />}
                  checked={expiredDateCheckbox}
                  onChange={handleChange('expiredDateCheckbox', 'nftCheckbox')}
                  value="checkedA"
                />
              }
              style={{ marginRight: 0 }}
              label="Expire soon"
            />
          </Grid>
        </Grid>
      </>
    );
  }
}

export default withStyles(styles)(HolderHeader);
