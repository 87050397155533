import { IUserProfile } from '../../setting/types';
import {
  ITableSettings,
  IMetaData
} from '../../../components/shared/table/redux/types';
import { formatDate, formatNumberToFixed } from '../../../helper';

export interface IHolder {
  joinDate: string;
  name: string;
  nfts: string;
  expireSoon: string;
}

export interface IHolderContainer {
  data: IHolder[];
  meta: IMetaData;
}
export interface IHolderRequest {
  limit: number;
  offset: number;
}

export interface IPaymentSumary {
  activeNFT: number;
  activeProfit: number;
  activeNFTVolume: number;
  totalNFT: number;
  totalProfit: number;
  totalNFTVolume: number;
  currentLevel: number;
  currentTotal: number;
  nextLevel: number;
  nextTotal: number;
}

export interface INFTPayment {
  id: string;
  userId: string;
  nftId: string;
  boughtDate: string;
  expireDate: string;
  active: boolean;
  currentTemProfit: number;
  totalProfit: number;
  nextGainDate: string;
  createdAt: string;
  updatedAt: string;
  user: IUserProfile;
  nft: INft;
}

export interface INftData {
  data: INFTPayment[];
  meta: IMetaData;
}
export interface INft {
  id: string;
  name: string;
  nftNo: string;
  txHash: string;
  description: string;
  surrenderCharge: number;
  administratorCharge: number;
  assetFee: number;
  ethAddress: string;
  priceTomo: number;
  price: number;
  createdAt: string;
  updatedAt: string;
}

export const initPaymentSumary: IPaymentSumary = {
  activeNFT: 0,
  activeProfit: 0,
  activeNFTVolume: 0,
  totalNFT: 0,
  totalProfit: 0,
  totalNFTVolume: 0,
  currentLevel: 0,
  currentTotal: 0,
  nextLevel: 0,
  nextTotal: 0
};

export interface INftRequest {
  limit?: number;
  offset?: number;
  sort?: string;
  dir?: string;
}

export const initNft: INft = {
  id: '',
  name: '',
  nftNo: '',
  txHash: '',
  description: '',
  surrenderCharge: 0,
  administratorCharge: 0,
  assetFee: 0,
  ethAddress: '',
  priceTomo: 0,
  price: 0,
  createdAt: '',
  updatedAt: ''
};

export interface IConfigInfo {
  id: string;
  name: string;
  amount: number;
  rate: number;
  withAgent: boolean;
}

export const initConfigInfo: IConfigInfo = {
  id: '',
  name: '',
  amount: 0,
  rate: 0,
  withAgent: false
};

const HEADER_TEXT = {
  fontFamily: 'Montserrat',
  fontWeight: '500',
  fontSize: '12px',
  lineHeight: '12px',
  color: '#222222'
};
export const nftTable: ITableSettings = {
  pagination: {
    show: true,
    option: {
      rowsPerPage: 10
    }
  },
  tableColumn: [
    {
      label: 'NFT No',
      key: `nft.name`,
      width: '18%',
      styles: HEADER_TEXT
    },
    {
      label: 'Bought Volume',
      key: `nft.priceTomo`,
      postFix: 'TOMO',
      format: value => formatNumberToFixed(value, 2),
      width: '18%',
      styles: {
        fontFamily: 'Montserrat',
        fontWeight: '500',
        fontSize: '12px',
        lineHeight: '15px',
        color: '#00AFC1'
      }
    },
    {
      label: 'Buyer',
      key: `user.username`,
      width: '23%',
      styles: HEADER_TEXT
    },
    {
      label: 'Join Date',
      key: 'createdAt',
      format: formatDate,
      width: '18%',
      styles: HEADER_TEXT
    },
    {
      label: 'Expired Date',
      key: 'expireDate',
      format: formatDate,
      width: '18%',
      styles: HEADER_TEXT,
      align: 'right'
    }
  ]
};

export const initMetaData: IMetaData = {
  currentPage: 0,
  limit: 0,
  offset: 0,
  pageCount: 0,
  total: 0
};

export interface IPaymentHistory {
  id: string;
  hash: string;
  transactionType: string;
  paymentDate: string;
  total: number;
  fee: number;
  complete: null;
  from: string;
  to: string;
  size: string;
  createdAt: string;
  updatedAt: string;
  nftNo: string;
}

export interface IPaymentHistoryContainer {
  data: IPaymentHistory[];
  meta: IMetaData;
}
