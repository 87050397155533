import { IAccounts } from '../../../../../types';
import { ISetAccountAction } from '../actions';
import { SET_ACCOUNT } from '../constants';
import { ISetHiddenRateAction, ISetProfitRateAction } from '../actions';
import { SET_HIDDEN_RATE, SET_PROFIT_RATE } from '../constants';
import { initMetaData } from '../../../../dashboard/types';

export const accounts = (
  state: IAccounts = {
    Admin: { data: [], meta: initMetaData },
    Agent: { data: [], meta: initMetaData },
    Trading: { data: [], meta: initMetaData }
  },
  action: ISetAccountAction
): IAccounts => {
  if (action.type === SET_ACCOUNT) {
    return {
      ...state,
      [action.payload.type]: action.payload.users
    };
  }
  return state;
};

export const hiddenRate = (
  state: number = 0,
  action: ISetHiddenRateAction
): number => {
  if (action.type === SET_HIDDEN_RATE) {
    return action.payload;
  }
  return state;
};

export const profitRate = (
  state: number = 0,
  action: ISetProfitRateAction
): number => {
  if (action.type === SET_PROFIT_RATE) {
    return action.payload;
  }
  return state;
};
