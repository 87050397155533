import React, { Dispatch } from 'react';
import { withStyles, WithStyles, Theme } from '@material-ui/core/styles';
import Dialog from '@material-ui/core/Dialog';
import {
  createStyles,
  Grid,
  Button,
  Typography,
  TextField,
  Paper
} from '@material-ui/core';
import { IStoreState } from '../../types';
import { IQrImage, ISecondFactorCode } from '../../scenes/setting/types';
import { connect } from 'react-redux';
import {
  IUpdateNotification,
  fireNotification
} from '../../actions/Notification.action';
import INotificationInfo from '../../types/Notification.type';
import { MESSAGE_NOTIFICATION_ERROR } from '../../constants/Notification.constant';
import Variant from '../../types/Variant.type';

interface IProps extends WithStyles<typeof styles> {
  showDialog: boolean;
  message?: string;
  handleCloseDialog: () => void;
  qrImage: IQrImage;
  setSecondFactorCode: (code: ISecondFactorCode) => void;
  fireNotification: (noti: INotificationInfo) => void;
  code: string;
}

interface IState {
  code: string;
}
const styles = (theme: Theme) =>
  createStyles({
    dialogTitle: {
      height: 70,
      padding: 0
    },
    closeButton: {
      position: 'absolute',
      right: 30,
      top: 28,
      color: 'black'
    },
    dialogContent: {
      height: 'calc(100% - 260px )',
      paddingLeft: 30,
      paddingRight: 30,
      borderBottom: '1px solid #F1F1F4'
    },
    dialogActions: {
      height: 100,
      borderTop: '1px solid #DCE0E4'
    },
    buttonYesWithdraw: {
      width: 255,
      height: 50,
      textTransform: 'capitalize'
    },
    buttonNoWithdraw: {
      width: 255,
      height: 50,
      textTransform: 'capitalize',
      backgroundColor: '#C5021C',
      '&:hover': {
        backgroundColor: '#C5021C'
      }
    },
    textWithDraw: {
      fontSize: 20,
      color: '#000000',
      lineHeight: '24px',
      fontWeight: 600
    },
    code: {
      textAlign: 'center',
      color: '#222222',
      fontFamily: 'Montserrat',
      fontStyle: 'normal',
      fontWeight: 'normal',
      fontSize: 40,
      lineHeight: '40px',
      letterSpacing: '0.3em',
      [theme.breakpoints.down('xs')]: {
        fontSize: 20
      }
    },
    buttonCanel: {
      color: '#222222',
      border: '1px solid #DCE0E4',
      width: 195,
      textTransform: 'capitalize',
      marginRight: 5,
      backgroundColor: '#FFFFFF',
      '&:hover': {
        backgroundColor: '#FFFFFF'
      },
      [theme.breakpoints.down('xs')]: {
        flex: 1
      }
    },
    buttonLogin: {
      width: 195,
      textTransform: 'capitalize',
      marginLeft: 5,
      [theme.breakpoints.down('xs')]: {
        flex: 1
      }
    },
    textAllYou: {
      color: '#222222',
      fontWeight: 500
    },
    textGoogle: {
      color: '#00AFC1',
      fontWeight: 500,
      cursor: 'pointer'
    },
    imgQrcode: {
      width: 200,
      height: 200,
      border: '1px solid #DCE0E4',
      padding: 15,
      boxSizing: 'border-box',
      borderRadius: 3,
      backgroundColor: '#FAFAFB'
    },
    rootDialog: {
      '& .MuiDialog-paperWidthSm': {
        height: 'auto'
      },
      [theme.breakpoints.down('sm')]: {
        width: '80%',
        padding: '0 10%'
      }
    },
    qrText: {
      fontSize: 10,
      fontWeight: 500,
      padding: '0 55px',
      wordBreak: 'break-all',
      color: '#9BA0A6',
      cursor: 'pointer',
      [theme.breakpoints.down('xs')]: {
        padding: '0 5px'
      }
    },
    qrSecrect: {
      background: '#FAFAFB',
      border: '1px solid #DCE0E4',
      boxSizing: 'border-box',
      borderRadius: 3,
      marginTop: 5,
      marginBottom: 10,
      height: 50,
      [theme.breakpoints.down('xs')]: {
        height: 60
      }
    },
    inputFiled: {
      height: '100%',
      backgroundColor: '#FAFAFB'
    },
    textFiled: {
      marginTop: 23,
      height: 70,
      [theme.breakpoints.down('xs')]: {
        height: 40
      }
    }
  });

class TwoAuthentication extends React.Component<IProps, IState> {
  public state: IState = {
    code: ''
  };

  public handleTextChange = (event: any) => {
    const code: string = event.target.value;
    if (code.length > 6 || code.match(/\D/g)) {
      return;
    }

    this.setState({ code });
  };

  clearText = () => {
    this.setState({ code: '' });
  };

  closeDialog = () => {
    this.clearText();
    this.props.handleCloseDialog();
  };

  verifyCode = () => {
    if (this.state.code === this.props.code) {
      return this.props.fireNotification({
        message: MESSAGE_NOTIFICATION_ERROR.VERIFY_CODE_INVALID,
        variant: Variant.ERROR
      });
    }
    this.props.setSecondFactorCode({ code: this.state.code, off: false });
    this.clearText();
  };

  public render() {
    const { showDialog, classes } = this.props;
    return (
      <Dialog
        onClose={this.closeDialog}
        aria-labelledby="customized-dialog-title"
        open={showDialog}
        maxWidth="sm"
        className={classes.rootDialog}
      >
        <Paper elevation={1}>
          <Grid className={classes.dialogTitle}>
            <Grid
              container
              justify="center"
              alignItems="center"
              style={{
                height: '100%',
                position: 'relative',
                borderBottom: '1px solid #DCE0E4'
              }}
            >
              <Typography style={{ textAlign: 'center' }} variant="h2">
                Two-Factor Authentication
              </Typography>
            </Grid>
          </Grid>
          <Grid
            container
            justify="center"
            alignContent="center"
            className={classes.dialogContent}
          >
            <Grid
              container
              justify="center"
              item
              xs={12}
              style={{ marginTop: 5 }}
            >
              <Typography style={{ textAlign: 'center' }} variant="subtitle2">
                Two-Factor authentication increases
              </Typography>
            </Grid>
            <Grid
              container
              justify="center"
              item
              xs={12}
              style={{ marginTop: 5 }}
            >
              <Typography style={{ textAlign: 'center' }} variant="subtitle2">
                the security of your account.
              </Typography>
            </Grid>
            <Grid
              container
              justify="center"
              item
              xs={12}
              style={{ marginTop: 14 }}
            >
              <Typography
                style={{ textAlign: 'center' }}
                variant="h4"
                className={classes.textAllYou}
              >
                All you need is a compatible app on your smartphone, for
                example:
              </Typography>
            </Grid>
            <Grid
              container
              justify="center"
              item
              xs={12}
              style={{ marginTop: 5 }}
            >
              <Typography variant="subtitle2" className={classes.textGoogle}>
                Google Authenticator
              </Typography>
            </Grid>
            <Grid
              container
              justify="center"
              item
              xs={12}
              style={{ marginTop: 19 }}
            >
              <Grid className={classes.imgQrcode}>
                <img
                  src={this.props.qrImage.dataURI}
                  alt="icon qrcode"
                  width="100%"
                  height="100%"
                />
              </Grid>
            </Grid>
            <Grid
              container
              justify="center"
              item
              xs={12}
              style={{ marginTop: 16 }}
            >
              <Typography
                style={{
                  fontWeight: 500,
                  fontSize: 12,
                  lineHeight: '15px',
                  color: '#222222',
                  textAlign: 'center'
                }}
              >
                If you have any problems with the QR code,
              </Typography>
            </Grid>
            <Grid
              container
              justify="center"
              item
              xs={12}
              style={{ marginTop: 5 }}
            >
              <Typography
                style={{
                  fontWeight: 500,
                  fontSize: 12,
                  lineHeight: '15px',
                  textAlign: 'center',
                  color: '#222222'
                }}
              >
                enter this code manually in to the app:
              </Typography>
            </Grid>
            <Grid
              container
              alignContent="center"
              justify="center"
              className={classes.qrSecrect}
            >
              <Typography data-test="qrcode-secret" className={classes.qrText}>
                {this.props.qrImage.secret}
              </Typography>
            </Grid>
          </Grid>
          <Grid
            container
            justify="center"
            alignContent="center"
            style={{ padding: 20 }}
          >
            <Grid container justify="center" alignContent="center">
              <Grid container justify="center" item xs={12}>
                <Typography style={{ textAlign: 'center' }} variant="subtitle2">
                  Enter the two-step Authentication code
                </Typography>
              </Grid>

              <TextField
                autoFocus={true}
                className={classes.textFiled}
                InputProps={{
                  className: classes.inputFiled
                }}
                value={this.state.code}
                inputProps={{
                  className: classes.code
                }}
                placeholder="_ _ _ _ _ _"
                onChange={this.handleTextChange}
                variant="outlined"
              />
            </Grid>
            <Grid
              container
              justify="center"
              alignItems="center"
              style={{ marginTop: 17 }}
            >
              <Button
                variant="contained"
                color="primary"
                size="large"
                className={classes.buttonCanel}
                onClick={this.closeDialog}
              >
                <span style={{ color: '#222222' }}> Cancel</span>
              </Button>
              <Button
                variant="contained"
                color="primary"
                size="large"
                disabled={this.state.code.length !== 6}
                onClick={this.verifyCode}
                className={classes.buttonLogin}
              >
                Verify
              </Button>
            </Grid>
          </Grid>
        </Paper>
      </Dialog>
    );
  }
}

const mapStateToProps = ({ user, code }: IStoreState) => ({
  qrImage: user.qr,
  code
});

const mapDispatchToProps = (dispatch: Dispatch<IUpdateNotification>) => {
  return {
    fireNotification: (noti: INotificationInfo) =>
      dispatch(fireNotification(noti))
  };
};
export default withStyles(styles)(
  connect(mapStateToProps, mapDispatchToProps)(TwoAuthentication)
);
