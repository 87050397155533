import React, { Dispatch } from 'react';
import {
  Paper,
  createStyles,
  WithStyles,
  withStyles,
  Typography,
  Grid,
  Theme
} from '@material-ui/core';
import SliderComponent from './shared/Slider.component';
import {
  IGetRankInfo,
  getRankInfoAction,
  IGetTomoPriceAction,
  getTomoPrice,
  IGetPaymentSumaryAction,
  getPaymentSumaryAction
} from '../scenes/dashboard/actions/payment.action';
import { connect } from 'react-redux';
const styles = (theme: Theme) =>
  createStyles({
    contentAccount: {
      height: 70,
      paddingLeft: 26,
      paddingRight: 26,
      borderBottom: '1px solid #F1F1F4',
      [theme.breakpoints.down('sm')]: {
        height: 50
      }
    },
    container: {
      height: 425,
      [theme.breakpoints.down('sm')]: {
        height: 300
      }
    }
  });

interface IProps extends WithStyles<typeof styles> {
  getRankInfoAction: () => void;
  getTomoPriceAction: () => void;
  getPaymentSumaryAction: () => void;
}
class CardRank extends React.Component<IProps> {
  componentDidMount() {
    this.props.getPaymentSumaryAction();
    this.props.getRankInfoAction();
    this.props.getTomoPriceAction();
  }
  public render() {
    const { classes } = this.props;
    return (
      <Paper elevation={0} className={classes.container}>
        <Grid container alignItems="center" className={classes.contentAccount}>
          <Typography variant="h3">Your rank</Typography>
        </Grid>
        <Grid style={{ paddingLeft: 20, paddingRight: 20, marginTop: 58 }}>
          <SliderComponent />
        </Grid>
      </Paper>
    );
  }
}
const mapDispatchToProps = (
  dispatch: Dispatch<
    IGetRankInfo | IGetTomoPriceAction | IGetPaymentSumaryAction
  >
) => {
  return {
    getRankInfoAction: () => dispatch(getRankInfoAction()),
    getTomoPriceAction: () => dispatch(getTomoPrice()),
    getPaymentSumaryAction: () => dispatch(getPaymentSumaryAction())
  };
};

export default withStyles(styles)(
  connect(undefined, mapDispatchToProps)(CardRank)
);
