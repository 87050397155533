import React, { ReactNode } from 'react';
import {
  Typography,
  Grid,
  createStyles,
  withStyles,
  Theme
} from '@material-ui/core';
import information_icon from '../../assets/images/information_icon.svg';
import TomoIcon from '../../assets/images/tomo.svg';
import { WithStyles } from '@material-ui/styles';
import classNames from 'classnames';
export interface IItem {
  title: ReactNode;
  value: ReactNode;
}

interface IProps extends WithStyles<typeof style> {
  items: IItem;
  type: ITEM_TYPE;
}

export enum ITEM_TYPE {
  DEFAULT = 0,
  ICON_TITLE = 1,
  ICON_VALUE = 2,
  COLOR_VALUE = 3,
  BIG_VALUE = 4
}

const style = (theme: Theme) =>
  createStyles({
    paddingTop: {
      paddingTop: 3
    },

    responsiveText: {
      [theme.breakpoints.down(1025)]: {
        fontSize: 12
      }
    },
    title: {
      [theme.breakpoints.down(1025)]: {
        fontSize: 9
      }
    }
  });
// Table without header, value is a pair of title-value
class TableDataRaw extends React.Component<IProps> {
  renderItem = () => {
    const { items, type, classes } = this.props;
    if (!items) {
      return;
    }
    switch (type) {
      case ITEM_TYPE.DEFAULT: {
        return (
          <Grid>
            <Typography
              className={classes.title}
              variant="subtitle1"
              style={{ color: '#222222' }}
            >
              {items.title}
            </Typography>
            <Typography
              variant="h2"
              style={{ color: '#FFFFFF', fontWeight: 500 }}
              className={classNames(classes.responsiveText, classes.paddingTop)}
            >
              {items.value}
            </Typography>
          </Grid>
        );
      }
      case ITEM_TYPE.ICON_TITLE: {
        return (
          <Grid>
            <Grid
              className={classes.title}
              container
              style={{ flexDirection: 'row' }}
            >
              <Typography variant="subtitle1">{items.title}</Typography>
              <img
                src={information_icon}
                style={{ width: 12, height: 12, paddingLeft: 7 }}
                alt="infomation icon"
              />
            </Grid>
            <Grid item className={classes.paddingTop}>
              <Typography
                variant="subtitle2"
                className={classes.responsiveText}
              >
                {items.value}
              </Typography>
            </Grid>
          </Grid>
        );
      }
      case ITEM_TYPE.ICON_VALUE: {
        return (
          <Grid container justify="flex-end">
            <Grid item>
              <Typography
                className={classes.title}
                variant="subtitle1"
                style={{ color: '#222222' }}
              >
                {items.title}
              </Typography>
            </Grid>
            <Grid
              container
              justify="flex-end"
              alignItems="center"
              style={{ flexDirection: 'row' }}
              className={classes.paddingTop}
            >
              <img
                src={TomoIcon}
                style={{ width: 20, height: 20, paddingRight: 5 }}
                alt="btc white"
              />
              <Typography
                variant="h2"
                className={classes.responsiveText}
                style={{ color: '#FFFFFF', fontWeight: 500 }}
              >
                {items.value}
              </Typography>
            </Grid>
          </Grid>
        );
      }
      case ITEM_TYPE.BIG_VALUE: {
        return (
          <Grid>
            <Typography className={classes.title} variant="subtitle1">
              {items.title}
            </Typography>
            <Typography
              className={classes.responsiveText}
              variant="h2"
              style={{ paddingTop: 4, fontWeight: 500 }}
            >
              {items.value}
            </Typography>
          </Grid>
        );
      }
      case ITEM_TYPE.COLOR_VALUE: {
        return (
          <Grid>
            <Typography className={classes.title} variant="subtitle1">
              {items.title}
            </Typography>
            <Typography
              variant="subtitle2"
              className={classes.responsiveText}
              style={{ color: '#E3001E', paddingTop: 4 }}
            >
              {items.value}
            </Typography>
          </Grid>
        );
      }
    }
  };
  render() {
    return this.renderItem();
  }
}

export const TableDataRawComponent = withStyles(style)(TableDataRaw);
