import React from 'react';
import { createStyles, Grid, Theme } from '@material-ui/core';
import { WithStyles, withStyles } from '@material-ui/styles';
import CardProfileComponent from '../../../components/CardProfile.component';
import CardAccountComponent from '../../../components/CardAccount.component';
import TitlePageComponent from '../../../components/TitlePage.component';
import CardRank from '../../../components/CardRank.component';
import { History } from 'history';
import { withRouter } from 'react-router';
import { connect } from 'react-redux';
import { ROLE } from '../../../constants/auth.constant';

const styles = (theme: Theme) =>
  createStyles({
    profile: {
      paddingRight: 10,
      [theme.breakpoints.down('sm')]: {
        marginTop: 30,
        padding: 0
      }
    },
    account: {
      paddingLeft: 10,
      paddingRight: 10,
      [theme.breakpoints.down('sm')]: {
        padding: 0,
        marginTop: 30
      }
    },
    rankInfo: {
      paddingLeft: 10,
      [theme.breakpoints.down('sm')]: {
        padding: 0,
        marginTop: 30
      }
    }
  });
interface IProps extends WithStyles<typeof styles> {
  history: History;
}

class Settings extends React.Component<IProps> {
  public render() {
    const { classes } = this.props;
    return (
      <>
        <Grid style={{ marginBottom: 9 }}>
          <TitlePageComponent title="Profile & Account" />
        </Grid>
        <Grid container>
          <Grid item xs={12} md={4} className={classes.profile}>
            <CardProfileComponent />
          </Grid>
          <Grid item xs={12} md={4} className={classes.account}>
            <CardAccountComponent
              role={ROLE.AGENT}
              history={this.props.history}
            />
          </Grid>
          <Grid item xs={12} md={4} className={classes.rankInfo}>
            <CardRank />
          </Grid>
        </Grid>
      </>
    );
  }
}

export default withRouter(
  connect(undefined, undefined)(withStyles(styles)(Settings))
);
