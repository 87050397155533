import { ISetUserProfile, ISetQrImage, ISetCountries } from '../actions/user';
import {
  SET_USER_PROFILE,
  SET_QR_IMAGE,
  SET_COUNTRIES
} from '../actions/constant';
import { IUser, initUser } from '../types';

export const user = (
  state: IUser = initUser,
  action: ISetUserProfile | ISetQrImage | ISetCountries
): IUser => {
  switch (action.type) {
    case SET_USER_PROFILE: {
      return { ...state, profile: action.payload };
    }
    case SET_COUNTRIES: {
      return { ...state, countries: action.payload };
    }
    case SET_QR_IMAGE: {
      return { ...state, qr: action.payload };
    }
  }
  return state;
};
