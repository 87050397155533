import { ITableSettings } from '../../../../../components/shared/table/redux/types';

export const HEADER_TEXT = {
  fontFamily: 'Montserrat',
  fontWeight: '500',
  fontSize: '12px',
  lineHeight: '12px',
  color: '#222222'
};

export const tableKickbackSettings: ITableSettings = {
  pagination: {
    show: false,
    option: {
      rowsPerPage: 10
    }
  },
  tableColumn: [
    {
      label: 'initial kickback (%)',
      key: `initKickback`,
      width: '11%',
      styles: HEADER_TEXT,
      editing: true
    },
    {
      label: 'term kickback (%)',
      key: 'termKickback',
      width: '11%',
      styles: HEADER_TEXT,
      editing: true
    }
  ]
};

export const tableFeeSettings: ITableSettings = {
  pagination: {
    show: false,
    option: {
      rowsPerPage: 10
    }
  },
  tableColumn: [
    {
      label: 'Admin charge (1) (With invite code) (%)',
      key: `adminChargeWithCode`,
      width: '11%',
      styles: HEADER_TEXT,

      editing: true
    },
    {
      label: 'Admin charge (2) (Without invite code) (%)',
      key: `adminCharge`,
      width: '11%',
      styles: HEADER_TEXT,

      editing: true
    },
    {
      label: 'Asset fee (3) (With invite code) (%)',
      key: `assetFeeWithCode`,
      width: '11%',
      styles: HEADER_TEXT,
      editing: true
    },
    {
      label: 'Asset fee (4) (Without invite code) (%)',
      key: 'assetFee',
      width: '11%',
      styles: HEADER_TEXT,

      editing: true
    },
    {
      label: 'Total fee = (1) + (3) (With invite code) (%)',
      key: 'totalFeeWithCode',
      width: '11%',
      styles: HEADER_TEXT
    },
    {
      label: 'Total fee = (2) + (4) (Without invite code) (%)',
      key: 'totalFee',
      width: '11%',
      styles: HEADER_TEXT
    }
  ]
};
