import React from 'react';
import { withStyles, WithStyles, Theme } from '@material-ui/core/styles';
import Dialog from '@material-ui/core/Dialog';
import { createStyles, Grid, Button, Typography } from '@material-ui/core';

interface IProps extends WithStyles<typeof styles> {
  showDialog: boolean;
  handleCloseDialog: () => void;
  handleOkButton: () => void;
  textHeader: string;
  textContent: string;
}
const styles = (theme: Theme) =>
  createStyles({
    dialogTitle: {
      padding: '30px 0',
      alignItems: 'center',
      width: '100%',
      borderBottom: '1px solid #DCE0E4',
      [theme.breakpoints.down('sm')]: {
        padding: 20
      },
      flexDirection: 'column'
    },
    closeButton: {
      position: 'absolute',
      right: 30,
      top: 28,
      color: 'black'
    },
    dialogContent: {
      height: 'calc(100% - 200px )',
      paddingLeft: 30,
      flexDirection: 'column',
      alignItems: 'center',
      paddingRight: 30,
      [theme.breakpoints.down('sm')]: {
        padding: '0 20px'
      }
    },
    dialogActions: {
      height: 100,
      borderTop: '1px solid #DCE0E4'
    },
    buttonYesWithdraw: {
      width: 200,
      height: 50,
      textTransform: 'capitalize',
      [theme.breakpoints.down('sm')]: {
        width: '80%'
      }
    },
    buttonNoWithdraw: {
      width: 200,
      height: 50,
      textTransform: 'capitalize',
      backgroundColor: '#C5021C',
      '&:hover': {
        backgroundColor: '#C5021C'
      },
      [theme.breakpoints.down('sm')]: {
        width: '80%'
      }
    },
    textWithDraw: {
      fontSize: 20,
      color: '#000000',
      lineHeight: '24px',
      fontWeight: 600,
      textAlign: 'center',
      [theme.breakpoints.down('sm')]: {
        fontSize: 14
      }
    },
    dialogLayout: {
      [theme.breakpoints.down('sm')]: {
        width: '80%',
        padding: '0 10%'
      }
    },
    titleText: {
      display: 'flex',
      flex: 1,
      textAlign: 'center',
      justifyContent: 'center',
      [theme.breakpoints.down('sm')]: {
        fontSize: 18
      }
    },
    root: {
      height: 400,
      [theme.breakpoints.down('xs')]: {
        height: 300
      }
    }
  });

class Confirm extends React.Component<IProps> {
  public render() {
    const {
      showDialog,
      handleCloseDialog,
      classes,
      textHeader,
      textContent
    } = this.props;
    return (
      <Dialog
        onClose={handleCloseDialog}
        aria-labelledby="customized-dialog-title"
        open={showDialog}
        maxWidth="md"
        className={classes.dialogLayout}
      >
        <Grid
          container
          justify="center"
          alignItems="center"
          className={classes.root}
        >
          <Grid className={classes.dialogTitle}>
            <Grid
              container
              justify="center"
              alignContent="center"
              style={{
                height: '100%',
                position: 'relative'
              }}
            >
              <Typography className={classes.titleText} variant="h2">
                {textHeader}
              </Typography>
            </Grid>
          </Grid>
          <Grid
            container
            justify="center"
            alignContent="center"
            className={classes.dialogContent}
          >
            <Typography className={classes.textWithDraw}>
              {textContent}
            </Typography>
          </Grid>
          <Grid
            container
            justify="center"
            alignItems="center"
            className={classes.dialogActions}
          >
            <Grid
              container
              justify="flex-end"
              xs={6}
              item
              style={{ paddingRight: 5 }}
            >
              <Button
                className={classes.buttonYesWithdraw}
                onClick={this.props.handleOkButton}
                variant="contained"
                color="secondary"
              >
                OK
              </Button>
            </Grid>
            <Grid xs={6} item style={{ paddingLeft: 5 }}>
              <Button
                className={classes.buttonNoWithdraw}
                variant="contained"
                color="secondary"
                onClick={this.props.handleCloseDialog}
              >
                Cancel
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </Dialog>
    );
  }
}

export default withStyles(styles)(Confirm);
