import React from 'react';
import {
  Tabs,
  Tab,
  createStyles,
  WithStyles,
  withStyles,
  Typography,
  Paper,
  Grid
} from '@material-ui/core';
import { TraddingAccountTab } from './tradingAccount.container';

const styles = createStyles({
  tabRoot: {
    backgroundColor: '#FAFAFB',
    textTransform: 'capitalize'
  },
  selected: {
    color: 'blue'
  }
});
interface IState {
  value: number;
}

export interface ITabAccount {
  label: string;
  index: number;
}

interface IProps extends WithStyles<typeof styles> {
  tabAccount: ITabAccount[];
}
class TradingBox extends React.Component<IProps, IState> {
  state: IState = {
    value: 0
  };

  handleChange = (event: React.ChangeEvent<{}>, value: number) => {
    this.setState({ value });
  };

  renderTabAccount = () => {
    return this.props.tabAccount.map(e => (
      <Tab
        style={
          this.state.value === e.index
            ? {
                backgroundColor: '#FFFFFF',
                minWidth: '33.33%'
              }
            : { minWidth: '33.33%' }
        }
        key={e.index}
        label={
          <Typography
            variant="h4"
            style={
              this.state.value === e.index
                ? {
                    fontWeight: 600,
                    textTransform: 'capitalize',
                    color: '#00AFC1'
                  }
                : {
                    fontWeight: 600,
                    textTransform: 'capitalize',
                    color: '#9BA0A6'
                  }
            }
          >
            {e.label}
          </Typography>
        }
      />
    ));
  };
  render() {
    const { classes } = this.props;
    return (
      <Paper elevation={0}>
        <Grid>
          <Tabs
            TabIndicatorProps={{
              style: {
                display: 'none'
              }
            }}
            style={{ justifyContent: 'flex-start' }}
            className={classes.tabRoot}
            value={this.state.value}
            onChange={this.handleChange}
            aria-label="simple tabs example"
          >
            {this.renderTabAccount()}
          </Tabs>
        </Grid>
        <Grid style={{ paddingTop: 10 }}>
          <TraddingAccountTab />
        </Grid>
      </Paper>
    );
  }
}

export const TradingBoxComponent = withStyles(styles)(TradingBox);
