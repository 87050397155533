import React, { Dispatch } from 'react';
import {
  Typography,
  Theme,
  createStyles,
  WithStyles,
  withStyles,
  Dialog,
  Paper,
  Grid,
  Button,
  TextField,
  FormControl,
  InputAdornment,
  IconButton
} from '@material-ui/core';
import {
  IAddAgentAccountAction,
  addAgentAccountAction
} from '../../scenes/admin/components/Top/actions';
import { IAddAgentAccountRequest } from '../../scenes/admin/components/Top/types';
import { connect } from 'react-redux';
import { validateEmail, validateTomoAddress } from '../../helper';
import iconSussesTextFied from '../../assets/images/iconSussesTextFied.svg';
import iconError from '../../assets/images/iconError.svg';

const styles = (theme: Theme) =>
  createStyles({
    rootLogin: {
      width: 500,
      height: 450,
      [theme.breakpoints.down('sm')]: {
        width: '80%',
        padding: '0 10%',
        height: 380
      }
    },
    titleHeader: {
      height: 100,
      [theme.breakpoints.down('sm')]: {
        height: 60
      },
      borderBottom: '1px solid #F1F1F4'
    },
    bodyPager: {
      height: 'calc(100% - 220px )',
      [theme.breakpoints.down('sm')]: {
        height: 'calc(100% - 180px)',
        [theme.breakpoints.down('xs')]: {
          height: 200
        }
      }
    },
    buttonCanel: {
      color: '#222222',
      border: '1px solid #DCE0E4',
      width: 195,
      textTransform: 'capitalize',
      marginRight: 5,
      backgroundColor: '#FFFFFF',
      '&:hover': {
        backgroundColor: '#FFFFFF'
      },
      [theme.breakpoints.down('xs')]: {
        width: '80%',
        flex: 1
      }
    },
    buttonLogin: {
      width: 195,
      textTransform: 'capitalize',
      marginLeft: 5,
      [theme.breakpoints.down('xs')]: {
        width: '80%',
        flex: 1
      }
    },

    textField: {
      backgroundColor: '#FFFFFF',
      textTransform: 'uppercase',
      '& :disabled': {
        color: '#000000'
      },
      [theme.breakpoints.down('xs')]: {
        width: '100%'
      }
    },
    formControl: {
      marginTop: 8
    },

    inputLabelProps: {
      color: '#9BA0A6 !important',
      fontSize: 12,
      fontWeight: 600,
      lineHeight: '12px',
      textTransform: 'uppercase'
    },

    inputAdornment: {
      '& .MuiIconButton-root': {
        '&:hover': {
          backgroundColor: 'inherit'
        }
      }
    },
    dialogLayout: {
      [theme.breakpoints.down('xs')]: {
        width: '80%',
        padding: '0 10%'
      }
    }
  });

interface IProps extends WithStyles<typeof styles> {
  showDialog: boolean;
  addAgentAccountAction: (request: IAddAgentAccountRequest) => void;
  handleCloseDialog: () => void;
}
interface IError {
  isError: boolean;
  message: string;
}
interface IState {
  emailError: IError;
  tomoAddressError: IError;
  email: string;
  tomoAddress: string;
}

class AddAgent extends React.Component<IProps> {
  state: IState = {
    email: '',
    tomoAddress: '',
    emailError: {
      isError: false,
      message: ''
    },
    tomoAddressError: {
      isError: false,
      message: ''
    }
  };
  handleEmailChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const email = event.target.value;
    let emailError = {
      isError: false,
      message: ''
    };
    if (!email.match(validateEmail)) {
      emailError = {
        isError: true,
        message: 'Invalid Email format.'
      };
    }
    this.setState({ ...this.state, email, emailError });
  };

  handleTomoAddressChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const tomoAddress = event.target.value;
    let tomoAddressError = {
      isError: false,
      message: ''
    };
    if (!tomoAddress.match(validateTomoAddress)) {
      tomoAddressError = {
        isError: true,
        message: 'Invalid tomo address format.'
      };
    }
    this.setState({ ...this.state, tomoAddress, tomoAddressError });
  };

  handleCloseError = (key: string, errorKey: string) => (event: any) => {
    if (
      (errorKey === 'emailError' && !this.state.emailError.isError) ||
      (errorKey === 'tomoAddressError' && !this.state.tomoAddressError.isError)
    ) {
      return;
    }

    this.setState({
      ...this.state,
      [key]: '',
      [errorKey]: {
        isError: false,
        message: ''
      }
    } as any);
  };

  cancelAction = () => {
    this.props.handleCloseDialog();
    this.setState({
      email: '',
      tomoAddress: '',
      emailError: {
        isError: false,
        message: ''
      },
      tomoAddressError: {
        isError: false,
        message: ''
      }
    });
  };
  addAccount = () => {
    this.props.addAgentAccountAction({
      email: this.state.email,
      tomoAddress: this.state.tomoAddress
    });
    this.props.handleCloseDialog();
    this.setState({
      email: '',
      tomoAddress: '',
      emailError: {
        isError: false,
        message: ''
      },
      tomoAddressError: {
        isError: false,
        message: ''
      }
    });
  };
  public render() {
    const { showDialog, classes } = this.props;
    const { email, tomoAddress, tomoAddressError, emailError } = this.state;
    return (
      <Dialog
        onClose={this.props.handleCloseDialog}
        aria-labelledby="customized-dialog-title"
        open={showDialog}
        className={classes.dialogLayout}
        maxWidth="md"
      >
        <Paper elevation={1} className={classes.rootLogin}>
          <Grid
            container
            alignItems="center"
            justify="center"
            className={classes.titleHeader}
          >
            <Typography variant="h2">Add new Agent</Typography>
          </Grid>
          <Grid
            container
            justify="center"
            alignItems="center"
            style={{ flexDirection: 'column' }}
            className={classes.bodyPager}
          >
            <FormControl required={true}>
              <TextField
                InputLabelProps={{
                  className: classes.inputLabelProps
                }}
                className={classes.textField}
                value={email}
                helperText={emailError.message}
                error={emailError.isError}
                label="Email address"
                variant="outlined"
                onChange={this.handleEmailChange}
                InputProps={{
                  endAdornment: email ? (
                    <InputAdornment
                      position="end"
                      onClick={this.handleCloseError('email', 'emailError')}
                      className={classes.inputAdornment}
                    >
                      <IconButton aria-label="Toggle password visibility">
                        {!emailError.isError ? (
                          <img
                            src={iconSussesTextFied}
                            alt="error tomo"
                            width={20}
                            height={20}
                          />
                        ) : null}
                        {emailError.isError ? (
                          <img
                            src={iconError}
                            alt="error tomo"
                            width={20}
                            height={20}
                          />
                        ) : null}
                      </IconButton>
                    </InputAdornment>
                  ) : (
                    ''
                  )
                }}
              />
            </FormControl>

            <FormControl required={true}>
              <TextField
                InputLabelProps={{
                  className: classes.inputLabelProps
                }}
                style={{ marginTop: 40 }}
                className={classes.textField}
                value={tomoAddress}
                helperText={tomoAddressError.message}
                error={tomoAddressError.isError}
                label="Tomo address"
                onChange={this.handleTomoAddressChange}
                variant="outlined"
                InputProps={{
                  endAdornment: tomoAddress ? (
                    <InputAdornment
                      position="end"
                      onClick={this.handleCloseError(
                        'tomoAddress',
                        'tomoAddressError'
                      )}
                      className={classes.inputAdornment}
                    >
                      <IconButton aria-label="Toggle password visibility">
                        {!tomoAddressError.isError ? (
                          <img
                            src={iconSussesTextFied}
                            alt="error tomo"
                            width={20}
                            height={20}
                          />
                        ) : null}
                        {tomoAddressError.isError ? (
                          <img
                            src={iconError}
                            alt="error tomo"
                            width={20}
                            height={20}
                          />
                        ) : null}
                      </IconButton>
                    </InputAdornment>
                  ) : (
                    ''
                  )
                }}
              />
            </FormControl>
          </Grid>
          <Grid
            container
            justify="center"
            alignItems="center"
            style={{ borderTop: '1px solid #F1F1F4', height: 120 }}
          >
            <Button
              variant="contained"
              color="primary"
              size="large"
              className={classes.buttonCanel}
              onClick={this.cancelAction}
            >
              <span style={{ color: '#222222' }}> Cancel</span>
            </Button>
            <Button
              variant="contained"
              color="primary"
              size="large"
              disabled={
                emailError.isError ||
                tomoAddressError.isError ||
                !email ||
                !tomoAddress
              }
              onClick={this.addAccount}
              className={classes.buttonLogin}
            >
              Add Agent
            </Button>
          </Grid>
        </Paper>
      </Dialog>
    );
  }
}

const mapDispatchToProps = (dispatch: Dispatch<IAddAgentAccountAction>) => {
  return {
    addAgentAccountAction: (request: IAddAgentAccountRequest) =>
      dispatch(addAgentAccountAction(request))
  };
};

export default connect(
  undefined,
  mapDispatchToProps
)(withStyles(styles)(AddAgent));
