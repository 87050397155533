import React from 'react';
import {
  createStyles,
  WithStyles,
  withStyles,
  Grid,
  Typography,
  FormControl,
  TextField,
  MenuItem
} from '@material-ui/core';
import CardPaymentHistoryAdminComponent from '../components/CardPaymentHistoryAdmin.component';
import { ICardPaymentHistoryInfo } from '../scenes/dashboard/types/PaymentHistoryAdmin.type';
import { History } from 'history';
const styles = () =>
  createStyles({
    formControl: {
      marginTop: 20
    },
    inputProps: {
      color: '#00AFC1',
      fontWeight: 600,
      fontSize: 24,
      lineHeight: '17px'
    },
    textField: {
      width: '100%',
      textTransform: 'uppercase',
      '& :disabled': {
        color: '#000000'
      }
    }
  });
const dataEx: ICardPaymentHistoryInfo[] = [
  {
    id: '1',
    name: 'Term 1',
    totalHolders: 10,
    totalHolderVolume: 11,
    fitProfit: 12,
    profitRate: 13
  },
  {
    id: '2',
    name: 'Term 2',
    totalHolders: 20,
    totalHolderVolume: 21,
    fitProfit: 22,
    profitRate: 23
  },
  {
    id: '3',
    name: 'Term 3',
    totalHolders: 30,
    totalHolderVolume: 31,
    fitProfit: 32,
    profitRate: 33
  },
  {
    id: '4',
    name: 'Term 4',
    totalHolders: 40,
    totalHolderVolume: 41,
    fitProfit: 42,
    profitRate: 43
  },
  {
    id: '5',
    name: 'Term 5',
    totalHolders: 50,
    totalHolderVolume: 51,
    fitProfit: 52,
    profitRate: 53
  },
  {
    id: '5',
    name: 'Term 6',
    totalHolders: 50,
    totalHolderVolume: 51,
    fitProfit: 52,
    profitRate: 53
  }
];

const dataEXSummary = [
  {
    id: '1',
    name: '2019'
  },
  {
    id: '2',
    name: '2018'
  },
  {
    id: '3',
    name: '2017'
  }
];

interface IProps extends WithStyles<typeof styles> {
  history: History;
}

interface IState {
  summary: string;
}
class ListCardPaymentHistoryAdmin extends React.Component<IProps, IState> {
  state: IState = {
    summary: '1'
  };

  handleItemChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({ summary: event.target.value });
  };
  public render() {
    const { classes } = this.props;
    return (
      <div>
        <Grid
          container
          style={{
            marginBottom: 10
          }}
        >
          <Typography variant="h2">Summary</Typography>
          <FormControl
            required={true}
            style={{
              marginLeft: 10
            }}
          >
            <TextField
              style={{
                width: 90
              }}
              select
              onChange={this.handleItemChange}
              value={this.state.summary}
              inputProps={{
                className: classes.inputProps
              }}
              InputProps={{ disableUnderline: true }}
            >
              {dataEXSummary.map(e => (
                <MenuItem key={e.id} value={e.id}>
                  {e.name}
                </MenuItem>
              ))}
            </TextField>
          </FormControl>
        </Grid>
        <Grid container>
          {dataEx.map((el, key) => (
            <Grid
              item
              lg={4}
              md={6}
              key={key}
              style={{
                marginBottom: 10,
                paddingRight: 10
              }}
            >
              <CardPaymentHistoryAdminComponent data={el} />
            </Grid>
          ))}
        </Grid>
      </div>
    );
  }
}

export default withStyles(styles)(ListCardPaymentHistoryAdmin);
